import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  GET_CITY_LIST_WITH_STATE_STARTED,
  GET_CITY_LIST_WITH_STATE_SUCCESS,
  GET_CITY_LIST_WITH_STATE_FAILED,
} from "../../../../constants/action-types";
import { REPORTED_LOCATION_WITH_ZIP_URL } from "../../../../constants/configurations";

export const getCityListWithStateAction = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: REPORTED_LOCATION_WITH_ZIP_URL(body),
        started: GET_CITY_LIST_WITH_STATE_STARTED,
        success: GET_CITY_LIST_WITH_STATE_SUCCESS,
        failure: GET_CITY_LIST_WITH_STATE_FAILED,
      },
      callBack
    );
  };
};
