import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  UPDATE_USER_FLIGHT_SUCCESS,
  UPDATE_USER_FLIGHT_FAILED,
  UPDATE_USER_FLIGHT_STARTED
} from "../../../../constants/action-types";
import { UPDATE_USER_FLIGHT_URL } from "../../../../constants/configurations";

export const updateUserFlightsAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "patch",
        api: UPDATE_USER_FLIGHT_URL(body.id),
        started: UPDATE_USER_FLIGHT_STARTED,
        success: UPDATE_USER_FLIGHT_SUCCESS,
        failure: UPDATE_USER_FLIGHT_FAILED
      },
      callBack
    );
  };
};
