/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import Draggable from "react-draggable";
import DragBottomContainers from "../../commons/dragBottomContainers";
import queryString from "query-string";
import { mobileFilterText } from "../../../constants/text-constants";
import "./style.css";
import moment from "moment";
import { abbreviate } from "../../../utils/abbreviate";
import { formatNumber } from "../../../utils/numberFormatters";
import { StatusLoader } from "../../commons/loader";

/**svg icons  */
import worldIcon from "../../../assets/images/globe.svg";
import AddActivity from "../AddActivity";
import { getHeightWithId } from "../../../utils/getHeightWithId";

const { allCountry, allProvince } = mobileFilterText;
export default class MobileViewSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      showReports: false,
      searchText: "",
      dragPositionTop: -100,
      dragStarted: false,
      dragYpos: 0,
    };
  }
  componentDidMount() {
    const parsed = queryString.parse(this.props.location.search);

    if (parsed.country && parsed.province) {
      this.setState(
        {
          showReports: true,
        },
        () => {
          this.setState({
            dragPositionTop: this.getReportedLocationHeight(),
          });
        }
      );
    } else {
      this.setState({
        dragPositionTop: this.getCountryFilterHeight(),
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.reducer.reportedLocation !== prevProps.reducer.reportedLocation
    ) {
      let { showReports } = this.state;
      if (this.props.reducer.reportedLocation && showReports) {
        this.setState({
          dragPositionTop: this.getReportedLocationHeight(),
        });
      }
    }
  }
  toggleStates = (name) => {
    let stateType = this.state[name];
    this.setState({
      [name]: !stateType,
    });
  };

  mutateState = (params) => {
    let { name, value } = params;
    this.setState({
      [name]: value,
    });
  };
  assignFilterToUrl = (item) => {
    let { type, value } = item;
    const parsed = queryString.parse(this.props.location.search);
    let body = {};
    /**toggle to show reported locations */
    this.props.handleChange({
      name: "mapLoading",
      value: true,
    });
    if (type === "province") {
      this.toggleStates("showReports");
    }
    /**the below clears the filter  */
    if (value === allCountry) {
      /**all filter are removed  */
      body = {};
    } else if (value === allProvince) {
      /**provinces are cleared and set to all */
      body.country = parsed.country;
      delete body.province;
    } else if (type === "country") {
      body[type] = value;
    } else {
      body[type] = value;
      body = { ...parsed, ...body };
    }
    let urlString = queryString.stringify({
      ...body,
    });

    this.props.history.push({
      search: urlString,
    });
  };

  clearAllFilter = () => {
    let urlString = queryString.stringify({});
    this.props.history.push({
      search: urlString,
    });
  };
  generateDropItem = (params) => {
    let { fieldName, array, type } = params;
    return array.map((item, index) => {
      if (item[fieldName] === allCountry) {
        return (
          <li
            className="mb-2 worldwide editable"
            key={index}
            onClick={() =>
              this.assignFilterToUrl({ value: item[fieldName], type })
            }
          >
            <span
              className="large com-drop sign-up-drp"
              data-value="option0"
              tabindex="-1"
            >
              <img src={worldIcon} className="mb-1 mr-2" alt={"world"} />
              {item[fieldName]}
            </span>
          </li>
        );
      } else if (item[fieldName] === allProvince) {
        return (
          <li className="mb-2 editable" key={index}>
            <span
              className="large com-drop sign-up-drp"
              data-value="option0"
              tabindex="-1"
            >
              {item[fieldName]}
            </span>
          </li>
        );
      } else
        return (
          <li
            className="mb-2 editable"
            key={index}
            onClick={() =>
              this.assignFilterToUrl({ value: item[fieldName], type })
            }
          >
            <span
              className="large com-drop sign-up-drp"
              data-value="option0"
              tabindex="-1"
            >
              {item[fieldName]}
              <span className="pull-right">{formatNumber(item.count)}</span>
            </span>
          </li>
        );
    });
  };

  showWorldIcon = (text) => {
    if (text === allCountry) {
      return (
        <>
          <img src={worldIcon} className="mb-1 mr-2" alt={"world"} />
          {text}
        </>
      );
    } else return text;
  };
  renderSelectOptions = (params) => {
    let { initialText, className, value, disabled } = params;
    let provinceList = this.generateDropItem(params);
    return (
      <>
        <button
          type="button"
          disabled={disabled}
          className={className}
          data-toggle="dropdown"
          aria-expanded="false"
          required
        >
          <span className="glyphicon glyphicon-cog"></span>
          {value.length ? value : this.showWorldIcon(initialText)}
          <span className="caret"></span>
        </button>
        <ul
          className={`dropdown-menu self-inp2 text-white mob-country-drp-dwn w-100 pt-0 pb-0 ${
            provinceList.length ? "" : "emptyList"
          }`}
        >
          {provinceList}
        </ul>
      </>
    );
  };
  renderOptions = (params) => {
    let { fieldName, array, className, type, value, disabled } = params;
    let optionsList = array.map((item) => {
      return <option>{item[fieldName]}</option>;
    });
    return (
      <select
        disabled={disabled}
        onChange={(e) =>
          this.assignFilterToUrl({ value: e.target.value, type })
        }
        value={value}
        className={className}
      >
        {optionsList}
      </select>
    );
  };
  renderCountrySelectOptions = (params) => {
    let { disabled } = params;
    let { affectedCountry } = this.props.reducer;
    let list = [{ name: allCountry, clear: true }].concat(
      affectedCountry.countryList
    );
    const parsed = queryString.parse(this.props.location.search);
    let value = "";
    if (parsed.country) {
      value = parsed.country;
    }
    return this.renderSelectOptions({
      fieldName: "name",
      disabled,
      array: list,
      type: "country",
      initialText: allCountry,
      value,
      className:
        "w-100 p-2 text-left rounded mb-12 Gilroy-Semibold form-control self-inp editable",
    });
  };
  renderStateDropDown = (params) => {
    let { disabled } = params;
    let { affectedCountry } = this.props.reducer;
    let list = [{ Province_State: allProvince, clear: true }].concat(
      affectedCountry.provinceStates
    );
    const parsed = queryString.parse(this.props.location.search);
    let value = "";
    if (parsed.province) {
      value = parsed.province;
    }
    console.log("list", list);
    return this.renderSelectOptions({
      fieldName: "Province_State",
      array: list,
      disabled: disabled || list.length===1,
      type: "province",
      initialText: allProvince,
      value,
      className:
        "w-100 p-2 text-left rounded mb-20 Gilroy-Semibold form-control self-inp editable",
    });
  };

  handleZipTextChange = (e) => {
    let { value } = e.target;
    this.props.handleChange({ name: "zipSearchText", value });
  };
  onUserSearchZip = (event) => {
    if (event.key === "Enter") {
      this.props.onSubmitZipSearch();
    }
  };
  listReportedLocation = (array) => {
    if (array && array.length) {
      let reportedLocList = array.map((reportInfo) => {
        let {
          id,
          location,
          first_exposure_date,
          latest_exposure_date,
          exposure,
        } = reportInfo;
        let lastDate = moment().diff(latest_exposure_date, "days");
        let dateString = lastDate ? `${lastDate} day ago` : "today";
        return (
          <div
            key={id}
            style={{ scrollbarWidth: "none !important" }}
            className="d-flex align-items-stretch item-center scroller-content-wrap editable"
          >
            <div className="user-int"> {abbreviate(location, 1, true)}</div>
            <div className="Exposure-txt">
              <h4> {abbreviate(location, 24)}</h4>
              <p title={location}>{abbreviate(location, 24)}</p>
              <h6>
                First Exposure Date: {moment(first_exposure_date).format("L")}
              </h6>
            </div>
            <div className="Exposure-location-info">
              <h4>Store</h4>
              <p>{dateString}</p>
              <h6>
                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                {formatNumber(exposure)}
              </h6>
            </div>
          </div>
        );
      });
      return <div className="scroller">{reportedLocList}</div>;
    } else return "No Reported Location";
  };

  renderRegionSelector = () => {
    let { mapLoaded } = this.props.reducer;
    const parsed = queryString.parse(this.props.location.search);

    return (
      <>
        <div className="mob-cont-sel-pad">
          <h6 className="fnt-14 Gilroy-Medium mob-chos-reg">
            CHOOSE A REGION
            {parsed.country && (
              <span
                className="fnt-14 Gilroy-Medium pull-right editable"
                onClick={() => {
                  this.clearAllFilter();
                }}
              >
                Clear All Filter
              </span>
            )}
          </h6>

          {this.renderCountrySelectOptions({ disabled: !mapLoaded })}
          {parsed.country ? (
            <>
              <div className="col-8 pull-left">
                <div className="row ">
                  {this.renderStateDropDown({ disabled: !mapLoaded })}
                </div>
              </div>
              <div className="col-4 pull-left pr-0 pl-12">
                <input
                  type="text"
                  className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control editable mob-zip-ht"
                  placeholder="Zip Code"
                  onKeyPress={(e) => {
                    this.onUserSearchZip(e);
                  }}
                  onChange={(e) => this.handleZipTextChange(e)}
                />
              </div>
            </>
          ) : (
            []
          )}
        </div>
      </>
    );
  };
  toggleLocation = () => {
    let { showReports } = this.state;
    let height = -100;
    if (showReports) height = this.getCountryFilterHeight();
    else height = this.getReportedLocationHeight();
    this.setState({
      dragYpos: 0,
    });
    this.setState(
      {
        dragStarted: false,
      },
      () => {
        this.setState(
          {
            dragPositionTop: height,
          },
          () => {
            this.setState({
              showReports: !showReports,
            });
          }
        );
      }
    );
  };
  renderReportedLocationBlock = () => {
    let { reportedLocation } = this.props.reducer;
    let { list, loading } = reportedLocation;
    // let { searchText } = this.state;
    let { mapLoaded } = this.props.reducer;
    list = list.filter((item) => item.is_infected);
    let locationList = list;
    return (
      <div className="choose-loc-data" id="report-location">
        <h6 className="fnt-14 Gilroy-Medium text-uppercase mob-chos-reg pull-left">
          REPORTED LOCATIONS
        </h6>
        <a
          className="fnt-14 Gilroy-Regular nav-p mob-chos-reg pull-right editable"
          onClick={() => {
            this.toggleLocation();
          }}
        >
          Clear filter
        </a>
        <input
          type="text"
          className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control editable"
          placeholder="Zip Code"
          disabled={!mapLoaded}
          name={"searchText"}
          onKeyPress={(e) => {
            this.onUserSearchZip(e);
          }}
          onChange={(e) => this.handleZipTextChange(e)}
        />
        <h6 className="mb-4 mt-4 fnt-12 mob-loc-hed">
          Location ({locationList.length})
        </h6>

        {loading ? <StatusLoader /> : this.listReportedLocation(locationList)}
      </div>
    );
  };
  getReportedLocationHeight = () => {
    let reportLocationHeight = getHeightWithId("report-location");
    return -(reportLocationHeight - 15);
  };
  getCountryFilterHeight = () => {
    let countryFilterHeight = getHeightWithId("slide-container");
    return -(countryFilterHeight - 25);
  };

  eventLogger = (e, data) => {
    this.setState({
      dragYpos: data.y,
    });
  };
  render() {
    let { showReports, dragPositionTop, dragYpos } = this.state;
    return (
      <div className="w-100">
        <Draggable
          axis="y"
          defaultPosition={{ x: 0, y: 0 }}
          position={{ x: 0, y: dragYpos }}
          onDrag={this.eventLogger}
          cancel=".editable"
          enableUserSelectHack={false}
          bounds={{ top: dragPositionTop, left: 0, right: 0, bottom: 0 }}
        >
          <div className="mob-region-select" id="slide-container">
            <div className="activity-filters editable">
              <AddActivity mobileView={true} />
            </div>
            <span class="mob-drag d-table w-100 m-auto"></span>
            {showReports
              ? this.renderReportedLocationBlock()
              : this.renderRegionSelector()}
          </div>
        </Draggable>
      </div>
    );
  }
}
