import React from "react";
import logo from '../../assets/images/samscloud-white.svg'
export const StatusLoader = (props) => {
  let { height, type ,simpleLoader} = props;
  //console.log("height", height);
  const mulitipleDotsLoader = () => {
    return (
      <>
        {" "}
        <div class="spinner-grow spinner-grow-sm text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow spinner-grow-sm text-light" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </>
    );
  };
  const singleDotLoader = () => {
    return (
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    );
  };
  const logoLoader = () => {
    return (
      <div className="logo-container">
        <img
          src={logo}
          className="logo-block"
          alt="logo-loading"
        />
      </div>
    );
  };
  const renderLoader = () => {
    switch (type) {
      case "multiDots":
        return mulitipleDotsLoader();
      case "logoLoader":
        return logoLoader();
      default:
        return singleDotLoader();
    }
  };
  if(simpleLoader){
    return  <div className="d-flex justify-content-center align-items-center">
    {renderLoader()}
  </div>
  }
 else  return (
  
    <div className="d-flex justify-content-center align-items-center" style={{ height:"100%",width:"100%" }}>
      {renderLoader()}
    </div>
  );
};
