import React, { Component } from "react";
import { ModalWindow } from "../modal";
import { Recommandations } from "../../LatestReports/LRContentBlock/recommendationBlock";
import history from "../../history";
import { getPlaceName } from "../../../utils/getRegionInfo";
import { abbreviate } from "../../../utils/abbreviate";
import { formatNumber } from "../../../utils/numberFormatters";
import { fromNow } from "../../../utils/commonDateFormatter";
import ContactsMap from "../../GoogleMap/contactsMap";
import { changeTextColor } from "../../../utils/changeRiskColor";
import { connect } from "react-redux";
import { StatusLoader } from "../loader";
import assesslocation from "../../../assets/images/assess-location.svg";

class MinifiedAccessRisk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exposureCount: 0,
      riskLvl: "Low",
      addedLocations: [],
    };
  }

  populateBody = () => {
    let locationInfo = JSON.parse(localStorage.getItem("addedLocs"));
    let count = 0;
    let multiplier = 20;
    let locationList = [];
    let level = "Low";
    for (const key in locationInfo) {
      if (locationInfo.hasOwnProperty(key)) {
        const element = locationInfo[key];
        count += element.location_infected_count * multiplier;
        if (count > 100) count = 100;
        locationList.push({
          ...element,
          is_infected: element.location_infected_count !== 0 ? true : false,
        });
      }
    }

    if (count <= 40) level = "Low";
    else if (count > 40 && count <= 80) level = "Medium";
    else level = "High";
    return { level, locationList, count };
  };

  renderAddedLocation = (info) => {
    let { level, locationList, count } = info;
    return locationList.map((locInfo) => {
      let {
        location,
        location_date,
        to_time,
        location_infected_count,
      } = locInfo;
      return (
        <div class="Exposure-locations element-bg">
          <div class="d-flex align-items-stretch item-center">
            <div class="user-int">w</div>
            <div class="Exposure-txt">
              <h4>{getPlaceName(location)}</h4>
              <p>{abbreviate(location, 12, false)}</p>
              <h6>First Exposure Date: {location_date}</h6>
            </div>
            <div class="Exposure-location-info">
              <h4>Place</h4>
              <p>1 m ago</p>
              <h6>
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                {formatNumber(location_infected_count)}
              </h6>
            </div>
          </div>
        </div>
      );
    });
  };
  renderLeftPane = (info) => {
    let { level, locationList, count } = info;
    let { userLastUpdate } = this.props.globalHeaderReducer;
    let { lastUpdate, loading } = userLastUpdate;
    return (
      <div class="col-sm-6">
        <div class="score-b element-bg">
          <div class="d-flex justify-content-center text-center score-b-inner assess-risk-lvl m-0 border-0">
            <div class="score-box">
              <h4 class="fnt-12">Exposure Score</h4>
              <p>{count}</p>
            </div>
            <div class="score-box">
              <h4 class="fnt-12">Risk Level</h4>
              <p class="" style={{ color: changeTextColor(level) }}>
                {level.toUpperCase()}
              </p>
            </div>
          </div>
          <div class="update-box-d">
            {" "}
            {loading ? (
              <StatusLoader />
            ) : (
              <>
                <span>updated:</span> {fromNow(lastUpdate)}
              </>
            )}
          </div>
        </div>
        <div className="assess-maping">
        <ContactsMap userLocationList={locationList} contactList={[]} />
        </div>
        <Recommandations risk_level={level} />
      </div>
    );
  };

  createAccountPrompt = () => {
    return (
      <div class="col-sm-6">
        <div class="row">
          <div class="element-bg Recommendation p-0 mt-0">
            <button className="assess-crt-acnt" onClick={() => history.push({ pathname: "/signup" })}>
              Create Account
            </button>
            <p className="assess-min-p Gilroy-Medium">
              You’ll receive risk and<br /> exposure updates for<br /> contacted people and<br />
              places visited as new<br /> information is updated.
            </p>
          </div>
        </div>
      </div>
    );
  };
  render() {
    let { showRiskDashboard, toggleModal } = this.props;
    let info = this.populateBody();
    let { level, locationList, count } = info;
    return (
      <ModalWindow
        showModal={showRiskDashboard}
        onModalClose={toggleModal}
        heading={
          <div class="modal-head w-100">
            <h4 className="text-center">Assess Risk Results</h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={toggleModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        }
        body={
          <div class="modal-body">
            <div class="row">
              {this.renderLeftPane(info)}
              <div class="col-sm-6">
                <div class="element-bg">
                  <div class="location-visited assess-rit-mod">
                    <div class="d-flex">
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="score-b-inner m-0 border-0 w-100 assess-scr-box">
                            <div class="score-box w-100">
                              <h4 class="fnt-12 w-100">Exposure</h4>
                              <p><img src={assesslocation} alt="" />{count}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.createAccountPrompt(info)}
                    </div>
                    <div class="d-flex justify-content-between item-center  Location-Exposure">
                      <p>Location Exposure ({locationList.length}) </p>
                    </div>
                  </div>
                </div>
                <div className="assess-loct-ht">
                  {this.renderAddedLocation(info)}
                </div>
              </div>
            </div>
          </div>
        }
      />
    );
  }
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    globalHeaderReducer: state.globalHeaderReducer,
  };
};
export default connect(mapStateToProps)(MinifiedAccessRisk);
