/**
 * reducer for home page
 */
import { createReducer } from "@reduxjs/toolkit";

/**helper functions */
import {
  formatCountryList,
  getSelfReportStats,
  formatReportedLocation,
  getActiveProvince,
  addSummaryForProvinces,
} from "./helpers";
import { defaultLatLng } from "../../../constants/configurations";
import {
  activityDrop,
  activityTypes,
  timeDropItems,
  weekDropItems,
} from "../../../components/Home/AddActivity/formConfigs";
import { groupBy } from "../../../utils/arrayFormatters";
export const selectCountryBody = () => {
  return {
    region: "Not Found",
    count: 0,
    cases: [
      { id: 1, color: "blue", status: "Confirmed", count: 0 },
      { id: 2, color: "yellow", status: "Active", count: 0 },
      { id: 3, color: "green", status: "Recovered", count: 0 },
      { id: 4, color: "orange", status: "Death", count: 0 },
    ],
    info: {},
  };
};
var tomorrow = new Date();
tomorrow.setDate(new Date().getDate() + 1);
const addActivityFormStates = {
  location: "",
  address: "",
  description: "",
  activity: activityDrop[0].name,
  type: activityTypes[0].name,
  dispaly_location: weekDropItems()[0].name,
  from_date: new Date(),
  from_time: timeDropItems()[0].name,
  to_date: tomorrow,
  to_time: timeDropItems()[0].name,
  phone_number: "",
  permanent: false,
  latLng: {},
};
const resetMapSettings = {
  radius: 10,
  centerLocation: defaultLatLng,
  circleApex: null,
  selectedLocation: "",
};
const INITIAL_STATE = {
  getStateLocation: {
    loading: false,
    stateData: null,
    locationchoose: "",
  },
  showActivityModal: false,
  mapLoading: false,
  hideCollapse: true,
  covidBrief: {
    loading: false,
    affectedCount: 0,
    deathCount: 0,
    recoveredCount: 0,
  },
  globalSearchText: "",
  activeComponent: "",
  affectedCountry: {
    loading: false,
    list: [],
    countryList: [],
    provinceStates: [],
    cityList: [],
  },
  sideBarList: {
    loading: false,
  },
  getStateData: {
    stateData: null,
    loading: false,
  },
  mapLoaded: false,
  proviceCountryCount: 0,
  lastUpdated: "",
  graphSettings: resetMapSettings,
  zipSearchText: "",
  filterBy: "All region",
  selfReportInfo: {
    count: 24747,
    cases: [
      { id: 1, color: "blue", status: "Confirmed", count: 20603 },
      { id: 2, color: "yellow", status: "Active", count: 20603 },
      { id: 3, color: "green", status: "Recovered", count: 2400 },
      { id: 4, color: "orange", status: "Death", count: 1532 },
    ],
  },
  hasResult: true,
  googleMapType: "",
  selfReportCount: 0,
  selectedCountryMapList: selectCountryBody(),
  activityFilter: [],
  reportedLocation: {
    loading: false,
    list: [],
  },
  activityListConf: [
    { name: "foodAid", label: "Food Aid", className: "map-food", count: 0 },
    {
      name: "supplies",
      label: "Supplies",
      className: "map-supplies",
      count: 0,
    },
    {
      name: "financialHelp",
      label: "Financial Help",
      className: "map-financial",
      count: 0,
    },
    {
      name: "testCenter",
      label: "Test Center",
      className: "map-testcenter",
      count: 0,
    },
    {
      name: "vaccine",
      label: "Vaccine",
      className: "map-vaccine",
      count: 0,
    },
  ],
  cityFetch: {
    loading: false,
    list: [],
  },
  countyInfoFetch: {
    loading: false,
    list: [],
  },
  addActivityForm: addActivityFormStates,
  activityFormValid:false,
  listActivity: {
    loading: false,
    data: {},
  },
};
const formatActivityArray = (array) => {
  return array.map((item) => {
    if (item.activity === "Food Aid") {
      item.name = "foodAid";
    } else if (item.activity === "Supplies") {
      item.name = "supplies";
    } else if (item.activity === "Financial Help") {
      item.name = "financialHelp";
    } else if (item.activity === "Test Center") {
      item.name = "testCenter";
    } else if (item.activity === "Vaccine") {
      item.name = "vaccine";
    }
    return { ...item };
  });
};
const resetStates = (type) => {
  switch (type) {
    case "addActivityForm":
      return addActivityFormStates;
    default:
      return;
  }
};
export const afterGlobalCountyInfo = () => {
  return {
    COUNTY_WISE_COVID_INFO_STARTED: (state, action) => {
      state.countyInfoFetch.loading = true;
    },
    COUNTY_WISE_COVID_INFO_SUCCESS: (state, action) => {
      state.countyInfoFetch.loading = false;
      state.countyInfoFetch.list = action.payload.response;
    },
    COUNTY_WISE_COVID_INFO_FAILED: (state, action) => {
      state.affectedCountry.loading = false;
    },
  };
};
/**reducer operations when the fetch total covid info action called */
export const afterStatewiseDetailsAction = () => {
  return {
    LIST_COVID_STATEWISE_STATUS_STARTED: (state, action) => {
      state.affectedCountry.loading = true;
      state.graphSettings = resetMapSettings;
      state.getStateData.stateData = null;
      state.reportedLocation.list = [];
    },
    LIST_COVID_STATEWISE_STATUS_SUCCESS: (state, action) => {
      state.affectedCountry.loading = false;
      let resp = formatCountryList(action.payload.response, "userClicked");
      if (resp.countryList.length) {
        state.affectedCountry.list = resp.countryList;
        state.selectedCountryMapList = getSelfReportStats({
          info: resp.countryList[0],
        });
      } else state.affectedCountry.list = [];
      // state.googleMapType = "proviceMap";
      state.affectedCountry.provinceStates = resp.proviceList;
      state.affectedCountry.cityList = [];
      state.cityFetch.list = [];
      if (resp.proviceList.length) {
        let totalSummary = addSummaryForProvinces(
          state.affectedCountry.provinceStates
        );
        state.proviceCountryCount = totalSummary;
        let currentActiveProvince = getActiveProvince(resp.proviceList);
        if (currentActiveProvince.id) {
          state.selectedCountryMapList = getSelfReportStats(
            {
              info: currentActiveProvince,
            },
            state
          );
        } else {
          state.selectedCountryMapList = selectCountryBody();
        }
      }
    },
    LIST_COVID_STATEWISE_STATUS_FAILED: (state, action) => {
      state.affectedCountry.loading = false;
    },
  };
};

/**reducer operations when the fetch total covid info action called */
export const afterFetchCovidSummary = () => {
  return {
    LIST_COVID_DETAILS_STARTED: (state, action) => {
      state.affectedCountry.loading = true;
      state.graphSettings = resetMapSettings;
      state.getStateData.stateData = null;
    },
    LIST_COVID_DETAILS_SUCCESS: (state, action) => {
      state.affectedCountry.loading = false;
      let result = formatCountryList(action.payload.response);
      state.affectedCountry.list = result.countryList;
      state.affectedCountry.provinceStates = [];
      state.reportedLocation.list = [];
      state.affectedCountry.cityList = [];
      state.cityFetch.list = [];
      state.selectedCountryMapList = selectCountryBody();
    },
    LIST_COVID_DETAILS_FAILED: (state, action) => {
      state.affectedCountry.loading = false;
    },
  };
};
/**reducer operations when the fetch total covid info action called */
export const afterCityFetchAction = () => {
  return {
    GET_CITY_LIST_WITH_STATE_STARTED: (state, action) => {
      state.cityFetch.loading = true;
      state.graphSettings = resetMapSettings;
    },
    GET_CITY_LIST_WITH_STATE_SUCCESS: (state, action) => {
      state.cityFetch.loading = false;
      let result = formatCountryList(action.payload.response, "zip");
      state.cityFetch.list = result.cityList;
    },
    GET_CITY_LIST_WITH_STATE_FAILED: (state, action) => {
      state.affectedCountry.loading = false;
    },
  };
};

/**reducer operations when the fetch total covid info action called */
export const afterFetchReportedWithzip = () => {
  return {
    GET_SELF_REPORT_WITH_ZIP_STARTED: (state, action) => {
      state.affectedCountry.loading = true;
      state.graphSettings = resetMapSettings;
    },
    GET_SELF_REPORT_WITH_ZIP_SUCCESS: (state, action) => {
      state.affectedCountry.loading = false;
      if (action.payload.response.length) {
        let result = formatCountryList(action.payload.response, "zip");
        state.affectedCountry.cityList = result.cityList;
        if (result.cityList.length) {
          state.selectedCountryMapList = getSelfReportStats(
            {
              info: result.cityList[0],
            },
            state
          );
        }
      } else {
        state.selectedCountryMapList = selectCountryBody();
        state.affectedCountry.cityList = [];
      }
      state.affectedCountry.provinceStates = [];
      state.affectedCountry.list = [];
      state.reportedLocation.list = [];
    },
    GET_SELF_REPORT_WITH_ZIP_FAILED: (state, action) => {
      state.affectedCountry.loading = false;
    },
  };
};
/**reducer operations when the fetch total covid info action called */
export const afterGetSelfReportLocationAction = () => {
  return {
    GET_SELF_REPORTED_LOCATION_STARTED: (state, action) => {
      state.reportedLocation.loading = true;
      state.graphSettings = resetMapSettings;
    },
    GET_SELF_REPORTED_LOCATION_SUCCESS: (state, action) => {
      state.reportedLocation.loading = false;
      state.selfReportCount = action.payload.response.self_report_count;
      state.reportedLocation.list = formatReportedLocation(
        action.payload.response
      );
    },
    GET_SELF_REPORTED_LOCATION_FAILED: (state, action) => {
      state.reportedLocation.loading = false;
    },
  };
};

/**reducer operations when the fetch total covid info action called */
export const getStateLocation = () => {
  return {
    GET_GOOGLE_STATE_LOCATION_STARTED: (state, action) => {
      state.getStateData.stateData = null;
      state.getStateData.loading = true;
    },
    GET_GOOGLE_STATE_LOCATION_SUCCESS: (state, action) => {
      state.getStateData.stateData = action.payload;
      state.getStateData.locationchoose = "stateZone";
      state.getStateData.loading = false;
    },
    GET_GOOGLE_STATE_LOCATION_FAILED: (state, action) => {
      state.getStateData.stateData = null;
      state.getStateData.loading = false;
    },
  };
};

export const getUsaZoneTestLab = () => {
  return {
    GET_GOOGLE_ALL_STATE_LOCATION_STARTED: (state, action) => {
      state.getStateData.stateData = null;
      state.getStateData.loading = true;
    },
    GET_GOOGLE_ALL_STATE_LOCATION_SUCCESS: (state, action) => {
      state.getStateData.stateData = action.payload;
      state.getStateData.locationchoose = "USAZONE";
      state.getStateData.loading = false;
    },
    GET_GOOGLE_ALL_STATE_LOCATION_FAILED: (state, action) => {
      state.getStateData.stateData = null;
      state.getStateData.loading = false;
    },
  };
};

/**reducer operations when the fetch total covid info action called */
export const afterListGlobalEffect = () => {
  return {
    LIST_GLOBAL_EFFECT_STARTED: (state, action) => {
      state.covidBrief.loading = true;
    },
    LIST_GLOBAL_EFFECT_SUCCESS: (state, action) => {
      state.covidBrief.loading = false;
      let {
        Total_confirmed,
        Total_Deaths,
        Total_Recovered,
      } = action.payload.response;
      state.covidBrief.affectedCount = Total_confirmed;
      state.covidBrief.deathCount = Total_Deaths;
      state.covidBrief.recoveredCount = Total_Recovered;
    },
    LIST_GLOBAL_EFFECT_FAILED: (state, action) => {
      state.covidBrief.loading = false;
    },
  };
};
const afterCountryWiseDetailFetch = () => {
  return {
    LIST_COVID_DETAILS_COUNTRY_STARTED: (state, action) => {
      state.affectedCountry.loading = true;
      state.getStateData.stateData = null;
      state.sideBarList.loading = true;
    },
    LIST_COVID_DETAILS_COUNTRY_SUCCESS: (state, action) => {
      let result = formatCountryList(action.payload.response);
      state.affectedCountry.countryList = result.countryList;
      state.sideBarList.loading = false;
    },
    LIST_COVID_DETAILS_COUNTRY_FAILED: (state, action) => {
      state.affectedCountry.loading = false;
      state.sideBarList.loading = false;
    },
  };
};
const formatList = (conf, result) => {
  for (const key in conf) {
    if (conf.hasOwnProperty(key)) {
      const confObj = conf[key];
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          const resObj = result[key];
          if (confObj.label === key) {
            confObj.count = resObj.length;
            break;
          }
        }
      }
    }
  }
  return conf;
};

const afterListActivityActions = () => {
  return {
    LIST_ACTIVITY_STARTED: (state, action) => {
      state.listActivity.loading = true;
    },
    LIST_ACTIVITY_SUCCESS: (state, action) => {
      state.listActivity.loading = false;
      state.listActivity.data = formatActivityArray(action.payload.response);
      state.activityListConf = formatList(
        state.activityListConf,
        groupBy(action.payload.response, "activity")
      );
    },
    LIST_ACTIVITY_FAILED: (state, action) => {
      state.listActivity.loading = false;
    },
  };
};
const validateAddActivityForm = (state) => {
  let isValid = true;
  let { location, address, description, phone_number } = state.addActivityForm;
  let body = { location, address, description, phone_number };
  for (const key in body) {
    if (body.hasOwnProperty(key)) {
      const element = body[key];
      if (!element.length) {
        isValid = false;
      }
    }
  }
  console.log('valid',isValid,body)
  return isValid;
};
export const handleHeaderChanges = () => {
  return {
    HANDLE_CHANGE_HOME_PAGE: (state, action) => {
      let { payload } = action;
      if (payload.type === "showProvinceTotal") {
        if (state.affectedCountry.provinceStates.length) {
          let currentActiveProvince = addSummaryForProvinces(
            state.affectedCountry.provinceStates
          );
          if (currentActiveProvince.id) {
            state.selectedCountryMapList = getSelfReportStats(
              {
                info: currentActiveProvince,
              },
              state
            );
          }
        }
      } else if (payload.type === "reset") {
        state[payload.name] = resetStates(payload.name);
      } else if (payload.type === "toggle") {
        state[payload.name] = !state[payload.name];
      } else {
        if (payload.parentName) {
          state[payload.parentName][payload.name] = payload.value;
          if (payload.parentName === "addActivityForm") {
            state.activityFormValid = validateAddActivityForm(state);
          }
        } else state[payload.name] = payload.value;
      }
    },
    HANDLE_CHANGE_COUNTRY_SELECT: (state, action) => {
      let { payload } = action;
      state.selectedCountryMapList = getSelfReportStats(
        {
          info: payload.value,
        },
        state
      );
    },
    HANDLE_CHANGE_DROPDOWN: (state, action) => {
      let { type, name } = action.payload;
      state.affectedCountry.countryList = toggleDropdownList(
        state.affectedCountry.countryList
      );
    },
    HANDLE_CHANGE_ACTIVITY_FILTER: (state, action) => {
      let { type, name } = action.payload;
      if (type === "add") {
        state.activityFilter.push(name);
      } else
        state.activityFilter = state.activityFilter.filter(
          (item) => item !== name
        );
    },
  };
};
const toggleDropdownList = (array) => {};
export default createReducer(INITIAL_STATE, {
  ...afterStatewiseDetailsAction(),
  ...afterFetchCovidSummary(),
  ...handleHeaderChanges(),
  ...afterListGlobalEffect(),
  ...afterGetSelfReportLocationAction(),
  ...afterFetchReportedWithzip(),
  ...afterCityFetchAction(),
  ...afterGlobalCountyInfo(),
  ...getStateLocation(),
  ...getUsaZoneTestLab(),
  ...afterCountryWiseDetailFetch(),
  ...afterListActivityActions(),
});
