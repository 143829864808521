import { connect } from "react-redux";

/**importing actions */
import {
    handleChangeLR,
    listLocationAction,
    deleteUserLocation,
    fetchUsersAction,
    getUserInfoAction,
    editUserLocation,
    newUserLocation,
    getAllTravelLocation,
} from "../../redux/actions";

/**importing components */
import GeoTracking from "./geoTracking";

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        reducer: state.latestReportReducer,
    };
};

const componentActions = (dispatch) => {
    return {
        handleChange: (params) => {
            dispatch(handleChangeLR(params));
        },
    };
};

const apiActions = (dispatch) => {
    return {
        newUserLocation: (params, next) => {
            dispatch(newUserLocation(params, next));
        },
        getAllTravelLocation: (params, next) => {
            dispatch(getAllTravelLocation(params, next));
        },
        fetchUsersAction: (params, next) => {
            dispatch(fetchUsersAction(params, next));
        },
        getUserInfoAction: (params, next) => {
            dispatch(getUserInfoAction(params, next));
        },
        deleteUserLocation: (params, next) => {
            dispatch(deleteUserLocation(params, next));
        },
        editUserLocation: (params, next) => {
            dispatch(editUserLocation(params, next));
        },
        listLocationAction: (params, next) => {
            dispatch(listLocationAction(params, next));
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...componentActions(dispatch),
        ...apiActions(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeoTracking);
