import React from "react";
import { CustomButton } from "../../commons/buttons";
import { latestReportConst } from "../../../constants/text-constants";
import { testCenterUrl } from "../../../constants/configurations";

const {
  title,
  highRiskDesc,
  lowRiskDesc,
  MediumDesc,
  buttonText,
} = latestReportConst.recommandationText;
export const Recommandations = (props) => {
  let { risk_level } = props;

  const redirectToCovidTestPage = () => {
    window.open(testCenterUrl, "_blank");
  };
  const renderRecommandationText = () => {
    switch (risk_level) {
      case "High":
        return highRiskDesc;
      case "Medium":
        return MediumDesc;
      case "Low":
        return lowRiskDesc;
      default:
        return "";
    }
  };
  return (
    <div className="element-bg Recommendation">
      <h4>{title}</h4>
      <p>{renderRecommandationText()}</p>
      <CustomButton text={buttonText} clickEvent={redirectToCovidTestPage} />
    </div>
  );
};
