import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export const ModalWindow = (props) => {
  const handleClose = () => {
    props.onModalClose();
  };
  return (
    <Modal
      style={{scrollbarWidth:'none !important'}}
      className={props.className}
      show={props.showModal}
      onHide={handleClose}
      centered
    >
      {props.heading && <Modal.Header>{props.heading}</Modal.Header>}
      <Modal.Body className={props.bodyClassName}>{props.body}</Modal.Body>
      {props.footer && (
        <Modal.Footer className={props.footerClassName}>
          {props.footer}
        </Modal.Footer>
      )}
    </Modal>
  );
};

ModalWindow.propTypes = {
  showModal: PropTypes.bool,
  onModalClose: PropTypes.func,
  heading: PropTypes.element,
  body: PropTypes.element,
  footer: PropTypes.element,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
};
