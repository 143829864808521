/**renders the qr card in right pane of the latest report page */

/**importing react dep */
import React, {Component} from "react";
import QRCode from "react-qr-code";
import moment from "moment";

import {BsArrowsAngleExpand} from "react-icons/bs";

export default class QRCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {userLastUpdate, clickEvent, QRCodeValue} = this.props;
        let userLastUpdateTime = moment(userLastUpdate.lastUpdate).fromNow();

        return (
            <div className="col-xl-12 col-lg-4 col-md-6 col-sm-12 px-2 score-card pull-left">
                <div className="qr-card element-bg">
                    <BsArrowsAngleExpand style={{
                        color: "#fff", position: "absolute", top: "14px",
                        right: "13px"
                    }}
                                         data-toggle="modal"
                                         data-target="#loca1"
                                         onClick={clickEvent}
                    />
                    <div className="qr-card-element">
                        <QRCode value={QRCodeValue} size={115} />
                    </div>
                </div>
                <div className="update-box-d">
                    <span>Updated:</span>
                    {" "}{userLastUpdateTime}
                </div>
            </div>
        );
    }
}
