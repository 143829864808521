import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  UPDATE_USER_CONTACTS_SUCCESS,
  UPDATE_USER_CONTACTS_FAILED,
  UPDATE_USER_CONTACTS_STARTED
} from "../../../../constants/action-types";
import {
  UPDATE_USER_CONTACT_URL
} from "../../../../constants/configurations";

export const updateUserContactAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "patch",
        api: UPDATE_USER_CONTACT_URL(body.id),
        started: UPDATE_USER_CONTACTS_STARTED,
        success: UPDATE_USER_CONTACTS_SUCCESS,
        failure: UPDATE_USER_CONTACTS_FAILED
      },
      callBack
    );
  };
};
