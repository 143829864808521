/**all the component actions such as handle change and toggle modal etc. keeped here */

import { HANDLE_CHANGE_ACCESS_RISK } from "../../../constants/action-types";

export const handleChangeAccessRisk = (params) => dispatch => {
  dispatch({
    type: HANDLE_CHANGE_ACCESS_RISK,
    payload: params
  });
};
