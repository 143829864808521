import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import datedwnarw from "../../../assets/images/Drop-down2.svg";
import { convertDateToUTC } from "../../../utils/getTimezone";
export default class CommonDatePicker extends Component {
  render() {
    let { configs, value, handleFormChange } = this.props;

    return (
      <div
        className={`date-picking w-100 mb-20 position-relative symptoms-date ${configs.pickerClassName}`}
      >
        <DatePicker
          disabled={configs.isDisabled}
          className={configs.className}
          popperPlacement="bottom"
          popperModifiers={{
            flip: {
              behavior: ["bottom"], // don't allow it to flip to be above
            },
            preventOverflow: {
              enabled: false, // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
              enabled: false, // turn off since needs preventOverflow to be enabled
            },
          }}
          selected={
            configs.isDisabled
              ? null
              : value.length
              ? convertDateToUTC(new Date(value))
              : convertDateToUTC(new Date())
          }
          maxDate={convertDateToUTC(new Date())}
          onChange={(value) => {
            let formated = apiDateFormat(value);
            handleFormChange({
              name: configs.name,
              value: formated,
            });
          }}
        />
        <img src={datedwnarw} className="eye-icn rep-dwn-icn" alt="" />
      </div>
    );
  }
}
