import { getDiseaseListAction } from "../../redux/actions/apiActions/userReportCRUD/getDiseaseListAction";
import { getSymtomsListAction } from "../../redux/actions/apiActions/userReportCRUD/getSymtomsListAction";
import { listCarrierFlightsForSelect } from "../../redux/actions";
import { listOrganizationsForSelect } from "../../redux/actions";

const formatDiseaseData = (params) => {
  let { data, input } = params;
  let result = data.map((item) => {
    return {
      name: item.name,
      label: item.name,
      value: item.id,
      isChecked: false,
    };
  });
  if (input) {
    result.push({
      label: `ADD- ${input}`,
      value: input,
      isChecked: false,
      isCustom: true,
      className: "custom-class",
    });
  }
  return result;
};

const formatFlightData = (params) => {
  let { data } = params;
  let result = data.map((item) => {
    return { ...item, label: item.name, value: item.id };
  });
  return result;
};

export const getFlightListLoadOptions = (input) => {
  return new Promise((resolve, reject) => {
    try {
      listCarrierFlightsForSelect()
        .then((res) => {
          resolve(formatFlightData({ data: res.data, input }));
        })
        .catch((error) => {
          reject();
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error", error);
    }
  });
};

const formatOrganizationsData = (params) => {
  let { data } = params;
  let result = data.map((item) => {
    return { ...item, label: item.name, value: item.id };
  });
  return result;
};

export const getOrganizationsListLoadOptions = (input) => {
  return new Promise((resolve, reject) => {
    try {
      listOrganizationsForSelect()
        .then((res) => {
          resolve(formatOrganizationsData({ data: res.data, input }));
        })
        .catch((error) => {
          reject();
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error", error);
    }
  });
};

export const getSymtomsLoadOptions = (input) => {
  return new Promise((resolve, reject) => {
    try {
      getSymtomsListAction()
        .then((res) => {
          resolve(formatDiseaseData({ data: res.data, input }));
        })
        .catch((error) => {
          reject();
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error", error);
    }
  });
};
export const getDiseasesLoadOptions = (input) => {
  return new Promise((resolve, reject) => {
    try {
      getDiseaseListAction()
        .then((res) => {
          resolve(formatDiseaseData({ data: res.data }));
        })
        .catch((error) => {
          reject();
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("error", error);
    }
  });
};
