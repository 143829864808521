/**common action handler :
 *
 * defining a common structure for axios
 */
import axios from "axios";

const actionStarted = (params) => {
  let { started } = params;
  return {
    type: started,
  };
};

const actionSuccess = (params) => {
  let { success, res, failure } = params;
  if (res.status === 200 || res.status === 201) {
    return {
      type: success,
      payload: { response: res.data },
    };
  } else
    return {
      type: failure,
      payload: { error: res.data },
    };
};
const actionFailed = (params) => {
  let { failure, error } = params;
  return {
    type: failure,
    payload: { error: error },
  };
};

export const simpleApiCalls = (params, callback) => {
  let { url, body, dispatch, methordType } = params;
  dispatch(actionStarted(params));
  return axios[methordType](url, body)
    .then((response) => {
      dispatch(actionSuccess({ ...params, res: response }));
      callback(response);
    })
    .catch((error) => {
      dispatch(actionFailed({ ...params, error }));
      callback(error, false);
    });
};
