import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_STATUS_SUCCESS,
  LIST_STATUS_FAILED,
  LIST_STATUS_STARTED
} from "../../../../constants/action-types";
import { GET_USER_STATUS } from "../../../../constants/configurations";

export const getStatusListAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "get",
      api: GET_USER_STATUS,
      started: LIST_STATUS_STARTED,
      success: LIST_STATUS_SUCCESS,
      failure: LIST_STATUS_FAILED
    },callBack);
  };
};
