import {
  NEW_USER_LOCATION_SUCCESS,
  NEW_USER_LOCATION_FAILED,
  NEW_USER_LOCATION_STARTED,
} from "../../../../constants/action-types";

import { apiServiceHandler } from "../../../../services/apiCallHandler";
import { GET_USER_LOCATIONS_URL } from "../../../../constants/configurations";

export const newUserLocation = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api: GET_USER_LOCATIONS_URL,
        started: NEW_USER_LOCATION_STARTED,
        success: NEW_USER_LOCATION_SUCCESS,
        failure: NEW_USER_LOCATION_FAILED,
      },
      callBack
    );
  };
};
