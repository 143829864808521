import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  DELETE_USER_LOCATION_SUCCESS,
  DELETE_USER_LOCATION_FAILED,
  DELETE_USER_LOCATION_STARTED,
} from "../../../../constants/action-types";
import { DELETE_USER_LOCATION_URL } from "../../../../constants/configurations";

export const deleteUserLocation = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        methordType: "delete",
        api: DELETE_USER_LOCATION_URL(body),
        started: DELETE_USER_LOCATION_STARTED,
        success: DELETE_USER_LOCATION_SUCCESS,
        failure: DELETE_USER_LOCATION_FAILED,
      },
      callBack
    );
  };
};
