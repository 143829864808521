export const getPivotPosition = (params) => {
    let { array, pivot, type } = params;
    for (const key in array) {
      if (array.hasOwnProperty(key)) {
        const element = array[key];
        if (element[type] === pivot) {
          return key;
        }
      }
    }
  };

  export const groupBy=(objectArray, property)=> {
    return objectArray.reduce((acc, obj) => {
       const key = obj[property];
       if (!acc[key]) {
          acc[key] = [];
       }
       acc[key].push(obj);
       return acc;
    }, {});
 }