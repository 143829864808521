/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { toastNotification } from "../commons/toastNotification";
import { APP_BASE_NAME } from "../../constants/configurations";
import GlobalHeader from "../GlobalHeader";
import { getRegionInfo } from "../../utils/getRegionInfo";
import { countryMobileCodes } from "../../constants/mobileCodes";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { stateCity } from "../../utils/stateCity";
import DatePicker from "react-datepicker";
import datedwnarw from "../../assets/images/Drop-down2.svg";
import firstaid from "../../assets/images/first-aid.svg";
import moment from "moment";
import eye from "../../assets/images/eye.svg";
import hiddensye from "../../assets/images/hidden.svg";
import { errorHandler } from "../../utils/commonErrorHandler";
import { secureText } from "../../constants/text-constants";
import SignupDropDown from "./signupDropDown";
import FilterableDropDown from "../commons/FilterDropDown";
const states = stateCity();
export const setLocalStorageItems = (res) => {
  let { id, token } = res.data;
  localStorage.setItem("logged_user", JSON.stringify(res));
  localStorage.setItem("token", token);
  localStorage.setItem("user_id", JSON.stringify(id));
};

export default class signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_type: "Individual",
      cities: [],
      city: null,
      showDrop: false,
      selectedCity: "",
      selectedState: "",
      racial_group: "",
      gender: "Male",
      phone_number: "",
      showPasswords: { password: false, connfirmPass: false },
      date_of_birth: new Date("2017-01-04T08:05:10"),
      racialGroupSelect: {
        list: [
          { name: "American Indian" },
          { name: "Alaska Native" },
          { name: "Asian" },
          { name: "Black or African American" },
          { name: "Native Hawaiian or Other Pacific Islander" },
          { name: "Hispanic or Latino" },
          { name: "White" },
        ],
      },
    };
  }
  checkUserType = (value, name) => {
    this.setState({
      [name]: value,
    });
  };
  /**actual sign up api call */
  callSignUpApi = (body) => {
    this.props.signupAction(body, (res) => {
      if (res.status === 201) {
        localStorage.setItem("phone_number", body.phone_number);
        this.props.history.push({
          pathname: `${APP_BASE_NAME}/verification`,
        });
        toastNotification({
          msg: "Redirected to verification portal",
          type: "info",
        });
      } else {
        toastNotification({
          msg: errorHandler(res.data),
          type: `error`,
        });
      }
    });
  };
  addCountryCodeToPhonenumber = (country) => {
    let code = "";
    countryMobileCodes.map((item) => {
      if (item.name === country) {
        code = item.dial_code;
      }
    });
    return code;
  };

  validationToast = () => {
    return toastNotification({
      msg: "you have an empty field! ",
      type: "info",
    });
  };
  validateStateAndCity = () => {
    let { selectedCity, selectedState } = this.state;
    let stateFound = false;
    let cityFound = false;
    let cityList = [];
    for (const key in states) {
      if (states.hasOwnProperty(key)) {
        const element = states[key];
        if (element.state === selectedState) {
          stateFound = true;
          cityList = element;
        }
      }
    }
    if (!stateFound) {
      return false;
    } else {
      for (const key in cityList) {
        if (cityList.hasOwnProperty(key)) {
          const city = cityList[key];
          if (city === selectedCity) {
            cityFound = true;
          }
        }
      }
      if (cityFound) {
        return true;
      } else return false;
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();
    event.persist();
    let {
      racial_group,
      date_of_birth,
      gender,
      phone_number,
      selectedCity,
      selectedState,
    } = this.state;
    this.props.getGooglePlaceDetailsAction({ place: selectedState }, (res) => {
      if (res.status === 200) {
        let result = getRegionInfo(res.data);
        let UserDetails = {
          first_name: event.target.elements.first_name.value,
          last_name: event.target.elements.last_name.value,
          email: event.target.elements.email.value,
          address: event.target.elements.address.value,
          state: selectedState,
          country: "US",
          city: selectedCity,
          zip: event.target.elements.zip.value,
          phone_number: phone_number,
          password: event.target.elements.password.value,
          confirm_password: event.target.elements.confirm_password.value,
          gender,
          date_of_birth: moment(date_of_birth).format("YYYY-MM-DD"),
          user_type: this.state.user_type,
        };
        if (racial_group.length) {
          UserDetails.racial_group = racial_group;
        }
        if (!phone_number.includes("+")) {
          let code = this.addCountryCodeToPhonenumber(result.country);
          UserDetails.phone_number = code + phone_number;
        } else UserDetails.phone_number = phone_number;

        this.callSignUpApi(UserDetails);
      } else {
        toastNotification({
          msg: "Please enter a valid city / state",
          type: "error",
        });
      }
    });
  };

  changeStates = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  generateRacialList = () => {
    let { list } = this.state.racialGroupSelect;
    return list.map((item, index) => {
      return (
        <li
          className="mb-2"
          key={index}
          onClick={() => this.changeStates("racial_group", item.name)}
        >
          <span
            className="large com-drop sign-up-drp"
            data-value="option0"
            tabindex="-1"
          >
            {item.name}
          </span>
        </li>
      );
    });
  };
  racialGroupBlock = () => {
    let { racial_group } = this.state;
    return (
      <div className="form-group position-relative w-100 mb-20">
        <label className="fnt-12 text-white">Racial group</label>
        <button
          type="button"
          className="form-control self-inp position-relative text-left w-100"
          data-toggle="dropdown"
          aria-expanded="false"
          required
        >
          <span className="glyphicon glyphicon-cog"></span>
          {racial_group.length ? racial_group : "Please select"}
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu self-inp2 text-white w-100 pt-0 pb-0">
          {this.generateRacialList()}
        </ul>
      </div>
    );
  };
  login = () => {
    this.props.history.push({ pathname: `${APP_BASE_NAME}/login` });
  };
  changeCountryState = (params) => {
    let { name } = params;
    this.changeStates("selectedCity", "");
    this.setState({
      selectedState: name,
    });
    let filterResult = states.filter((item) => item.state === name);
    if (filterResult && filterResult[0]) {
      let { cities } = filterResult[0];
      this.setState({
        cities,
      });
    }
  };
  setSelectedCity = (params) => {
    let { name } = params;
    this.changeStates("selectedCity", name);
  };
  renderStateSelectBlock = () => {
    let { selectedState, showDrop } = this.state;
    let stateList = states.map((item, idx) => {
      return { name: item.state, id: idx };
    });
    return (
      <div className="col-sm-12 pull-left mb-20">
        <div className="row">
          <SignupDropDown
            showDrop={showDrop}
            className="form-control self-inp position-relative text-left w-100"
            handleChange={this.changeCountryState}
            userTextChange={this.changeStates}
            optionsList={stateList}
            label={"State"}
            name={"selectedState"}
            value={selectedState}
          />
        </div>
      </div>
    );
  };
  renderCitySelectBlock = () => {
    let { cities, selectedCity, showDrop } = this.state;
    let list = [];
    if (cities.length) list = cities.map((item) => ({ name: item.city }));
    return (
      <div className="col-sm-12 pull-left mb-20">
        <div className="row">
          <SignupDropDown
            showDrop={showDrop}
            handleChange={this.setSelectedCity}
            userTextChange={this.changeStates}
            disabled={cities.length ? false : true}
            optionsList={list}
            label={"City"}
            name={"selectedCity"}
            value={selectedCity}
          />
        </div>
      </div>
    );
  };
  renderCustomHeaderPicker = (params) => {
    let {
      date,
      decreaseMonth,
      increaseMonth,
      prevMonthButtonDisabled,
      nextMonthButtonDisabled,
    } = params;
    return (
      <div
        style={{
          margin: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          {"<"}
        </button>
        <span className="month-block">{moment(date).format("MMM YYYY")}</span>

        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          {">"}
        </button>
      </div>
    );
  };
  render() {
    let { gender, showPasswords, phone_number } = this.state;
    let { confirmPass, password } = showPasswords;

    return (
      <React.Fragment>
        <GlobalHeader type={"login"} />
        <div className="body-wrapper2">
          <div className="title-head">
            <div className="">
              <h4>COVID-19 Registration</h4>
              <p>Take care of your health and protect others.</p>
            </div>
          </div>
          <div className="login-wrapper signup-page w-100 pull-left">
            <div className="signup-wrapper log-main m-auto w-100 h-100">
              <div style={{ paddingLeft: "15px" }}>{secureText}</div>
              <div className="col-md-8 pull-left">
                <form
                  className="form-wrapper pull-left w-100"
                  onSubmit={this.handleSubmit}
                >
                  <div className="title-head p-0 mb-4">
                    <h4>Sign Up</h4>
                    <p>It’s quick and easy.</p>
                  </div>

                  <div className="col-sm-6 pull-left signup-pad">
                    <h6 className="fnt-12 text-white">First name</h6>
                    <input
                      type="text"
                      name="first_name"
                      required
                      className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="col-sm-6 pull-left signup-pad2">
                    <h6 className="fnt-12 text-white">Last name (Optional)</h6>
                    <input
                      type="text"
                      name="last_name"
                      className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                      placeholder=""
                    />
                  </div>
                  <div className="col-lg-12 pull-left">
                    <div className="row">
                      <h6 className="fnt-12 text-white">Date of birth</h6>
                      <div className="date-picking w-100 mb-20 position-relative">
                        <DatePicker
                          className={"form-control date-input w-100"}
                          selected={this.state.date_of_birth}
                          maxDate={new Date()}
                          // renderCustomHeader={(params) => {
                          //   return this.renderCustomHeaderPicker(params);
                          // }}
                          name="date_of_birth"
                          onChange={(e) => {
                            this.changeStates("date_of_birth", e);
                          }}
                        />
                        <span className="date-dwn-arw">
                          <img src={datedwnarw} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 pull-left">
                    <div className="row">{this.racialGroupBlock()}</div>
                  </div>
                  <div className="col-sm-12 pull-left">
                    <div className="row">
                      <div class="form-group position-relative w-100 mb-20">
                        <label class="fnt-12 text-white">Gender</label>
                        <button
                          type="button"
                          class="form-control self-inp position-relative text-left w-100"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <span class="glyphicon glyphicon-cog"></span>
                          {gender}
                          <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu self-inp2 text-white w-100 pt-0 pb-0">
                          <li
                            class="mb-2"
                            onClick={(e) => this.changeStates("gender", "Male")}
                          >
                            <span
                              class="large com-drop sign-up-drp"
                              data-value="option0"
                              tabindex="-1"
                            >
                              Male
                            </span>
                          </li>
                          <li
                            class="mb-2"
                            onClick={(e) =>
                              this.changeStates("gender", "Female")
                            }
                          >
                            <span
                              class="large com-drop sign-up-drp border-bottom-0"
                              data-value="option0"
                              tabindex="-1"
                            >
                              Female
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {this.renderStateSelectBlock()}
                  {this.renderCitySelectBlock()}
                  <div className="col-sm-12 pull-left">
                    <div className="row">
                      <h6 className="fnt-12 text-white">Address (Optional)</h6>
                      <input
                        name="address"
                        type="text"
                        className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 pull-left">
                    <div className="row">
                      <h6 className="fnt-12 text-white">Zip code</h6>
                      <input
                        name="zip"
                        type="text"
                        className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                        placeholder=""
                      />
                    </div>
                  </div>

                  <div className="col-sm-12 pull-left">
                    <div className="row">
                      <h6 className="fnt-12 text-white">Email address</h6>
                      <input
                        name="email"
                        type="email"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                        className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 pull-left">
                    <div className="row">
                      <h6 className="fnt-12 text-white w-100">Password</h6>
                      <div className="position-relative w-100">
                        <input
                          name="password"
                          type={password ? "text" : "password"}
                          required
                          className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                          placeholder=""
                        />
                        <span
                          onClick={(e) =>
                            this.setState({
                              showPasswords: {
                                ...showPasswords,
                                password: !password,
                              },
                            })
                          }
                        >
                          {!password ? (
                            <img className="eye-icn" src={eye} alt="" />
                          ) : (
                            <img className="eye-icn" src={hiddensye} alt="" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 pull-left">
                    <div className="row">
                      <h6 className="fnt-12 text-white w-100">
                        Password again
                      </h6>
                      <div className="position-relative w-100">
                        <input
                          name="confirm_password"
                          type={confirmPass ? "text" : "password"}
                          required
                          className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                          placeholder=""
                        />
                        <span
                          onClick={(e) =>
                            this.setState({
                              showPasswords: {
                                ...showPasswords,
                                confirmPass: !confirmPass,
                              },
                            })
                          }
                        >
                          {!confirmPass ? (
                            <img className="eye-icn" src={eye} alt="" />
                          ) : (
                            <img className="eye-icn" src={hiddensye} alt="" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 pull-left">
                    <div className="row">
                      <h6 className="fnt-12 text-white">
                        Phone number (Enter number to verify your account.)
                      </h6>
                      <input
                        type="text"
                        name="phone_number"
                        pattern="[0-9+]*"
                        value={phone_number}
                        onChange={(e) => {
                          e.target.validity.valid &&
                            this.setState({ phone_number: e.target.value });
                        }}
                        required
                        className="w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="w-100 pull-left">
                    <button className="btn pl-4 pr-4 btn-submit w-100 fnt-14">
                      Submit
                    </button>
                  </div>
                  <div className="text-center w-100 fnt-13 ald-pad text-white pull-left w-100">
                    Already have an account?{" "}
                    <a
                      onClick={this.login}
                      className="color Gilroy-Medium"
                      href=""
                    >
                      Login
                    </a>
                  </div>
                  <div className="text-center w-100 fnt-11 nav-p terms-wdt m-auto w-100 d-table">
                    By proceeding, I agree to SamsCloud{" "}
                    <a href="" className="color">
                      {`Terms of Use `}
                    </a>
                    {"  "}
                    and <a href="">Privacy Policy.</a>
                    <br />I also confirm that the above information about me is
                    correct and true.
                  </div>
                </form>
              </div>
              <div className="col-md-4 pull-left">
                <div className="signup-right position-relative pull-left w-100">
                  <div class="circle-wrapper position-relative m-auto d-table">
                    <div class="circle pull-left"></div>
                    <div class="circle circle-pad pull-left">
                      <span class="cir-line"></span>
                    </div>
                    <div class="circle circle-pad pull-left"></div>
                  </div>
                  <div className="signup-rht-cont text-center">
                    <img src={firstaid} alt="" className="mb-20" />
                    <h6 className="text-center text-white">Join the fight</h6>
                    <p className="nav-p fnt-13 m-auto text-center">
                      Together we can win the battle and slow the spread of
                      COVID-19. Join us.
                    </p>
                  </div>
                  <span className="nav-p signip-rht-btm text-center fnt-13">
                    Need help?{" "}
                    <a href="" className="color">
                      Contact Us
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
