import React, { Component } from "react";
import AddAccessRiskForm from "./addAccessRiskForm";
import AccessRiskLocationList from "./locationListing";
import moment from "moment";
import EvaluatedAccessRisk from "./evaluatedAccessRisk";
import { checkForDuplicates } from "../../utils/checkForDuplicateArray";
import { toastNotification } from "../commons/toastNotification";
import { accessRiskLoader } from "../../constants/text-constants";
import history from "../history";
import { locationMap } from "../GoogleMap/locationMapInit";
import AccessRiskMapBlock from "./mapBlock";
import { minimumScreenRatio } from "../../constants/configurations";

export default class QuickAccessRisk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showValidation: false,
      mobileView: false,
    };
  }

  componentDidMount() {
    let list = localStorage.getItem("addedLocs");
    let { localLocationList } = this.props.reducer;
    if (list) {
      let parsed = JSON.parse(list);
      let array = parsed.map((item, idx) => {
        return { ...item, id: idx, alreadyAdded: true };
      });
      this.props.handleChange({
        name: "localLocationList",
        value: array,
      });
    }
    locationMap({
      mapId: "location-map",
      addNewLocation: this.handleChange,
      locationsAdded: localLocationList,
      mobileView: this.state.mobileView
    });
    this.resizeEvent = window.addEventListener(
      "resize",
      this.resize.bind(this)
    );
  }
  componentWillUnmount = () => {
    if (this.resizeEvent) this.resizeEvent();
  };
  resize() {
    this.setState({ mobileView: window.innerWidth <= minimumScreenRatio });
  }
  componentDidUpdate(prevProps, prevState) {
    let { localLocationList } = this.props.reducer;
    if (
      prevProps.reducer.localLocationList !==
      this.props.reducer.localLocationList
    ) {
      locationMap({
        mapId: "location-map",
        addNewLocation: this.handleChange,
        locationsAdded: localLocationList,
        mobileView: this.state.mobileView
      });
    }
  }
  clearLocation = () => {
    document.getElementById("pac-input").value = "";
  };
  handleChange = (params) => {
    let { name, value, type } = params;
    let { mode } = this.props.reducer;
    if (mode === "add") {
      this.props.handleChange({
        parentName: "addAccessRiskVariables",
        name,
        value,
        type,
      });
    } else {
      this.props.handleChange({
        parentName: "addAccessRiskVariables",
        name,
        value,
        type,
      });
    }
  };

  handleChangeMapLocation = (params) => {
    let { name, value, type } = params;
    this.props.handleChange({ name, value, type });
  };
  toggleValidation = () => {
    this.setState({
      showValidation: !this.state.showValidation,
    });
  };
  addLocationToList = (params) => {
    let { localLocationList, addAccessRiskVariables } = this.props.reducer;
    let { location } = addAccessRiskVariables;
    this.toggleValidation();
    if (location.length) {
      if (
        !checkForDuplicates({
          array: localLocationList,
          pivot: "location",
          name: location,
        })
      ) {
        this.props.handleChange({ mode: "add", name: "localLocationList" });
        this.props.handleChange({
          type: "reset",
          name: "addAccessRiskVariables",
        });
        this.clearLocation();
      } else
        toastNotification({
          msg: "You already added this location! Please try another ",
          type: "warning",
        });
    } else
      toastNotification({
        msg: "Location is a required field !",
        type: "warning",
      });
  };

  deleteLocation = (params) => {
    let { id } = params;
    this.props.handleChange({ mode: "delete", name: "localLocationList", id });
  };
  formatVariables = (body) => {
    let reqBody = body.map((item) => {
      let date = item.from_time;
      date = date ? date : moment();
      return {
        ...item,
        from_time: item.alreadyAdded
          ? item.from_time
          : moment(date).format("HH:mm:s"),
        to_time: item.alreadyAdded
          ? item.to_time
          : moment(date).add(5, "minute").format("HH:mm:s"),
      };
    });
    return reqBody;
  };
  addAccessRiskAction = () => {
    let { localLocationList } = this.props.reducer;
    let body = this.formatVariables(localLocationList);
    this.props.addUnauthuserLocation(body, (res) => {
      if (res.status === 200) {
        localStorage.setItem("addedLocs", JSON.stringify(res.data));
        this.props.handleChange({ name: "activePage", value: "evaluate-risk" });
        history.push({
          pathname: "/accessrisk/result",
        });
      }
    });
  };
  contentHandler = () => {
    let path = history.location.pathname;
    if (path === "/accessrisk/result") {
      return (
        <>
          {this.evaluatedRiskPage()}
          {this.accessRiskMap(true)}
        </>
      );
    } else return this.addRiskPage();
  };
  accessRiskMap = (hideMap) => {
    let {mobileView} = this.state;
    return (
      <AccessRiskMapBlock
        {...this.props}
        hideMap={hideMap}
        mobileView={mobileView}
        handleChange={this.handleChange}
      />
    );
  };
  addRiskPage = () => {
    let { addAccessRisk } = this.props.reducer;
    let { loading } = addAccessRisk;
    return (
      <div class="col-lg-8 col-sm-12 assess-risk-mapping">
        {loading ? (
          accessRiskLoader
        ) : (
          <>
            {this.accessRiskMap(false)}
            <button
              class="btn btn-block btn-assess-success Gilroy-Semibold"
              onClick={this.addAccessRiskAction}
            >
              Evaluate Risk
            </button>
          </>
        )}
      </div>
    );
  };

  evaluatedRiskPage = () => {
    let { addAccessRisk } = this.props.reducer;
    let { loading } = addAccessRisk;
    return (
      <EvaluatedAccessRisk
        loading={loading}
        addAccessRiskAction={this.addAccessRiskAction}
      />
    );
  };
  render() {
    let {
      addAccessRiskVariables,
      localLocationList,
      accessRiskConf,
    } = this.props.reducer;
    return (
      <div class="body-wrapper2">
        <div class="content-wrapper assess-risk-body">
          <div class="col-sm-12">
            <div class="row ">
              <div class="col-lg-4 col-sm-12">
                <AddAccessRiskForm
                  handleChangeForm={this.handleChange}
                  addLocationToList={this.addLocationToList}
                  variables={addAccessRiskVariables}
                  showValidation={this.state.showValidation}
                  {...this.props}
                />
                <AccessRiskLocationList
                  localLocationList={localLocationList}
                  deleteLocation={this.deleteLocation}
                  rowConf={accessRiskConf}
                />
              </div>

              {this.contentHandler()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
