import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_USER_FLIGHTS_SUCCESS,
  LIST_USER_FLIGHTS_FAILED,
  LIST_USER_FLIGHTS_STARTED
} from "../../../../constants/action-types";
import { GET_FLIGHTS_LIST_URL } from "../../../../constants/configurations";

export const listFlightActions = body => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "get",
      api: GET_FLIGHTS_LIST_URL,
      started: LIST_USER_FLIGHTS_STARTED,
      success: LIST_USER_FLIGHTS_SUCCESS,
      failure: LIST_USER_FLIGHTS_FAILED
    });
  };
};
