import React, { Component } from "react";
import { drawLoadingMap } from "../../GoogleMap/staticMap";
import loading from '../../../assets/images/globeloading.gif'
export default class MapLoading extends Component {
  componentDidMount() {
    drawLoadingMap({ mapId: "loading-map" });
  }

  render() {
    return (
      <div className="map-container">
        {/* <span className="loading-text-map">
          <img src={loading} alt="gif-loading"/>
        </span> */}

        <div
          className="graphCanvas"
          id={"loading-map"}
          style={{ height: `${window.innerHeight}px` }}
        ></div>
      </div>
    );
  }
}
