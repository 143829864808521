import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  DELETE_USER_CONTACTS_SUCCESS,
  DELETE_USER_CONTACTS_FAILED,
  DELETE_USER_CONTACTS_STARTED
} from "../../../../constants/action-types";

export const deleteUserContactsAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "delete",
        api: `/contacts/${body.id}/delete/`,
        started: DELETE_USER_CONTACTS_STARTED,
        success: DELETE_USER_CONTACTS_SUCCESS,
        failure: DELETE_USER_CONTACTS_FAILED
      },
      callBack
    );
  };
};
