import { connect } from "react-redux";

/**importing actions */
import {
  handleChange,
  fetchUsersAction,
  getUserInfoAction,
  addUserReportAction,
  updateUserReportAction,
  handleChangeLR,
  listFlightActions,
  listUserContactsAction,
  listUserVaccinesAction,
  listUserTestingsAction,
  deleteUserContactsAction,
  listFlightCarriersAction,
  addUserFlightsAction,
  addUserContactsAction,
  addLocationAction,
  addUserVaccinesAction,
  deleteUserVaccinesAction,
  updateUserVaccinesAction,
  getDosesListAction,
  getManufacturersListAction,
  addUserTestingsAction,
  deleteUserTestingsAction,
  updateUserTestingsAction,
  listLocationAction,
  deleteUserFlightsAction,
  updateUserFlightsAction,
  getAllTravelLocation,
  updateUserContactAction,
  listSymptomsActions,
  newUserLocation,
  editUserLocation,
  deleteUserLocation,
  getStatusListAction,
} from "../../redux/actions";

/**importing components */
import SelfReport from "./selfReport";

const componentActions = (dispatch) => {
  return {
    handleChange: (params) => {
      dispatch(handleChange(params));
    },
    handleChangePopups: (params) => {
      dispatch(handleChangeLR(params));
    },
  };
};
const apiActions = (dispatch) => {
  return {
    fetchUsersAction: (params) => {
      dispatch(fetchUsersAction(params));
    },
    getUserInfoAction: (params) => {
      dispatch(getUserInfoAction(params));
    },
    getStatusListAction: (params) => {
      dispatch(getStatusListAction(params));
    },
    newUserLocation: (params, next) => {
      dispatch(newUserLocation(params, next));
    },
    deleteUserLocation: (params, next) => {
      dispatch(deleteUserLocation(params, next));
    },
    editUserLocation: (params, next) => {
      dispatch(editUserLocation(params, next));
    },
    getAllTravelLocation: (params, next) => {
      dispatch(getAllTravelLocation(params, next));
    },
    addUserReportAction: (params, next) => {
      dispatch(addUserReportAction(params, next));
    },
    updateUserReportAction: (params, next) => {
      dispatch(updateUserReportAction(params, next));
    },
    updateUserContactAction: (params, next) => {
      dispatch(updateUserContactAction(params, next));
    },
    listFlightActions: (params) => {
      dispatch(listFlightActions(params));
    },
    addUserContactsAction: (params, next) => {
      dispatch(addUserContactsAction(params, next));
    },
    listUserContactsAction: (params, next) => {
      dispatch(listUserContactsAction(params, next));
    },
    deleteUserContactsAction: (params, next) => {
      dispatch(deleteUserContactsAction(params, next));
    },
    listFlightCarriersAction: (params, next) => {
      dispatch(listFlightCarriersAction(params, next));
    },
    addUserFlightsAction: (params, next) => {
      dispatch(addUserFlightsAction(params, next));
    },
    listLocationAction: (params, next) => {
      dispatch(listLocationAction(params, next));
    },
    addLocationAction: (params, next) => {
      dispatch(addLocationAction(params, next));
    },
    deleteUserFlightsAction: (params, next) => {
      dispatch(deleteUserFlightsAction(params, next));
    },
    updateUserFlightsAction: (params, next) => {
      dispatch(updateUserFlightsAction(params, next));
    },
    listSymptomsActions: (params, next) => {
      dispatch(listSymptomsActions(params, next));
    },
    listUserVaccinesAction: (params, next) => {
      dispatch(listUserVaccinesAction(params, next));
    },
    addUserVaccinesAction: (params, next) => {
      dispatch(addUserVaccinesAction(params, next));
    },
    updateUserVaccinesAction: (params, next) => {
      dispatch(updateUserVaccinesAction(params, next));
    },
    deleteUserVaccinesAction: (params, next) => {
      dispatch(deleteUserVaccinesAction(params, next));
    },
    getDosesListAction: (params, next) => {
      dispatch(getDosesListAction(params, next));
    },
    getManufacturersListAction: (params, next) => {
      dispatch(getManufacturersListAction(params, next));
    },
    listUserTestingsAction: (params, next) => {
      dispatch(listUserTestingsAction(params, next));
    },
    addUserTestingsAction: (params, next) => {
      dispatch(addUserTestingsAction(params, next));
    },
    updateUserTestingsAction: (params, next) => {
      dispatch(updateUserTestingsAction(params, next));
    },
    deleteUserTestingsAction: (params, next) => {
      dispatch(deleteUserTestingsAction(params, next));
    },
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ...componentActions(dispatch),
    ...apiActions(dispatch),
  };
};

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    reducer: state.selfReportReducer,
    latestReportReducer: state.latestReportReducer,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelfReport);
