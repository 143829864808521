import React from "react";

/**constants */
import { homePageConst } from "../../../constants/text-constants";
import { StatusLoader } from "../../commons/loader";
import { formatNumber } from "../../../utils/numberFormatters";
let { selfReportTitle } = homePageConst.sideBarConst;

export const SelfReportInfo = (props) => {
  let { count } = props.selectedCountryMapList;
  const generateStatusArray = () => {
    let { cases } = props.selectedCountryMapList;
    if (cases.length) {
      return cases.map((casesItem) => {
        return (
          <p key={casesItem.id} className="fnt-12">
            <span
              className={`cir ${casesItem.color} pull-left mar-5 mr-2`}
            ></span>
            {casesItem.status}
            <span className="pull-right nav-p">
              {formatNumber(casesItem.count || 0)}
            </span>
          </p>
        );
      });
    }
  };
  const { affectedCountry ,selfReportCount} = props.reducer;
  const { loading } = affectedCountry;
  return (
    <div className="self-rep-cases mt-3 mb-3 w-100 pull-left rounded">
      <h6 className="text-uppercase nav-p fnt-10 let-spac-8">
        {selfReportTitle}
      </h6>
      {!loading ? (
        <React.Fragment>
          <h5 className="clr-blue mb-3 Gilroy-Semibold">
            {formatNumber(selfReportCount)}
          </h5>
          {generateStatusArray()}
        </React.Fragment>
      ) : (
        <StatusLoader />
      )}
    </div>
  );
};
