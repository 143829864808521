import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_SYMPTOMS_SUCCESS,
  LIST_SYMPTOMS_FAILED,
  LIST_SYMPTOMS_STARTED
} from "../../../../constants/action-types";
import { GET_SYMTOMS_URL } from "../../../../constants/configurations";

export const listSymptomsActions = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "get",
      api: GET_SYMTOMS_URL,
      started: LIST_SYMPTOMS_STARTED,
      success: LIST_SYMPTOMS_SUCCESS,
      failure: LIST_SYMPTOMS_FAILED
    },callBack);
  };
};
