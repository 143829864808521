import React from "react";

/**constants */
import { latestReportConst } from "../../../constants/text-constants";
import { StatusLoader } from "../../commons/loader";
import { abbreviate } from "../../../utils/abbreviate";

const {
  reportText,
  statusText,
  testedText,
  vaccinatedText
} = latestReportConst.contentPaneTexts.summaryText;

/**functional component definition */
export const UserSummary = (props) => {
  let { report, status, tested, vaccinated, loading } = props;

  const userSummaryBlock = () => {
    return (
      <React.Fragment>
        <div className="user-tt">
          <p>{reportText}</p>
          <span className="infected">{report}</span>
        </div>
        <div className="user-tt">
          <p>{statusText}</p>
          <span className="active-t" data-toggle="tooltip" title={`${status}`}>
            {abbreviate(status, 6)}
          </span>
        </div>
        <div className="user-tt">
          <p>{testedText}</p>
          <span className="tested">{tested}</span>
        </div>
        <div className="user-tt">
          <p>{vaccinatedText}</p>
          <span className="tested">{vaccinated}</span>
        </div>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      {!loading ? userSummaryBlock() : <StatusLoader />}
    </React.Fragment>
  );
};
