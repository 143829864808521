/**This file defineds the set of action types used in this app */

/**auth actions */
export const LOGIN_STARTED = "LOGIN_STARTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const GET_GOOGLE_ALL_STATE_LOCATION_SUCCESS  = "GET_GOOGLE_ALL_STATE_LOCATION_SUCCESS";
export const GET_GOOGLE_ALL_STATE_LOCATION_FAILED ="GET_GOOGLE_ALL_STATE_LOCATION_FAILED";
export const GET_GOOGLE_ALL_STATE_LOCATION_STARTED = "GET_GOOGLE_ALL_STATE_LOCATION_STARTED";

export const GET_GOOGLE_STATE_LOCATION_SUCCESS  = "GET_GOOGLE_STATE_LOCATION_SUCCESS";
export const GET_GOOGLE_STATE_LOCATION_FAILED   = "GET_GOOGLE_STATE_LOCATION_FAILED";
export const GET_GOOGLE_STATE_LOCATION_STARTED  = "GET_GOOGLE_STATE_LOCATION_STARTED";




export const CREATE_SUBSCRIPRION_SUCCESS  = "CREATE_SUBSCRIPRION_SUCCESS";
export const CREATE_SUBSCRIPRION_FAILED   = "CREATE_SUBSCRIPRION_FAILED";
export const CREATE_SUBSCRIPRION_STARTED  = "CREATE_SUBSCRIPRION_STARTED";

export const ADD_ACTIVITY_SUCCESS  = "ADD_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_FAILED   = "ADD_ACTIVITY_FAILED";
export const ADD_ACTIVITY_STARTED  = "ADD_ACTIVITY_STARTED";

export const DELETE_ACTIVITY_SUCCESS  = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILED   = "DELETE_ACTIVITY_FAILED";
export const DELETE_ACTIVITY_STARTED  = "DELETE_ACTIVITY_STARTED";

export const LIST_ACTIVITY_SUCCESS  = "LIST_ACTIVITY_SUCCESS";
export const LIST_ACTIVITY_FAILED   = "LIST_ACTIVITY_FAILED";
export const LIST_ACTIVITY_STARTED  = "LIST_ACTIVITY_STARTED";

export const SIGNUP_STARTED = "SIGNUP_STARTED";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

export const OTP_VERIFICATION_STARTED = "OTP_VERIFICATION_STARTED";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_FAILED = "OTP_VERIFICATION_FAILED";

export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const FORGOT_PASSWORD_VERIFY_STARTED = "FORGOT_PASSWORD_VERIFY_STARTED";
export const FORGOT_PASSWORD_VERIFY_SUCCESS = "FORGOT_PASSWORD_VERIFY_SUCCESS";
export const FORGOT_PASSWORD_VERIFY_FAILED = "FORGOT_PASSWORD_VERIFY_FAILED";

export const FORGOT_PASSWORD_OTP_STARTED = "FORGOT_PASSWORD_OTP_STARTED";
export const FORGOT_PASSWORD_OTP_SUCCESS = "FORGOT_PASSWORD_OTP_SUCCESS";
export const FORGOT_PASSWORD_OTP_FAILED = "FORGOT_PASSWORD_OTP_FAILED";

export const CHANGE_PHONENUMBER_STARTED = "CHANGE_PHONENUMBER_STARTED";
export const CHANGE_PHONENUMBER_SUCCESS = "CHANGE_PHONENUMBER_SUCCESS";
export const CHANGE_PHONENUMBER_FAILED = "CHANGE_PHONENUMBER_FAILED";

/**Component actions types defined below */
export const HANDLE_CHANGE_SELF_REPORT = "HANDLE_CHANGE_SELF_REPORT";
export const HANDLE_CHANGE_ACTIVITY_FILTER = "HANDLE_CHANGE_ACTIVITY_FILTER";
export const HANDLE_CHANGE_ACCESS_RISK = "HANDLE_CHANGE_ACCESS_RISK";
export const HANDLE_CHANGE_LR_PAGE = "HANDLE_CHANGE_LR_PAGE";
export const HANDLE_CHANGE_HOME_PAGE = "HANDLE_CHANGE_HOME_PAGE";
export const HANDLE_CHANGE_COUNTRY_SELECT = "HANDLE_CHANGE_COUNTRY_SELECT";
export const HANDLE_CHANGE_GLOBAL_HEADER = "HANDLE_CHANGE_GLOBAL_HEADER";

/** Third party actions defined here */
export const GET_REGION_WITH_ZIP_STARTED = "GET_REGION_WITH_ZIP_STARTED";
export const GET_REGION_WITH_ZIP_SUCCESS = "GET_REGION_WITH_ZIP_SUCCESS";
export const GET_REGION_WITH_ZIP_FAILED = "GET_REGION_WITH_ZIP_FAILED";

/**API action types defined below */
export const FETCH_COVID_SUMMARY_STARTED = "FETCH_COVID_SUMMARY_STARTED";
export const FETCH_COVID_SUMMARY_SUCCESS = "FETCH_COVID_SUMMARY_SUCCESS";
export const FETCH_COVID_SUMMARY_FAILED = "FETCH_COVID_SUMMARY_FAILED";

export const GET_USER_INFO_STARTED = "GET_USER_INFO_STARTED";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

export const GET_ORG_LIST_STARTED = "GET_ORG_LIST_STARTED";
export const GET_ORG_LIST_SUCCESS = "GET_ORG_LIST_SUCCESS";
export const GET_ORG_LIST_FAILED = "GET_ORG_LIST_FAILED";


export const GET_SUBSCRIPTIONS_LIST_STARTED = "GET_SUBSCRIPTIONS_LIST_STARTED";
export const GET_SUBSCRIPTIONS_LIST_SUCCESS = "GET_SUBSCRIPTIONS_LIST_SUCCESS";
export const GET_SUBSCRIPTIONS_LIST_FAILED = "GET_SUBSCRIPTIONS_LIST_FAILED";

export const CREATE_SUBSCRIPTIONS_LIST_STARTED = "CREATE_SUBSCRIPTIONS_LIST_STARTED";
export const CREATE_SUBSCRIPTIONS_LIST_SUCCESS = "CREATE_SUBSCRIPTIONS_LIST_SUCCESS";
export const CREATE_SUBSCRIPTIONS_LIST_FAILED = "CREATE_SUBSCRIPTIONS_LIST_FAILED";

export const FETCH_COVID_TOTAL_INFO_STARTED = "FETCH_COVID_TOTAL_INFO_STARTED";
export const FETCH_COVID_TOTAL_INFO_SUCCESS = "FETCH_COVID_TOTAL_INFO_SUCCESS";
export const FETCH_COVID_TOTAL_INFO_FAILED = "FETCH_COVID_TOTAL_INFO_FAILED";

export const  LIST_COVID_DETAILS_COUNTRY_STARTED = "LIST_COVID_DETAILS_COUNTRY_STARTED";
export const  LIST_COVID_DETAILS_COUNTRY_SUCCESS = "LIST_COVID_DETAILS_COUNTRY_SUCCESS";
export const  LIST_COVID_DETAILS_COUNTRY_FAILED = "LIST_COVID_DETAILS_COUNTRY_FAILED";

export const FETCH_USERS_ACTION_STARTED = "FETCH_USERS_ACTION_STARTED";
export const FETCH_USERS_ACTION_SUCCESS = "FETCH_USERS_ACTION_SUCCESS";
export const FETCH_USERS_ACTION_FAILED = "FETCH_USERS_ACTION_FAILED";

export const ADD_USER_REPORT_STARTED = "ADD_USER_REPORT_STARTED";
export const ADD_USER_REPORT_SUCCESS = "ADD_USER_REPORT_SUCCESS";
export const ADD_USER_REPORT_FAILED = "ADD_USER_REPORT_FAILED";

export const ADD_USER_CONTACTS_STARTED = "ADD_USER_CONTACTS_STARTED";
export const ADD_USER_CONTACTS_SUCCESS = "ADD_USER_CONTACTS_SUCCESS";
export const ADD_USER_CONTACTS_FAILED = "ADD_USER_CONTACTS_FAILED";

export const DELETE_USER_CONTACTS_STARTED = "DELETE_USER_CONTACTS_STARTED";
export const DELETE_USER_CONTACTS_SUCCESS = "DELETE_USER_CONTACTS_SUCCESS";
export const DELETE_USER_CONTACTS_FAILED = "DELETE_USER_CONTACTS_FAILED";

export const LIST_USER_CONTACTS_STARTED = "LIST_USER_CONTACTS_STARTED";
export const LIST_USER_CONTACTS_SUCCESS = "LIST_USER_CONTACTS_SUCCESS";
export const LIST_USER_CONTACTS_FAILED = "LIST_USER_CONTACTS_FAILED";

export const UPDATE_USER_CONTACTS_STARTED = "UPDATE_USER_CONTACTS_STARTED";
export const UPDATE_USER_CONTACTS_SUCCESS = "UPDATE_USER_CONTACTS_SUCCESS";
export const UPDATE_USER_CONTACTS_FAILED = "UPDATE_USER_CONTACTS_FAILED";

export const UPDATE_USER_REPORT_STARTED = "UPDATE_USER_REPORT_STARTED";
export const UPDATE_USER_REPORT_SUCCESS = "UPDATE_USER_REPORT_SUCCESS";
export const UPDATE_USER_REPORT_FAILED = "UPDATE_USER_REPORT_FAILED";

export const LIST_USER_FLIGHTS_STARTED = "LIST_USER_FLIGHTS_STARTED";
export const LIST_USER_FLIGHTS_SUCCESS = "LIST_USER_FLIGHTS_SUCCESS";
export const LIST_USER_FLIGHTS_FAILED = "LIST_USER_FLIGHTS_FAILED";

export const DELETE_USER_FLIGHT_STARTED = "DELETE_USER_FLIGHT_STARTED";
export const DELETE_USER_FLIGHT_SUCCESS = "DELETE_USER_FLIGHT_SUCCESS";
export const DELETE_USER_FLIGHT_FAILED = "DELETE_USER_FLIGHT_FAILED";

export const UPDATE_USER_FLIGHT_STARTED = "UPDATE_USER_FLIGHT_STARTED";
export const UPDATE_USER_FLIGHT_SUCCESS = "UPDATE_USER_FLIGHT_SUCCESS";
export const UPDATE_USER_FLIGHT_FAILED = "UPDATE_USER_FLIGHT_FAILED";

export const LIST_FLIGHT_CARRIERS_STARTED = "LIST_FLIGHT_CARRIERS_STARTED";
export const LIST_FLIGHT_CARRIERS_SUCCESS = "LIST_FLIGHT_CARRIERS_SUCCESS";
export const LIST_FLIGHT_CARRIERS_FAILED = "LIST_FLIGHT_CARRIERS_FAILED";

export const ADD_USER_FLIGHT_STARTED = "ADD_USER_FLIGHT_STARTED";
export const ADD_USER_FLIGHT_SUCCESS = "ADD_USER_FLIGHT_SUCCESS";
export const ADD_USER_FLIGHT_FAILED = "ADD_USER_FLIGHT_FAILED";

export const LIST_USER_VACCINES_STARTED = "LIST_USER_VACCINES_STARTED";
export const LIST_USER_VACCINES_SUCCESS = "LIST_USER_VACCINES_SUCCESS";
export const LIST_USER_VACCINES_FAILED = "LIST_USER_VACCINES_FAILED";

export const DELETE_USER_VACCINE_STARTED = "DELETE_USER_VACCINE_STARTED";
export const DELETE_USER_VACCINE_SUCCESS = "DELETE_USER_VACCINE_SUCCESS";
export const DELETE_USER_VACCINE_FAILED = "DELETE_USER_VACCINE_FAILED";

export const UPDATE_USER_VACCINE_STARTED = "UPDATE_USER_VACCINE_STARTED";
export const UPDATE_USER_VACCINE_SUCCESS = "UPDATE_USER_VACCINE_SUCCESS";
export const UPDATE_USER_VACCINE_FAILED = "UPDATE_USER_VACCINE_FAILED";

export const ADD_USER_VACCINE_STARTED = "ADD_USER_VACCINE_STARTED";
export const ADD_USER_VACCINE_SUCCESS = "ADD_USER_VACCINE_SUCCESS";
export const ADD_USER_VACCINE_FAILED = "ADD_USER_VACCINE_FAILED";

export const LIST_USER_TESTINGS_STARTED = "LIST_USER_TESTINGS_STARTED";
export const LIST_USER_TESTINGS_SUCCESS = "LIST_USER_TESTINGS_SUCCESS";
export const LIST_USER_TESTINGS_FAILED = "LIST_USER_TESTINGS_FAILED";

export const DELETE_USER_TESTING_STARTED = "DELETE_USER_TESTING_STARTED";
export const DELETE_USER_TESTING_SUCCESS = "DELETE_USER_TESTING_SUCCESS";
export const DELETE_USER_TESTING_FAILED = "DELETE_USER_TESTING_FAILED";

export const UPDATE_USER_TESTING_STARTED = "UPDATE_USER_TESTING_STARTED";
export const UPDATE_USER_TESTING_SUCCESS = "UPDATE_USER_TESTING_SUCCESS";
export const UPDATE_USER_TESTING_FAILED = "UPDATE_USER_TESTING_FAILED";

export const ADD_USER_TESTING_STARTED = "ADD_USER_TESTING_STARTED";
export const ADD_USER_TESTING_SUCCESS = "ADD_USER_TESTING_SUCCESS";
export const ADD_USER_TESTING_FAILED = "ADD_USER_TESTING_FAILED";

export const LIST_USER_LOCATIONS_STARTED = "LIST_USER_LOCATIONS_STARTED";
export const LIST_USER_LOCATIONS_SUCCESS = "LIST_USER_LOCATIONS_SUCCESS";
export const LIST_USER_LOCATIONS_FAILED = "LIST_USER_LOCATIONS_FAILED";

export const ADD_USER_LOCATIONS_STARTED = "ADD_USER_LOCATIONS_STARTED";
export const ADD_USER_LOCATIONS_SUCCESS = "ADD_USER_LOCATIONS_SUCCESS";
export const ADD_USER_LOCATIONS_FAILED = "ADD_USER_LOCATIONS_FAILED";

export const ADD_UNAUTH_USER_LOCATION_STARTED = "ADD_UNAUTH_USER_LOCATION_STARTED";
export const ADD_UNAUTH_USER_LOCATION_SUCCESS = "ADD_UNAUTH_USER_LOCATION_SUCCESS";
export const ADD_UNAUTH_USER_LOCATION_FAILED = "ADD_UNAUTH_USER_LOCATION_FAILED";

export const EDIT_USER_LOCATION_STARTED = "EDIT_USER_LOCATION_STARTED";
export const EDIT_USER_LOCATION_SUCCESS = "EDIT_USER_LOCATION_SUCCESS";
export const EDIT_USER_LOCATION_FAILED = "EDIT_USER_LOCATION_FAILED";

export const NEW_USER_LOCATION_STARTED = "NEW_USER_LOCATION_STARTED";
export const NEW_USER_LOCATION_SUCCESS = "NEW_USER_LOCATION_SUCCESS";
export const NEW_USER_LOCATION_FAILED = "NEW_USER_LOCATION_FAILED";

export const DELETE_USER_LOCATION_STARTED = "DELETE_USER_LOCATION_STARTED";
export const DELETE_USER_LOCATION_SUCCESS = "DELETE_USER_LOCATION_SUCCESS";
export const DELETE_USER_LOCATION_FAILED = "DELETE_USER_LOCATION_FAILED";

export const GET_GOOGLE_PLACES_STARTED = "GET_GOOGLE_PLACES_STARTED";
export const GET_GOOGLE_PLACES_SUCCESS = "GET_GOOGLE_PLACES_SUCCESS";
export const GET_GOOGLE_PLACES_FAILED = "GET_GOOGLE_PLACES_FAILED";

export const GET_SELF_REPORT_WITH_ZIP_STARTED = "GET_SELF_REPORT_WITH_ZIP_STARTED";
export const GET_SELF_REPORT_WITH_ZIP_SUCCESS = "GET_SELF_REPORT_WITH_ZIP_SUCCESS";
export const GET_SELF_REPORT_WITH_ZIP_FAILED = "GET_SELF_REPORT_WITH_ZIP_FAILED";

export const GET_USER_TRAVEL_LOCATION_STARTED = "GET_USER_TRAVEL_LOCATION_STARTED";
export const GET_USER_TRAVEL_LOCATION_SUCCESS = "GET_USER_TRAVEL_LOCATION_SUCCESS";
export const GET_USER_TRAVEL_LOCATION_FAILED = "GET_USER_TRAVEL_LOCATION_FAILED";

export const GET_SELF_REPORTED_LOCATION_STARTED = "GET_SELF_REPORTED_LOCATION_STARTED";
export const GET_SELF_REPORTED_LOCATION_SUCCESS = "GET_SELF_REPORTED_LOCATION_SUCCESS";
export const GET_SELF_REPORTED_LOCATION_FAILED = "GET_SELF_REPORTED_LOCATION_FAILED";

export const GET_CITY_LIST_WITH_STATE_STARTED = "GET_CITY_LIST_WITH_STATE_STARTED";
export const GET_CITY_LIST_WITH_STATE_SUCCESS = "GET_CITY_LIST_WITH_STATE_SUCCESS";
export const GET_CITY_LIST_WITH_STATE_FAILED = "GET_CITY_LIST_WITH_STATE_FAILED";

export const LIST_SYMPTOMS_STARTED = "LIST_SYMPTOMS_STARTED";
export const LIST_SYMPTOMS_SUCCESS = "LIST_SYMPTOMS_SUCCESS";
export const LIST_SYMPTOMS_FAILED = "LIST_SYMPTOMS_FAILED";

export const LIST_STATUS_STARTED = "LIST_STATUS_STARTED";
export const LIST_STATUS_SUCCESS = "LIST_STATUS_SUCCESS";
export const LIST_STATUS_FAILED = "LIST_STATUS_FAILED";

export const LIST_DOSES_STARTED = "LIST_DOSES_STARTED";
export const LIST_DOSES_SUCCESS = "LIST_DOSES_SUCCESS";
export const LIST_DOSES_FAILED = "LIST_DOSES_FAILED";

export const LIST_MANUFACTURES_STARTED = "LIST_MANUFACTURES_STARTED";
export const LIST_MANUFACTURES_SUCCESS = "LIST_MANUFACTURES_SUCCESS";
export const LIST_MANUFACTURES_FAILED = "LIST_MANUFACTURES_FAILED";

export const LIST_COVID_DETAILS_STARTED = "LIST_COVID_DETAILS_STARTED";
export const LIST_COVID_DETAILS_SUCCESS = "LIST_COVID_DETAILS_SUCCESS";
export const LIST_COVID_DETAILS_FAILED = "LIST_COVID_DETAILS_FAILED";

export const LIST_GLOBAL_EFFECT_STARTED = "LIST_GLOBAL_EFFECT_STARTED";
export const LIST_GLOBAL_EFFECT_SUCCESS = "LIST_GLOBAL_EFFECT_SUCCESS";
export const LIST_GLOBAL_EFFECT_FAILED = "LIST_GLOBAL_EFFECT_FAILED";

export const COUNTY_WISE_COVID_INFO_STARTED = "COUNTY_WISE_COVID_INFO_STARTED";
export const COUNTY_WISE_COVID_INFO_SUCCESS = "COUNTY_WISE_COVID_INFO_SUCCESS";
export const COUNTY_WISE_COVID_INFO_FAILED = "COUNTY_WISE_COVID_INFO_FAILED";

export const LIST_COVID_STATEWISE_STATUS_STARTED =
  "LIST_COVID_STATEWISE_STATUS_STARTED";
export const LIST_COVID_STATEWISE_STATUS_SUCCESS =
  "LIST_COVID_STATEWISE_STATUS_SUCCESS";
export const LIST_COVID_STATEWISE_STATUS_FAILED =
  "LIST_COVID_STATEWISE_STATUS_FAILED";

export const GET_USER_LAST_UPDATE_SUCCESS ="GET_USER_LAST_UPDATE_SUCCESS";
export const GET_USER_LAST_UPDATE_FAILED = "GET_USER_LAST_UPDATE_FAILED";
export const GET_USER_LAST_UPDATE_STARTED = "GET_USER_LAST_UPDATE_STARTED";
