/**common action handler :
 *
 * defining a common structure for axios
 */
import { ApiBase } from "./apiBaseInstance";

const actionStarted = (params) => {
  let { started } = params;
  return {
    type: started,
  };
};

const actionSuccess = (params) => {
  let { success, res, failure } = params;
  if (res.status === 200 || res.status === 201) {
    
    return {
      type: success,
      payload: { response: res.data },
    };
  } else
    return {
      type: failure,
      payload: { error: res.data },
    };
};
const actionFailed = (params) => {
  let { failure, error } = params;
  return {
    type: failure,
    payload: { error: error },
  };
};

export const apiServiceHandler = (params, callback = null) => {
  let { api, body, dispatch, methordType } = params;
  dispatch(actionStarted(params));
  const apiBaseInstance = new ApiBase();
  return apiBaseInstance.instance[methordType](api,  body )
    .then((response) => {
      dispatch(actionSuccess({ ...params, callback, res: response }));
      callback && callback(response);
    })
    .catch((error) => {
      dispatch(actionFailed({ ...params, error }));
      callback && callback(error);
    });
};
