import React, { Component } from "react";
import { TableBuilder } from "../../commons/tableBuilder";
import { contactsEmptyState } from "../../../constants/text-constants";

export default class ContactsListingBlock extends Component {
  render() {
    let { addContactTableConf, addedContactList } = this.props.reducer;
    let infectedContacts = addedContactList.filter(item=>item.is_infected);
    return (
      <div className="col-sm-6 p-10 pull-left">
        <div className="contact-confirmed element-bg">
          <div className="d-flex contact-confirmed-top item-center align-items-stretch">
            <h1>{infectedContacts.length}</h1>
            <h4>
              Contact
              <br />
              Confirmed
            </h4>
            <button
              type="button"
              className="ml-auto"
              data-toggle="modal"
              data-target="#contact-people"
              onClick={() => this.props.toggleModal("showContactModal")}
            >
              + Add Contact
            </button>
          </div>
          {infectedContacts.length ? (
            <TableBuilder
              classNameName="table"
              editToDelete={this.props.editToDelete}
              disableDropDown={true}
              editButton={true}
              tableItemList={infectedContacts}
              tableConfig={addContactTableConf}
              onEditItem={this.props.onEditContact}
              clickEvent={() => this.props.toggleModal("showContactModal")}
            />
          ) : (
            contactsEmptyState
          )}
        </div>
      </div>
    );
  }
}
