import React, { Component } from "react";

export default class OtpHeader extends Component {
  render() {
    return (
      <div className="title-head">
        <div className="">
          <h4>COVID-19 Registration</h4>
          <p>Take care of your health and protect others.</p>
        </div>
      </div>
    );
  }
}
