import React from "react";
import { BuildFormHandler } from "../BuildFields";
import { latestReportConst } from "../../../constants/text-constants";
import { AddContactLocationModal } from "./addContactLocationModal.jsx";
import UserAddedContactBlock from "./userAddedContactBlock";

const { locationText, descText } = latestReportConst.addContactTexts;
export const ACBody = props => {
  let {
    addContactConfig,
    addContactForm,
    handleFormChange,
    toggleSubModal,
    submitContactForm,
    close
  } = props;
  let { editContactForm, contactFormMode } = props.reducer;
  return (
    <React.Fragment>
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-5">
            <BuildFormHandler
              formDefinition={addContactConfig}
              variables={
                contactFormMode === "add" ? addContactForm : editContactForm
              }
              handleFormChange={handleFormChange}
            />
            <div className="add-loca-1">
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a
                data-toggle="modal"
                data-target="#loca1"
                onClick={toggleSubModal} href="/"
              >
                {contactFormMode === "add" ? (addContactForm.location !== '' ? addContactForm.location : locationText) :(editContactForm.location !== '' ? editContactForm.location : locationText)}
              </a>
              <p>{descText}</p>
            </div>
            <div className="text-right mt-3 mb-5">
              <button className="add-location-btn" onClick={submitContactForm}>
                {contactFormMode === "add" ? "Add Contact" : "Update Contact"}
              </button>
            </div>
          </div>
          <UserAddedContactBlock {...props} />
        </div>
        <button class="btn btn-block btn-submit fnt-14 SFProText-Regular" onClick={close}>
          Submit
        </button>
      </div>
      <AddContactLocationModal {...props} />
    </React.Fragment>
  );
};
