import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_UNAUTH_USER_LOCATION_SUCCESS,
  ADD_UNAUTH_USER_LOCATION_FAILED,
  ADD_UNAUTH_USER_LOCATION_STARTED,
} from "../../../../constants/action-types";
import { ADD_UNAUTH_USER_LOCATION } from "../../../../constants/configurations";

export const addUnauthuserLocation = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api: ADD_UNAUTH_USER_LOCATION,
        started: ADD_UNAUTH_USER_LOCATION_STARTED,
        success: ADD_UNAUTH_USER_LOCATION_SUCCESS,
        failure: ADD_UNAUTH_USER_LOCATION_FAILED,
      },
      callBack
    );
  };
};
