import React, { Component } from "react";
import AddLocationModal from "../../commons/AddLocationModal/addLocationModal";
import MinifiedAccessRisk from "../../commons/MinifiedAccessRisk/minifiedAccessRisk";

export default class HomeModals extends Component {
  componentDidMount() {
    let list = localStorage.getItem("addedLocs");
    if (list) {
      let parsed = JSON.parse(list);
      let array = parsed.map((item, idx) => {
        return { ...item, id: idx ,alreadyAdded:true };
      });

      this.props.handleChangePopups({
        name: "localLocationList",
        value: array,
      });
    }
  }

  toggleModal = (type) => {
    let visbility = this.props.latestReportReducer[type];
    this.props.handleChangePopups({
      name: type,
      value: !visbility,
    });
  };

  render() {
    let {
      showUnauthModal,
      userInfo,
      showRiskDashboard,
    } = this.props.latestReportReducer;

    return (
      <React.Fragment>
        {showUnauthModal && (
          <AddLocationModal
            unAuthorizedUser={true}
            newUserLocation={this.props.newUserLocation}
            getUserInfoAction={this.props.getUserInfoAction}
            deleteUserLocation={this.props.deleteUserLocation}
            editUserLocation={this.props.editUserLocation}
            showModal={showUnauthModal}
            listLocationAction={this.props.listLocationAction}
            handleChange={this.props.handleChangePopups}
            getAllTravelLocation={this.props.getAllTravelLocation}
            addLocationAction={this.props.addLocationAction}
            addUnauthuserLocation={this.props.addUnauthuserLocation}
            toggleModal={() => this.toggleModal("showUnauthModal")}
            reducer={this.props.latestReportReducer}
          />
        )}
        <MinifiedAccessRisk
          showRiskDashboard={showRiskDashboard}
          toggleModal={() => this.toggleModal("showRiskDashboard")}
          {...userInfo}
          getUserLastUpdate={this.props.getUserLastUpdate}
        />
      </React.Fragment>
    );
  }
}
