import React, { Component } from "react";
import { homePageConst, alertIcon } from "../../../constants/text-constants";
import moment from "moment";
import { StatusLoader } from "../../commons/loader";
import { abbreviate } from "../../../utils/abbreviate";

let { reportedLocDesc, reportedLocTitle } = homePageConst.sideBarConst;
export default class ReportedLocationBlock extends Component {
  reportedList = () => {
    let { list } = this.props.reportedLocation;
    if (list && list.length) {
      return list.map((reportInfo) => {
        let {
          id,
          location,
          first_exposure_date,
          latest_exposure_date,
          exposure,
        } = reportInfo;
        let lastDate = moment().diff(latest_exposure_date, "days");
        let dateString = lastDate ? `${lastDate} day ago` : "today";
        return (
          <div
            key={id}
            className="Exposure-locations mb-2 bg-white rounded position-relative p-0 pb-2 border-bottom"
          >
            <div className="d-flex align-items-stretch item-center">
              <div className="user-int bg-clr-loc">
                {abbreviate(location, 1, true)}
              </div>
              <div className="Exposure-txt text-dark">
                <h4 className="fnt-14" title={location}>
                  {abbreviate(location, 20)}
                </h4>
                <p className="add-wdth fnt-12" title={location}>
                  {location}
                </p>
                <h6 className="fnt-10 Gilroy-Medium">
                  First Exposure : {moment(first_exposure_date).format("L")}
                </h6>
                <p className="nav-p fnt-10 mb-2">
                  Last Report Date:{" "}
                  <span className="clr-blue">{dateString}</span>
                </p>
              </div>
              <div className="Exposure-location-info">
                <h4 className="clr-blue fnt-12">Region</h4>
                <h6 className="text-dark  fnt-12">
                  {alertIcon} {exposure} Reports
                </h6>
              </div>
            </div>
          </div>
        );
      });
    }
    return [];
  };

  render() {
    let { list, loading } = this.props.reportedLocation;
    return (
      <div className="rep-loc-filter pull-left w-100">
        <h6 className="Gilroy-Medium let-spac">{reportedLocTitle}</h6>
        <input
          type="text"
          placeholder="Search location..."
          className="pt-0 pb-0 pl-3 pr-3 fnt-12 self-rep-cases heit-26 let-spac-5 nav-p rounded w-100"
        />
        {!loading ? (
          <React.Fragment>
            <h6 className="mb-4 mt-4 let-spac Gilroy-Medium fnt-14">{`${reportedLocDesc} (${list.length})`}</h6>
            <div className="location-min-ht">{this.reportedList()}</div>
          </React.Fragment>
        ) : (
          <StatusLoader height={"200px"} />
        )}
      </div>
    );
  }
}
