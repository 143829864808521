import React, { Component } from "react";
import { TableBuilder } from "../commons/tableBuilder";
import { locationEmptyState } from "../../constants/text-constants";
import loc_assess from "../../assets/images/group-6.svg";

export default class AccessRiskLocationList extends Component {
  generateLocation = () => {
    let { localLocationList, rowConf, deleteLocation } = this.props;
    return (
      <TableBuilder
        emptyState={locationEmptyState}
        onDeleteItem={deleteLocation}
        tableItemList={localLocationList}
        tableConfig={rowConf}
        // noDropDown={true}
        disableDropDown={true}
        deleteButton={true}
        clickEvent={() => {}}
        // editContact={editContact}
      />
    );
  };
  render() {
    let { localLocationList } = this.props;
    return (
      <div class="contact-confirmed element-bg assess-risk-table p-0">
        <div class="d-flex contact-confirmed-top item-center align-items-stretch">
          <h1>{localLocationList.length}</h1>
          <h4>
            Added
            <br />
            Location
          </h4>
          <img class="pull-right ml-auto" src={loc_assess} alt="" />
        </div>
        {this.generateLocation()}
      </div>
    );
  }
}
