/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { CustomButton } from "./buttons";
import closeIcon from '../../assets/images/close-img.svg'
export const CommonModalHeader = (props) => {
  let { title, toggleHandler, customTitle } = props;
  return (
    <React.Fragment>
      {customTitle ? customTitle : <h4>{title}</h4>}
      <CustomButton
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        clickEvent={toggleHandler}
        text={
          <a
          >
            <span aria-hidden="true">
              <img src={closeIcon} alt="closeicon"/>
            </span>
          </a>
        }
      />
    </React.Fragment>
  );
};
