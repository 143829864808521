import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  DELETE_USER_FLIGHT_SUCCESS,
  DELETE_USER_FLIGHT_FAILED,
  DELETE_USER_FLIGHT_STARTED
} from "../../../../constants/action-types";
import { DELET_USER_FLIGHTS_URL } from "../../../../constants/configurations";

export const deleteUserFlightsAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "delete",
        api: DELET_USER_FLIGHTS_URL(body.id),
        started: DELETE_USER_FLIGHT_STARTED,
        success: DELETE_USER_FLIGHT_SUCCESS,
        failure: DELETE_USER_FLIGHT_FAILED
      },
      callBack
    );
  };
};
