import { toastNotification } from "../../commons/toastNotification";

export const checkFieldValid = (value) => {
    if (value.length) return true;
    else {
      toastNotification({
        msg: "This field cannot be empty",
        type: "warning",
      });
      return false;
    }
  };