import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  GET_SELF_REPORT_WITH_ZIP_STARTED,
  GET_SELF_REPORT_WITH_ZIP_SUCCESS,
  GET_SELF_REPORT_WITH_ZIP_FAILED,
} from "../../../../constants/action-types";
import { REPORTED_LOCATION_WITH_ZIP_URL } from "../../../../constants/configurations";

export const getReportedLocationWithZip = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: REPORTED_LOCATION_WITH_ZIP_URL(body),
        started: GET_SELF_REPORT_WITH_ZIP_STARTED,
        success: GET_SELF_REPORT_WITH_ZIP_SUCCESS,
        failure: GET_SELF_REPORT_WITH_ZIP_FAILED,
      },
      callBack
    );
  };
};
