import React, { Component } from "react";
import { locationMap } from "../GoogleMap/locationMapInit";

export default class AccessRiskMapBlock extends Component {
  componentDidMount() {
    let { localLocationList } = this.props.reducer;
    locationMap({
      mapId: "location-map",
      addNewLocation: this.props.handleChange,
      locationsAdded: localLocationList,
      mobileView: this.props.mobileView,
    });
  }

  render() {
    let { hideMap } = this.props;
    return (
      <div
        className="graphCanvas"
        style={hideMap ? { display: "none" } : {}}
        id={"location-map"}
      ></div>
    );
  }
}
