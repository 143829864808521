import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  UPDATE_USER_TESTING_SUCCESS,
  UPDATE_USER_TESTING_FAILED,
  UPDATE_USER_TESTING_STARTED
} from "../../../../constants/action-types";
import {
    UPDATE_USER_TESTING_URL
} from "../../../../constants/configurations";

export const updateUserTestingsAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "patch",
        api: UPDATE_USER_TESTING_URL(body.get('id')),
        started: UPDATE_USER_TESTING_STARTED,
        success: UPDATE_USER_TESTING_SUCCESS,
        failure: UPDATE_USER_TESTING_FAILED
      },
      callBack
    );
  };
};
