import React, {Component} from "react";
import {ModalWindow} from "../modal";
import {QRBody} from "./QRBody";
import moment from "moment";

export default class QRModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vaccinated_date: '',
            since_latest_test: '',
            user_status: false,
            qr_code: '',
            width: ''
        };
    }

    componentWillMount(){
        this.setState({width: window.innerWidth + 'px'});
    }

    render() {
        let {showModal, toggleModal, QRCode, sinceLatestTest, vaccinatedDate } = this.props;
        return (
            <>
                <ModalWindow
                    showModal={showModal}
                    onModalClose={toggleModal}
                    className="qr-modal"
                    body={
                        <QRBody
                            {...this.props}
                            vaccinatedDate={vaccinatedDate}
                            sinceLatestTest={sinceLatestTest}
                            QRCode={QRCode}
                            width={this.state.width}
                            closeModal={toggleModal}
                        />
                    }
                />
            </>)
    }

}