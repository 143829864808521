import React, { Component } from "react";
import { mapStyles } from "./config";
import { defaultLatLng } from "../../constants/configurations";
// import history from '../history';
import blueMarker from "../../assets/images/Exposedlocation@3x.svg";
import blueMarkerMobile from "../../assets/images/Exposedlocation@3x-mob.svg";

const google = window.google;

var map = null;
var marker = null;
var contactMarkers = [];
var icon = {
  url: blueMarker,
  size: new google.maps.Size(50, 50),
  origin: new google.maps.Point(0, 0),
};

export const locationMap = (props) => {
  let { mapId } = props;
  map = new window.google.maps.Map(document.getElementById(mapId), {
    center: defaultLatLng,
    zoom: props.zoom || 10,
    styles: mapStyles,
    zoomControl: true,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL,
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    minZoom: 2,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  });
  addSearch(props);
};
const drawSelectedLocations = (props) => {
  marker && marker.setVisible(false);
  contactMarkers = [];
  let { locationsAdded, mobileView } = props;
  if (mobileView) {
    icon.url = blueMarkerMobile;
  }
  if (locationsAdded.length) {
    let addedList = locationsAdded.map((item) => {
      return {
        ...item,
        position: new google.maps.LatLng(item.latitude, item.longitude),
      };
    });
    for (var i = 0; i < addedList.length; i++) {
      let { location, position } = addedList[i];
      let markers = new google.maps.Marker({
        position: position,
        map: map,
        icon: icon,
        title: `Name: ${location}`,
      });
      contactMarkers.push(markers);
    }
    if (contactMarkers.length) {
      var bounds = new google.maps.LatLngBounds();
      for (var j = 0; j < contactMarkers.length; j++) {
        bounds.extend(contactMarkers[j].getPosition());
      }
      map.fitBounds(bounds);
    }
  }
};
const addSearch = (props) => {
  let { addNewLocation, mobileView } = props;
  if (mobileView) {
    icon.url = blueMarkerMobile;
  }
  var input = document.getElementById("pac-input");
  var autocomplete = new google.maps.places.Autocomplete(input);
  autocomplete.bindTo("bounds", map);
  autocomplete.setFields(["address_components", "geometry", "icon", "name"]);
  autocomplete.addListener("place_changed", () => {
    var place = autocomplete.getPlace();
    if (!place.geometry) {
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }
    var marker = new google.maps.Marker({
      map,
      icon,
      anchorPoint: new google.maps.Point(0, -29),
    });
    addNewLocation({
      name: "location",
      type: "mapAddress",
      value: place,
    });
    // this.drawSelectedLocations();
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17); // Why 17? Because it looks good.
    }
    marker.setPosition(place.geometry.location);
    marker.setVisible(true);
  });
  drawSelectedLocations(props);
};
