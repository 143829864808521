/* eslint-disable no-loop-func */
import React, { Component } from "react";
import { popUpTemplate, mapStyles, activityPopUp } from "./config";
import queryString from "query-string";
import history from "../history";
import { groupAddress } from "../../utils/getRegionInfo";
import { formatNumber } from "../../utils/numberFormatters";

/**all svg icons */
import test_location from "./../../assets/images/Testlocation.svg";
import test_location_mob from "./../../assets/images/Testlocation-mobile.svg";
import blueMarker from "../../../src/assets/images/Exposedlocation@3x.svg";
import blueMarkerMob from "../../../src/assets/images/Exposedlocation@3x-mob.svg";
/**activity icons */
import testCenter from "../../../src/assets/images/markerMap.svg";
import vaccine from "../../../src/assets/images/marker-drug-store.svg";
import foodSupply from "../../../src/assets/images/marker-chess-store.svg";
import supply from "../../../src/assets/images/marker-car.svg";
import moneySupply from "../../../src/assets/images/marker-money.svg";

import { defaultLatLng } from "../../constants/configurations";

const google = window.google;

export default class SelfReportMap extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.infowindow = new google.maps.InfoWindow();
    this.radius_circle = null;
    this.centerApexPoint = null;
    this.markerArray = [];
    this.markers = [];
    this.markersConf = [];
    this.circleCords = null;
    this.inputField = null;
    this.activityMarkers = [];
    this.searchBox = null;
    this.currentLocation = null;
    this.intensityCircles = null;
    this.radius_length = 1000;
    this.miles = 1609.34;
    this.labMarkers = [];
  }
  componentDidMount() {
    let { googleMapType } = this.props;
    /**incase of empty map when there is no data
     * calls a google api by passing in places and gets there lat , lng details
     */
    if (googleMapType === "emptyMap") {
      let parsed = queryString.parse(history.location.search);
      let { city, country, province } = parsed;
      this.props.getGooglePlaceDetailsAction(
        { place: city || country || province },
        (res) => {
          if (res && res.status === 200) {
            let data = res.data.results;
            if (data.length) {
              this.currentLocation = data[0].geometry.location;
            } else alert("place not found!");
            this.drawMap();
          }
        }
      );
    } else this.drawMap();
  }

  componentDidUpdate(prevProps, prevState) {
    /**draw test labs on map data change */
    // console.log('test lab',this.props.stateData)
    if (this.props.activityFilter !== prevProps.activityFilter) {
      this.drawActivityGroups();
    } else if (this.props.activityList !== prevProps.activityList) {
      this.drawActivityGroups();
    } else {
      if (this.props.stateData !== prevProps.stateData) {
        this.drawTestLabs();
      }
      /**redraw when map type changes */
      if (prevProps.googleMapType !== this.props.googleMapType) this.drawMap();
    }
  }
  /**function to draw basic map */
  drawMap = () => {
    let {
      countryList,
      intensityArray,
      googleMapType,
      restriction,
      graphSettings,
    } = this.props;
    let { selectedLocation } = graphSettings;
    this.props.handleChange({ name: "mapLoaded", value: false });
    if (googleMapType.length) {
      if (countryList.length) {
        /**when user first list the countries */
        this.currentLocation = countryList[0].latLng;
      } else if (intensityArray.length) {
        this.currentLocation = intensityArray[0].latLng;
      }
      this.map = new google.maps.Map(document.getElementById("google-maps"), {
        // center: this.currentLocation, sidebar overlap on test lap location icon

        center: this.currentLocation || defaultLatLng,
        // this.currentLocation &&
        // this.props.stateData.locationchoose !== "USAZONE"
        //   ? this.currentLocation
        //   : defaultLatLng,
        zoom: this.props.zoom || 10,
        styles: mapStyles,
        zoomControl: true,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
        minZoom: 2,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        restriction: googleMapType !== "basicMap" ? null : restriction,
        fullscreenControl: false,
      });
      // this.addSearchBox();
      this.markPointsInsideCircle();
    }
    this.drawTestLabs();
    this.drawActivityGroups();
    this.checkIfMapLoaded();
  };
  checkIfMapLoaded = () => {
    google.maps.event.addListenerOnce(this.map, "tilesloaded", () => {
      this.props.handleChange({ name: "mapLoaded", value: true });
    });
  };

  returnFilteredActivities = () => {
    let { activityList, activityFilter } = this.props;
    let filteredList = [];
    if (activityList.length) {
      if (activityFilter.length) {
        for (const key in activityFilter) {
          if (activityFilter.hasOwnProperty(key)) {
            const element = activityFilter[key];
            let list = activityList.filter((item) => item.name === element);
            filteredList = filteredList.concat(list);
          }
        }
      } else filteredList = activityList;
    }
    let { search } = this.props.location;
    let urlParsed = queryString.parse(search);
    let { country, province } = urlParsed;
    if (country) {
      filteredList = filteredList.filter(
        (item) => item.Country_Region === country
      );
    }
    if (province) {
      filteredList = filteredList.filter(
        (item) => item.Province_State === province
      );
    }
    return filteredList;
  };
  drawActivityGroups = () => {
    let { activityFilter } = this.props;
    if (activityFilter.length && !activityFilter.includes("testCenter")) {
      this.clearAllMarkers({ array: this.labMarkers, name: "marker" });
      this.labMarkers = [];
    } else if (!this.labMarkers.length) {
      this.drawTestLabs();
    }
    this.clearAllMarkers({ array: this.activityMarkers, name: "markers" });
    let listOfActivities = this.returnFilteredActivities();
    if (listOfActivities.length) {
      for (const key in listOfActivities) {
        if (listOfActivities.hasOwnProperty(key)) {
          const element = listOfActivities[key];
          this.drawFacilities({ data: element });
        }
      }
    }
    // if(this.activityMarkers.length){
    //   var bounds = new google.maps.LatLngBounds();
    //   for (var i = 0; i < this.activityMarkers.length; i++) {
    //     bounds.extend(this.activityMarkers[i].markers.getPosition());
    //   }

    //   this.map.fitBounds(bounds);
    // }
  };

  getIcons = (type) => {
    switch (type) {
      case "foodAid":
        return foodSupply;
      case "supplies":
        return supply;
      case "financialHelp":
        return moneySupply;
      case "testCenter":
        return testCenter;
      case "vaccine":
        return vaccine;
      default:
        return [];
    }
  };

  clearAllMarkers = (params) => {
    let { array, name } = params;
    if (array && array.length) {
      for (const key in array) {
        if (array.hasOwnProperty(key)) {
          const element = array[key];
          element[name].setMap(null);
        }
      }
    }

    // marker.setMap(null);
  };
  /**Functions draws the acitivities on map such as food suppliers, test center etc. */
  drawFacilities = (params) => {
    let { data } = params;

    var icon = {
      url: this.getIcons(data.name), // url
      // scaledSize: new google.maps.Size(50, 50), // size
    };
    var infowindow = new google.maps.InfoWindow();
    let { latitude, longitude } = data;
    let markerInfo = {};
    markerInfo = {
      info: { ...data },
      markers: new google.maps.Marker({
        position: new google.maps.LatLng(Number(latitude), Number(longitude)),
        map: this.map,
        icon: icon,
        infowindow: new google.maps.InfoWindow({
          pixelOffset: new google.maps.Size(-5, -4),
          content: activityPopUp({ ...data }).html,
        }),
      }),
    };

    window.removeActivty = (params) => {
      this.props.removeActivityCall(params);
    };
    this.activityMarkers.push(markerInfo);

    google.maps.event.addListener(
      markerInfo.markers,
      "click",
      ((markerInfo) => {
        return () => {
          this.clearInfoWindows({
            array: this.labMarkers,
            name: "marker",
          });
          this.clearInfoWindows({
            array: this.activityMarkers,
            name: "markers",
          });
          markerInfo.markers.infowindow.open(this.map, markerInfo.markers);
        };
      })(markerInfo)
    );
  };

  clearInfoWindows = (params) => {
    let { array, name } = params;
    if (array && array.length)
      for (const key in array) {
        if (array.hasOwnProperty(key)) {
          const element = array[key];
          element[name].infowindow.close();
        }
      }
  };
  generatePhoneNumbers = (phoneList) => {
    let phoneNumbers = "";
    for (const key in phoneList) {
      if (phoneList.hasOwnProperty(key)) {
        const element = phoneList[key];
        if (Number(key) === phoneList.length - 1) {
          phoneNumbers += element.number;
        } else phoneNumbers += `${element.number} ,`;
      }
    }
    return phoneNumbers;
  };
  generateAddress = (addressItem) => {
    let address = "";
    if (addressItem) {
      let {
        address_1,
        city,
        region,
        state_province,
        country,
        postal_code,
      } = addressItem;
      let body = {
        address_1,
        city,
        region,
        state_province,
        country,
        postal_code,
      };
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          const element = body[key];
          if (key === "postal_code") {
            address += element;
          } else if (element && element.length && (element).toLowerCase()!=="none") {
            address += `${element}, `;
          }
        }
      }
    }
    return address;
  };
  apiTestLabDataFormatter = (info) => {
    let {
      name,
      physical_address,
      phones,
      regular_schedule,
      description,
    } = info;
    const address = this.generateAddress(physical_address[0]);
    const phone = this.generatePhoneNumbers(phones);
    let from_time =
      (regular_schedule[0] && regular_schedule[0].opens_at) || null;
    let to_time =
      (regular_schedule[0] && regular_schedule[0].closes_at) || null;
    let data = {
      name: "testCenter",
      location: name,
      phone_number: phone,
      address,
      from_time,
      to_time,
      description,
      confirmed:true
    };
    return { ...data };
  };
  drawTestLabs = () => {
    let { mobileView } = this.props;

    if (this.props.stateData.stateData !== null) {
      let testLabs = this.props.stateData.stateData.response;
      var icon = {
        url: this.getIcons("testCenter"), // url
      };
      for (let i = 0; i < testLabs.length; i++) {
        this.testLabMarkers = {};
        this.testLabMarkers = {
          marker: new google.maps.Marker({
            position: {
              lat: testLabs[i].location.lat,
              lng: testLabs[i].location.lng,
            },
            map: this.map,
            icon: icon,
            scaledSize: new google.maps.Size(35, 35),
            infowindow: new google.maps.InfoWindow({
              pixelOffset: new google.maps.Size(-5, -4),
              content: activityPopUp({
                ...this.apiTestLabDataFormatter(testLabs[i]),
              }).html,
            }),
          }),
        };

        this.labMarkers.push(this.testLabMarkers);
      }
    } else this.clearAllMarkers({ array: this.testLabMarkers, name: "marker" });
    this.addListenerToTestLab();
  };
  addListenerToTestLab = () => {
    if (this.labMarkers && this.labMarkers.length) {
      for (const key in this.labMarkers) {
        if (this.labMarkers.hasOwnProperty(key)) {
          const element = this.labMarkers[key];
          google.maps.event.addListener(
            element.marker,
            "click",
            (() => {
              return () => {
                this.clearInfoWindows({
                  array: this.labMarkers,
                  name: "marker",
                });
                this.clearInfoWindows({
                  array: this.activityMarkers,
                  name: "markers",
                });
                element.marker.infowindow.open(this.map, element.marker);
              };
            })(element)
          );
        }
      }
    }
  };
  pushSearchedLocationToUrl = (place) => {
    let { googleMapType } = this.props;
    if (googleMapType !== "basicMap") {
      let params = groupAddress(place[0].address_components);
      let body = {};
      for (const key in params) {
        if (params.hasOwnProperty(key)) {
          const element = params[key];
          if (element.length) body[key] = element;
        }
      }
      let urlItems = queryString.stringify(body);
      history.push({
        search: urlItems,
      });
    }
  };
  /**adding search box  */
  addSearchBox = () => {
    this.inputField = document.getElementById("pac-input") || this.inputField;
    this.searchBox = new google.maps.places.SearchBox(this.inputField);

    this.map.addListener("bounds_changed", () => {
      this.searchBox.setBounds(this.map.getBounds());
    });

    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    this.searchBox.addListener("places_changed", () => {
      var places = this.searchBox.getPlaces();
      if (places && places.length === 0) {
        alert("error occured while fetching data from map...");
        return;
      }
      this.pushSearchedLocationToUrl(places);
      var bounds = new google.maps.LatLngBounds();

      places.forEach((place) => {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        this.circleCords = place.geometry.location;
        this.props.handleChange({
          parentName: "graphSettings",
          name: "selectedLocation",
          value: place.name,
        });
        this.props.handleChange({
          parentName: "graphSettings",
          name: "circleApex",
          value: place.geometry.location,
        });
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
    });
    // if (this.currentLocation) this.drawCircle();
    this.markPointsInsideCircle();
  };

  /**function to draw a circle */
  drawCircle = () => {
    let { radius, circleApex } = this.props.graphSettings;
    this.radius_circle = new google.maps.Circle({
      center: this.currentLocation,
      radius: radius * this.miles,
      clickable: false,
      map: this.map,
    });
    this.radius_circle.setOptions({
      strokeOpacity: 0,
      fillOpacity: 0,
    });

    if (!circleApex) {
      this.centerApexPoint = this.currentLocation;
    } else {
      this.centerApexPoint = circleApex;
      if (this.radius_circle)
        this.map.fitBounds(this.radius_circle.getBounds());
    }
    this.markPointsInsideCircle();
  };

  formatPlaceName = (info) => {
    let { Admin2, Province_State, City, County, Country_Region } = info;
    return `${Admin2 && Admin2.length ? Admin2 + " ," : ""}${
      City && City.length ? City + " ," : ""
    }${
      Province_State && Province_State.length ? Province_State + " ," : ""
    } ${Country_Region}`;
  };
  addCircleTooltip = (name) => {
    let { googleMapType } = this.props;
    for (const key in this.intensityCircles) {
      if (this.intensityCircles.hasOwnProperty(key)) {
        const element = this.intensityCircles[key];
        //circle is the google.maps.Circle-instance
        google.maps.event.addListener(element.path, "mouseover", () => {
          let placeName = "";
          if (googleMapType === "basicMap")
            placeName = this.formatPlaceName(element.info);
          else {
            if (element.info[name].length) {
              placeName = element.info[name];
            } else placeName = element.info["Province_State"];
          }
          this.map
            .getDiv()
            .setAttribute(
              "title",
              this.renderTooltip(
                `${formatNumber(
                  element.info.Confirmed || element.info.count
                )} confirmed cases in ${placeName}`
              )
            );
        });
        google.maps.event.addListener(element.path, "mouseout", () => {
          this.map.getDiv().removeAttribute("title");
        });
      }
    }
  };

  /**tooltip for circle */
  renderTooltip = (value) => {
    return `${value}`;
  };

  addZoomListenersForCircles = (params) => {
    let { count } = params;
    this.map.addListener("zoom_changed", () => {
      var zoom = this.map.getZoom();
      for (const key in this.intensityCircles) {
        if (this.intensityCircles.hasOwnProperty(key)) {
          const circles = this.intensityCircles[key];
          circles.path.setRadius(
            (Math.sqrt(circles.info[count]) * this.radius_length) / zoom
          );
        }
      }
    });
  };
  /**drawing intensity map  for first listing*/
  drawIntensity = (countryList, name) => {
    if (countryList.length) {
      let result = [];
      for (const key in countryList) {
        if (countryList.hasOwnProperty(key)) {
          const city = countryList[key];
          result.push({
            info: city,
            path: new google.maps.Circle({
              strokeColor: "#95B6FB",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#95B6FB",
              fillOpacity: 0.35,
              map: this.map,
              center: city.latLng,
              radius:
                (Math.sqrt(city.count) * this.radius_length) /
                this.map.getZoom(),
            }),
          });
        }
      }
      this.intensityCircles = result;
      this.addCircleTooltip(name);
      //   if (result.length) {
      //     let bounds = new google.maps.LatLngBounds();
      //     result.forEach((circle) => {
      //       bounds.union(circle.path.getBounds());
      //     });
      //     this.map.fitBounds(bounds);
      //   }
    }
    this.addZoomListenersForCircles({ count: "count" });
  };

  gatherIntensityArray = (city) => {
    return {
      info: city,
      path: new google.maps.Circle({
        strokeColor: "#95B6FB",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#95B6FB",
        fillOpacity: 0.35,
        map: this.map,
        center: city.latLng,
        radius:
          (Math.sqrt(city.Confirmed) * this.radius_length) / this.map.getZoom(),
      }),
    };
  };

  drawBasicMap = () => {
    let { globalCountyList } = this.props;
    let list = globalCountyList;
    if (list && list.length) {
      let result = [];
      for (const key in list) {
        if (list.hasOwnProperty(key)) {
          let city = { ...list[key] };

          if (city.Lat && city.Long) {
            city["latLng"] = new google.maps.LatLng(
              Number(city.Lat),
              Number(city.Long)
            );
            result.push(this.gatherIntensityArray(city));
          }
        }
      }
      this.intensityCircles = result;
      this.addCircleTooltip();
      this.addZoomListenersForCircles({ count: "Confirmed" });
    }
  };
  drawIntensityOnly = () => {
    let { countryList } = this.props;
    this.drawIntensity(countryList, "Country_Region");
  };
  drawMarkers = () => {
    let { countryList, mobileView } = this.props;
    var infowindow = new google.maps.InfoWindow();
    /**adding markers */
    console.log("country list", countryList);
    if (countryList.length) {
      for (const key in countryList) {
        if (countryList.hasOwnProperty(key)) {
          const location = countryList[key];
          this.marker_lat_lng = new google.maps.LatLng(
            location.Lat,
            location.Long
          );

          let markers = {
            marker: new google.maps.Marker({
              position: this.marker_lat_lng,
              map: this.map,
              icon:  blueMarker,
              title: location.location,
            }),
            info: location,
          };
          this.markersConf.push(markers);
        }
      }
    }

    /**adding info window to markers */
    if (this.markersConf.length)
      for (const key in this.markersConf) {
        if (this.markersConf.hasOwnProperty(key)) {
          const element = this.markersConf[key];
          google.maps.event.addListener(element.marker, "click", () => {
            infowindow.setContent(popUpTemplate({ info: element.info }).html);
            infowindow.open(this.map, element.marker);
          });
        }
      }
    if (this.props.hasIntensityChart) {
      let { intensityArray, intensityPivotName } = this.props;
      this.drawIntensity(intensityArray, intensityPivotName);
    }
  };
  /** function marks the location inside the bounded region*/
  markPointsInsideCircle = () => {
    let { googleMapType } = this.props;
    switch (googleMapType) {
      case "basicMap":
        this.drawBasicMap();
        break;
      case "proviceMap":
        this.drawMarkers();
        break;
      case "intensityProvinceMap":
        this.drawIntensityOnly();
        break;
      case "zipMarkerMap":
      case "hasCityIntensity":
        this.drawMarkers();
        break;
      default:
        return [];
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.inputBody}
        <div className="graphCanvas" id={"google-maps"}></div>
      </React.Fragment>
    );
  }
}
