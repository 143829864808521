import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  COUNTY_WISE_COVID_INFO_SUCCESS,
  COUNTY_WISE_COVID_INFO_FAILED,
  COUNTY_WISE_COVID_INFO_STARTED,
} from "../../../../constants/action-types";
import { COUNTY_WISE_INFO_URL } from "../../../../constants/configurations";

export const globalCountyWiseInfo = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: COUNTY_WISE_INFO_URL,
        started: COUNTY_WISE_COVID_INFO_STARTED,
        success: COUNTY_WISE_COVID_INFO_SUCCESS,
        failure: COUNTY_WISE_COVID_INFO_FAILED,
      },
      callBack
    );
  };
};
