import React, { Component } from "react";
import { BuildFormHandler } from "../BuildFields";
import { CustomButton } from "../buttons";
import { latestReportConst } from "../../../constants/text-constants";
import UserVisitedLocations from "./userVisitedLocations";
import { StatusLoader } from "../loader";
import { initMap } from "../../googleMap";
import { Prev } from "react-bootstrap/PageItem";

let { addLocText, updateLocatext } = latestReportConst.addLocationTexts;
export default class ALBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationval: "",
      edit: false
    };
  }

  handleLocChange = (params) => {
    this.props.handleChange(params);
  };

  locationstore = (address, locfield) => {
    if (locfield) {
      this.onChangeLoc(address, true);
    }
  };

  componentDidMount = () => {
    let type = this.props.unAuthorizedUser
      ? "localLocationList"
      : "userLocationList";
    let arrayList = this.props.reducer[type];
    let { alreadyAddedLocList } = this.props.reducer;
    arrayList = arrayList.concat(alreadyAddedLocList);
    initMap(
      "map",
      arrayList,
      true,
      this.handleLocChange,
      this.locationstore,
    );
  };

  editContact = () => this.setState({ edit: true }) 

  updateMapForAuthUsers = (prevProps) => {
    let arrayList = this.props.reducer["userLocationList"];
    if (
      this.props.reducer.userLocationList !== prevProps.reducer.userLocationList
    ) {
      initMap("map", arrayList, true, this.handleLocChange, this.locationstore);
    }
    if (
      this.props.reducer.locationFormMode !== prevProps.reducer.locationFormMode
    ) {
      if (this.props.reducer.locationFormMode === "edit") {
        document.getElementById(
          "pac-input"
        ).value = this.props.reducer.editLocationForm.location;
        this.setState({
          locationval: this.props.reducer.editLocationForm.location,
        });
        this.props.handleChange({
          name: "UserLocation",
          value: {
            location: this.props.reducer.editLocationForm.location,
            latitude: this.props.reducer.editLocationForm.latitude,
            longitude: this.props.reducer.editLocationForm.longitude,
          },
        });
      } else {
        this.setState({
          locationval: this.props.reducer.addLocationForm.location,
        });
      }
    }
  };

  updateMapForNonAuthUsers = (prevProps) => {
    let { alreadyAddedLocList,localLocationList } = this.props.reducer;
    let currentList = alreadyAddedLocList.concat(localLocationList);
    if (
      (alreadyAddedLocList !== prevProps.reducer.alreadyAddedLocList) ||  (localLocationList !== prevProps.reducer.localLocationList)
    ) {
      document.getElementById(
        "pac-input"
      ).value = this.props.reducer.editLocationForm.location;
      initMap("map", currentList, true, this.handleLocChange, this.locationstore);

    }
  
  };
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.clear !== prevProps.clear) {
      document.getElementById('pac-input').value = '';
      this.setState({ locationval: "" } , () => {
        this.props.resetClear();
      });
    }
     

    if(this.state.edit) {
      document.getElementById('pac-input').value = this.props.reducer.editLocationForm.location;
      this.setState({
        edit: false,
        locationval: this.props.reducer.editLocationForm.location,
      });
      this.props.handleChange({
        name: "UserLocation",
        value: {
          location: this.props.reducer.editLocationForm.location,
          latitude: this.props.reducer.editLocationForm.latitude,
          longitude: this.props.reducer.editLocationForm.longitude,
        },
      });
    }
    if (this.props.unAuthorizedUser) {
      this.updateMapForNonAuthUsers(prevProps);
    } else {
      this.updateMapForAuthUsers(prevProps);
    } 
  };

  onChangeLoc = (e, val) => {
    if (val) {
      this.props.handleFormChangedLocation({ name: "location", value: e });
    } else {
      this.props.handleFormChangedLocation({
        name: "location",
        value: e.target.value,
      });
    }
  };

  render() {
    let {
      locationLoading,
      addLocationForm,
      addLocFormConfig,
      editLocationForm,
      locationFormMode,
    } = this.props.reducer;

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-6">
            <label>Location</label>
            <input
              className="form-control h-35"
              type={"text"}
              id={"pac-input"}
              onChange={(e) => this.onChangeLoc(e, false)}
            ></input>
          </div>
          <BuildFormHandler
            className="col"
            formDefinition={addLocFormConfig}
            variables={
              locationFormMode === "add" ? addLocationForm : editLocationForm
            }
            handleFormChange={this.props.handleFormChangedLocation}
          />
        </div>
        <div className="text-right mb-20">
          <CustomButton
            clickEvent={this.props.submitAddLocation}
            className="add-location-btn"
            text={locationFormMode === "add" ? addLocText : updateLocatext}
          />
        </div>

        <div className="location-direction">
          <div>
            <div className="location-canvas" id="map"></div>
          </div>
          {locationLoading ? (
            <StatusLoader />
          ) : (
            <UserVisitedLocations editContact={this.editContact} {...this.props} />
          )}
        </div>
        <button
          onClick={
            this.props.unAuthorizedUser
              ? this.props.addUnauthlocations
              : this.props.close
          }
          className="btn btn-block btn-submit fnt-14"
        >
          Submit
        </button>
      </div>
    );
  }
}
