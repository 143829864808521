import React, { Component } from "react";
import { ModalWindow } from "../modal";
import { AOHeader } from "./AOheader";
import { AOBody } from "./AOBody";
import { CustomButton } from "../../commons/buttons";
import { toastNotification } from "../toastNotification";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { latestReportConst } from "../../../constants/text-constants";
import { errorHandler } from "../../../utils/commonErrorHandler";

let { confirmation } = latestReportConst.addContactTexts;

export default class AddOrganizationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,
      showOrganization: "",
      editToDelete: '',
      orgToAdd : [],
      proCode:[],
      org_list : null,
      subscriptions : []
    };
  }
  componentDidMount() {
    // console.log(this.props.reducer.org_subscriptionsList.data);
    this.setState({ org_list : this.props.reducer.orgList.data})
    this.setState({ subscriptions : this.props.reducer.org_subscriptionsList.data})
    this.onSelfReportPreview();
  }


  

  onSelfReportPreview = () => {
    if (this.props.selfReportVariables) {
      let { vaccinated_date, lot, dosage, manufacturer, is_reminded, vaccine_id } = this.props.selfReportVariables;

      let parentName = "addOrganizationForm";
      this.handleFormChange({ parentName, name: 'is_reminded', value: is_reminded })
      this.handleFormChange({ parentName, name: 'lot', value: lot })
      this.handleFormChange({ parentName, name: 'vaccinated_date', value: vaccinated_date })
      this.handleFormChange({ parentName, name: 'manufacturer', value: manufacturer })
      this.handleFormChange({ parentName, name: 'dosage', value: dosage })
    }
  };


  onViewVaccine = (params) => {
    let { id } = params;
    this.setState({
      showOrganization: id,
    });
    this.toggleVaccineViewModal()
  };


  callAddVaccineApi = () => {
    let { addVaccineForm } = this.props.reducer;

    let res = this.formatBody(addVaccineForm);
    let { body, valid } = res;

    if (valid) {
      const formData = new FormData();

      for (const key in body) {
        formData.append(key, body[key]);
      }
      this.props.addUserVaccinesAction(formData, (res) => {
        if (res.status === 201) {
          toastNotification({
            msg: "Added Record Successfully",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listUserVaccinesAction();
          this.props.handleChangeForm({
            type: "reset",
            formType: "vaccine",
          });
        } else {
          toastNotification({
            msg: `Added Record Failed :: ${errorHandler(res.data)}}`,
            type: "error",
          });
        }
      });
    }
  };

  callEditVaccineApi = () => {
    let { editVaccineForm } = this.props.reducer;
    let res = this.formatBody(editVaccineForm);
    let { body, valid } = res;
    if (valid) {
      const formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      this.props.updateUserVaccinesAction(formData, (res) => {
        if (res.status === 200) {
          toastNotification({
            msg: "Update Vaccine Success",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listUserVaccinesAction();
          this.props.handleChangeForm({
            type: "reset",
            formType: "vaccine",
          });
        }
      });
    }
  };
  submitAddVaccine = () => {
    let { vaccineFormMode } = this.props.reducer;
    if (vaccineFormMode === "add") this.callAddVaccineApi();
    else if (vaccineFormMode === "edit") this.callEditVaccineApi();
  };


  handleChangeLocationForm = (params) => {
    this.handleFormChange({ ...params, type: "latLng" });
  };



  handleFormChange = (params) => {
    this.setState({orgToAdd: params})
    if(params) {
      localStorage.setItem("org_id", params.id)
    }
    let { orgFormMode } = this.props.reducer;
    const { name, value, type } = params;
    let parentName = "";
    if (orgFormMode === "add") {
      parentName = "addOrgForm";
    } else if (orgFormMode === "edit") {
      parentName = "editOrgForm";
    }
    this.props.handleChangeForm({ parentName, name, value, type });
  };

  handleProCodeFormChange = (params) => {
    this.setState({proCode: params})
    if(params) {
      localStorage.setItem("org_id", params.id)
    }
    let { orgProCodeFormMode } = this.props.reducer;
    const { name, value } = params;
    let parentName = "";
    if (orgProCodeFormMode === "add") {
      parentName = "addOrgProCodeForm";
    } else if (orgProCodeFormMode === "edit") {
    }
    this.props.handleChangeForm({ parentName, name, value, formType: "ProCode" });
  };

 

  toggleOrganizationViewModal = () => {
    let { showOrganizationViewModal } = this.props.reducer;
    this.props.handleChangeForm({
      name: "showOrganizationViewModal",
      value: !showOrganizationViewModal,
    });
  };

  confirmationModal = () => {
    let { vaccineFormMode, addVaccineForm, editVaccineForm } = this.props.reducer;
    let data = vaccineFormMode === "add" ? addVaccineForm : editVaccineForm;
    if (data.Vaccine_no !== "") {
      this.setState({
        confirmationModal: true,
      });
    } else {
      this.props.toggleModal();
    }
  };

  onBacktoEdit = () => {
    this.setState({ confirmationModal: false });
  };

  close = () => {
    this.props.toggleModal();
    this.setState({ confirmationModal: false });
  };

  submit = () => {
    this.props.toggleModal();
    // this.setState({ confirmationModal: false }, () => {
    //   this.submitAddVaccine();
    // });
    this.setState({ confirmationModal: false });
  };

  onEditOrg = (params) => {
    let { id } = params;
    this.setState({
      editToDelete: id,
    });
    this.props.handleChangeForm({ mode: "edit", id, formType: "Organization" });
  }

  render() {
    let { showModal } = this.props;
    let { confirmationModal} = this.state;
    return (
      <>
        <ModalWindow
          showModal={showModal}
          onModalClose={this.confirmationModal}
          heading={<AOHeader toggleModal={this.confirmationModal} />}
          body={
            <AOBody
              {...this.props}
              handleFormChange={this.handleFormChange}
              handleProCodeFormChange ={this.handleProCodeFormChange}
              toggleViewModal={this.toggleOrganizationViewModal}
              closeModal={this.confirmationModal}
              close={this.close}
              orgToAdd={this.state.orgToAdd}
              proCode={this.state.proCode}
              state = {this.state}
              onEditOrg={this.onEditOrg}
            />
          }
        />
        <ModalWindow
          showModal={confirmationModal}
          onModalClose={this.onBacktoEdit}
          heading={
            <AOHeader toggleModal={this.onBacktoEdit} title={confirmation} />
          }
          body={
            <div className="text-center">
              <h6 className="modal-text mt-3">
                Do you want to Submit the Changes?
              </h6>
              <div class="row mt-4 text-center w-100 m-auto edit-sub-modal">
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Submit"
                    clickEvent={this.submit}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Don't Submit"
                    clickEvent={this.close}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    text="Close"
                    className=" btn btn-block btn-submit fnt-14 SFProText-Regular"
                    clickEvent={this.onBacktoEdit}
                  ></CustomButton>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>
      </>
    );
  }
}
