import React, { useEffect } from "react";
import { CustomButton } from "../buttons";
import UserAddedTestingBlock from "./userAddedTestingBlock";
import { AddTestingLocationModal } from "./addTestingLocationModal";
import { AddTestingViewModal } from "./addTestingViewModal";
import { latestReportConst } from "../../../constants/text-constants";
import { submitText, trashIcon } from "../../../constants/text-constants";
import { BuildFormHandler } from "../BuildFields";
import { Overlay, Popover } from "react-bootstrap";
import { TextInput } from "../textField";

export const ATBody = (props) => {

  const onRemoveTestingImage = (params) => {
    props.handleFormChange({
      parentName: "addTestingForm",
      name: 'file_upload',
      value: 'None'
    });
  };

  // useEffect(() => {
  //   let { testingFormConfig } = props;
  //   let { addedTestingList } = props.reducer

  //   console.log('test444', testingFormConfig)
  //   if (testing_id && addedTestingList.length) {
  //     props.onEditTesting({ id: testing_id})
  //   }
  // }, []);

  const validateField = () => {
    let { testingFormConfig } = props;
    const location = testingFormConfig.find(x => x.name === 'location');

    if (!location.isValid) {
      return "border-red";
    }

    return false;
  };

  const userInputBlock = () => {
    let { testingFormConfig, addTestingForm, toggleLocationModal, toggleViewModal, isDisabledTestingSubmitBtn } = props;
    let { editTestingForm, testingFormMode } = props.reducer;

    const notDisabledBtnClass = !isDisabledTestingSubmitBtn ? 'btn-submit' : '';

    const { locationText } = latestReportConst.addRecordTexts;
    return (
      <div className="col-sm-5">
        <div className="row">
          <div className="col-12">
            <label>Testing Center or Site</label>
            <input
              className={"form-control h-35 " + `${validateField()}`}
              type="text"
              placeholder={locationText}
              value={testingFormMode === "add" ? (addTestingForm.location !== '' ? addTestingForm.location : '') 
                :(editTestingForm.hasOwnProperty('location') && editTestingForm.location !== '' ? editTestingForm.location : '')}
              onChange={toggleLocationModal}
            ></input>
          </div>
          <BuildFormHandler
            formDefinition={testingFormConfig}
            handleFormChange={onRemoveTestingImage}
            variables={testingFormMode === "add" ? addTestingForm : editTestingForm}
            {...props}
          />
        </div>
        <div className="text-right mt-1 mb-1">
              <button className={"add-record-btn w-100 " + `${notDisabledBtnClass}`} onClick={props.submitAddTesting}>
                {testingFormMode === "add" ? "Add Record" : "Update Record"}
              </button>
        </div>
        <AddTestingLocationModal {...props} />
      </div>
    );
  };
  let { testingFormConfig, addTestingForm } = props;
  let { editTestingForm } = props.reducer;
  // console.log('PROPS1245677', props)
  return (
    <div className="modal-body">
      <div className="col-sm-12">
        <div className="row">
          {userInputBlock()}
          <UserAddedTestingBlock {...props} />
        </div>

        <div className="d-flex justify-content-center mt-3">
            { addTestingForm && addTestingForm.file_upload && addTestingForm.file_upload instanceof Blob || editTestingForm && editTestingForm.file_upload && editTestingForm.file_upload instanceof Blob ? (
              <img src={URL.createObjectURL(addTestingForm.file_upload || editTestingForm.file_upload)} style={{ maxWidth: "380px"}} alt="dummy" width="100%" height="100%" />) : ''}
               {editTestingForm && editTestingForm.file_upload && editTestingForm.file_upload !== 'None' && !(editTestingForm.file_upload instanceof Blob) ? (
              <img src={editTestingForm.file_upload} style={{ maxWidth: "380px"}} alt="dummy" width="100%" height="100%" />) : ''}
        </div>
        <div className="d-flex justify-content-end mt-1">
            {addTestingForm && addTestingForm.file_upload && addTestingForm.file_upload !== 'None' || editTestingForm && editTestingForm.file_upload && editTestingForm.file_upload !== 'None' ? (
              <button class="btn modal-remove-btn fnt-14 SFProText-Regular" onClick={onRemoveTestingImage}>
              <span className="modal-remove-icon">{trashIcon}</span> Remove
            </button>) : ''}
        </div>
       
        {/* <button class="btn btn-block btn-submit fnt-14 SFProText-Regular" onClick={props.closeModal}>
          Submit
        </button> */}
        {/* <button
          type="button"
          className="btn btn-block btn-submit fnt-14 mt-4"
          data-container="body"
          data-toggle="popover"
          data-placement="left"
          data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          onClick={props.closeModal}
          >
          {submitText}
        </button>*/}
      </div>
      <AddTestingViewModal {...props} />
    </div>
  );
};
