import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILED,
  DELETE_ACTIVITY_STARTED
} from "../../../../constants/action-types";
import { DELETE_ACTIVITY } from "../../../../constants/configurations";

export const deleteActivity = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "delete",
      api: DELETE_ACTIVITY(body.id),
      started: DELETE_ACTIVITY_STARTED,
      success: DELETE_ACTIVITY_SUCCESS,
      failure: DELETE_ACTIVITY_FAILED
    },callBack);
  };
};
