/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { APP_BASE_NAME } from "../../constants/configurations";
import { toastNotification } from "../commons/toastNotification";
import { setLocalStorageItems } from "./signup";
import GlobalHeader from "../GlobalHeader";
import eye from "../../assets/images/eye.svg";
import hiddensye from "../../assets/images/hidden.svg";
import { errorHandler } from "../../utils/commonErrorHandler";

export const LogIn = (props) => {
  const [loginStats, setLoginStats] = useState(false);
  const [inputType, setInputType] = useState(false);
  if (localStorage.getItem("logged_user"))
    localStorage.removeItem("logged_user");
  const handleSubmit = (event) => {
    event.preventDefault();
    setLoginStats(false);
    localStorage.setItem("email", event.target.elements.email.value);
    props.loginAction(
      {
        email: event.target.elements.email.value,
        password: event.target.elements.password.value,
      },
      (res) => {
        if (res.status === 200) {
          // localStorage.setItem("organization_slug", JSON.stringify(res.data))
          toastNotification({
            msg: "Successfully logged in",
            type: "success",
          });
          setLocalStorageItems(res);
          window.location.href = `/latestreports`;
          // props.history.push({ pathname: `${APP_BASE_NAME}` });
          // props.history.push({ pathname: `/latestreports` });
        } else {
          if (
            res.data.non_field_errors &&
            res.data.non_field_errors[0] ===
              "You have not verified your account. Please verify it before login"
          ) {
            toastNotification({
              msg: "Not a verified user! \n please verify your account",
              type: "info",
            });
            pushPage("verification");
          } else {
            setLoginStats(true);
            toastNotification({
              msg: `${errorHandler(res.data)}`,
              type: "error",
            });
          }
        }
      }
    );
  };
  const pushPage = (name) => {
    props.history.push({ pathname: `${APP_BASE_NAME}/${name}` });
  };
  return (
    <div>
      <GlobalHeader type={"login"} />
      <div className="body-wrapper2 bodyWrapper-pd">
        <div className="title-head">
          <div className="">
            <h4>COVID-19 Report</h4>
            <p>Practice social distancing</p>
          </div>
        </div>
        <div className="login-wrapper login-page-wrapper">
          <div className="log-main m-auto w-100 d-table h-100 pl-3 pr-3">
            <div className="d-table-cell align-middle">
              <div className="w-100 pull-left login-page position-relative">
                <h4 className="text-center text-white mb-0">Welcome back!</h4>
                <p className="nav-p text-center fnt-12">
                  Log in with your email and password
                </p>
                <form onSubmit={handleSubmit}>
                  <h6 className="fnt-12 text-white">Email address</h6>
                  <input
                    name="email"
                    type="email"
                    className="w-100 p-2 rounded mb-3 nav-p self-inp2 form-control"
                    placeholder=""
                  />
                  <h6 className="fnt-12 text-white">Password</h6>
                  <div className="position-relative">
                    <input
                      name="password"
                      type={inputType ? "text" : "password"}
                      className="w-100 p-2 rounded mb-3 nav-p self-inp2 form-control"
                      placeholder=""
                    />
                    <span onClick={(e) => setInputType(!inputType)}>
                      {!inputType ? (
                        <img className="eye-icn" src={eye} alt="" />
                      ) : (
                        <img className="eye-icn" src={hiddensye} alt="" />
                      )}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className="text-center fnt-14 h-35 w-100 text-white border-0 rounded mt-3 mb-2 btn blue Gilroy-Semibold"
                  >
                    Log In
                  </button>
                  <div className="fnt-12 text-center text-white log-pas-reset">
                    Forgot your password?{" "}
                    <a
                      onClick={(e) => pushPage("forgotpassword")}
                      className="color clr-blue link-style"
                    >
                      Reset it
                    </a>
                  </div>
                  <div className="text-center w-100 fnt-12 text-white log-reg">
                    New here?{" "}
                    <a
                      onClick={(e) => pushPage("signup")}
                      className="color clr-blue link-style"
                    >
                      Register
                    </a>
                  </div>
                  <div className="text-center w-100 fnt-11 nav-p w-100">
                    By clicking Log in, I agree to SamsCloud{" "}
                    <a className="color clr-blue link-style">Terms of Use</a>{" "}
                    and <a className="clr-blue link-style">Privacy Policy.</a>
                  </div>
                </form>
                {/* {loginStats ? (
                  <div className="correct-email text-white">
                    <h6 className="fnt-14">Hey, What?</h6>
                    <p className="mb-0 fnt-12">
                      Please enter a correct Email and password.
                      <br /> Note that both fields are case-sensitive.
                    </p>
                    <div className="arrow-left"></div>
                  </div>
                ) : (
                  []
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
