import React, { useEffect, useState } from "react";
import "./style.css";
import { Dropdown } from "react-bootstrap";
import { CustomMenu } from "../../AddOrganizationModal/Filterable/optionMenu";
/**
 * The options array should contain objects.
 * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
 */
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="form-control self-inp position-relative text-left w-100"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

const FilterableDropDownOrg = (props) => {
  const [state, setState] = useState([]);
  const filteData = state.list
    ? state.list.map((el) => ({
        proCod: el.pro_code,
        value: el.organization_name,
        name: el.organization_name,
        id: el.id,
      }))
    : [];

  useEffect(() => {
    if (props.state)
      setState((prev) => ({ ...prev, list: props.state.org_list }));
  }, [props]);

  const changeflightCarrier = (id, name, proCod) => {
    setState((prev) => ({ ...prev, filterOrganization: name, id, proCod }));
    props.handleChange({ name: props.name, id: id, value: name });
  };

  const listFlights = () => {
    if (filteData.length)
      return filteData.map((item) => {
        return (
          <Dropdown.Item
            eventKey={item.id}
            onClick={() => changeflightCarrier(item.id, item.name, item.proCod)}
          >
            {item.name}
          </Dropdown.Item>
        );
      });
  };

  let { value } = props;
  return (
    <div className={"flight-drp-dwn"}>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {value}
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>{listFlights()}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default FilterableDropDownOrg;
