import React, { Component } from "react";
import HomePageGoogleMapWrapper from "./homeMapWrapper";
import { StatusLoader } from "../../commons/loader";
import MapLoading from "./mapLoading";

export default class HomeMapBlock extends Component {
  commonMisConditions = () => {
    let { googleMapType } = this.props.reducer;
    if (googleMapType === "emptyMap") return true;
    else return false;
  };

  commonProps = () => {
    let {
      graphSettings,
      googleMapType,
      listActivity,
      activityFilter,
    } = this.props.reducer;
    let { mobileView } = this.props;
    return {
      location: this.props.location,
      activityList: listActivity.data,
      removeActivityCall: this.props.removeActivityCall,
      activityFilter,
      stateData: this.props.reducer.getStateData,
      getUsaZoneTestLab: this.props.getUsaZoneTestLab,
      getStateLocation: this.props.getStateLocation,
      handleChange: this.props.handleChange,
      graphSettings: graphSettings,
      googleMapType: googleMapType,
      mobileView,
      getGooglePlaceDetailsAction: this.props.getGooglePlaceDetailsAction,
    };
  };

  renderMapLoader = () => {
    return <MapLoading />;
  };
  /**function which renders which map to show up */
  renderMaps = () => {
    let {
      affectedCountry,
      googleMapType,
      reportedLocation,
      cityFetch,
      countyInfoFetch,
      getStateData,
      mapLoading,
    } = this.props.reducer;

    const { loading, list, provinceStates, cityList } = affectedCountry;
    const cityIntensityList = cityFetch.list;
    const testLabLoading = getStateData.loading;
    const cityFetchLoading = cityFetch.loading;
    const loadingReportedLoc = reportedLocation.loading;
    let reportedList = reportedLocation.list.filter(
      (item) => item.latest_exposure_date
    );
    /**initial case where user first sees graph */
    if (!mapLoading && googleMapType.length) {
      if (
        list &&
        list.length &&
        !provinceStates.length &&
        !this.commonMisConditions()
      ) {
        if (!loading && !countyInfoFetch.loading) {
          return (
            <HomePageGoogleMapWrapper
              {...this.commonProps()}
              history={this.props.history}
              restriction={{
                latLngBounds: {
                  north: 85,
                  south: -85,
                  west: -180,
                  east: 179,
                },
                strictBounds: false,
              }}
              countryList={list}
              globalCountyList={countyInfoFetch.list}
              zoom={1}
            />
          );
        } else return this.renderMapLoader();
      } else if (
        provinceStates &&
        provinceStates.length &&
        !cityIntensityList.length &&
        !this.commonMisConditions()
      ) {
        if (!loading && !loadingReportedLoc && !testLabLoading) {
          return (
            <HomePageGoogleMapWrapper
              {...this.commonProps()}
              hasIntensityChart={true}
              intensityPivotName={"Province_State"}
              zoom={3}
              intensityArray={provinceStates}
              countryList={reportedList}
            />
          );
        } else return this.renderMapLoader();
      } else if (cityIntensityList.length && !this.commonMisConditions()) {
        if (!cityFetchLoading && !loadingReportedLoc && !testLabLoading) {
          return (
            <HomePageGoogleMapWrapper
              {...this.commonProps()}
              hasIntensityChart={true}
              intensityPivotName={"City"}
              intensityArray={cityIntensityList}
              countryList={reportedList}
              zoom={6}
            />
          );
        } else return this.renderMapLoader();
      } else if (cityList.length && !this.commonMisConditions()) {
        if (!loading && !loadingReportedLoc) {
          return (
            <HomePageGoogleMapWrapper
              {...this.commonProps()}
              hasIntensityChart={true}
              intensityPivotName={"City"}
              intensityArray={cityList}
              countryList={reportedList}
            />
          );
        } else return this.renderMapLoader();
      } else {
        return (
          <HomePageGoogleMapWrapper
            {...this.commonProps()}
            countryList={[]}
            intensityArray={[]}
            getGooglePlaceDetailsAction={this.props.getGooglePlaceDetailsAction}
          />
        );
      }
    } else return <MapLoading />;
  };
  render() {
    return <div class="map-wrapper position-relative">{this.renderMaps()}</div>;
  }
}
