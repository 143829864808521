import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_USER_VACCINE_SUCCESS,
  ADD_USER_VACCINE_FAILED,
  ADD_USER_VACCINE_STARTED
} from "../../../../constants/action-types";
import { ADD_USER_VACCINE_URL } from "../../../../constants/configurations";

export const addUserVaccinesAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler(
    {
      dispatch,
      body,
      methordType: "post",
      api: ADD_USER_VACCINE_URL,
      started: ADD_USER_VACCINE_STARTED,
      success: ADD_USER_VACCINE_SUCCESS,
      failure: ADD_USER_VACCINE_FAILED
    },callBack);
  };
};
