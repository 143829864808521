import React, { PureComponent } from "react";
import { headerConst } from "../../constants/text-constants";
import { TextInput } from "../commons/textField";
import  search   from "../../../src/assets/images/search.svg";


const { searchInput } = headerConst;

export default class GlobalSearchBar extends PureComponent {
  render() {
    let { searchText } = this.props.reducer;
    let {className} = this.props;
    return (
      <div className={className}>
        <TextInput
          className="w-100 border-0 rounded"
          type="text"
          name="searchText"
          placeholder={searchInput.placeholder}
          handleChange={this.props.handChangeSearchBar}
          keypressEvent={this.props.onUserSearchZip}
          value={searchText}
        />
        <span className="srch-icn">
          <img src={search}  alt="search"/>
          {/* <i className="fa fa-search"></i> */}
        </span>
      </div>
    );
  }
}
