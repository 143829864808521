import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_FLIGHT_CARRIERS_SUCCESS,
  LIST_FLIGHT_CARRIERS_FAILED,
  LIST_FLIGHT_CARRIERS_STARTED
} from "../../../../constants/action-types";
import { GET_FLIGHTS_CARRIERS_LIST_URL } from "../../../../constants/configurations";

export const listFlightCarriersAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GET_FLIGHTS_CARRIERS_LIST_URL,
        started: LIST_FLIGHT_CARRIERS_STARTED,
        success: LIST_FLIGHT_CARRIERS_SUCCESS,
        failure: LIST_FLIGHT_CARRIERS_FAILED
      },
      callBack
    );
  };
};
