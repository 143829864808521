import React, { Component } from "react";

import moment from "moment";
/**images */
// import greenIcon from "../../assets/images/marker.svg";
// import redIcon from "../../assets/images/marker-red.svg";
import ContactedIcon from "../../assets/images/Contacted@3x.svg";
import ContactedIconMob from "../../assets/images/Contacted@3x-mob.svg";
import blueMarker from "../../assets/images/Exposedlocation@3x.svg";
import blueMarkerMob from "../../assets/images/Exposedlocation@3x-mob.svg";

/**constants */
import { mapStyles } from "./config";
import {
  defaultLatLng,
  minimumScreenRatio,
} from "../../constants/configurations";

const google = window.google;

export default class ContactsMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileView: false,
    };
    this.currentLocation = defaultLatLng;
    this.map = null;
    this.inputField = null;
    this.searchBox = null;
    this.markers = [];
    this.locationMarkers = [];
    this.contactMarkers = [];
  }
  /** */
  componentDidMount() {
    this.drawMap();
    this.resizeEvent = window.addEventListener(
      "resize",
      this.resize.bind(this)
    );
  }
  resize() {
    this.setState({ mobileView: window.innerWidth <= minimumScreenRatio });
  }
  componentWillUnmount = () => {
    if (this.resizeEvent) this.resizeEvent();
  };
  componentDidUpdate() {
    this.map = null;
    this.drawMap();
  }
  /**function to draw basic map */
  drawMap = () => {
    let { contactList, userLocationList } = this.props;
    this.map = new google.maps.Map(document.getElementById("riskListing-map"), {
      center: this.currentLocation,
      zoom: 3,
      disableDefaultUI: true,
      styles: mapStyles,
      mapTypeId: "roadmap",
    });
    if (contactList.length) this.drawContactMarkers();
    if (userLocationList.length) this.drawLocationMarkers();

    /** push all markers into an array */
    this.markers = this.locationMarkers.concat(this.contactMarkers);
    if (this.markers.length) {
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < this.markers.length; i++) {
        bounds.extend(this.markers[i].getPosition());
      }

      this.map.fitBounds(bounds);
    }
  };

  drawContactMarkers = () => {
    let { contactList } = this.props;
    let { mobileView } = this.state;
    let contacts = contactList.map((item) => {
      return {
        ...item,
        position: new google.maps.LatLng(item.latitude, item.longitude),
      };
    });
    var icon = {
      url: mobileView ? ContactedIconMob : ContactedIcon,
      size: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
    };
    // console.log('contacts',contacts)
    // create contact markers.
    for (var i = 0; i < contacts.length; i++) {
      let { position, date_created, is_infected } = contacts[i];
      if (is_infected) {
        let markers = new google.maps.Marker({
          position: position,
          icon,
          map: this.map,
          title: `Date: ${moment(date_created).format("DD/MM/YYYY")}`,
        });
        this.contactMarkers.push(markers);
      }
    }
  };

  drawLocationMarkers = () => {
    let { mobileView } = this.state;
    let { userLocationList } = this.props;
    let locations = userLocationList.map((item) => {
      return {
        ...item,
        position: new google.maps.LatLng(item.latitude, item.longitude),
      };
    });
    var icon = {
      url: mobileView ? blueMarkerMob : blueMarker,
      size: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0),
    };
    /**create location markers */
    for (var j = 0; j < locations.length; j++) {
      let { position, location, is_infected } = locations[j];
      if (is_infected) {
        let markers = new google.maps.Marker({
          position: position,
          icon,
          map: this.map,
          title: `region: ${location}`,
        });
        this.locationMarkers.push(markers);
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="location-canvas" id="riskListing-map"></div>
      </React.Fragment>
    );
  }
}
