import React, { PureComponent } from "react";
import { CovidInfo } from "./covidInfo";
import AffectedCountries from "./affectedCountries";
import CountryListing from "./countryListing";
import { googleAddressComplete } from "../AddActivity/googleAutoComplete";

export default class HomeSideBar extends PureComponent {

  
  render() {
    let { covidBrief, affectedCountry } = this.props.reducer;
    // let { mobileView } = this.props;
    return (
      <nav>
        <CovidInfo affectedCountry={affectedCountry} covidBrief={covidBrief} />
        {/* <CountryListing
          affectedCountryList={affectedCountry}
          loading={affectedCountry.loading}
          {...this.props}
        /> */}
        <AffectedCountries
          affectedCountryList={affectedCountry}
          loading={affectedCountry.loading}
          {...this.props}
        />
      </nav>
    );
  }
}
