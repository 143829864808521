import React, { Component } from "react";
import { connect } from "react-redux";
import {
  latestReportConst,
  userIconP,
  flightIconP,
} from "../../../constants/text-constants";
import locationMarker from "../../../../src/assets/images/location.svg";
import moment from "moment";
import { changeTextColor } from "../../../utils/changeRiskColor";
import { StatusLoader } from "../../commons/loader";
const {
  expText,
  riskText,
  InfectedText,
  exposureText,
  flightText,
} = latestReportConst.userDiagnonsticText;

const scoreBoxConf = [
  { title: expText, name: "exposureScore" },
  { title: riskText, name: "risk_level" },
];

const spreadsConf = [
  {
    label: InfectedText,
    icon: userIconP,
    name: "infectedCount",
    text: "Contacts",
  },
  {
    label: exposureText,
    icon: locationMarker,
    name: "exposureCount",
    text: "Location",
  },
  {
    label: flightText,
    icon: flightIconP,
    name: "flightsCount",
    text: "Flight",
  },
];

class UserDiagonistics extends Component {
  scoreBox = () => {
    let { userInfo } = this.props;
    return scoreBoxConf.map((item, index) => {
      return (
        <div key={index} className="score-box">
          <h4>{item.title}</h4>
          <p
            className={`${item.className} `}
            style={{ color: changeTextColor(userInfo[item.name]) }}
          >
            {item.name === "risk_level"
              ? userInfo[item.name].toUpperCase()
              : userInfo[item.name]}
          </p>
        </div>
      );
    });
  };

  contactedSpread = () => {
    return spreadsConf.map((item, index) => {
      return (
        <div key={index} className="">
          <h4>{item.label}</h4>
          {item.text !== "Location" ? (
            <p>
              {item.icon}{" "}
              {this.props[item.name].filter((item) => item.is_infected).length}{" "}
              {item.text}
            </p>
          ) : (
            <p>
              <img className="img-icons-11P" src={item.icon} alt="Location" />{" "}
              {this.props[item.name].filter((item) => item.is_infected).length}{" "}
              {item.text}
            </p>
          )}
        </div>
      );
    });
  };
  render() {
    let { userLastUpdate } = this.props;
    return (
      <div className="score-b element-bg">
        <div className="d-flex justify-content-center text-center score-b-inner">
          {this.scoreBox()}
        </div>
        <div className="d-flex score-b-info justify-content-between ">
          {this.contactedSpread()}
        </div>
        <div className="update-box-d">
          {" "}
          {userLastUpdate.loading ? (
            <StatusLoader />
          ) : (
            <>
              <span>Updated:</span>{" "}
              {moment(userLastUpdate.lastUpdate).fromNow()}
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    userLastUpdate: state.globalHeaderReducer.userLastUpdate,
  };
};

export default connect(mapStateToProps)(UserDiagonistics);
