import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  FETCH_USERS_ACTION_SUCCESS,
  FETCH_USERS_ACTION_FAILED,
  FETCH_USERS_ACTION_STARTED
} from "../../../../constants/action-types";
import { GET_USERS_URL } from "../../../../constants/configurations";

export const fetchUsersAction = payload => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body:payload,
      methordType: "get",
      api: GET_USERS_URL,
      started: FETCH_USERS_ACTION_STARTED,
      success: FETCH_USERS_ACTION_SUCCESS,
      failure: FETCH_USERS_ACTION_FAILED
    });
  };
};
