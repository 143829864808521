import {
  LOGIN_STARTED,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  SIGNUP_STARTED,
  SIGNUP_SUCCESS,
  SIGNUP_FAILED,
  OTP_VERIFICATION_STARTED,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAILED,
  FORGOT_PASSWORD_VERIFY_STARTED,
  FORGOT_PASSWORD_VERIFY_SUCCESS,
  FORGOT_PASSWORD_VERIFY_FAILED,
  FORGOT_PASSWORD_OTP_STARTED,
  FORGOT_PASSWORD_OTP_SUCCESS,
  FORGOT_PASSWORD_OTP_FAILED,
  CHANGE_PASSWORD_STARTED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PHONENUMBER_STARTED,
  CHANGE_PHONENUMBER_SUCCESS,
  CHANGE_PHONENUMBER_FAILED,
} from "../../../constants/action-types";
import {
  POST_LOGIN_CREDENTIAL_URL,
  POST_SIGNUP_INFO_URL,
  POST_OTP_RESEND_VERIFICATION_URL,
  FORGOT_PASSWORD_VERIFY,
  POST_OTP_VERIFICATION_URL,
  FORGOT_PASSWORD_OTP,
  CHANGE_PASSWORD,
  CHANGE_PHONENUMBER_ACTION,
} from "../../../constants/configurations";
import { apiServiceHandler } from "../../../services/apiCallHandler";

export const loginAction = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        noToken: true,
        api: POST_LOGIN_CREDENTIAL_URL,
        started: LOGIN_STARTED,
        success: LOGIN_SUCCESS,
        failure: LOGIN_FAILED,
      },
      callBack
    );
  };
};

export const signupAction = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api: POST_SIGNUP_INFO_URL,
        started: SIGNUP_STARTED,
        success: SIGNUP_SUCCESS,
        failure: SIGNUP_FAILED,
      },
      callBack
    );
  };
};

export const otpAction = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api: POST_OTP_VERIFICATION_URL,
        started: OTP_VERIFICATION_STARTED,
        success: OTP_VERIFICATION_SUCCESS,
        failure: OTP_VERIFICATION_FAILED,
      },
      callBack
    );
  };
};

export const resendOtpAction = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api:  POST_OTP_RESEND_VERIFICATION_URL,
        started: OTP_VERIFICATION_STARTED,
        success: OTP_VERIFICATION_SUCCESS,
        failure: OTP_VERIFICATION_FAILED,
      },
      callBack
    );
  };
};

export const forgotPasswordVerification = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api:  FORGOT_PASSWORD_VERIFY,
        started: FORGOT_PASSWORD_VERIFY_STARTED,
        success: FORGOT_PASSWORD_VERIFY_SUCCESS,
        failure: FORGOT_PASSWORD_VERIFY_FAILED,
      },
      callBack
    );
  };
};

export const forgotPasswordOtp = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api:  FORGOT_PASSWORD_OTP,
        started: FORGOT_PASSWORD_OTP_STARTED,
        success: FORGOT_PASSWORD_OTP_SUCCESS,
        failure: FORGOT_PASSWORD_OTP_FAILED,
      },
      callBack
    );
  };
};

export const changePhoneNumberAction = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api:  CHANGE_PHONENUMBER_ACTION,
        started: CHANGE_PHONENUMBER_STARTED,
        success: CHANGE_PHONENUMBER_SUCCESS,
        failure: CHANGE_PHONENUMBER_FAILED,
      },
      callBack
    );
  };
};

export const changePasswordAction = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api:  CHANGE_PASSWORD,
        started: CHANGE_PASSWORD_STARTED,
        success: CHANGE_PASSWORD_SUCCESS,
        failure: CHANGE_PASSWORD_FAILED,
      },
      callBack
    );
  };
};
