/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

/**constants */
/**Images */

import { latestReportConst, vaccineEmptyState } from "../../../constants/text-constants";
import { TableBuilder } from "../tableBuilder";
import { StatusLoader } from "../loader";
const { addedRecord, viewText } = latestReportConst.addRecordTexts;

export default class UserAddedVaccineBlock extends Component {
  render() {
    let {
      addedVaccineList,
      addVaccineTableConf,
      onDeleteVaccine,
      onEditVaccine,
      onViewVaccine,
      editToDelete
    } = this.props;
    let { listUserVaccines, newAddedVaccineList } = this.props.reducer;
    let vaccineList = addedVaccineList.concat(newAddedVaccineList);

    return (
      <div className="col-sm-7">
        <div className="modal-data-bg">
        <div className="d-flex d-op">
          <div className="d-flex">
            <h1 className="mt-1">{vaccineList && vaccineList.length}</h1>
            <h4 className="mt-0">{addedRecord}</h4>
          </div>
        </div>
        {listUserVaccines.loading ? (
          <StatusLoader />
        ) : vaccineList.length ? (
          <TableBuilder
            editToDelete ={editToDelete}
            tableItemList={vaccineList}
            tableConfig={addVaccineTableConf}
            onDeleteItem={onDeleteVaccine}
            onEditItem={onViewVaccine}
            viewMode={true}
            onViewItem={onViewVaccine}
          />
        ) : (
          vaccineEmptyState
        )}
        </div>
      </div>
    );
  }
}
