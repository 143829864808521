import React, { Component } from "react";
import AddTestingModal from "../../commons/AddTestingModal/addTestingModal";
import AddVaccineModal from "../../commons/AddVaccineModal/addVaccineModal";
import AddOrganizationModal from '../../commons/AddOrganizationModal/addOrganizationModal'
import QRModal from "../../commons/QRModal/QRModal";

export default class LRRightModals extends Component {
  render() {
    let {
      showTestingModal,
      showSubTestingLocationModal,
      showTestingViewModal,
      addTestingConfig,
      addTestingForm,
      addTestingTableConf,
      addedTestingList,
      showVaccineModal,
      showSubVaccineLocationModal,
      showVaccineViewModal,
      showOrganizationModal,
      showOrganizationViewModal,
      addVaccineConfig,
      locationDetails,
      addVaccineForm,
      addVaccineTableConf,
      addedVaccineList,
      isDisabledVaccineSubmitBtn,
      isDisabledTestingSubmitBtn,
      showQRModal,
    } = this.props.reducer;

    return (
      <>
        {showTestingModal && (
          <AddTestingModal
            showModal={showTestingModal}
            handleChangeForm={this.props.handleChange}
            getUserInfoAction={this.props.getUserInfoAction}
            deleteUserTestingsAction={this.props.deleteUserTestingsAction}
            updateUserTestingsAction={this.props.updateUserTestingsAction}
            toggleModal={this.props.toggleAddTestingModal}
            showSubTestingLocationModal={showSubTestingLocationModal}
            showTestingViewModal={showTestingViewModal}
            testingFormConfig={addTestingConfig}
            addUserTestingsAction={this.props.addUserTestingsAction}
            listUserTestingsAction={this.props.listUserTestingsAction}
            addTestingForm={addTestingForm}
            addedTestingList={addedTestingList}
            addTestingTableConf={addTestingTableConf}
            isDisabledTestingSubmitBtn={isDisabledTestingSubmitBtn}
            reducer={this.props.reducer}
          />
        )},
        {showVaccineModal && (
           <AddVaccineModal
            showModal={showVaccineModal}
            handleChangeForm={this.props.handleChange}
            getUserInfoAction={this.props.getUserInfoAction}
            locationDetails={locationDetails}
            listUserVaccinesAction={this.props.listUserVaccinesAction}
            getDosesListAction={this.props.getDosesListAction}
            getManufacturersListAction={this.props.getManufacturersListAction}
            deleteUserVaccinesAction={this.props.deleteUserVaccinesAction}
            updateUserVaccinesAction={this.props.updateUserVaccinesAction}
            addUserVaccinesAction={this.props.addUserVaccinesAction}
            toggleModal={this.props.toggleAddVaccineModal}
            showSubVaccineLocationModal={showSubVaccineLocationModal}
            showVaccineViewModal={showVaccineViewModal}
            vaccineFormConfig={addVaccineConfig}
            addVaccineForm={addVaccineForm}
            addedVaccineList={addedVaccineList}
            addVaccineTableConf={addVaccineTableConf}
            isDisabledVaccineSubmitBtn={isDisabledVaccineSubmitBtn}
            reducer={this.props.reducer}
         />
        )},
        {showOrganizationModal && (
           <AddOrganizationModal
            showModal={showOrganizationModal}
            toggleModal={this.props.toggleAddOrganizationModal}
            showOrganizationViewModal={showOrganizationViewModal}
            isDisabledVaccineSubmitBtn={isDisabledVaccineSubmitBtn}
            reducer={this.props.reducer}
            handleChangeForm={this.props.handleChange}
            {...this.props}
         />
        )},
        {
          showQRModal && (
              <QRModal
                  showModal={showQRModal}
                  toggleModal={this.props.toggleQRModal}
                  userLastUpdate={this.props.userLastUpdate}
                  reducer={this.props.reducer}
                  QRCode={this.props.QRCode}
                  sinceLatestTest={this.props.sinceLatestTest}
                  vaccinatedDate={this.props.vaccinatedDate}
              />
          )
        }
      </>
    );
  }
}
