import { connect } from "react-redux";

/**importing actions */
import {
  handleChangeHomePage,
  activityFilterChange,
  handleChangeLR,
  addActivityAction,
  listActivitiesAction,
  deleteActivity,
} from "../../../redux/actions";

/**importing components */
import Activity from "./addActivity";

// const Counter = ...

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    reducer: state.homePageReducer,
  };
};

const componentActions = (dispatch) => {
  return {
    handleChange: (params) => {
      dispatch(handleChangeHomePage(params));
    },
    activityFilterChange: (params) => {
      dispatch(activityFilterChange(params));
    },
    handleChangePopups: (params) => {
      dispatch(handleChangeLR(params));
    },
  };
};
const apiActions = (dispatch) => {
  return {
    addActivityAction: (params, next) => {
      dispatch(addActivityAction(params, next));
    },
    listActivitiesAction: (params, next) => {
      dispatch(listActivitiesAction(params, next));
    },
    deleteActivity: (params, next) => {
      dispatch(deleteActivity(params, next));
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...componentActions(dispatch),
    ...apiActions(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
