import React from "react";
import { ModalWindow } from "../modal";
import AVLBody  from "./AVLBody";
import { latestReportConst } from "../../../constants/text-constants";
import { CommonModalHeader } from "../ModalHeader";

const { locationText } = latestReportConst.addContactTexts;
export const AddVaccineLocationModal = props => {
  let { toggleLocationModal, showSubVaccineLocationModal } = props;
  return (
    <ModalWindow
      showModal={showSubVaccineLocationModal}
      onModalClose={toggleLocationModal}
      heading={
        <CommonModalHeader title={locationText} toggleHandler={toggleLocationModal} />
      }
      body={<AVLBody {...props}
      buttonText = 'Add Location' />}
    />
  );
};
