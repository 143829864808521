import React, { Component } from "react";
import { changeTextColor } from "../../utils/changeRiskColor";
import { StatusLoader } from "../commons/loader";
import { fromNow, commonDateFormtter } from "../../utils/commonDateFormatter";
import { connect } from "react-redux";
import { getPlaceName } from "../../utils/getRegionInfo";
import { abbreviate, abbreviateName } from "../../utils/abbreviate";
import { formatNumber } from "../../utils/numberFormatters";
import history from "../history";
import ContactsMap from "../GoogleMap/contactsMap";
import {
  accessRiskLoader,
  locationEmptyState,
} from "../../constants/text-constants";
import markerwhite from "../../assets/images/marker-white.svg";

class EvaluatedAccessRisk extends Component {
  populateBody = () => {
    let locationInfo = JSON.parse(localStorage.getItem("addedLocs"));
    let count = 0;
    let locationCount = 0;
    let multiplier = 20;
    let locationList = [];
    let level = "Low";
    for (const key in locationInfo) {
      if (locationInfo.hasOwnProperty(key)) {
        const element = locationInfo[key];
        count += element.location_infected_count * multiplier;
        locationCount += element.location_infected_count;
        if (count > 100) count = 100;
        locationList.push({
          ...element,
          is_infected: element.location_infected_count !== 0 ? true : false,
        });
      }
    }

    if (count <= 40) level = "Low";
    else if (count > 40 && count <= 80) level = "Medium";
    else level = "High";
    let infected_loc = locationList.filter(item=>item.is_infected)
    return { level, locationList, count, locationCount ,infected_loc};
  };

  renderAddedLocation = (info) => {
    let { infected_loc } = info;
    if (infected_loc.length) {
      
      return infected_loc.map((locInfo) => {
        let {
          location,
          location_date,
          is_infected,
          latest_exposure_date,
          to_time,
          location_infected_count,
        } = locInfo;
        return (
          <div class="Exposure-locations element-bg">
            <div class="d-flex align-items-stretch item-center">
              <div class="user-int">{abbreviate(location, 1, true)}</div>
              <div class="Exposure-txt">
                <h4>{getPlaceName(location)}</h4>
                <p>{abbreviate(location, 12, false)}</p>
                <h6>First Exposure Date: {location_date}</h6>
              </div>
              <div class="Exposure-location-info">
                <h4>Place</h4>
                <p>
                  {latest_exposure_date.length
                    ? fromNow(latest_exposure_date)
                    : fromNow()}
                </p>
                <h6>
                  <i class="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                  {formatNumber(location_infected_count)}
                </h6>
              </div>
            </div>
          </div>
        );
      });
    } else return locationEmptyState;
  };
  renderLastUpdatedTime = () => {
    let { userLastUpdate } = this.props.globalHeaderReducer;
    let { lastUpdate, loading } = userLastUpdate;
    return (
      <div className="update-box-d exp-scr-hit"></div>
      // <div class="update-box-d">
      //   {" "}
      //   {loading ? (
      //     <StatusLoader />
      //   ) : (
      //     <>
      //       <span >updated:</span> {fromNow(lastUpdate)}
      //     </>
      //   )}
      // </div>
    );
  };
  createAccountPrompt = () => {
    return (
      <div class="element-bg-assess Recommendation">
        <h6 class="assess-risk-head">What to do next:</h6>
        <p>
          You’ll receive risk and exposure updates for contacted people and
          places visited as new information is updated.
        </p>
        <button
          className="assess-acnt-crt"
          onClick={() => history.push({ pathname: "/signup" })}
        >
          Create an account
        </button>
      </div>
    );
  };
  render() {
    let info = this.populateBody();
    let { level, infected_loc, locationCount, count } = info;
    let { loading } = this.props;
    return (
      <div class="col-lg-8 col-sm-12">
        <div class="element-bg d-flex assess-risk-right">
          {loading ? (
            accessRiskLoader
          ) : (
            <>
              <div class="col-lg-6">
                <div className="row">
                  <h6 class="assess-risk-head w-100">Assess Risk Details</h6>
                  <div class="d-flex w-100">
                    <div class="col-lg-8">
                      <div class="row">
                        <div class="score-b element-bg-assess w-100 assess-risk-padding">
                          <div class="d-flex justify-content-center text-center score-b-inner border-0 m-0">
                            <div class="score-box">
                              <h4>Exposure Score</h4>
                              <p>{count}</p>
                            </div>
                            <div class="score-box">
                              <h4>Risk Level</h4>
                              <p
                                className=""
                                style={{ color: changeTextColor(level) }}
                              >
                                {level.toUpperCase()}
                              </p>
                            </div>
                          </div>
                          {/* {this.renderLastUpdatedTime()} */}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="score-b element-bg-assess assess-risk-padding w-100">
                        <div class="d-flex justify-content-center text-center score-b-inner border-0 m-0">
                          <div class="score-box">
                            <h4>Exposure</h4>
                            <p>
                              <img src={markerwhite} alt="" />
                              {locationCount}
                            </p>
                          </div>
                        </div>
                        <div className="update-box-d exp-scr-hit"></div>
                      </div>
                    </div>
                  </div>
                  <div className="xs-pr25 w-100">
                    <h6 class="assess-risk-head assess-risk-loc-mar">
                      Location Exposure ({infected_loc.length})
                    </h6>
                    <div class="location-exposure-bg-clr">
                      {this.renderAddedLocation(info)}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 xs-pr25">
                <ContactsMap userLocationList={infected_loc} contactList={[]} />{" "}
                {this.createAccountPrompt()}
              </div>
            </>
          )}
        </div>
        <button
          class="btn btn-block btn-assess-success Gilroy-Semibold"
          onClick={this.props.addAccessRiskAction}
        >
          Evaluate Risk
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state /*, ownProps*/) => {
  return {
    globalHeaderReducer: state.globalHeaderReducer,
  };
};
export default connect(mapStateToProps)(EvaluatedAccessRisk);
