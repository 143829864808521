/**the content block of the lastest report  */

/**importing react deps */
import React, { Component } from "react";

/**components */
import { UserInfoPane } from "./userInfoPane";
import { UserSummary } from "./userSummary";
import { UserActivityButtons } from "./userActivityButtons";
import AddFlightModal from "../../commons/AddFlightModal/addFlightModal";
import UserDiagnostics from "./userDiagonistics";
import AddContactModal from "../../commons/AddContactModal/addcontactModal";
import { Recommandations } from "./recommendationBlock";
import ContactsListingBlock from "./contactsListingBlock";
import { StatusLoader } from "../../commons/loader";
import ContactsMap from "../../GoogleMap/contactsMap";

export default class LRcontentBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editToDelete: "",
    };
  }
  handleFormChange = (params) => {
    const { name, value } = params;
    this.props.handleChange({ parentName: "addFlightForm", name, value });
  };

  handleFormChangeContacts = (params) => {
    const { name, value, type } = params;
    if (type === "latLng") {
      this.props.handleChange({ parentName: "addContactForm", type });
    } else
      this.props.handleChange({ parentName: "addContactForm", name, value });
  };

  handleChangeLocationForm = (params) => {
    this.props.handleChange(params);
  };
  toggleContactsSubModal = () => {
    let { showSubContactModal } = this.props.reducer;
    this.props.handleChange({
      name: "showSubContactModal",
      value: !showSubContactModal,
    });
  };
  toggleModal = (type) => {
    let visbility = this.props.reducer[type];
    this.props.handleChange({
      name: type,
      value: !visbility,
    });
  };
  onEditContact = (params) => {
    let { id } = params;
    this.setState({
      editToDelete: id,
    });
    this.props.handleChange({ mode: "edit", id, formType: "contact" });
  };
  render() {
    let {
      userInfo,
      flightFormConfig,
      addFlightTableConf,
      addedFlightList,
      showFlightModal,
      showContactModal,
      locationDetails,
      showSubContactModal,
      userDiagnonsticInfo,
      addContactConfig,
      addFlightForm,
      addContactForm,
      addContactTableConf,
      listUserContacts,
      listUserLocation,
      addedContactList,
    } = this.props.reducer;
    let { editToDelete } = this.state;

    return (
      <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 p-0 item-resp-2 pull-left">
        <div className="p-10">
          <div className="patient-contact align-items-stretch item-center element-bg user-dd">
            <UserInfoPane {...userInfo} />
            <UserSummary {...userInfo} />
            <UserActivityButtons
              toggleModal={() => this.toggleModal("showFlightModal")}
            />
          </div>
        </div>
        <div className="pull-left w-100">
          <div className="col-sm-6 p-10 pull-left">
            {!userInfo.loading ? (
              <UserDiagnostics
                {...userDiagnonsticInfo}
                userInfo={userInfo}
                reducer={this.props.reducer}
                {...this.props}
              />
            ) : (
              <StatusLoader />
            )}
            <Recommandations {...userInfo} />
          </div>
          {!listUserContacts.loading ? (
            <ContactsListingBlock
              // editToDelete ={editToDelete}
              toggleModal={this.toggleModal}
              onEditContact={this.onEditContact}
              {...this.props}
            />
          ) : (
            <StatusLoader />
          )}
        </div>
        {showContactModal && (
          <AddContactModal
            showModal={showContactModal}
            getUserInfoAction={this.props.getUserInfoAction}
            addUserContactsAction={this.props.addUserContactsAction}
            listUserContactsAction={this.props.listUserContactsAction}
            updateUserContactAction={this.props.updateUserContactAction}
            deleteUserContactsAction={this.props.deleteUserContactsAction}
            locationDetails={locationDetails}
            handleChangeForm={this.props.handleChange}
            toggleModal={() => this.toggleModal("showContactModal")}
            showSubContactModal={showSubContactModal}
            addContactConfig={addContactConfig}
            addContactForm={addContactForm}
            addContactTableConf={addContactTableConf}
            addedContactList={addedContactList}
            reducer={this.props.reducer}
          />
        )}
        {showFlightModal && (
          <AddFlightModal
            showModal={showFlightModal}
            getUserInfoAction={this.props.getUserInfoAction}
            deleteUserFlightsAction={this.props.deleteUserFlightsAction}
            handleChangeForm={this.props.handleChange}
            listFlightActions={this.props.listFlightActions}
            listFlightCarriersAction={this.props.listFlightCarriersAction}
            addUserFlightsAction={this.props.addUserFlightsAction}
            updateUserFlightsAction={this.props.updateUserFlightsAction}
            toggleModal={() => this.toggleModal("showFlightModal")}
            flightFormConfig={flightFormConfig}
            addedFlightList={addedFlightList}
            addFlightForm={addFlightForm}
            addFlightTableConf={addFlightTableConf}
            reducer={this.props.reducer}
          />
        )}

        <div className="contacts-map-container w-100 pull-left p-10">
          {!listUserContacts.loading && !listUserLocation.loading ? (
            <ContactsMap
              contactList={addedContactList}
              userLocationList={listUserLocation.list}
              listUserContactsAction={this.props.listUserContactsAction}
              listFlightActions={this.props.listFlightActions}
              listLocationAction={this.props.listLocationAction}
            />
          ) : (
            <StatusLoader />
          )}
        </div>

        {/* <img alt="reportmap" src={image} width="100%" className="mt-3"></img> */}
      </div>
    );
  }
}
