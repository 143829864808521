import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_COVID_DETAILS_SUCCESS,
  LIST_COVID_DETAILS_FAILED,
  LIST_COVID_DETAILS_STARTED
} from "../../../../constants/action-types";
import { GET_COVID_STATUS_URL } from "../../../../constants/configurations";

export const listCovidDetailsActions = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GET_COVID_STATUS_URL,
        started: LIST_COVID_DETAILS_STARTED,
        success: LIST_COVID_DETAILS_SUCCESS,
        failure: LIST_COVID_DETAILS_FAILED
      },
      callBack
    );
  };
};
