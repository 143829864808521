import React, { Component } from "react";
import { ModalWindow } from "../../commons/modal";
import { CommonModalHeader } from "../../commons/ModalHeader";
import DatePicker from "react-datepicker";
import {
  timeDropItems,
  weekDropItems,
  activityDrop,
  activityTypes,
} from "./formConfigs";
import { googleAddressComplete } from "./googleAutoComplete";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { toastNotification } from "../../commons/toastNotification";
import datedwnarw from "../../../assets/images/Drop-down2.svg";
import { errorHandler } from "../../../utils/commonErrorHandler";

export default class AddActivityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formValid: false,
    };
  }
  componentDidMount() {
    googleAddressComplete({ handleChange: this.props.handleChange });
  }
  onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  /**api call to submit activity */
  activitySubmit = () => {
    let { addActivityForm } = this.props.reducer;
    let body = {};
    for (const key in addActivityForm) {
      if (addActivityForm.hasOwnProperty(key)) {
        const element = addActivityForm[key];
        if (key === "latLng") {
          body["latitude"] = element.lat;
          body["longitude"] = element.lng;
          body["Country_Region"] = element.country;
          body["Province_State"] = element.province;
        } else body[key] = element;
      }
    }
    body.from_date = apiDateFormat(addActivityForm.from_date);
    body.to_date = apiDateFormat(addActivityForm.to_date);
    this.props.addActivityAction(body, (res) => {
      if (res.status === 200 || res.status === 201) {
        this.props.toggleModal();
        toastNotification({
          msg: "Fetch Activity Success!",
          type: "success",
        });
        this.props.listActivitiesAction();
      } else {
        toastNotification({
          msg: `${errorHandler(res.data)}`,
          type: "error",
        });
      }
    });
  };
 
  /**common handle change */
  handleChangeForm = (params) => {
    let { name, value } = params;
    this.props.handleChange({ parentName: "addActivityForm", name, value });
  };
  /**common input field for this component */
  renderInput = (conf) => {
    let { placeholder, name, pattern, id } = conf;
    let value = this.props.reducer.addActivityForm[name];
    return (
      <input
        type="text"
        style={{ outline: "none" }}
        name={name}
        id={id}
        placeholder={placeholder}
        pattern={pattern}
        value={value}
        onChange={(e) => {
          e.target.validity.valid &&
            this.handleChangeForm({
              name: e.target.name,
              value: e.target.value,
            });
        }}
        className="border-bottom border-top-0 border-right-0 border-left-0 w-100 inp-bx-shadow transition-inp"
      />
    );
  };
  renderDatePicker = (conf) => {
    let { name } = conf;
    let value = this.props.reducer.addActivityForm[name];
    return (
      <div className="loc-modal-date-picker date-picking w-100 position-relative">
        <DatePicker
          className={
            "form-control loc-modal-btn date-input w-100 border-0 inp-bx-shadow"
          }
          selected={value}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3).toUpperCase()}
          // maxDate={new Date()}
          name={name}
          onChange={(value) => {
            this.handleChangeForm({ name, value });
          }}
        />
        {/* <span className="date-dwn-arw">
          <img src={datedwnarw} alt="" />
        </span> */}
      </div>
    );
  };
  generateDropItem = (params) => {
    let { name, options } = params;
    return options.map((item, index) => {
      return (
        <li
          className="mb-2"
          key={index}
          onClick={() =>
            this.handleChangeForm({
              name,
              value: item.name,
            })
          }
        >
          <span
            className="large com-drop sign-up-drp"
            data-value="option0"
            tabindex="-1"
          >
            {item.name}
          </span>
        </li>
      );
    });
  };
  renderSelectOptions = (conf) => {
    let { name } = conf;
    let value = this.props.reducer.addActivityForm[name];
    return (
      <>
        <button
          type="button"
          className={
            "border-bottom form-control self-inp loc-modal-btn border-top-0 border-right-0 border-left-0 w-100 inp-bx-shadow"
          }
          data-toggle="dropdown"
          aria-expanded="false"
          required
        >
          <span className="glyphicon glyphicon-cog"></span>
          {value}
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu loc-modal-btn-list w-100 pt-0 pb-0">
          {this.generateDropItem(conf)}
        </ul>
      </>
    );
  };
  /**common select field for this component */
  renderSelect = (conf) => {
    let { options, name } = conf;
    return (
      <select
        type="text"
        name={name}
        onChange={(e) =>
          this.handleChangeForm({
            name: e.target.name,
            value: e.target.value,
          })
        }
        className="border-bottom border-top-0 border-right-0 border-left-0 w-100"
      >
        {options.map((item) => (
          <option>{item.name}</option>
        ))}
      </select>
    );
  };

  locationName = () => {
    return (
      <div class="col-12 pull-left mb-3">
        <label>Location*</label>
        {this.renderInput({
          placeholder: "Name",
          name: "location",
        })}
      </div>
    );
  };

  activityName = () => {
    return (
      <div class="col-6 pull-left mb-3">
        <label>{"Activity*"}</label>
        {this.renderSelectOptions({
          name: "activity",
          options: activityDrop,
        })}
      </div>
    );
  };

  typeOfActivity = () => {
    return (
      <div class="col-6 pull-left mb-3">
        <div className="type-block">
          <label>{"Type*"}</label>
          {this.renderSelectOptions({
            name: "type",
            options: activityTypes,
          })}
        </div>
      </div>
    );
  };

  addressField = () => {
    let { permanent } = this.props.reducer;
    return (
      <div class="col-12 pull-left mb-3">
        <label>Address*</label>
        <input
          id="autocomplete"
          onFocus={this.onFocus}
          className="border-bottom border-top-0 border-right-0 border-left-0 w-100 inp-bx-shadow transition-inp"
          placeholder="Location address"
          type="text"
        />
        <span class="map-modal-checkbox">
          <label className="map-mod-che-bx">
            <input
              type="checkbox"
              className="mr-2"
              name={"permanent"}
              checked={permanent}
              onClick={(e) =>
                this.handleChangeForm({
                  name: e.target.name,
                  value: e.target.checked,
                })
              }
            />
            <span class="fake-checkbox"></span>
          </label>
          <span className="pr-loc">Permanent Location</span>
        </span>
      </div>
    );
  };
  timeRange = (params) => {
    let { datename, timename, label } = params;
    return (
      <>
        <div class="col-6 pull-left mb-3">
          <label>{label}</label>
          {this.renderDatePicker({
            name: datename,
          })}
        </div>
        <div class="col-6 pull-left mb-3">
          <h6 class="mt-24">{""}</h6>
          {this.renderSelectOptions({
            name: timename,
            options: timeDropItems(),
          })}
        </div>
      </>
    );
  };
  eventStart = () => {
    return this.timeRange({
      label: "Event Starts*",
      datename: "from_date",
      timename: "from_time",
    });
  };
  eventEnd = () => {
    return this.timeRange({
      label: "Event Ends*",
      datename: "to_date",
      timename: "to_time",
    });
  };
  displayLocation = () => {
    return (
      <div class="col-12 pull-left mb-3">
        <div className="disp-location mob-disp-location">
          <label>Display Location*</label>
          {this.renderSelectOptions({
            name: "dispaly_location",
            options: weekDropItems(),
          })}
        </div>
      </div>
    );
  };
  contactBlock = () => {
    return (
      <div class="col-12 pull-left mb-3">
        <label>Contact*</label>
        {this.renderInput({
          placeholder: "Phone Number",
          name: "phone_number",
          pattern: "[0-9+]*",
        })}
      </div>
    );
  };
  describeEventBlock = () => {
    let { description } = this.props.reducer.addActivityForm;
    return (
      <div class="col-12 pull-left mb-3">
        <label>Describe Event*</label>
        <div class="map-pop-modal-description">
          {this.renderInput({
            placeholder: "Description",
            pattern: "^[A-Za-z ]{1,200}$",
            name: "description",
          })}
          <span>{200 - description.length}</span>
        </div>
      </div>
    );
  };
  modalHeader = () => {
    let { toggleModal } = this.props;
    return (
      <CommonModalHeader
        customTitle={
          <h5
            class="modal-title w-100 fnt-16 text-center loc-modal-heading"
            id="exampleModalLongTitle"
          >
            Add Location
          </h5>
        }
        toggleHandler={toggleModal}
      />
    );
  };
  modalBody = () => {
    return (
      <>
        {this.locationName()}
        {this.activityName()}
        {this.typeOfActivity()}
        {this.addressField()}
        {this.eventStart()}
        {this.eventEnd()}
        {this.displayLocation()}
        {this.contactBlock()}
        {this.describeEventBlock()}
      </>
    );
  };
  modalFooter = () => {
    let { activityFormValid } = this.props.reducer;
    return (
      <div className="modal-footer w-100 p-0 m-0 bg-white border-0">
        <button
          type="submit"
          disabled={!activityFormValid}
          className={`btn btn-light w-100 loc-modal-submit ${
            activityFormValid ? "loc-modal-submit-confirm" : ""
          }`}
          data-dismiss="modal"
          onClick={this.activitySubmit}
        >
          Submit
        </button>
      </div>
    );
  };
  render() {
    let { showActivityModal } = this.props.reducer;
    let { toggleModal } = this.props;
    return (
      <>
        <ModalWindow
          className={"modal fade map-bottom-button-modal"}
          showModal={showActivityModal}
          onModalClose={toggleModal}
          heading={this.modalHeader()}
          bodyClassName="modal-body bg-white p-0"
          body={this.modalBody()}
          footerClassName="bg-white border-0 pt-0"
          footer={this.modalFooter()}
        />
      </>
    );
  }
}
