import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  GET_SELF_REPORTED_LOCATION_STARTED,
  GET_SELF_REPORTED_LOCATION_SUCCESS,
  GET_SELF_REPORTED_LOCATION_FAILED,
} from "../../../../constants/action-types";
import { GET_SELF_REPORTED_LOCATION_URL } from "../../../../constants/configurations";

export const getReportedLocations = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GET_SELF_REPORTED_LOCATION_URL(body),
        started: GET_SELF_REPORTED_LOCATION_STARTED,
        success: GET_SELF_REPORTED_LOCATION_SUCCESS,
        failure: GET_SELF_REPORTED_LOCATION_FAILED,
      },
      callBack
    );
  };
};
