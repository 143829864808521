/**all the component actions such as handle change and toggle modal etc. keeped here */

import {
  HANDLE_CHANGE_HOME_PAGE,
  HANDLE_CHANGE_COUNTRY_SELECT,
  HANDLE_CHANGE_ACTIVITY_FILTER,
} from "../../../constants/action-types";

export const handleChangeHomePage = (params) => (dispatch) => {
  dispatch({
    type: HANDLE_CHANGE_HOME_PAGE,
    payload: params,
  });
};
export const changeSelectedCountry = (params) => (dispatch) => {
  dispatch({
    type: HANDLE_CHANGE_COUNTRY_SELECT,
    payload: params,
  });
};
export const activityFilterChange = (params) => (dispatch) => {
  dispatch({
    type: HANDLE_CHANGE_ACTIVITY_FILTER,
    payload: params,
  });
};

export const componentActionHome = (params) => (dispatch) => {
  let {type} = params;
  dispatch({
    type,
    payload: params,
  });
};

