/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Icon from "@material-ui/core/Icon";
import modalempty from "../assets/images/contact-empty.svg";
import lock from "../assets/images/lock.svg";
import fightEmpty from "../assets/images/ic-flight-takeoff-48-px.svg";
import recordEmpty from "../assets/images/record-empty.svg";
import uploadIcon from "../assets/images/upload-image.svg";
import locationEmpty from "../assets/images/group-6.svg";
import edit from "../assets/images/icon-135-pen-angled.svg";
import delete_icon from "../assets/images/Delete.svg";
import trash_icon from "../assets/images/trash-icon.svg";
import download_icon from "../assets/images/download.svg";
import alert from "../assets/images/alert-circle.svg";
import user from "../assets/images/account-circle.svg";
import flight from "../assets/images/flight.svg";
import update from "../assets/images/update.svg";
import search from "../assets/images/search-black.svg";
import clip from "../assets/images/clip.svg";
import check_circle from "../assets/images/check-circle.svg";
import check_circle_grey from "../assets/images/check-circle-grey.svg";
import { StatusLoader } from "../components/commons/loader";
import { CustomIcon } from "../components/commons/Icon/icon";
/**all the hard coded values are keeped here (used of language localization) */
export const continueText = "Continue";
export const verifyCodeText = "Verify Code";
export const sendActivationText = "Send Activation";
export const secureText = (
  <p className="secure-txt">
    <img src={lock} alt="" className="img-icons" />
    Application Secure
  </p>
);
export const searchIconBlack = (
  <img src={search} alt="" className="img-icons-14" style={{ margin: "0px" }} />
);
export const updateIcon = (
  <img
    src={update}
    alt=""
    className="img-icons-11"
    style={{ height: "12px" }}
  />
);
export const checkCircle = (
  <img src={check_circle} alt="" className="img-icons-8" />
);
export const checkCircleGrey = (
  <img src={check_circle_grey} alt="" className="img-icons-8" />
);
export const flightIcon = <img src={flight} alt="" className="img-icons-11" />;
export const ClipIcon = () => {
  return <CustomIcon svg={clip} style={{ marginTop: "-18px" }} />;
};
export const UploadIcon = () => {
  return <CustomIcon svg={uploadIcon} style={{ marginTop: "-18px" }} />;
};
export const userIcon = <img src={user} alt="" className="img-icons-11" />;
export const userIconP = <img src={user} alt="" className="img-icons-11P" />;
export const flightIconP = (
  <img src={flight} alt="" className="img-icons-11P" />
);
export const alertIcon = (
  <img
    src={alert}
    alt=""
    className="img-icons"
    style={{ margin: "0 0 2px 0" }}
  />
);
export const editIcon = <img src={edit} alt="" className="img-icons" />;
export const deleteIcon = (
  <img src={delete_icon} alt="" className="img-icons" />
);
export const trashIcon = <img src={trash_icon} alt="" />;
export const downloadIcon = <img src={download_icon} alt="" />;
export const loginPageConst = {
  reallyYouText: "Is it really you?",
  noRecieveText: "Didn’t receive the code?",
  termsAndConditionText: (
    <>
      By proceeding, I agree to Samscloud{" "}
      <a href="" className="color">
        {`Terms of Use  `}
      </a>{" "}
      {` and`} <a href=""> Privacy Policy.</a>I also confirm that the above
      information about me is correct and true.
    </>
  ),
  wrongNumberText: "Wrong phone number?",
  editText: "Edit it here",
  resendText: "Resend it now",
  enterCodeText: "Enter code",
  enterCodePlaceholder: "Enter your OTP",
  enterPhoneText: "Enter Phone Number",
  enterPhonePlaceholder: "Enter your Phone Number",
};
export const optText = (number) => {
  return (
    <p>
      For added security, we need to verify your phone number. We’ve sent a
      verification code to {number}.
    </p>
  );
};
export const mobileText = () => {
  return (
    <p>Please enter your phone number. we’ll sent the verification code.</p>
  );
};
export const homePageConst = {
  sideBarConst: {
    reportHeading: "covid-19 report",
    reportDesc: "confirmed cases (world)",
    deathText: "Deaths",
    recoveredText: "Recovered",
    emptycountryList: "List empty!",
    collapseText: "Enter zip code...",
    collapseButtonText: "Go",
    selfReportTitle: "self reported cases",
    reportedLocTitle: "Reported Locations",
    reportedLocDesc: "Location",
  },
  header: "Featured",
  addLocText: "Radius From  ",
  radiusText: "Radius",
  metric: "Miles",
  title: "Special title treatment",
  content:
    "With supporting text below as a natural lead-in to additional content.",
  footer: "2 days to go",
};

export const signUpConst = {
  accountQuery: "Already have an account? ",
  termsAgree: "By proceeding, I agree to SamsCloud ",
  confirmInfo:
    " I also confirm that the above information about me is correct and true.",
  joinQuote: "Join the fight",
  footerQuote:
    "Let’s all look out for each other by knowing our status, trying not to infect others.",
  needHelp: "Need help? ",
  contactUs: "Contact Us",
};

export const selfReportConst = {
  flightUserDesc:
    "Have you taken any flights? we'll let you know if you've been exposed while traveling.",
  // contactUserDesc: `In the last 14 days, have you been exposed to others who are known to have COVID-19?`,
  contactUserDesc: `Add the people you've been in contact with and we'll let you know if
  you’ve been exposed.`,
  locationUserDesc: (
    <React.Fragment>
      {/* In the last 14 days, have you been in an area where COVID-19 is
      widespread? Add the location to help you check. */}
      Add the locations you’ve visited and we’ll check your Covid-19 exposure
      risk.
    </React.Fragment>
  ),
};
export const accessRiskLoader = (
  <React.Fragment>
    <div className="element-bg w-100 text-center d-table assess-loader-pg">
      <div className=" align-middle w-100 text-white d-table-cell">
        <StatusLoader type={"multiDots"} />
        <h3>Checking visited locations</h3>
        <p>Please wait while we evaluate your risk..</p>
      </div>
    </div>
  </React.Fragment>
);
export const latestReportConst = {
  header: "LATEST REPORT",
  subHeading: "COVID-19 Self Report",
  leftPaneTexts: {
    title: "Locations Visited",
    addLocText: "Add Location",
    expoText: "Location Exposure",
    placeholder: "Search location..",
    editExpoText: "Edit",
    emptyText: "Location empty",
  },
  rightPaneTexts: {
    vaccineText: "Vaccine Reminder",
    testText: "Test Records",
    organizationText: "Organizations",
    recordsLineLabel: "Records",
    orgsLineLabel: "Orgs",
    addText: "Add",
    viewText: "View",
  },
  contentPaneTexts: {
    summaryText: {
      reportText: "Report",
      statusText: "Status",
      testedText: "Tested",
      vaccinatedText: "Vaccinated",
      updateStatusText: "Update Status",
      addFlight: "Add Flight",
    },
  },
  addFlightTexts: {
    addedFlight: (
      <React.Fragment>
        Added
        <br />
        Flights
      </React.Fragment>
    ),
  },
  addRecordTexts: {
    locationText: "Search",
    viewTitle: "Vaccination Record",
    viewTestingTitle: "Testing Record",
    viewText: "View",
    addedRecord: (
      <React.Fragment>
        Added
        <br />
        Records
      </React.Fragment>
    ),
  },
  addLocationTexts: {
    addLocText: "Add Location",
    updateLocatext: "Update Location",
  },
  recommandationText: {
    title: "Recommendation",
    highRiskDesc: `Your risk level is high. It is recommended that you practice social distancing, self quarantine for at least 14 days, and test to confirm your status. If you need medical assistance, consult your physician and in case of an emergency call 911.`,

    lowRiskDesc: `Your risk level is Low. It is recommended that you continue to practice social distancing and if you develop any symptoms you should test to confirm your status. If you need medical assistance, consult your physician and in case of an emergency call 911.`,

    MediumDesc: `Your risk level is Medium. It is recommended that you practice social distancing, and test to confirm your status. If you need medical assistance, consult your physician and in case of an emergency call 911.`,
    buttonText: "Testing Info",
  },
  userDiagnonsticText: {
    expText: "Exposure Score",
    riskText: "Risk Level",
    InfectedText: "Infected",
    exposureText: "Exposure",
    flightText: "Flights",
    contacts: "Contacts",
  },
  addContactTexts: {
    peopleCText: "People Contacted",
    confirmation: "Conformation",
    locationText: "Add Location",
    descText: "Get address from a map",
    locText: "Location",
    addedContactsText: (
      <>
        Added
        <br />
        Contacts
      </>
    ),
  },
};
export const firstExpText = "First Exposure Date: ";
export const submitText = "Submit";
export const updateText = "Submit";
export const zipText = "ZIP :";
export const lastLoginText = "last login: ";
export const noData = "List empty!";
export const contactsEmptyState = (
  // <label style={{ color: "white", padding: "10px" }}>No data yet</label>

  <label className="w-100 text-center">
    <img style={{ margin: "32px 0 16px 0" }} src={modalempty} alt="" />
    <h6 className="text-center w-100 " style={{ color: "#fff" }}>
      No exposed contacts
    </h6>
    <p className="modal-no-data fnt-12 text-center">
      Add the people you've been in contact with and we'll let you know if
      you’ve been exposed.
    </p>
  </label>
);
export const mobileFilterText = {
  allProvince: "Select State",
  allCountry: "World wide",
};
export const locationEmptyState = (
  // <label style={{ color: "white", padding: "10px" }}>No data yet</label>

  <label className="w-100 text-center">
    <img style={{ margin: "32px 0 16px 0" }} src={locationEmpty} alt="" />
    <h6 className="text-center w-100 text-white">No location Exposure</h6>
    <p className="modal-no-data fnt-12 text-center">
      Add the locations you’ve visited and we’ll check your Covid-19 exposure
      risk.
    </p>
  </label>
);
export const flightEmptyState = (
  // <label style={{ color: "white", padding: "10px" }}>No data yet</label>

  <label className="w-100 text-center">
    <img style={{ margin: "32px 0 16px 0" }} src={fightEmpty} alt="" />
    <h6 className="text-center w-100">No available flight info</h6>
    <p className="modal-no-data fnt-12 text-center">
      Add flight information and we’ll let you know if you’ve been exposed to
      Covid-19.
    </p>
  </label>
);
export const vaccineEmptyState = (
  // <label style={{ color: "white", padding: "10px" }}>No data yet</label>

  <label className="w-100 text-center">
    <img style={{ margin: "32px 0 16px 0" }} src={recordEmpty} alt="" />
    <h6 className="text-center w-100">No added records</h6>
    <p className="modal-no-data fnt-12 text-center">
      Add a vaccine and we’ll let you know if you’ve been exposed to Covid-19.
    </p>
  </label>
);

export const headerConst = {
  searchInput: {
    placeholder: "Search location or zip code..",
  },

  screenText: "Screen",
  selfReportText: "Self Report",
  accessRiskText: "Assess Risk",
  loginText: "Login",
  logoutText: "Logout",
};
export const QRConst = {
  testDaysText: "Days Since Test",
  vaccineDateText: "Vaccine Date",
  shareRecordsBlock: {
    title: "Share Records",
    vaccineTitle: "Test",
    testingTitle: "Vaccine",
  },
  downloadBtnText: "Download",
};
