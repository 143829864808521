/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

/**constants */
/**Images */

import { latestReportConst, vaccineEmptyState } from "../../../constants/text-constants";
import { TableBuilder } from "../tableBuilder";
import { StatusLoader } from "../loader";
const { addedRecord, viewText } = latestReportConst.addRecordTexts;

export default class UserAddedTestingBlock extends Component {
  render() {
    let {
      addedTestingList,
      addTestingTableConf,
      onDeleteTesting,
      onEditTesting,
      onViewTesting,
      editToDelete
    } = this.props;

    let { listUserTestings, newAddedTestingList } = this.props.reducer;
    let testingList = addedTestingList.concat(newAddedTestingList);

    return (
      <div className="col-sm-7">
        <div className="modal-data-bg">
        <div className="d-flex d-op">
          <div className="d-flex">
            <h1 className="mt-1">{testingList && testingList.length}</h1>
            <h4 className="mt-0">{addedRecord}</h4>
          </div>
        </div>
        {listUserTestings.loading ? (
          <StatusLoader />
        ) : testingList.length ? (
          <TableBuilder
            editToDelete ={editToDelete}
            tableItemList={testingList}
            tableConfig={addTestingTableConf}
            onDeleteItem={onDeleteTesting}
            onEditItem={onViewTesting}
            viewMode={true}
            onViewItem={onViewTesting}
          />
        ) : (
          vaccineEmptyState
        )}
        </div>
      </div>
    );
  }
}
