import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  DELETE_USER_TESTING_SUCCESS,
  DELETE_USER_TESTING_FAILED,
  DELETE_USER_TESTING_STARTED
} from "../../../../constants/action-types";
import { DELETE_USER_TESTING_URL } from "../../../../constants/configurations";

export const deleteUserTestingsAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "delete",
        api: DELETE_USER_TESTING_URL(body.id),
        started: DELETE_USER_TESTING_STARTED,
        success: DELETE_USER_TESTING_SUCCESS,
        failure: DELETE_USER_TESTING_FAILED
      },
      callBack
    );
  };
};
