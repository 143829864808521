/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState, useEffect } from "react";
import { checkFieldValid } from "../OtpPage/utils";
import { toastNotification } from "../../commons/toastNotification";
import { APP_BASE_NAME } from "../../../constants/configurations";
import {
  loginPageConst,
  sendActivationText,
  verifyCodeText,
  submitText,
} from "../../../constants/text-constants";
import GlobalHeader from "../../GlobalHeader";
import { errorHandler } from "../../../utils/commonErrorHandler";
let {
  noRecieveText,
  termsAndConditionText,
  wrongNumberText,
  editText,
  resendText,
} = loginPageConst;

export const ForgotPassword = (props) => {
  let info = localStorage.getItem("phone_number");
  let code_verifed = localStorage.getItem("code_verifed");
  let phoneNumber = info && info.length ? info : "";

  const [formValues, setformValues] = useState({
    phone_number: phoneNumber,
    otp: "",
    new_password: "",
    confirm_password: "",
  });

  /**getting which page to render */
  const setCurrentMode = () => {
    if (phoneNumber.length && !code_verifed) {
      return "code";
    } else if (phoneNumber.length && code_verifed) return "change_password";
    else return "phone_number";
  };

  const [mode, setmode] = useState(setCurrentMode);

  useEffect(() => {
    setformValues(formValues);
  }, [formValues]);

  /**when user clicks submit otp */
  const handleSubmit = (event) => {
    event.preventDefault();
    let { otp, new_password, confirm_password } = formValues;
    if (mode === "phone_number") {
      sendOtp();
    } else if (mode === "code") {
      if (checkFieldValid(otp)) {
        props.forgotPasswordOtp(formValues, (res) => {
          if (res.status === 200) {
            localStorage.setItem("code_verifed", true);
            setmode("change_password");
            // props.history.push({ pathname: `${APP_BASE_NAME}/login` });
          } else
            return toastNotification({
              msg: `${errorHandler(res.data)}`,
              type: "error",
            });
        });
      }
    } else if (mode === "change_password") {
      if (checkFieldValid(new_password) && checkFieldValid(confirm_password))
        props.changePasswordAction(formValues, (res) => {
          if (res.status === 200) {
            localStorage.clear();
            props.history.push({ pathname: `${APP_BASE_NAME}/login` });
          } else
            return toastNotification({
              msg: `${errorHandler(res.data)}`,
              type: "error",
            });
        });
    }
  };

  const sendOtp = () => {
    let { phone_number } = formValues;
    if (checkFieldValid(phone_number)) {
      props.verifyForgotPassAction(formValues, (response) => {
        if (response.status === 200) {
          // props.history.push({ pathname: `${APP_BASE_NAME}/login` });
          setmode("code");
          localStorage.setItem("phone_number", phone_number);
        } else
          return toastNotification({
            msg: `${errorHandler(response.data)}`,
            type: "error",
          });
      });
    }
  };
  const handleChangeForm = (params) => {
    let { name, value } = params;
    setformValues({ ...formValues, [name]: value });
  };
  const changeFormMode = (name) => {
    setmode(name);
  };
  const getFormConfig = () => {
    if (mode === "phone_number")
      return {
        type: "text",
        label: "Enter phone number",
        name: "phone_number",
        pattern: "[0-9+]*",
        placeholder: "",
      };
    else if (mode === "code")
      return {
        type: "text",
        label: "Enter code",
        name: "otp",
        placeholder: "Enter your verification code",
      };
    else if (mode === "change_password")
      return [
        {
          type: "password",
          label: "Enter new password",
          name: "new_password",
          placeholder: "",
        },
        {
          type: "password",
          label: "Confirm new password",
          name: "confirm_password",
          placeholder: "",
        },
      ];
  };
  const title = () => {
    return (
      <div className="title-head">
        <div className="">
          <h4>COVID-19 Report</h4>
          <p>Practice social distancing</p>
        </div>
      </div>
    );
  };

  const introBlock = () => {
    return (
      <>
        {" "}
        <h4 className="text-center text-white mb-0">Forgot your password?</h4>
        <p className="nav-p text-center fnt-12">
          We'll send you a link to reset your password
        </p>{" "}
      </>
    );
  };

  const formFooter = () => {
    if (mode === "code") return verifyCodeFooter();
    else return enterNumberFooter();
  };
  const enterNumberFooter = () => {
    return (
      <>
        <div className="fnt-12 text-center text-white log-pas-reset">
          Remember account?{" "}
          <a
            onClick={() =>
              props.history.push({ pathname: `${APP_BASE_NAME}/login` })
            }
            className="color clr-blue link-style"
          >
            Login
          </a>
        </div>
        <div className="text-center w-100 fnt-12 text-white log-reg">
          New here?{" "}
          <a
            onClick={() =>
              props.history.push({ pathname: `${APP_BASE_NAME}/signup` })
            }
            className="color clr-blue link-style"
          >
            Register
          </a>
        </div>
      </>
    );
  };
  const verifyCodeFooter = () => {
    return (
      <>
        <div className="text-center w-100 fnt-13 ald-pad text-white pull-left w-100 mb-0">
          {noRecieveText}{" "}
          <a onClick={sendOtp} className="color clr-blue link-style">
            {resendText}
          </a>
        </div>
        <div className="text-center w-100 fnt-13 ald-pad2 text-white pull-left w-100">
          {wrongNumberText}{" "}
          <a className="color clr-blue link-style" onClick={() => changeFormMode("phone_number")}>
            {editText}
          </a>
        </div>
        <div className="text-center w-100 fnt-11 nav-p terms-wdt m-auto w-100 d-table">
          {termsAndConditionText}
        </div>
      </>
    );
  };

  const renderInput = (config) => {
    let { label, name, placeholder, type, pattern } = config;
    return (
      <>
        <h6 className="fnt-12 text-white">{label}</h6>
        <input
          type={type}
          name={name}
          pattern={pattern}
          value={formValues[name]}
          onChange={(e) => {
            if (pattern)
              e.target.validity.valid &&
                handleChangeForm({ name, value: e.target.value });
            else handleChangeForm({ name, value: e.target.value });
          }}
          className="w-100 p-2 rounded mb-3 nav-p self-inp2 form-control"
          placeholder={placeholder}
        />{" "}
      </>
    );
  };
  const createInputForm = (conf) => {
    if (Array.isArray(conf)) {
      return conf.map((item) => {
        return renderInput(item);
      });
    } else return renderInput(conf);
  };

  const renderButtonText = () => {
    switch (mode) {
      case "code":
        return verifyCodeText;
      case "change_password":
        return submitText;
      case "phone_number":
        return sendActivationText;
      default:
        return [];
    }
  };

  let currentFormConfig = getFormConfig();

  return (
    <>
      <GlobalHeader noRedirection={true} />
      <div className="body-wrapper2">
        {title()}
        <div className="login-wrapper login-page-wrapper">
          <div className="log-main m-auto w-100 d-table h-100 pl-3 pr-3">
            <div className="d-table-cell align-middle">
              <div className="w-100 pull-left login-page position-relative">
                {introBlock()}
                <form>
                  {createInputForm(currentFormConfig)}
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="text-center fnt-14 h-35 w-100 text-white border-0 rounded mt-3 mb-2 btn blue Gilroy-Semibold"
                  >
                    {renderButtonText()}
                  </button>
                  {formFooter()}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
