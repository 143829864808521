import React, { Component } from "react";
import { formatNumber } from "../../../utils/numberFormatters";
import { StatusLoader } from "../../commons/loader";
import CatchoutsideEvent from "../../commons/CatchOutsideEvent/catchOutsideEvent";

export default class CountryListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrop: false,
    };
  }
  highlightItem = () => {
    return "cont-bg-active";
  };
  clickApiCalls = (params) => {
    let { listItem, name, type } = params;
    let { isCollapsed } = this.props;
    /**all the events called when user clicks a country in side bar */
    if (listItem.clickable) {
      if (type === "country") {
        this.props.fetchCountryDetails(listItem[name]);
      } else this.props.handleChangeCountrySelect({ value: listItem });
    } else this.props.changeCollapse(!isCollapsed);
  };

  toggleState = (params) => {
    let { name } = params;
    let toggle = this.state[name];
    this.setState({
      [name]: !toggle,
    });
  };
  renderList = () => {
    let { affectedCountry } = this.props.reducer;
    let { countryList, provinceStates } = affectedCountry;
    console.log("country listing", countryList);
    let { showDrop } = this.state;
    return countryList.map((elem, idx) => {
      return (
        // <CatchoutsideEvent
        // targetId={`dropItem${idx}`}
        // toggleHandler={this.toggleState}
        // stateName="showDrop"
        // index={idx}>
        elem.province_state ? (
          <>{this.countryWithProvince(elem, idx)}</>
        ) : (
          <>
            <button
              key={idx}
              onClick={() => {
                // this.toggleState({
                //   name: "showDrop",
                // });
                this.clickApiCalls({
                  listItem: elem,
                  type: "country",
                  name: "name",
                });
              }}
              className="country-button w-100 pt-2 pb-2 pl-3 pr-3 rounded fnt-14 cont-bg mb-2 text-white cursor "
            >
              <>
                {elem.name}
                <span class="pull-right nav-p hvr-clr">
                  {formatNumber(elem.count)}
                </span>
              </>
            </button>
          </>
        )
      );
    });
  };
  showDropClass = () => {
    if (this.state.showDrop) return "show";
    else return "";
  };
  provinceDropDown = (conf) => {
    let { affectedCountry } = this.props.reducer;
    let country = conf.Country_Region;
    let { provinceStates } = affectedCountry;
    let list = [];
    if (provinceStates.length) {
      list = provinceStates.map((info) => {
        let { Province_State, count, Country_Region } = info;
        if (Country_Region === country) {
          return (
            <button
              onClick={()=>this.clickApiCalls({
                listItem: conf,
                type: "country",
                name: "name",
              })}
              class="text-white pt-2 pb-2 pl-3 bg-transparent border-0 text-left fnt-14 cursor w-100 dropdown-item"
              type="button"
            >
              {Province_State}{" "}
              <span class="pull-right nav-p">{formatNumber(count)}</span>
            </button>
          );
        } else return <StatusLoader />;
      });
    }

    return (
      <div
        class={`usa-drop w-100 dropdown-menu `}
        aria-labelledby="dropdownMenu2"
      >
        {list}
      </div>
    );
  };
  countryWithProvince = (conf, idx) => {
    let { Country_Region, count } = conf;
    let { affectedCountry } = this.props.reducer;
    let { provinceStates } = affectedCountry;
    return (
      <div class="dropdown">
        <button
          key={idx}
          onClick={(e) => {
            e.preventDefault();
            this.toggleState({
              name: "showDrop",
            });
            this.clickApiCalls({
              listItem: conf,
              type: "country",
              name: "name",
            });
          }}
          className="country-button w-100 pt-2 pb-2 pl-3 pr-3 rounded fnt-14 cont-bg text-white cursor mb-2"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {Country_Region}
          <span class="pull-right nav-p hvr-clr">{formatNumber(count)}</span>
        </button>
        {this.provinceDropDown(conf)}
      </div>
    );
  };
  render() {
    let { sideBarList } = this.props.reducer;
    let { loading } = sideBarList;
    return (
      <div class="covid_report_btn_con" id="covid_report_btn_con_id">
        {loading ? <StatusLoader /> : this.renderList()}
      </div>
    );
  }
}
