import React, { useEffect, useState } from "react";
import AddUserOrganizationBlock from "./addUserOrganizationBlock";
import { latestReportConst } from "../../../constants/text-constants";
import { BuildFormHandler } from "../BuildFields";
import { toastNotification } from "../toastNotification";

export const AOBody = (props) => {
// console.log("<<<<<<<<<<<<<",props);

  const userInputBlock = () => {
    let { editOrgForm, orgFormMode, organizationsFormConfig, proCodOrgFormConfig , orgProCodeFormMode, addOrgForm, addOrgProCodeForm} =
      props.reducer;
    const notDisabledBtnClass = addOrgForm.hasOwnProperty("organization") || addOrgProCodeForm.hasOwnProperty("pro_code") ? "btn-submit" : "";
    

    const addSubscription = () => {
      const body = {
        "user": localStorage.getItem("user_id"),
        "organization": localStorage.getItem("org_id")
      }
      props.addSubscriptionsRequestAction(body, (res) => {
        // console.log(res);
        if (res.status === 200) {
          toastNotification({
            msg: res.data,
            type: "success",
          });
          props.close()
        } else {
          toastNotification({
            msg: "test2 ",
            type: "error",
          });
        }
      });
    }

    const onAddOrg = () => {
      const body = {
        "user": localStorage.getItem("user_id"),
        "organization": localStorage.getItem("org_id")
      }
      props.createSubscriptionAction(body, (res) => {
        if (res.status === 201) {
          localStorage.setItem("subs_id", res.data.id)
          addSubscription()
        } else {
          toastNotification({
            msg: "test",
            type: "error",
          });
        }
      });
    }

  

    return (
      <div className="col-sm-5">
        <div className="row">
          <div className="col-12">
            <BuildFormHandler
              formDefinition={organizationsFormConfig}
              variables={orgFormMode === "add" ? addOrgForm : editOrgForm}
              {...props}
            />
          </div>
          <span
            className="-or-"
            style={{
              width: 26,
              height: 14,
              fontSize: 12,
              lineHeight: 1.17,
              letterSpacing: 0.5,
              color: "#8c8e8f",
              margin: "auto",
              marginTop: 10,
            }}
          >
            -or-
          </span>
            <div className="col-12">
              <BuildFormHandler
                formDefinition={proCodOrgFormConfig}
                variables={orgProCodeFormMode === "add" ? addOrgProCodeForm : editOrgForm}
                {...props}
              />
          </div>
        </div>
        <div className="text-right mt-1 mb-1">
          <button
            className={"add-record-btn w-100 " + `${notDisabledBtnClass}`}
            style={{ marginTop: 92 }}
            onClick={() => onAddOrg()}
          >
            Add Organization
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-body">
      <div className="col-sm-12">
        <div className="row">
          {userInputBlock()}
          <AddUserOrganizationBlock {...props} />
        </div>
      </div>
    </div>
  );
};
