import React from "react";
import { getLocationDetails } from "../../../utils/getRegionInfo";
import { toastNotification } from "../../commons/toastNotification";

const google = window.google;
var autocomplete = null;
export const googleAddressComplete = (props) => {
  initAutocomplete(props);
};

const initAutocomplete = (props) => {
  // Create the autocomplete object, restricting the search predictions to
  // geographical location types.
  autocomplete = new google.maps.places.Autocomplete(
    document.getElementById("autocomplete")
  );
  // Avoid paying for data that you don't need by restricting the set of
  // place fields that are returned to just the address components.
  //   autocomplete.setFields(["address_component"]);

  // When the user selects an address from the drop-down, populate the
  // address fields in the form.
  autocomplete.addListener("place_changed", () => fillInAddress(props));
};
const fillInAddress = (props) => {
  // Get the place details from the autocomplete object.
  var places = autocomplete.getPlace();
  let value = document.getElementById("autocomplete").value;
  if (places && places.geometry) {
    let fullAddress = getLocationDetails(places);
    let { lat, lng, country, province } = fullAddress;
    let latLng = { lat, lng, country, province };
    props.handleChange({
      parentName: "addActivityForm",
      name: "address",
      value,
    });
    props.handleChange({
      parentName: "addActivityForm",
      name: "latLng",
      value: latLng,
    });
  } else {
    toastNotification({
      msg: "Please try again. Error in fetching location",
      type: "warning",
    });
    document.getElementById("autocomplete").value = "";
  }
};
