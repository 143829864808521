import React, { Component } from "react";

import {
  latestReportConst,
  submitText
} from "../../../constants/text-constants";
import { initMap }from "../../googleMap";

import { TextInput } from "../textField";
import { CustomButton } from "../buttons";

const { locText } = latestReportConst.addContactTexts;

export default class ACLBody extends Component {

   handleChange = params => {
    this.props.handleChangeLocationForm(params);
    this.props.handleFormChange(params );
  };

  componentDidMount = () => {
    initMap("map", [], true, this.handleChange, this.locationstore, 'contact' )
  }
  locationstore = () => {
    console.log('sadas')
  }
  submitForm = () => {
    this.props.toggleSubModal();
    // this.props.handleFormChange({ type: "latLng" });
  };
  render() {

    return (
      <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group">
              <label>{locText}</label>
              <TextInput
                className={"form-control"}
                type={"text"}
                id={"pac-input"}
                handleChange={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="location-direction">
          <div className="location-canvas" id="map"></div>
        </div>
        <CustomButton
          className="btn btn-block btn-submit fnt-14 SFProText-Regular"
          text={this.props.buttonText ? this.props.buttonText : submitText}
          clickEvent={this.submitForm}
        />
      </div>)
      }
    }
