import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  UPDATE_USER_VACCINE_SUCCESS,
  UPDATE_USER_VACCINE_FAILED,
  UPDATE_USER_VACCINE_STARTED
} from "../../../../constants/action-types";
import {
    UPDATE_USER_VACCINE_URL
} from "../../../../constants/configurations";

export const updateUserVaccinesAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "patch",
        api: UPDATE_USER_VACCINE_URL(body.get('id')),
        started: UPDATE_USER_VACCINE_STARTED,
        success: UPDATE_USER_VACCINE_SUCCESS,
        failure: UPDATE_USER_VACCINE_FAILED
      },
      callBack
    );
  };
};
