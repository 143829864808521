/**renders the left pane in the latest report page */

/**importing react dep */
import React, { Component } from "react";

/**components */
import { TextInput } from "../../commons/textField";
import { CustomButton } from "../../commons/buttons";
import { LRLeftListing } from "./LRLeftListing";

/**constants */
import { latestReportConst, locationEmptyState, editIcon } from "../../../constants/text-constants";
import AddLocationModal from "../../commons/AddLocationModal/addLocationModal";
const {
  title,
  addLocText,
  expoText,
  placeholder,
  editExpoText,
} = latestReportConst.leftPaneTexts;

export default class LRLeftPane extends Component {
  toggleAddlocationModal = () => {
    let { showLocationModal } = this.props.reducer;
    this.props.handleChange({
      name: "showLocationModal",
      value: !showLocationModal,
    });
  };

  handleChangeAddLocForm = (params) => {
    let { name, value, parentName } = params;
    this.props.handleChange({
      parentName: parentName,
      name,
      value,
    });
  };
  /**search block in the left pane */
  inputSearchBlock = () => {
    let { searchKey } = this.props.reducer;
    return (
      <TextInput
        type="text"
        className="form-control"
        value={searchKey}
        name={"searchKey"}
        placeholder={placeholder}
        handleChange={this.props.handleChange}
      />
    );
  };

  componentDidMount = () => {
    this.props.getAllTravelLocation();
  };

  addLocationButtonBlock = () => {
    return (
      <div className="d-flex justify-content-between item-center">
        <p>{title}</p>
        <CustomButton
          type="button"
          className="add-location-btn"
          data-toggle="modal"
          data-target="#loca2"
          text={`+ ${addLocText}`}
          clickEvent={this.toggleAddlocationModal}
        />
      </div>
    );
  };

  locationExpoHeader = () => {
    let { userLocationList } = this.props.reducer;
    return (
      <div className="d-flex justify-content-between item-center  Location-Exposure">
        <p
          onClick={() =>
            this.handleChangeAddLocForm({ name: "searchKey", value: "" })
          }
        >
          {expoText} ({userLocationList.filter(item=>item.is_infected).length}){" "}
        </p>{" "}
        {/*eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="editLoc" onClick={this.toggleAddlocationModal}>
         {editIcon}{editExpoText}
        </a>
      </div>
    );
  };
  searchBlock = () => {
    return (
      <div className="element-bg">
        <div className="location-visited">
          {this.addLocationButtonBlock()}
          {this.inputSearchBlock()}
          {this.locationExpoHeader()}
        </div>
      </div>
    );
  };
  render() {
    let {
      userLocationList,
      showLocationModal,
      locationLoading,
      searchKey,
    } = this.props.reducer;
    return (
      <div className="col-xl-3 col-lg-12 col-md-12 col-sm-12 p-10 item-resp-1 pull-left">
        {this.searchBlock()}
        <LRLeftListing
          searchKey={searchKey}
          locationLoading={locationLoading}
          listingArray={userLocationList}
          emptyText={locationEmptyState}
        />
        {showLocationModal && (
          <AddLocationModal
            showModal={showLocationModal}
            newUserLocation={this.props.newUserLocation}
            deleteUserLocation={this.props.deleteUserLocation}
            editUserLocation={this.props.editUserLocation}
            toggleModal={this.toggleAddlocationModal}
            handleFormChange={this.handleChangeAddLocForm}
            {...this.props}
          />
        )}
      </div>
    );
  }
}
