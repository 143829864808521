/**all the component actions such as handle change and toggle modal etc. keeped here */

import { HANDLE_CHANGE_GLOBAL_HEADER } from "../../../constants/action-types";

export const handleChangeGlobalHeader = (params) => dispatch => {
  dispatch({
    type: HANDLE_CHANGE_GLOBAL_HEADER,
    payload: params
  });
};
