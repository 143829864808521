import moment from "moment";

export const commonDateFormtter = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

export const apiDateFormat = (date) => {
  let converted = moment(date).format("YYYY-MM-DD");
  return converted;
};

export const fromNow = (date) => {
  return moment(date).fromNow();
};

export const differenceInDate = (params) => {
  let {from_date , to_date , interval} = params;
  var date1 = moment(from_date);
  var date2 = moment(to_date);
  return date2.diff(date1, interval); // 1
};
