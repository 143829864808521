/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

/**constants */
/**Images */

import { latestReportConst, flightEmptyState } from "../../../constants/text-constants";
import { TableBuilder } from "../tableBuilder";
import { StatusLoader } from "../loader";
// import {flighticon} from "../../../assets/images/backicon/ic-flight-48px.svg";
import flighticon from "../../../assets/images/ic-flight-48px.svg";
const { addedFlight } = latestReportConst.addFlightTexts;

export default class UserAddedFlightBlock extends Component {
  render() {
    let {
      addedFlightList,
      addFlightTableConf,
      onDeleteFlight,
      onEditFlight,
      editToDelete
    } = this.props;
    let { listUserFlights, newAddedFlightList } = this.props.reducer;
    let flightList = addedFlightList.concat(newAddedFlightList);
    return (
      <div className="col-sm-7">
        <div className="modal-data-bg">
        <div className="d-flex d-op">
          <div>
            <img src={flighticon} alt={"flight-icon"} />
            {/* <img src={contactImage} alt={"contact-icon"} /> */}
          </div>
          <h4>{addedFlight}</h4>
          <h1>{flightList && flightList.length}</h1>
        </div>
        {listUserFlights.loading ? (
          <StatusLoader />
        ) : flightList.length ? (
          <TableBuilder
            editToDelete ={editToDelete}
            tableItemList={flightList}
            tableConfig={addFlightTableConf}
            onDeleteItem={onDeleteFlight}
            onEditItem={onEditFlight}
          />
        ) : (
          flightEmptyState
        )}
        </div>
      </div>
    );
  }
}
