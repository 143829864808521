import React, { Component, useEffect } from "react";
import { ModalWindow } from "../modal";
import { ATHeader } from "./ATheader";
import { ATBody } from "./ATBody";
import { CustomButton } from "../../commons/buttons";
import { toastNotification } from "../toastNotification";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { latestReportConst } from "../../../constants/text-constants";
import { errorHandler } from "../../../utils/commonErrorHandler";

let { confirmation } = latestReportConst.addContactTexts;

export default class AddTestingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,
      locationDetails: false,
      editToDelete: "",
      showTesting: ""
    };
  }

  
  componentDidMount() {
    // this.props.listUserTestingsAction();
    this.onSelfReportPreview();
  }
  componentWillUnmount() {
    this.props.handleChangeForm({ type: "reset", formType: "testing" });
  }

  onSelfReportPreview = () => {
    if (this.props.selfReportVariables) {
      let { tested_date, test_result, testing_id } = this.props.selfReportVariables
      let parentName = "addTestingForm";
      this.handleFormChange({ parentName, name: 'tested_date', value: tested_date})
      this.handleFormChange({ parentName, name: 'test_result', value: test_result})
    }
  };

  onDeleteTesting = (params) => {
    let { id } = params;
    this.props.deleteUserTestingsAction({ id }, (res) => {
      if (res.status === 204) {
        toastNotification({
          msg: "Succesfully Deleted Record",
          type: "success",
        });
        this.props.getUserInfoAction();
        this.props.handleChangeForm({ type: "reset", formType: "testing" });
        this.props.listUserTestingsAction();
      } else {
        toastNotification({
          msg: "Failed to Deleted Record ",
          type: "error",
        });
      }
    });
  };
  onEditTesting = (params) => {
    let { id } = params;
    this.setState({
      editToDelete: id,
    });
    this.props.handleChangeForm({ mode: "edit", id, formType: "testing" });
  };

  onViewTesting = (params) => {
    let { id } = params;
    this.setState({
      showTesting: id,
    });
    this.toggleTestingViewModal()
  };

  formatBody = (formBody) => {
    let body = {};
    let valid = true;
    let { testingFormMode } = this.props.reducer;
    console.log('testingFormBody', formBody);
    for (const key in formBody) {
      if (formBody.hasOwnProperty(key)) {
        const element = formBody[key];
        /**here we get the flight id from the name */
        /**function to highlight missed out field */
        if (testingFormMode === 'edit' && key === "file_upload" && element !== 'None'
          && !(element instanceof Blob)) {
           continue
        }
        if ((key === 'test_result' || key === 'location' || key === 'file_upload') && element === '') {
          this.props.handleChangeForm({
            validate: true,
            name: key,
            type: "testing",
          });
          valid = false;
        } else {
          if ((key === 'test_result' || key === 'location' || key === 'file_upload') && element !== '' && element !== 'None') {
            this.props.handleChangeForm({
              valid: true,
              name: key,
              type: "testing",
            });
          }
          if (key === "tested_date") {
            body[key] = apiDateFormat(element.length ? element : new Date());
          } else body[key] = element;
        }
      }
    }

    if (formBody['file_upload'] && formBody['file_upload'] === 'None') {
      body['empty_file'] = true;
      valid = false;
      delete body['file_upload'];
    }

    return { body, valid };
  };

  isDuplicate = (body) => {
    let testingList = this.props.reducer.addedTestingList;
    let result = 0;
    testingList.map((iter) => {
      if (
        body.testing === iter.testing.id
        // body.Testing === iter.vaccine.id &&
        // body.flight_no === iter.flight_no &&
        // body.date_journey === iter.date_journey
      ) {
        result++;
      }
    });
    return result === 0 ? false : true;
  };

  callAddTestingApi = () => {
    let { addTestingForm } = this.props.reducer;

    let res = this.formatBody(addTestingForm);
    let { body, valid } = res;

    if (valid) {
      const formData = new FormData();

      for (const key in body) {
        formData.append(key, body[key]);
      }
      this.props.addUserTestingsAction(formData, (res) => {
        if (res.status === 201) {
          toastNotification({
            msg: "Added Record Successfully",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listUserTestingsAction();
          this.props.handleChangeForm({
            type: "reset",
            formType: "testing",
          });
        } else {
          toastNotification({
            msg: `Added Record Failed :: ${errorHandler(res.data)}}`,
            type: "error",
          });
        }
      });
    }
  };

  callEditTestingApi = () => {
    let { editTestingForm } = this.props.reducer;
    let res = this.formatBody(editTestingForm);
    let { body, valid } = res;
    if (valid) {
      const formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      this.props.updateUserTestingsAction(formData, (res) => {
        if (res.status === 200) {
          toastNotification({
            msg: "Update Testing Success",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listUserTestingsAction();
          this.props.handleChangeForm({
            type: "reset",
            formType: "testing",
          });
        }
      });
    }
  };
  submitAddTesting = () => {
    let { testingFormMode } = this.props.reducer;
    if (testingFormMode === "add") this.callAddTestingApi();
    else if (testingFormMode === "edit") this.callEditTestingApi();
  };

  handleFormChangedLocation = (params) => {
    let { testingFormMode } = this.props.reducer;
    const { name, value } = params;
    let parentName = "";
    if (testingFormMode === "add") {
      parentName = "addTestingForm";
    } else if (testingFormMode === "edit") {
      parentName = "editTestingForm";
    }
    this.props.handleChangeForm({ parentName, name, value });
  };

  handleChangeLocationForm = (params) => {
    this.handleFormChange({ ...params, type: "latLng" });
  };

  handleFormChange = (params) => {
    let { testingFormMode } = this.props.reducer;
    const { name, value, type } = params;
    let parentName = "";
    if (testingFormMode === "add") {
      parentName = "addTestingForm";
    } else if (testingFormMode === "edit") {
      parentName = "editTestingForm";
    }

    this.props.handleChangeForm({ parentName, name, value, type });
  };

  addNewTestingsToList = () => {
    let { addTestingForm } = this.props.reducer;
    this.props.handleChangeForm({
      type: "addToList",
      name: "newAddedTestingList",
      listName: "addedTestingList",
      value: addTestingForm,
    });
  };

  toggleTestingLocationModal = () => {
    let { showSubTestingLocationModal } = this.props.reducer;
    this.props.handleChangeForm({
      name: "showSubTestingLocationModal",
      value: !showSubTestingLocationModal,
    });
  };

  toggleTestingViewModal = () => {
    let { showTestingViewModal } = this.props.reducer;
    this.props.handleChangeForm({
      name: "showTestingViewModal",
      value: !showTestingViewModal,
    });
  };

  confirmationModal = () => {
    let { testingFormMode, addTestingForm, editTestingForm } = this.props.reducer;
    let data = testingFormMode === "add" ? addTestingForm : editTestingForm;
    if (data.Testing_no !== "") {
      this.setState({
        confirmationModal: true,
      });
    } else {
      this.props.toggleModal();
    }
  };

  onBacktoEdit = () => {
    this.setState({ confirmationModal: false });
  };

  close = () => {
    this.props.toggleModal();
    this.setState({ confirmationModal: false });
  };

  submit = () => {
    this.setState({ confirmationModal: false }, () => {
      this.submitAddTesting();
    });
  };

  render() {
    let { showModal } = this.props;
    let { confirmationModal, editToDelete, showTesting} = this.state;
    return (
      <>
        <ModalWindow
          showModal={showModal}
          onModalClose={this.confirmationModal}
          heading={<ATHeader toggleModal={this.confirmationModal} />}
          body={
            <ATBody
              {...this.props}
              editToDelete={editToDelete}
              showTesting={showTesting}
              // handleFormChangedLocation={this.handleFormChangedLocation}
              handleChangeLocationForm={this.handleChangeLocationForm}
              handleFormChange={this.handleFormChange}
              submitAddTesting={this.submitAddTesting}
              onDeleteTesting={this.onDeleteTesting}
              onEditTesting={this.onEditTesting}
              onViewTesting={this.onViewTesting}
              addNewTestingsToList={this.addNewTestingsToList}
              toggleLocationModal={this.toggleTestingLocationModal}
              toggleViewModal={this.toggleTestingViewModal}
              closeModal={this.confirmationModal}
            />
          }
        />
        <ModalWindow
          showModal={confirmationModal}
          onModalClose={this.onBacktoEdit}
          heading={
            <ATHeader toggleModal={this.onBacktoEdit} title={confirmation} />
          }
          body={
            <div className="text-center">
              <h6 className="modal-text mt-3">
                Do you want to Submit the Changes?
              </h6>
              <div class="row mt-4 text-center w-100 m-auto edit-sub-modal">
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Submit"
                    clickEvent={this.submit}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Don't Submit"
                    clickEvent={this.close}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    text="Close"
                    className=" btn btn-block btn-submit fnt-14 SFProText-Regular"
                    clickEvent={this.onBacktoEdit}
                  ></CustomButton>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>
      </>
    );
  }
}
