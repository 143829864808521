import React from "react";
/**components */
import { TextInput } from "../../commons/textField";

/**constants */
import { homePageConst, searchIconBlack } from "../../../constants/text-constants";
let { collapseText, collapseButtonText } = homePageConst.sideBarConst;

export const CollapseSearchBar = (props) => {
  let { filterBy, selectedCountryMapList, zipSearchText, getStateData } = props.reducer;
  console.log('getStateLocation', props.reducer)
  return (
    <div className="w-100 pull-left">
      <div className="pull-left">
        <h6 className="Gilroy-Medium let-spac mar-btm-5 country-overflow" title="">
          {selectedCountryMapList.name || selectedCountryMapList.region}
        </h6>
        <p className="nav-p fnt-12 let-spac Gilroy-Medium">{ getStateData.locationchoose ==="" || getStateData.locationchoose ==="USAZONE" ?  'All States': 'All Cities' }</p>
      </div>
      <div className="search-wrapper popup-inp position-relative d-inline-block fnt-14 pull-right">
        <TextInput
          type="text"
          value={zipSearchText}
          name="zipSearchText"
          handleChange={props.handleChangeText}
          placeholder={collapseText}
          className="w-100 rounded"
        />
        <span className="srch-icn">
        {searchIconBlack}
        </span>
        <button
          className="text-white rounded border-0 fnt-12"
          onClick={props.onSubmitZipSearch}
        >
          {collapseButtonText}
        </button>
      </div>
    </div>
  );
};
