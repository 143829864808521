import React, { Component } from "react";
import { CollapseSearchBar } from "./collapseSearch";
import { SelfReportInfo } from "./selfReportedInfo";
import ReportedLocationBlock from "./reportedLocationBlock";
import deleteButton from "../../../assets/images/delete-button.svg";
export default class HomeCollapseMenu extends Component {
  render() {
    let type = "";
    let {
      selectedCountryMapList,
      affectedCountry,
      reportedLocation,
    } = this.props.reducer;
    let { list, loading } = reportedLocation;
    list = list.filter((item) => item.is_infected);
    if (affectedCountry.list.length) type = "country";
    else if (affectedCountry.provinceStates) type = "province";
    return (
      <div className="filter-wrapper bg-white w-100">
        <div
          onClick={(e) => this.props.changeCollapse(true)}
          className="pull-right mb-2 cursor close-collapse"
        >
          <img src={deleteButton} alt="deleteicon" />
        </div>
        <CollapseSearchBar {...this.props} />
        <SelfReportInfo
          type={type}
          selectedCountryMapList={selectedCountryMapList}
          {...this.props}
        />
        <ReportedLocationBlock reportedLocation={{ list, loading }} />
      </div>
    );
  }
}
