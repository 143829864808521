import React from "react";
export const CustomButton = props => {
  let { text, type, className, datatoggle, dataTarget, clickEvent } = props;
  return (
    <button
      type={type}
      className={className}
      data-toggle={datatoggle}
      data-target={dataTarget}
      onClick={clickEvent}
    >
      {text}
    </button>
  );
};
