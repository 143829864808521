import React from "react";
import { ModalWindow } from "../modal";
import ATLBody  from "./ATLBody";
import { latestReportConst } from "../../../constants/text-constants";
import { CommonModalHeader } from "../ModalHeader";

const { locationText } = latestReportConst.addContactTexts;
export const AddTestingLocationModal = props => {
  let { toggleLocationModal, showSubTestingLocationModal } = props;
  return (
    <ModalWindow
      showModal={showSubTestingLocationModal}
      onModalClose={toggleLocationModal}
      heading={
        <CommonModalHeader title={locationText} toggleHandler={toggleLocationModal} />
      }
      body={<ATLBody {...props}
      buttonText = 'Add Location' />}
    />
  );
};
