/** APP constants  */

export const API_BASE_URL = "https://api.samscloud.io/api/covid19/";
export const APP_BASE_NAME = "";
const slug = localStorage.getItem("organization_slug")
/** Third party API  */
/**third party api creds */
export const googleMapKey = "AIzaSyBLkzKH5T4OMFauzbMR6SHhJoRrpu8LJAQ";

export const GET_REGION_WITH_ZIP = (zip) =>
  `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${googleMapKey}`;

export const minimumScreenRatio = 992;
/** App API endpoints */
export const defaultLatLng = { lat: 39.3958, lng: -101.0524 };
export const COUNTY_WISE_INFO_URL = "/location/global-data-json/";
export const UPDATE_USERS_URL = (id) => {
  return `/users/user-report/${id}/update/`;
};

export const GET_USERS_URL = `users/user-report/`;
export const GET_USER_INFO = "/users/";
export const GET_DISEASE_URL = "/contacts/list-disease/";
export const GET_SYMTOMS_URL = "/contacts/list-symptoms/";
export const GET_USER_STATUS = "/users/list-status/";
export const GET_VACCINE_DOSES_URL = "/vaccines/list-doses/";
export const GET_VACCINE_MANUFACTURES_URL = "/vaccines/list-manufacturers/";

/**global apis */
export const testCenterUrl =
  "https://www.cdc.gov/coronavirus/2019-ncov/symptoms-testing/testing.html";
export const GET_COVID_STATUS_URL = "/location/list_global-countrys/";
export const GET_STATE_STATUS_URL = (country) =>
  `/location/global-country-details/${country}/`;
export const GLOBAL_EFFECT_API = "/location/global-effects/";

export const ADD_ACTIVITIES = "/location/add-assistance-locations/";
export const LIST_ACTIVITIES = "/location/list-assistance-locations/";
export const DELETE_ACTIVITY = (id) => {
  return `/location/${id}/assistance/delete/`;
};

/**flight CRUD apis */
export const UPDATE_USER_FLIGHT_URL = (id) => {
  return `/flight/user-flightdetails/${id}/update/`;
};
export const GET_FLIGHTS_LIST_URL = "/flight/add-list-flightdetails/";
export const DELET_USER_FLIGHTS_URL = (id) => {
  return `/flight/${id}/delete/`;
};
export const GET_FLIGHTS_CARRIERS_LIST_URL = "/flight/list-carrier/";

/**organization CRUD apis */
export const GET_ORGANIZATIONS_LIST_URL = () => {
  return `https://api.samscloud.io/api/organization/covid_organizations_list/`;
};

export const CREATE_SUBSCRIPTIONS_LIST_URL = () => {
  return `https://api.samscloud.io/api/covid19/users/covid-subscriptions/`;
};
export const ADD_BUTTON_ORGANIZATIONS_LIST_URL = (id) => {
  console.log(id);
  return `https://api.samscloud.io/api/covid19/users/covid-subscriptions/${id}/covid_request/`;
};
export const GET_VIEW_ORGANIZATIONS_LIST_URL = (id) => {
  return `https://api.samscloud.io/api/covid19/users/covid-subscriptions/`;
};

// https://api.samscloud.io/api/covid19/users/covid-subscriptions/

/**contact CRUD apis */
export const UPDATE_USER_CONTACT_URL = (id) => {
  return `/contacts/user-contact/${id}/update/`;
};
export const ADD_USER_CONTACT_URL = "/contacts/add-contact/";

/**vaccine CRUD apis */
export const UPDATE_USER_VACCINE_URL = (id) => {
  return `/vaccines/user-vaccines/${id}/update/`;
};
export const ADD_USER_VACCINE_URL = "/vaccines/add-vaccines/";
export const DELETE_USER_VACCINE_URL = (id) => `/vaccines/${id}/delete/`;

/**testing CRUD apis */
export const UPDATE_USER_TESTING_URL = (id) => {
  return `/users/user-testing/${id}/update/`;
};
export const ADD_USER_TESTING_URL = "/users/user-testing/";
export const DELETE_USER_TESTING_URL = (id) =>
  `/users/user-testing/${id}/delete/`;

/**location CRUD apis */
export const UPDATE_USER_LOCATION_URL = (id) =>
  `/location/user-locations/${id}/update/`;
export const GET_SELF_REPORTED_LOCATION_URL = (params) => {
  let { province, country, city } = params;
  return `/location/users-location-details/${country}${
    province ? `?province=${province}${city ? `&city=${city}` : ""}` : ""
  }`;
};
export const REPORTED_LOCATION_WITH_ZIP_URL = (params) => {
  let { province, country, city } = params;
  return `/location/city-details-zip/${country}/${province}${
    city ? `?city=${city}` : ""
  }`;
};
export const DELETE_USER_LOCATION_URL = (id) => `/location/${id}/delete/`;
export const GET_USER_LOCATIONS_URL = "/location/add-locations/";
export const ADD_UNAUTH_USER_LOCATION = "/location/unknownuser_location_risk/";

/**test lab urls */
export const GET_ALL_US_TESTLABS = "/location/all-lab-locations/";

/*Auth*/
export const POST_LOGIN_CREDENTIAL_URL = "/users/login/";
export const POST_SIGNUP_INFO_URL = "/users/";
export const POST_OTP_VERIFICATION_URL = "/users/verify-otp/";
export const POST_OTP_RESEND_VERIFICATION_URL = "/users/resent-otp/";
export const CHANGE_PHONENUMBER_ACTION = "/users/sent-otp/";
export const FORGOT_PASSWORD_VERIFY = "/users/forgot-password/";
export const FORGOT_PASSWORD_OTP = "/users/forgot-password-verify-otp/";
export const CHANGE_PASSWORD = "/users/forgot-password-update/";

export const GET_USER_LAST_UPDATE = "/users/last-updated/";
