import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_DOSES_SUCCESS,
  LIST_DOSES_STARTED,
  LIST_DOSES_FAILED
} from "../../../../constants/action-types";
import { GET_VACCINE_DOSES_URL } from "../../../../constants/configurations";

export const getDosesListAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "get",
      api: GET_VACCINE_DOSES_URL,
      started: LIST_DOSES_STARTED,
      success: LIST_DOSES_SUCCESS,
      failure: LIST_DOSES_FAILED
    },callBack);
  };
};
