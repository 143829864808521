/**utils */
import history from "../../../components/history";
import queryString from "query-string";

const google = window.google;

export const getActiveProvince = (list) => {
  let { location } = history;
  let urlParams = queryString.parse(location.search);
  let res = {};
  if (urlParams.province) {
    for (const key in list) {
      if (list.hasOwnProperty(key)) {
        const element = list[key];
        if (element.Province_State === urlParams.province) {
          res = element;
        }
      }
    }
  }
  return res;
};
export const getSelfReportStats = (params, state) => {
  let { info } = params;
  let body = {
    region: "",
    count: 0,
    cases: [
      { id: 1, color: "blue", status: "Confirmed", count: 0 },
      { id: 2, color: "yellow", status: "Active", count: 0 },
      { id: 3, color: "green", status: "Recovered", count: 0 },
      { id: 4, color: "orange", status: "Deaths", count: 0 },
    ],
  };
  let {
    Confirmed__sum,
    Confirmed,
    Deaths,
    Deaths__sum,
    Recovered,
    Recovered__sum,
    Active,
    Active__sum,
  } = info;
  body.region = info.City
    ? info.City
    : info.Province_State.length
    ? info.Province_State
    : info.Country_Region;
  let pos = addStats(info, body.cases);
  body.cases[pos.cfm].count = Confirmed__sum || Confirmed||0;
  body.cases[pos.death].count = Deaths__sum || Deaths||0;
  body.cases[pos.rcv].count = Recovered__sum || Recovered||0;
  body.cases[pos.active].count = Active__sum || Active||0;
  body.count = Confirmed__sum || Confirmed||0;
  body.info = info;
  return body;
};

const addStats = (info, array) => {
  let postions = { cfm: -1, active: -1, rcv: -1, death: -1 };
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.status === "Confirmed") postions.cfm = key;
      else if (element.status === "Active") postions.active = key;
      else if (element.status === "Recovered") postions.rcv = key;
      else if (element.status === "Deaths") postions.death = key;
    }
  }
  return postions;
};

export const addSummaryForProvinces = (array) => {
  let Confirmed__sum = 0;
  let Recovered__sum = 0;
  let City = "";
  let Active__sum = 0;
  let Deaths__sum = 0;
  let id = "";
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (!City.length) {
        City = element.Country_Region;
        id = element.id;
      }
      Confirmed__sum += element.Confirmed__sum;
      Recovered__sum += element.Recovered__sum;
      Deaths__sum += element.Deaths__sum;
      Active__sum += element.Active__sum;
    }
  }
  return {
    id,
    Confirmed__sum,
    Recovered__sum,
    City,
    Deaths__sum,
    Active__sum,
  };
};
const addBodyCountry = (element, key) => {
  let obj = {};
  obj["id"] = key;
  obj["name"] = element.Country_Region;
  obj["count"] = element.Confirmed__sum;
  if (element.Country_Region === "US") {
    // obj["latLng"] = new google.maps.LatLng(38.991136, -105.193959);
    obj["latLng"] = new google.maps.LatLng(37.09024 ,-95.712891);
  } else obj["latLng"] = new google.maps.LatLng(element.Lat, element.Long);
  return { ...element, ...obj };
};
const addProvince = (element, key, urlParams) => {
  let obj = {};
  if (urlParams.city) {
    if (element.Province_State.toLowerCase() === urlParams.city.toLowerCase()) {
      obj["id"] = key;
      obj["province"] = element.Province_State;
      obj["country"] = element.Country_Region;
      obj["count"] = element.Confirmed__sum;
      obj["latLng"] = new google.maps.LatLng(element.Lat, element.Long);
      return { ...element, ...obj };
    }
  } else {
    obj["id"] = key;
    obj["province"] = element.Province_State;
    obj["country"] = element.Country_Region;
    obj["count"] = element.Confirmed__sum || element.Confirmed;
    obj["latLng"] = new google.maps.LatLng(element.Lat, element.Long);
    return { ...element, ...obj };
  }
  return { obj };
};

const addCity = (element, key) => {
  let object = {};
  object["id"] = key;
  object["province"] = element.Province_State;
  object["city"] = element.City;
  object["country"] = element.Country_Region;
  object["count"] = element.Confirmed;
  object["latLng"] = new google.maps.LatLng(element.Lat, element.Long);
  return { ...element, ...object };
};
export const formatCountryList = (countryArray, type) => {
  let result = [];
  let proviceList = [];
  let cityList = [];
  let totalCovidSummary = {};
  let proviceBody = {};
  let cityBody = {};
  let body = {};
  let clickable = true;
  let showDrop = false
  // eslint-disable-next-line no-unused-vars
  let urlParams = null;
  let { location } = history;
  if (countryArray.length) {
    if (location.search) {
      urlParams = queryString.parse(location.search);
    }
    if (countryArray && countryArray.length) {
      for (const key in countryArray) {
        if (countryArray.hasOwnProperty(key)) {
          const element = countryArray[key];
          if (type === "userClicked") {
            if (countryArray.length === 1) {
              clickable = false;
            }
          }
          if (type === "zip") {
            cityBody = addCity(element, key);
          } else if (element.Province_State) {
            proviceBody = addProvince(element, key, {});
          } else if (element.Country_Region) {
            body = addBodyCountry(element, key);
          } else if (element.Total_confirmed) {
            totalCovidSummary = element;
          }
        }
        if (proviceBody.id) proviceList.push({ ...proviceBody, clickable,showDrop });
        if (cityBody.id) cityList.push({ ...cityBody, clickable ,showDrop});
        else if (body.id) result.push({ ...body, clickable,showDrop });
      }
    }
  }
  return { countryList: result, totalCovidSummary, proviceList, cityList };
};

export const formatReportedLocation = (list) => {
  let {location} =list;
  return location.map((item) => {
    return {
      ...item,
      name: item.location,
      Last_Update: item.updated_at,
      Lat: item.latitude,
      Confirmed: item.user,
      First_Update: item.date_created,
      Long: item.longitude,
      latLng: new google.maps.LatLng(item.latitude, item.longitude),
    };
  });
};
