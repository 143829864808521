import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_USER_TESTING_SUCCESS,
  ADD_USER_TESTING_FAILED,
  ADD_USER_TESTING_STARTED
} from "../../../../constants/action-types";
import { ADD_USER_TESTING_URL } from "../../../../constants/configurations";

export const addUserTestingsAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler(
    {
      dispatch,
      body,
      methordType: "post",
      api: ADD_USER_TESTING_URL,
      started: ADD_USER_TESTING_STARTED,
      success: ADD_USER_TESTING_SUCCESS,
      failure: ADD_USER_TESTING_FAILED
    }, callBack);
  };
};
