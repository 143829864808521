import { mapStyles } from "../components/GoogleMap/config.js";
import { groupAddress } from "../utils/getRegionInfo.js";

const generateAddress = (grouped, placeName) => {
  let address = "";
  for (const key in grouped) {
    if (grouped.hasOwnProperty(key)) {
      const element = grouped[key];
      if (element.length) {
        if (key === "country") address += element;
        else if (key !== "zip") address += `${element} ,`;
      }
    }
  }
  return placeName + ", " + address;
};
export const initMap = (
  mapId,
  LocationData,
  autosearch,
  callback,
  locationstore,
  contact
) => {
  let listofData = LocationData;
  let initialLoc = listofData.length > 0 ? listofData[0] : [];
  let current = {
    lat:
      initialLoc.latitude !== undefined
        ? parseInt(initialLoc.latitude, 10)
        : -33.92,
    lng:
      initialLoc.longitude !== undefined
        ? parseInt(initialLoc.longitude, 10)
        : 151.25,
  };

  var map = new window.google.maps.Map(document.getElementById(mapId), {
    center: current,
    zoom: 2,
    styles: mapStyles,
    disableDefaultUI: true,
  });
  if (autosearch) {
    var inputField = document.getElementById("pac-input");
    var autocomplete = new window.google.maps.places.Autocomplete(inputField);
    autocomplete.bindTo("bounds", map);

    autocomplete.addListener("place_changed", () => {
      var place = autocomplete.getPlace();
      if (place === undefined || place.geometry === undefined) {
        if (locationstore) {
          locationstore("add", false);
        }
        return;
      } else {
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(2); // Why 17? Because it looks good.
        }
      }

      /**mainly used for add location */
      let Country_Region = "";
      let City = "";
      let address = "";
      let Province_State = "";
      let placeName = place.name;
      let grouped = groupAddress(place.address_components);
      let { province, locality, city, country } = grouped;
      Province_State = province;
      City = city || locality;
      Country_Region = country;
      // address = generateAddress(grouped, placeName);
      address = document.getElementById("pac-input").value;
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
      if (locationstore && contact === undefined) {
        locationstore(address, true);
        callback({
          name: "UserLocation",
          value: {
            location: address,
            Country_Region,
            Province_State,
            City,
            latitude: lat,
            longitude: lng,
          },
        });
      }

      if (locationstore && contact === "contact") {
        locationstore(address, true);
        callback({
          name: "locationDetails",
          type: "latLng",
          value: { name: address, Country_Region, lat, lng },
        });
      }
      marker = new window.google.maps.Marker({
        position: place.geometry.location,
        map: map,
        title: address,
      });
    });
  }

  // eslint-disable-next-line no-unused-vars
  let marker;
  let locationMarkers = [];
  if (listofData.length > 0) {
    let features = listofData.map((item, index) => {
      return {
        position: new window.google.maps.LatLng(item.latitude, item.longitude),
      };
    });
    // Create markers.
    for (let i = 0; i < features.length; i++) {
      marker = new window.google.maps.Marker({
        position: features[i].position,
        label: `${i + 1}`,
        map: map,
      });
      locationMarkers.push(marker);
    }
    var bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < locationMarkers.length; i++) {
      bounds.extend(locationMarkers[i].getPosition());
    }

    map.fitBounds(bounds);
  }
};
