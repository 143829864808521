/**
 * Component renders the listing of location
 * can be used commonly in our app
 */

/**importing react dependencies */
import React from "react";

/**constants */
import { firstExpText, alertIcon } from "../../../constants/text-constants";
import moment from "moment";
import { abbreviate } from "../../../utils/abbreviate";
import { StatusLoader } from "../../commons/loader";

export const LRLeftListing = (props) => {
  /**lists the location info */
  const listItems = () => {
    let { listingArray, searchKey } = props;
    
    let resultArray = listingArray.filter(item=>item.is_infected);
    if (resultArray && resultArray.length) {
      return resultArray.map((arrayItem) => {
        let {
          id,
          location,
          first_exposure_date,
          latest_exposure_date,
          exposure,
        } = arrayItem;
        if (
          location.toLowerCase().includes(searchKey.toLowerCase())
        )
          return (
            <div key={id} className="Exposure-locations element-bg">
              <div className="d-flex align-items-stretch item-center">
                <div className="user-int">{abbreviate(location, 1, true)}</div>
                <div className="Exposure-txt">
                  <h4 title={location}>{abbreviate(location, 12)}</h4>
                  <p title={location}>{abbreviate(location, 12)}</p>
                  <h6>
                    {firstExpText}
                    {moment(first_exposure_date).format("DD/MM/YYYY")}
                  </h6>
                </div>
                <div className="Exposure-location-info">
                  <h4>{"Place"}</h4>
                  <p>{moment(latest_exposure_date).fromNow()}</p>
                  <h6>
                  {alertIcon} {exposure}
                  </h6>
                </div>
              </div>
            </div>
          );
      });
    } else return <h2>{props.emptyText}</h2>;
  };
  let { locationLoading } = props;
  return !locationLoading ? listItems() : <StatusLoader />;
};
