import { simpleApiCalls } from "../../../../services/simpleApiCalls";
import {
  GET_REGION_WITH_ZIP_SUCCESS,
  GET_REGION_WITH_ZIP_FAILED,
  GET_REGION_WITH_ZIP_STARTED,
} from "../../../../constants/action-types";
import { GET_REGION_WITH_ZIP } from "../../../../constants/configurations";

export const getRegionWithZipAction = (body, callBack) => {
  return (dispatch) => {
    simpleApiCalls(
      {
        dispatch,
        body,
        methordType: "get",
        url: GET_REGION_WITH_ZIP(body.zip),
        started: GET_REGION_WITH_ZIP_STARTED,
        success: GET_REGION_WITH_ZIP_SUCCESS,
        failure: GET_REGION_WITH_ZIP_FAILED,
      },
      callBack
    );
  };
};
