import React, { Component } from "react";
import { ModalWindow } from "../modal";
import { AFHeader } from "./AFheader";
import { AFBody } from "./AFBody";
import { CustomButton } from "../../commons/buttons";
import { toastNotification } from "../toastNotification";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { latestReportConst } from "../../../constants/text-constants";
import { errorHandler } from "../../../utils/commonErrorHandler";

let { confirmation } = latestReportConst.addContactTexts;

export default class AddFlightModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,
      locationDetails: false,
      editToDelete: "",
    };
  }
  componentDidMount() {
    this.props.listFlightActions();
    this.props.listFlightCarriersAction();
  }
  componentWillUnmount() {
    this.props.handleChangeForm({ type: "reset", formType: "flight" });
  }

  onDeleteFlight = (params) => {
    let { id } = params;
    this.props.deleteUserFlightsAction({ id }, (res) => {
      if (res.status === 204) {
        toastNotification({
          msg: "Succesfully Deleted Flight ",
          type: "success",
        });
        this.props.getUserInfoAction();
        this.props.handleChangeForm({ type: "reset", formType: "flight" });
        this.props.listFlightActions();
      } else {
        toastNotification({
          msg: "Failed to  Deleted Flight ",
          type: "error",
        });
      }
    });
  };
  onEditFlight = (params) => {
    let { id } = params;
    
    this.setState({
      editToDelete: id,
    });
    this.props.handleChangeForm({ mode: "edit", id, formType: "flight" });
  };
  formatBody = (formBody) => {
    let body = {};
    let valid = true;
    let { listCarrierFlight } = this.props.reducer;
    for (const key in formBody) {
      if (formBody.hasOwnProperty(key)) {
        const element = formBody[key];
        /**here we get the flight id from the name */
        /**function to highlight missed out field */
        if (key !== "date_journey" && element.length === 0) {
          this.props.handleChangeForm({
            validate: true,
            name: key,
            type: "flight",
          });
          valid = false;
        } else {
          if (key === "flight") {
            for (const pos in listCarrierFlight.list) {
              if (listCarrierFlight.list.hasOwnProperty(pos)) {
                const carriers = listCarrierFlight.list[pos];
                if (carriers.name === element) {
                  body[key] = carriers.id;
                }
              }
            }
          } else if (key === "date_journey") {
            body[key] = apiDateFormat(element.length ? element : new Date());
          } else body[key] = element;
        }
      }
    }
    return { body, valid };
  };

  isDuplicate = (body) => {
    let flightList = this.props.reducer.addedFlightList;
    let result = 0;
    flightList.map((iter) => {
      if (
        body.flight === iter.flight.id &&
        body.flight_no === iter.flight_no &&
        body.date_journey === iter.date_journey
      ) {
        result++;
      }
    });
    return result === 0 ? false : true;
  };

  callAddFlightApi = () => {
    let { addFlightForm } = this.props.reducer;
    let res = this.formatBody(addFlightForm);
    let { body, valid } = res;
    if (valid) {
      this.props.addUserFlightsAction(body, (res) => {
        if (res.status === 201) {
          toastNotification({
            msg: "Added Flight Successfully",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listFlightActions();
          this.props.handleChangeForm({
            type: "reset",
            formType: "flight",
          });
        } else {
          toastNotification({
            msg: `Added Flight Failed :: ${errorHandler(res.data)}}`,
            type: "error",
          });
        }
      });
    }
  };

  callEditFlightApi = () => {
    let { editFlightForm } = this.props.reducer;
    
    let res = this.formatBody(editFlightForm);
    let { body, valid } = res;
    if (valid) {
      this.props.updateUserFlightsAction(body, (res) => {
        if (res.status === 200) {
          toastNotification({
            msg: "Update Flight Success",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listFlightActions();
          this.props.handleChangeForm({
            type: "reset",
            formType: "flight",
          });
        }
      });
    }
  };
  submitAddFlight = () => {
    let { flightFormMode } = this.props.reducer;
    if (flightFormMode === "add") this.callAddFlightApi();
    else if (flightFormMode === "edit") this.callEditFlightApi();
  };

  handleFormChange = (params) => {
    let { flightFormMode } = this.props.reducer;
    const { name, value } = params;
    let parentName = "";
    if (flightFormMode === "add") {
      parentName = "addFlightForm";
    } else if (flightFormMode === "edit") {
      parentName = "editFlightForm";
    }

    this.props.handleChangeForm({ parentName, name, value });
  };

  addNewContactsToList = () => {
    let { addFlightForm } = this.props.reducer;
    this.props.handleChangeForm({
      type: "addToList",
      name: "newAddedFlightList",
      listName: "addedFlightList",
      value: addFlightForm,
    });
  };

  confirmationModal = () => {
    let { flightFormMode, addFlightForm, editFlightForm } = this.props.reducer;
    let data = flightFormMode === "add" ? addFlightForm : editFlightForm;
    if (data.flight_no !== "") {
      this.setState({
        confirmationModal: true,
      });
    } else {
      this.props.toggleModal();
    }
  };

  onBacktoEdit = () => {
    this.setState({ confirmationModal: false });
  };

  close = () => {
    this.props.toggleModal();
    this.setState({ confirmationModal: false });
  };

  submit = () => {
    this.setState({ confirmationModal: false }, () => {
      this.submitAddFlight();
    });
  };

  render() {
    let { showModal } = this.props;
    let { confirmationModal, editToDelete } = this.state;

    return (
      <>
        <ModalWindow
          showModal={showModal}
          onModalClose={this.confirmationModal}
          heading={<AFHeader toggleModal={this.confirmationModal} />}
          body={
            <AFBody
              {...this.props}
              editToDelete={editToDelete}
              handleFormChange={this.handleFormChange}
              submitAddFlight={this.submitAddFlight}
              onDeleteFlight={this.onDeleteFlight}
              onEditFlight={this.onEditFlight}
              addNewContactsToList={this.addNewContactsToList}
              closeModal={this.confirmationModal}
            />
          }
        />
        <ModalWindow
          showModal={confirmationModal}
          onModalClose={this.onBacktoEdit}
          heading={
            <AFHeader toggleModal={this.onBacktoEdit} title={confirmation} />
          }
          body={
            <div className="text-center">
              <h6 className="modal-text mt-3">
                Do you want to Submit the Changes?
              </h6>
              <div class="row mt-4 text-center w-100 m-auto edit-sub-modal">
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Submit"
                    clickEvent={this.submit}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Don't Submit"
                    clickEvent={this.close}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    text="Close"
                    className=" btn btn-block btn-submit fnt-14 SFProText-Regular"
                    clickEvent={this.onBacktoEdit}
                  ></CustomButton>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>
      </>
    );
  }
}
