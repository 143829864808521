export const matchRegionNames = (name) => {
  if (name.includes("County")) {
    return name.split(" ")[0];
  }
  switch (name) {
    case "United States":
      return "US";
    default:
      return name;
  }
};
export const getLocationDetails = (place) => {
  let lat = place.geometry.location.lat();
  let lng = place.geometry.location.lng();
  return {
    ...groupAddress(place.address_components),
    address: returnAddress(place),
    lat,
    lng,
  };
};
export const groupAddress = (addressItems) => {
  let body = {
    locality: "",
    neighbour: "",
    city: "",
    province: "",
    country: "",
    zip: "",
  };
  for (const key in addressItems) {
    if (addressItems.hasOwnProperty(key)) {
      const element = addressItems[key];
      if (element.types.includes("country")) {
        body.country = matchRegionNames(element.long_name);
      }
      if (element.types.includes("locality")) {
        body.locality = matchRegionNames(element.long_name);
      }
      if (element.types.includes("administrative_area_level_2")) {
        body.city = matchRegionNames(element.long_name);
      }
      if (element.types.includes("administrative_area_level_1")) {
        body.province = matchRegionNames(element.long_name);
      }
      if (element.types.includes("neighborhood")) {
        body.neighbour = matchRegionNames(element.long_name);
      }
      if (element.types.includes("postal_code")) {
        body.zip = matchRegionNames(element.long_name);
      }
    }
  }
  return body;
};
export const getRegionInfo = (resp) => {
  let info = resp.results[0];
  let addressItems = info.address_components;
  return groupAddress(addressItems);
};

export const returnAddress = (place) => {
  let formattedAddress = groupAddress(place.address_components);
  return generateAddress(formattedAddress, place.name);
};

export const getPlaceName = (address) => {
  return address.split(" ,")[0];
};
export const generateAddress = (grouped, placeName) => {
  let address = "";
  for (const key in grouped) {
    if (grouped.hasOwnProperty(key)) {
      const element = grouped[key];
      if (element.length) {
        if (key === "country") address += element;
        else if (key !== "zip") address += `${element} ,`;
      }
    }
  }
  if (placeName === grouped["locality"]) {
    return address;
  } else return placeName + ", " + address;
};
