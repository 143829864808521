/**
 * reducer for home page
 */
import { createReducer } from "@reduxjs/toolkit";
import { APP_BASE_NAME } from "../../../constants/configurations";
import { headerConst } from "../../../constants/text-constants";
import { checkIfUserAuthorized } from "../../../utils/checkAuth";


const { selfReportText, accessRiskText, loginText, logoutText } = headerConst;


/**helper functions */

const INITIAL_STATE = {
  userLastUpdate: {
    loading: false,
    lastUpdate: "",
  },
  searchText: "",
  showNavs: false,
  activeComponent: "",
  buttonConf: [
    {
      label: selfReportText,
      route: `${APP_BASE_NAME}/selfreport`,
      isActive: false,
      showButton: true,
    },
    {
      label: accessRiskText,
      route: `${APP_BASE_NAME}/latestreports`,
      isActive: false,
      showButton: true,
    },
    {
      label: checkIfUserAuthorized() ? logoutText : loginText,
      route: checkIfUserAuthorized() ? `/` : `${APP_BASE_NAME}/login`,
      // route: checkIfUserAuthorized() ? "/" : `/login`,
      isActive: false,
      showButton: true,
    },
  ],
};

const hightLightActiveButton = (route, activePath, label) => {
  if (route === activePath) {
    if (checkIfUserAuthorized() && label !== logoutText) {
      return true;
    } else if (label === loginText) {
      return true;
    } else {
      return false;
    }
  } else return false;
};

const returnAuthOrUnAuthroutes = (element) => {
  /**this function changes route for self report button and latestreport button only */
  if (checkIfUserAuthorized()) {
    /**the route is authorized */
    if (element.label === selfReportText) {
      return `${APP_BASE_NAME}/selfreport`;
    } else return `${APP_BASE_NAME}/latestreports`;
  } else if (element.label === selfReportText) {
    return `${APP_BASE_NAME}/signup`;
  } else return `/accessrisk`;
};
const showButton = (element) => {
  if (checkIfUserAuthorized()) {
    if (element.label === accessRiskText) {
      return false;
    } else return true;
  } else return true;
};
const authNavButtonVisibility = (state, action) => {
  let buttonArrays = [...state.buttonConf];
  let result = [];
  let { visible, pathname } = action.payload;
  for (const key in buttonArrays) {
    if (buttonArrays.hasOwnProperty(key)) {
      const element = buttonArrays[key];
      if (element.label === logoutText || element.label === loginText) {
        result.push({
          ...element,
          label: visible ? logoutText : loginText,
          /**change route */
          route: visible ? `/` : `${APP_BASE_NAME}/login`,
          // route: visible ? `/` : `${APP_BASE_NAME}/login`,
          isActive: hightLightActiveButton(
            element.route,
            pathname,
            element.label
          ),
        });
      } else {
        result.push({
          ...element,
          route: returnAuthOrUnAuthroutes(element),
          /**change route */
          showModals: returnAuthOrUnAuthroutes(element) === `/` ? true : false,
          showButton: showButton(element),
          isActive: hightLightActiveButton(
            element.route,
            pathname,
            element.label
          ),
        });
      }
    }
  }
  return result;
};
export const handleHeaderChanges = () => {
  return {
    HANDLE_CHANGE_GLOBAL_HEADER: (state, action) => {
      if (action.payload.type === "showNavs") {
        state.buttonConf = authNavButtonVisibility(state, action);
      }
      state[action.payload.name] = action.payload.value;
    },
  };
};

const userLastUpdate = () => {
  return {
    GET_USER_LAST_UPDATE_STARTED: (state, action) => {
      state.userLastUpdate.loading = true;
    },
    GET_USER_LAST_UPDATE_SUCCESS: (state, action) => {
      state.userLastUpdate.loading = false;
      state.userLastUpdate.lastUpdate = action.payload.response.updated_time;
    },
    GET_USER_LAST_UPDATE_FAILED: (state, action) => {
      state.userLastUpdate.loading = false;
    },
  };
};

export default createReducer(INITIAL_STATE, {
  ...handleHeaderChanges(),
  ...userLastUpdate(),
});
