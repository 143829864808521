import axios from "axios";
import { API_BASE_URL } from "../constants/configurations";
// import {
//     API_BASE_URL,
//     API_BASE_STAGE_URL
// } from '../../../configurations/constants';
export class ApiBase {
  constructor(params) {
    this.instance = axios.create({
      baseURL: API_BASE_URL,
      headers: this.handleHeaders(params),
      validateStatus: function (status) {
        return true;
      },
    });
    this.instance.interceptors.response.use(
      function (response) {
        if (
          (response.data.message === "null" ||
            response.data.message === null) &&
          response.status === 500
        ) {
          localStorage.clear();
        } else return response;
      },
      (error) => {
        this.handleError(error);
      }
    );
    this.handleHeaders = this.handleHeaders.bind(this);
  }

  handleError(error) {
    // when error 401 and no response
    if (error.message) {
      if (
        error.message === null ||
        error.message === "null" ||
        error.message === "Network Error"
      ) {
        // localStorage.clear();
        // history.push('/expired');
      }
    } else if (error.response === undefined) {
      // localStorage.clear();
      // history.push('/expired');
    }
    // when the api call returns an error.
    return Promise.reject(error);
  }

  handleHeaders = (params) => {
    let token = localStorage.getItem("token");
    let body = {
      "Content-Type": "application/json",
    };
    if (token&&token.length) {
      body.Authorization = `Bearer ${token}`;
    }
    return body;
  };
}
