/**renders the right pane in the latest report page */

/**importing react dep */
import React, {Component} from "react";
import {connect} from "react-redux";
import UserCard from "./userCard";
import QRCard from "./QRCard";
import LRRightModals from "./LRRightModals";

/**components */
import {TextInput} from "../../commons/textField";
import {CustomButton} from "../../commons/buttons";

/**constants */
import {latestReportConst, locationEmptyState, editIcon} from "../../../constants/text-constants";
import moment from "moment";
import {StatusLoader} from "../../commons/loader";

const {
    vaccineText,
    testText,
    organizationText,
    recordsLineLabel,
    orgsLineLabel
} = latestReportConst.rightPaneTexts;

class LRRightPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vaccinated_date: '',
            since_latest_test: '',
            user_status: false,
            qr_code: ''
        };
    }

    componentDidMount() {
        this.props.listUserTestingsAction();
        this.props.listUserVaccinesAction();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reducer.listUserTestings.list.length !== this.props.reducer.listUserTestings.list.length ||
            prevProps.reducer.listUserVaccines.list.length !== this.props.reducer.listUserVaccines.list.length) {
            this.props.handleChange({name: "qrCode", value: null});
        }
    }

    toggleAddTestingModal = (id) => {
        if (Number.isInteger(id)) {
            this.props.handleChange({mode: "edit", id, formType: "testing"});
        }
        let {showTestingModal} = this.props.reducer;
        this.props.handleChange({
            name: "showTestingModal",
            value: !showTestingModal,
        });
    };

    toggleAddOrgModal = (id) => {
        if (Number.isInteger(id)) {
            this.props.handleChange({mode: "edit", id, formType: "testing"});
        }
        let {showOrganizationModal} = this.props.reducer;
        this.props.handleChange({
            name: "showOrganizationModal",
            value: !showOrganizationModal,
        });
    };

    toggleAddVaccineModal = (id) => {
        if (Number.isInteger(id)) {
            this.props.handleChange({mode: "edit", id, formType: "vaccine"});
        }
        let {showVaccineModal} = this.props.reducer;
        this.props.handleChange({
            name: "showVaccineModal",
            value: !showVaccineModal,
        });
    };

    toggleAddOrganizationModal = (id) => {
        // if (Number.isInteger(id)) {
        //     this.props.handleChange({mode: "edit", id, formType: "vaccine"});
        // }
        let {showOrganizationModal} = this.props.reducer;
        this.props.handleChange({
            name: "showOrganizationModal",
            value: !showOrganizationModal,
        });
    };

    toggleQRModal = () => {
        let {showQRModal} = this.props.reducer;
        this.props.handleChange({
            name: "showQRModal",
            value: !showQRModal,
        });
    };

    formatCounter = (counter) => {
        if (counter > 0 && counter < 10) {
            return '0' + counter;
        }
        return counter;
    }

    renderCards = () => {
        let {userDiagnonsticInfo, latestRecords} = this.props.reducer;
        let {userLastUpdate} = this.props;
        let userLastUpdateTime = moment(userLastUpdate.lastUpdate).fromNow();

        const cardsConf = [
            {
                label: vaccineText,
                lineLabel: recordsLineLabel,
                counter: this.formatCounter(userDiagnonsticInfo.vaccineReminderDays),
                name: "vaccine-card",
                clickEvent: this.toggleAddVaccineModal,
                viewEvent: () => {
                    this.toggleAddVaccineModal(latestRecords.vaccine_id)
                },
                isDaysCount: true,
                updated: userDiagnonsticInfo.vaccineInfoLastUpdate || userLastUpdateTime,
            },
            {
                label: testText,
                lineLabel: recordsLineLabel,
                counter: this.formatCounter(userDiagnonsticInfo.testingCount.length),
                name: "testing-card",
                type: "showTestingModal",
                clickEvent: this.toggleAddTestingModal,
                viewEvent: () => {
                    this.toggleAddTestingModal(latestRecords.testing_id)
                },
                isDaysCount: false,
                updated: userDiagnonsticInfo.testingInfoLastUpdate || userLastUpdateTime
            },
            {
                label: organizationText,
                lineLabel: orgsLineLabel,
                counter: this.props.reducer?this.props.reducer.org_subscriptionsList.data.length: 0,
                name: "organization-card",
                type: "showOrganizationModal",
                clickEvent: this.toggleAddOrganizationModal,
                viewEvent: () => {
                    this.toggleAddOrgModal()
                },
                updated: userDiagnonsticInfo.testingInfoLastUpdate || userLastUpdateTime
            },

        ];

        return cardsConf.map((item, index) => {
            return (
                <div key={index} className="">
                    <UserCard
                        label={item.label}
                        lineLabel={item.lineLabel}
                        name={item.name}
                        counter={item.counter}
                        clickEvent={item.clickEvent}
                        viewEvent={item.viewEvent}
                        isDaysCount={item.isDaysCount}
                        updated={item.updated}
                    />
                </div>
            );
        });
    };

    render() {
        let { qrCode, sinceLatestTest, vaccinatedDate } = this.props.reducer.userDiagnonsticInfo;
        return (
            <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 cards-pane item-resp-1 pull-left">
                {this.renderCards()}
                <QRCard
                    clickEvent={this.toggleQRModal}
                    QRCodeValue={qrCode}
                    {...this.props}
                />
                <LRRightModals
                    toggleAddTestingModal={this.toggleAddTestingModal}
                    toggleAddVaccineModal={this.toggleAddVaccineModal}
                    toggleAddOrganizationModal={this.toggleAddOrganizationModal}
                    toggleQRModal={this.toggleQRModal}
                    QRCode={qrCode}
                    sinceLatestTest={sinceLatestTest}
                    vaccinatedDate={vaccinatedDate}
                    {...this.props} />
            </div>
        );
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        userLastUpdate: state.globalHeaderReducer.userLastUpdate,
    };
};

export default connect(mapStateToProps)(LRRightPane);
