import React from "react";
import { homePageConst } from "../../../constants/text-constants";
import { StatusLoader } from "../../commons/loader";
import { formatNumber } from "../../../utils/numberFormatters";

/**constants */
let {
  reportHeading,
  reportDesc,
  deathText,
  recoveredText,
} = homePageConst.sideBarConst;
export const CovidInfo = (props) => {
  let { covidBrief } = props;
  let { loading, affectedCount, deathCount, recoveredCount } = covidBrief;
  return (
    <div class="nav-top-wrapper">
      <h6 className="text-uppercase text-white Gilroy-Medium let-spac mb-20 ">
        {reportHeading}
      </h6>
      <p className="text-uppercase nav-p fnt-12 let-spac mb-7 Gilroy-Medium ">
        {reportDesc}
      </p>
      {!loading ? (
        <>
          <h3 className="Gilroy-Semibold ">{formatNumber(affectedCount)}</h3>
          <div className="mt-4 mb-2 ">
            <p className="text-white fnt-12">
              <span className="cir orange pull-left mar-5 mr-2"></span>
              {deathText}
              <span className="pull-right nav-p">
                {formatNumber(deathCount)}
              </span>
            </p>
            <p className="text-white fnt-12">
              <span className="cir green pull-left mar-5 mr-2"></span>
              {recoveredText}
              <span className="pull-right nav-p">
                {formatNumber(recoveredCount)}
              </span>
            </p>
          </div>
        </>
      ) : (
        <StatusLoader />
      )}
    </div>
  );
};
