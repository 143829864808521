import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_GLOBAL_EFFECT_SUCCESS,
  LIST_GLOBAL_EFFECT_FAILED,
  LIST_GLOBAL_EFFECT_STARTED
} from "../../../../constants/action-types";
import { GLOBAL_EFFECT_API } from "../../../../constants/configurations";

export const listGlobalEffectsAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GLOBAL_EFFECT_API,
        started: LIST_GLOBAL_EFFECT_STARTED,
        success: LIST_GLOBAL_EFFECT_SUCCESS,
        failure: LIST_GLOBAL_EFFECT_FAILED
      },
      callBack
    );
  };
};
