/**
 * component lists the countries shown in the sidebar
 *
 */

import React, { Component } from "react";

/**constants  */
import { homePageConst } from "../../../constants/text-constants";
import { StatusLoader } from "../../commons/loader";
import { deleteAllSearchParamsInUrl } from "../../../utils/urlOperations";
import queryString from "query-string";
import { formatNumber } from "../../../utils/numberFormatters";
import datedwnarw from "../../../assets/images/Drop-down2.svg";

const { emptycountryList } = homePageConst.sideBarConst;

export default class AffectedCountries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: "",
      showList: true,
    };
  }

  highlightList = (query) => {
    if (query) return { background: "#1D64F9", color: "white !important" };
    else return {};
  };

  collapseProvince = () => {
    this.setState({ showList: !this.state.showList });
    this.props.handleChange({
      type: "showProvinceTotal",
    });
    if (this.state.showList) {
      let parsed = queryString.parse(this.props.location.search);
      let body = { country: parsed.country };
      this.props.history.push({
        search: queryString.stringify(body),
      });
    }
  };
  showCountryForProvice = (params) => {
    let { listItem, name } = params;
    let { proviceCountryCount } = this.props.reducer;
    let { showList } = this.state;
    return (
      <div
        className="pt-2 pb-2 pl-3 pr-3 rounded fnt-14 cont-bg mb-2 text-white"
        style={this.highlightList(showList)}
        onClick={() => this.collapseProvince()}
      >
        <span className="Gilroy-Semibold">{listItem[name]}</span>
        <span className={`pull-right ${showList ? "text-white" : "nav-p"}`}>
          {formatNumber(proviceCountryCount.Confirmed__sum)}
        </span>
      </div>
    );
  };
  clickApiCalls = (params) => {
    let { listItem, name, type } = params;
    let { isCollapsed } = this.props;
    /**all the events called when user clicks a country in side bar */
    if (listItem.clickable) {
      if (type === "country") {
        this.props.fetchCountryDetails(listItem[name]);
      } else this.props.handleChangeCountrySelect({ value: listItem });
    } else this.props.changeCollapse(!isCollapsed);
  };

  renderClickEvent = (params) => {
    let parsed = queryString.parse(this.props.location.search);
    let { getStateData } = this.props.reducer;
    const loadingTestLab = getStateData.loading;
    /**done since some api calls are bulky and can cause wrong info been rendered */
    if (parsed.country === "US") {
      if (loadingTestLab) return () => {};
      else return this.clickApiCalls(params);
    } else {
      return this.clickApiCalls(params);
    }
  };
  /**common list rendering function */
  renderItemList = (params) => {
    let { listItem, name, count, type } = params;
    let { selectedCountryMapList } = this.props.reducer;
    let { region } = selectedCountryMapList;
    return (
      <React.Fragment>
        <div
          key={listItem.id}
          onClick={(e) => this.renderClickEvent(params)}
          className={
            type === "province"
              ? "text-white pl-3 pr-3 pt-2 pb-2 fnt-14 cursor"
              : "affected-country-list pl-3 pr-3 rounded fnt-12 cont-bg mb-2 text-white cursor"
          }
          style={this.highlightList(region === listItem[name])}
        >
          <span className="Gilroy-Medium">{listItem[name]}</span>
          <span
            className={`pull-right ${
              region === listItem[name] ? "text-white" : "nav-p hvr-clr"
            }`}
          >
            {formatNumber(listItem[count])}
          </span>
        </div>
      </React.Fragment>
    );
  };
  /** map the countries and list name and count */
  listCountries = () => {
    let { affectedCountryList, location } = this.props;
    /**render only if user selects a country */
    if (
      location.search &&
      location.search.length &&
      affectedCountryList.provinceStates.length
    ) {
      let { showList } = this.state;
      let provinceStateList = affectedCountryList.provinceStates;
      return (
        <React.Fragment>
          {this.showCountryForProvice({
            listItem: provinceStateList[0],
            name: "Country_Region",
          })}
          {showList &&
            provinceStateList.map((provinceStates) => {
              return this.renderItemList({
                name: "Province_State",
                count: "count",
                type: "province",
                listItem: provinceStates,
              });
            })}
        </React.Fragment>
      );
    } else if (affectedCountryList.list && affectedCountryList.list.length) {
      let countriesList = affectedCountryList.list;
      return countriesList.map((countryItem) => {
        // if (countryItem.name === "US")
        return this.renderItemList({
          name: "name",
          count: "count",
          type: "country",
          listItem: countryItem,
        });
      });
    } else if (
      affectedCountryList.list &&
      affectedCountryList.cityList.length
    ) {
      let cityList = affectedCountryList.cityList;

      return cityList.map((countryItem) => {
        return this.renderItemList({
          name: "city",
          count: "count",
          type: "city",
          listItem: countryItem,
        });
      });
    } else return <h3>{emptycountryList}</h3>;
  };

  appendRemoveScrollClass = () => {
    let { loading } = this.props;
    if (!loading) {
      let list = this.listCountries();
      if (list.length < 3) return "country-filter";
    } else return "";
  };
  /**main render */
  render() {
    let { loading, location } = this.props;
    return (
      <React.Fragment>
        <div className="country-brd-top"></div>
        <div className={`country-sec ${this.appendRemoveScrollClass()}`}>
          {location.search && location.search.length && (
          <div
            className="clearfix fnt-12 clr-fltr-brd"
            style={{
              color: "#8c8e8f",
              textAlign: "right",
              marginBottom: 9,
              cursor: "pointer",
            }}
            onClick={() => {
              this.props.handleChange({
                name: "mapLoading",
                value: true,
              });
              deleteAllSearchParamsInUrl();
            }}
          >
            Clear Filter
          </div>
        )}
            {!loading ? this.listCountries() : <StatusLoader />}
        </div>
      </React.Fragment>
    );
  }
}
