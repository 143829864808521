import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_USER_CONTACTS_SUCCESS,
  LIST_USER_CONTACTS_FAILED,
  LIST_USER_CONTACTS_STARTED
} from "../../../../constants/action-types";
import { ADD_USER_CONTACT_URL } from "../../../../constants/configurations";

export const listUserContactsAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: ADD_USER_CONTACT_URL,
        started: LIST_USER_CONTACTS_STARTED,
        success: LIST_USER_CONTACTS_SUCCESS,
        failure: LIST_USER_CONTACTS_FAILED
      },
      callBack
    );
  };
};
