import React from "react";
import { CustomButton } from "../buttons";
import UserAddedVaccineBlock from "./userAddedVaccineBlock";
import { AddVaccineLocationModal } from "./addVaccineLocationModal";
import { AddVaccineViewModal } from "./addVaccineViewModal";
import { latestReportConst } from "../../../constants/text-constants";
import { submitText, downloadIcon } from "../../../constants/text-constants";
import { BuildFormHandler } from "../BuildFields";
import { Overlay, Popover } from "react-bootstrap";
import { TextInput } from "../textField";

export const AVVBody = (props) => {
  const { vaccine, toggleViewModal } = props

  const onEditItem = () => {
    toggleViewModal()
    props.onEditVaccine({ id: vaccine.id });
  }

  const download = () => {
    fetch(vaccine.file_upload, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", vaccine.file_upload.split('/').pop());
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const previewImage = () => {
    return (
      <div>
        <div className="d-flex justify-content-center mt-2">
          <img src={vaccine.file_upload} style={{ maxWidth: "450px" }} alt="dummy" width="100%" height="100%" />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button class="btn btn-submit fnt-14 SFProText-Regular" onClick={() => download()}>
            {downloadIcon} Download
          </button>
        </div>
      </div>
    );
  }

  let { vaccineFormConfig, addVaccineForm } = props;
  let { editVaccineForm } = props.reducer;

  return (
    <div className="modal-body">
      <div className="col-12">
        <div className="row">
          <table className="table d-op-table">
            <thead>
              <tr>
                <th className="col-2">
                  Dosage
              </th>
                <th className="col-2">
                  Manufacturer
              </th>
                <th className="col-1">
                  Lot #
              </th>
                <th className="col-1">
                  Reminder
              </th>
                <th className="col-3">
                  Location
              </th>
                <th className="col-2">
                  Date
              </th>
                <th className="col-1">
                  Action
              </th>
              </tr>
            </thead>
            <tbody style={{ background: "#242527", overflowY: "unset", height: "50px" }}>
              <tr>
                <td className="col-2">{vaccine.dosage.name}</td>
                <td className="col-2">{vaccine.manufacturer.name}</td>
                <td className="col-1">{vaccine.lot}</td>
                <td className="col-1">{vaccine.is_reminded ? 'On' : 'Off'}</td>
                <td className="col-3">{vaccine.location}</td>
                <td className="col-2">{vaccine.date}</td>
                <td className="col-1"><button className="btn-edit fnt-10" onClick={onEditItem}>Edit</button></td>
              </tr>
            </tbody>
          </table>
        </div>
        {
          vaccine.file_upload && vaccine.file_upload !== 'None' ?  previewImage() : ''
        }
      </div>
    </div>
  );
};
