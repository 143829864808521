import React, { Component } from "react";

export default class CheckBoxDrop extends Component {
  handleCheckBoxClick = (item, event) => {
    let { name, checked } = event.target;
    if (!checked) {
      this.props.handleChange({
        type: "customCheckbox",
        name,
        operation: "delete",
        value: item.id
      });
    } else
      this.props.handleChange({
        type: "customCheckbox",
        name,
        operation: "add",
        value: item
      });
  };
  checkIfValuesHasSelectedArray = id => {
    let { value } = this.props;
    let found = false;
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const element = value[key];
        if (element.id === id) {
          found = true;
          break;
        }
      }
    }
    return found;
  };
  generateOption = () => {
    let { optionsList, name } = this.props;
    return optionsList.map((item, index) => {
      let checked = this.checkIfValuesHasSelectedArray(item.id);
      return (
        <li className="mb-2">
          <span className="large fnt-12" data-value={`option${index}`} tabIndex="-1">
            <input
              type="checkbox"
              name={name}
              checked={checked}
              onClick={e => this.handleCheckBoxClick(item, e)}
            />
            &nbsp;{item.name}
          </span>
        </li>
      );
    });
  };
  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className={this.props.className}
          data-toggle="dropdown"
        >
          <span className="glyphicon glyphicon-cog"></span>Select Symptoms
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu self-inp2 text-white symptoms-drop">{this.generateOption()}</ul>
      </React.Fragment>
    );
  }
}
