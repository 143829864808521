import React, { Component } from "react";
import deleteButton from "../../../assets/images/delete-button-dark.svg";

export default class SelectWithLabels extends Component {
  handleCheckBoxClick = (params) => {
    let { name, isAdd, item } = params;
    if (!isAdd) {
      this.props.handleChange({
        type: "customCheckbox",
        name,
        operation: "delete",
        value: item.id,
      });
    } else
      this.props.handleChange({
        type: "customCheckbox",
        name,
        operation: "add",
        value: item,
      });
  };
  checkIfValuesHasSelectedArray = (id) => {
    let { value } = this.props;
    let found = false;
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const element = value[key];
        if (element.id === id) {
          found = true;
          break;
        }
      }
    }
    return found;
  };
  generateOption = () => {
    let { optionsList, name, value } = this.props;

    let resultArray = optionsList.filter(
      ({ id: id1 }) => !value.some(({ id: id2 }) => id2 === id1)
    );
    return resultArray.map((item, index) => {
      return (
        <li
          className=""
          onClick={(e) => this.handleCheckBoxClick({ isAdd: true, item, name })}
        >
          <span
            className="large fnt-12"
            data-value={`option${index}`}
            tabIndex="-1"
          >
            {item.name}
          </span>
        </li>
      );
    });
  };

  listSelectedValues = () => {
    let { value, name } = this.props;

    let list = value.map((item) => {
      return (
        <li className="list-item" style={{ color: "white" }}>
          <label style={{ lineHeight: 1.8 }}>{item.name}</label>
          <span
            onClick={(e) =>
              this.handleCheckBoxClick({ isAdd: false, item, name })
            }
          >
            <img src={deleteButton} alt="close" />
          </span>
        </li>
      );
    });
    return <ul className="listing-block p-0">{list}</ul>;
  };
  render() {
    let { disabled } = this.props;

    return (
      <React.Fragment>
        <button
          type="button"
          className={this.props.className}
          data-toggle="dropdown"
          disabled={disabled}
        >
          <span className="glyphicon glyphicon-cog"></span>Select Symptoms
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu self-inp2 text-white symptoms-drop">
          {this.generateOption()}
        </ul>
        {this.listSelectedValues()}
      </React.Fragment>
    );
  }
}
