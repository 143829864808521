import React, { Component } from "react";
import AsyncSelect from "react-select/async";

const customStyles = {
  control: styles => ({
    ...styles,
    fontSize: "14px",
    background: "#1f2021",
    borderColor: "#3e3f42",
    width: "100%",
    margin:0
  }),
  option: (provided, state) => ({
    ...provided,
  }),
};

export default class CustomAsynSelect extends Component {
  handleInputChange = newValue => {
    this.props.handleChange({
      type: "customSelect",
      name: this.props.name,
      value: newValue
    });
  };
  render() {
    return (
      <AsyncSelect
        cacheOptions
        className={this.props.className}
        loadOptions={this.props.loadOptions}
        defaultOptions
        isMulti={true}
        onChange={this.handleInputChange}
        value={this.props.value}
        styles={customStyles}
      />
    );
  }
}
