import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  UPDATE_USER_REPORT_SUCCESS,
  UPDATE_USER_REPORT_FAILED,
  UPDATE_USER_REPORT_STARTED
} from "../../../../constants/action-types";
import { UPDATE_USERS_URL } from "../../../../constants/configurations";

export const updateUserReportAction = (body, callback) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "patch",
        api: UPDATE_USERS_URL(body.id),
        started: UPDATE_USER_REPORT_STARTED,
        success: UPDATE_USER_REPORT_SUCCESS,
        failure: UPDATE_USER_REPORT_FAILED
      },
      callback
    );
  };
};
