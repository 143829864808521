import React, { Component } from "react";
import "./style.css";
import { Dropdown } from "react-bootstrap";
import { CustomMenu } from "./optionMenu";
/**
 * The options array should contain objects.
 * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
 */
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    className="form-control self-inp position-relative text-left w-100"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

export default class FilterableDropDown extends Component {
  changeflightCarrier = (name) => {
    this.props.handleChange({ name: this.props.name, value: name });
  };

  listFlights = () => {
    let { optionsList } = this.props;
    
    if (optionsList.length)
      return optionsList.map((item) => {
        return (
          <Dropdown.Item
            eventKey={item.id}
            onClick={() => this.changeflightCarrier(item.name)}
          >
            {item.name}
          </Dropdown.Item>
        );
      });
  };
  render() {
    let { value } = this.props;
    return (
      <div className={" flight-drp-dwn"}>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            {value}
          </Dropdown.Toggle>

          <Dropdown.Menu as={CustomMenu}>{this.listFlights()}</Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}
