import React, { Component } from "react";
import TimeInput from "material-ui-time-picker";

export default class CustomTimePickers extends Component {
  render() {
    let { name, value } = this.props;
    return (
      <TimeInput
       className = 'MuiInputBase-input-time-picker'
        mode="12h"
        value={value}
        onChange={(time) => {
          this.props.onChange({ name, value: time });
        }}
      />
    );
  }
}
