import React, {useEffect, useState} from "react";
import QRCode from "react-qr-code";
import {downloadIcon, QRConst} from "../../../constants/text-constants";
import moment from "moment";
import {withStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {checkCircle, checkCircleGrey} from "../../../constants/text-constants";
import {UserInfoPane} from "../../LatestReports/LRContentBlock/userInfoPane";
import {UserSummary} from "../../LatestReports/LRContentBlock/userSummary";

const saveSvgAsPng = require('save-svg-as-png')

export const QRBody = (props) => {
    const {testDaysText, vaccineDateText, downloadBtnText, shareRecordsBlock} = QRConst;

    let {
        userInfo,
        userDiagnonsticInfo
    } = props.reducer;

    const [state, setState] = useState({
        checkedTest: false,
        checkedVaccine: false,
    });

    const handleChange = (event) => {
        setState({...state, [event.target.name]: event.target.checked});
        if (state.checkedTest && state.checkedVaccine) {
            props.handleChange({name: "qrCode", value: 'fullInfo'});
        } else if (state.checkedTest && !state.checkedVaccine) {
            props.handleChange({name: "qrCode", value: 'testing'});
        } else if (!state.checkedTest && state.checkedVaccine) {
            props.handleChange({name: "qrCode", value: 'vaccine'});
        } else {
            props.handleChange({name: "qrCode", value: 'vaccine'});
        }
    };

    const downloadQR = () => {
        saveSvgAsPng.saveSvgAsPng(document.getElementById("qr-code"), "diagram.png");
    };

    const CustomCheckbox = withStyles({
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    let userLastUpdateTime = moment(props.userLastUpdate.lastUpdate).fromNow();
    let checkedVaccine = state.checkedVaccine ? 'custom-checkbox-block checkbox-block-checked' : 'custom-checkbox-block';
    let checkedTest = state.checkedTest ? 'custom-checkbox-block checkbox-block-checked' : 'custom-checkbox-block';

    return (
        <>
            <div className="col-sm-12 qr-body">
                <div className="trac-pass-block d-sm-none">
                    <div className="trac-pass-title">Trac Pass</div>
                    <div className="trac-pass-description">Take care of your health and protect others.</div>
                </div>
                <div className="qr-user-info d-sm-none">
                    <div className="row pl-3 mb-4">
                        <UserInfoPane {...userInfo} />
                    </div>
                    <div className="qr-user-info-summary row justify-content-center mb-4">
                        <UserSummary {...userInfo} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 offset-md-3 col-md-6 text-center">
                        <div className="qr-block">
                            <div className="qr-calc-data d-flex flex-row justify-content-around">
                                <div>
                                    <div className="qr-block-name">{testDaysText} <span
                                        className="qr-block-icon">{userDiagnonsticInfo.userTestingStatus ? checkCircle : checkCircleGrey}</span></div>
                                    <div className="qr-block-value mt-2">{props.sinceLatestTest}</div>
                                </div>
                                <div>
                                    <div className="qr-block-name">{vaccineDateText}<span
                                        className="qr-block-icon">{userDiagnonsticInfo.userVaccineStatus ? checkCircle : checkCircleGrey}</span></div>
                                    <div className="qr-block-value mt-2">{props.vaccinatedDate}</div>
                                </div>
                            </div>
                            <div className="qr-code">
                                <QRCode id="qr-code" value={props.QRCode}
                                        size={parseInt(props.width) > 576 ? 250 : 210}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 mt-md-5">
                        <div className="row">
                            <div className="col-12 qr-share-title mt-md-4">{shareRecordsBlock.title}</div>
                            <div className="col-5 col-md-12 qr-share-block justify-content-sm-center">
                                <FormControlLabel
                                    control={<CustomCheckbox checked={state.checkedVaccine}
                                                             onChange={handleChange}
                                                             name="checkedVaccine"/>}
                                    classes={{label: 'custom-checkbox-label', root: checkedVaccine}}
                                    label={shareRecordsBlock.vaccineTitle}
                                />
                            </div>
                            <div className="col-5 col-md-12 qr-share-block justify-content-sm-center">
                                <FormControlLabel
                                    control={<CustomCheckbox checked={state.checkedTest} onChange={handleChange}
                                                             name="checkedTest"/>}
                                    classes={{label: 'custom-checkbox-label', root: checkedTest}}
                                    label={shareRecordsBlock.testingTitle}
                                    color="#f1f1f2"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-right">
                    <div className="col-12 qr-download-btn">
                        <button className="qr-btn fnt-14 SFProText-Regular" onClick={downloadQR}>
                            Download
                        </button>
                    </div>
                </div>
            </div>
            <div className="qr-close-icon">
                <button
                    type="button"
                    className="close"
                    onClick={props.toggleModal}
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="update-box-d bg-transparent">
                <span>Updated:</span>
                {" "}{userLastUpdateTime}
            </div>
        </>

    );
};
