import React from "react";

/**constants */
import { lastLoginText, zipText } from "../../../constants/text-constants";
import { abbreviateName } from "../../../utils/abbreviate";

/**functional component definition */
export const UserInfoPane = (props) => {
  let name = localStorage.getItem("name");
  let { zipCode, risk_level, lastLoginTime } = props;

  const userImageBlock = () => {
    return (
      <div className="d-inline-flex rounded-circle pro-pic">
        {" "}
        <div class="user-int bg-clr-loc">{abbreviateName(name)}</div>
      </div>
    );
  };

  const userInfo = () => {
    return (
      <div className="user-dd-info">
        <h4 style={{ marginBottom: 0 }}>{name}</h4>
        <h6>
          {`${zipText} ${zipCode} `}
          <span>{risk_level}</span>
        </h6>
        <p>
          <span>{lastLoginText}</span>
          {lastLoginTime}
        </p>
      </div>
    );
  };
  return (
    <React.Fragment>
      {userImageBlock()}
      {userInfo()}
    </React.Fragment>
  );
};
