export const timeDropItems = () => {
  let timeArray = [];
  for (let idx = 0; idx < 2; idx++) {
    let meridiem = idx ? "PM" : "AM";
    timeArray.push({ name: `12:00 ${meridiem}` });
    for (let index = 1; index <= 11; index++) {
      timeArray.push({ name: `${index}:00 ${meridiem}` });
    }
  }
  return timeArray;
};

export const weekDropItems = () => {
  let weekDrop = [];
  for (let index = 2; index <= 8; index++) {
    weekDrop.push({ name: `${index} weeks` });
  }
  return weekDrop;
};

export const activityDrop = [
  { name: "Food Aid" },
  { name: "Supplies" },
  { name: "Financial Help" },
  { name: "Test Center" },
  { name: "Vaccine" },
];

export const activityTypes = [{ name: "Giveaway" }, { name: "Assistance" }];
