/**react dependencies */
import { Switch, Route, Redirect } from "react-router-dom";

/** components  */
import Home from "../Home";
import LatestReports from "../LatestReports";
import GlobalHeader from "../GlobalHeader";
import SelfReport from "../SelfReport";
import Auth from "../Auth";
import React, { Component } from "react";
import {
  APP_BASE_NAME,
  minimumScreenRatio,
} from "../../constants/configurations";
import QuickAccessRisk from "../QuickAccessRisk";

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileView: false,
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this, "fixed"));
    this.resizeEvent = window.addEventListener(
      "resize",
      this.resize.bind(this)
    );
  }
  resize() {
    this.setState({ mobileView: window.innerWidth <= minimumScreenRatio });
  }
  componentWillUnmount() {
    if (this.resizeEvent) this.resizeEvent();
    window.removeEventListener("scroll", this.handleScroll.bind(this, ""));
  }

  handleScroll = (className) => {
    var element = document.getElementById("gheader");
    if (element) element.classList.add(className);
  };

  commonProps = (props) => {
    let { mobileView } = this.state;
    let body = {
      ...this.props,
      ...props,
      mobileView,
    };
    return body;
  };
  render() {
    return (
      <>
        <Switch>
          <Route
            path={`${APP_BASE_NAME}/selfreport`}
            exact
            render={(props) => (
              <div>
                <GlobalHeader {...this.commonProps()} />
                <SelfReport {...this.commonProps(props)} />
              </div>
            )}
          />
          <Route
            exact
            path={`${APP_BASE_NAME}/latestreports`}
            render={(props) => (
              <div>
                <GlobalHeader {...this.props} />
                <LatestReports {...this.commonProps(props)} />
              </div>
            )}
          />
          <Route
            // exact
            path={`${APP_BASE_NAME}/accessrisk`}
            render={(props) => (
              <div>
                <GlobalHeader {...this.props} />
                <QuickAccessRisk {...this.commonProps(props)} />
              </div>
            )}
          />
          <Route
            exact
            path={`/`}
            render={(props) => (
              <div>
                <GlobalHeader {...this.props} />
                <Home {...this.commonProps(props)} />
              </div>
            )}
          />
          <Route
            exact
            path={`${APP_BASE_NAME}/login`}
            render={(props) => <Auth.LogIn {...this.commonProps(props)} />}
          />
          <Route
            exact
            path={`${APP_BASE_NAME}/signup`}
            render={(props) => <Auth.SignUp {...this.commonProps(props)} />}
          />
          <Route
            exact
            path={`${APP_BASE_NAME}/verification`}
            render={(props) => <Auth.Otp {...this.commonProps(props)} />}
          />
          <Route
            exact
            path={`${APP_BASE_NAME}/forgotpassword`}
            render={(props) => (
              <Auth.ForgotPassword {...this.commonProps(props)} />
            )}
          />
          <Redirect to={APP_BASE_NAME} />
        </Switch>
      </>
    );
  }
}
