/**
 *
 * @param {the array to be checked} array
 * @param {name of key to be checked} pivot
 * @param {actual value} name
 */
export const checkForDuplicates = (params) => {
  let { array, pivot, name } = params;

  let found = false;
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element[pivot] === name) {
        found = true;
      }
    }
  }
  return found;
};
