/**
 * This is the default landing page of app
 */

/**importing react dependencies */
import React, { PureComponent } from "react";

/**components */
import HomeSideBar from "./HomeSideBar/homeSideBar";
import HomeCollapseMenu from "./HomeCollapseMenu/homeCollapseMenu";
import HomeMapBlock from "./HomeMapBlock/homeMapBlock";
import HomeHeader from "./HomeHeader/homeHeader";

/**utils */
import queryString from "query-string";
import { toastNotification } from "../commons/toastNotification";
import { getRegionInfo } from "../../utils/getRegionInfo";
import HomeModals from "./HomeModals/homeModals";
import { minimumScreenRatio } from "../../constants/configurations";
import { formatNumber } from "../../utils/numberFormatters";
import { StatusLoader } from "../commons/loader";
import MobileViewSlider from "./MobileViewSlider/mobileViewSlider";
import DragBottomContainers from "../commons/dragBottomContainers";
import AddActivity from "./AddActivity";
import { errorHandler } from "../../utils/commonErrorHandler";
import GeoTracking from "../GeoTracking"

export default class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      mobileView: false,
    };
  }
  resize() {
    this.setState({ mobileView: window.innerWidth <= minimumScreenRatio });
  }
  componentDidMount = () => {
    let { search } = this.props.location;
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.props.getUserInfoAction();
    this.props.listGlobalEffectsAction();
    this.props.globalCountyWiseInfo();
    // if (window.innerWidth <= minimumScreenRatio) {
    this.props.listCountryWiseDetails({}, (res) => {});
    // }
    let urlParsed = queryString.parse(search);
    if (urlParsed.province) {
      this.setState({ isCollapsed: false });
    }
    /**if the url contains a country tag then filter facet by country */
    if (search && search.length) {
      this.callCountryWiseStats(this.props.location);
    } else
      this.props.listCovidDetailsActions({}, (res) => {
        if (res.status === 200) {
          this.props.handleChange({
            name: "googleMapType",
            value: "basicMap",
          });
          this.props.handleChange({
            name: "mapLoading",
            value: false,
          });
        }
      });
    /**else call the overall listing api */
    this.unlisten = this.props.history.listen((location, prev) => {
      // this.props.handleChange({
      //   parentName: "getStateData",
      //   name: "stateData",
      //   value: null,
      // });
      this.checkIfSearchParamsDifferent(location);
    });
  };

  /**un mount the listener for performance */
  componentWillUnmount = () => {
    if (this.unlisten) this.unlisten();
    window.removeEventListener("resize", this.resize);
  };

  /**delete activity api call */
  removeActivityCall = (params) => {
    let { id } = params;
    this.props.deleteActivity({ id }, (res) => {
      if (res.status === 204) {
        toastNotification({
          msg: "activity deleted successfully",
          type: "success",
        });
        this.props.listActivitiesAction();
      } else {
        toastNotification({
          msg: `${errorHandler(res.data)}`,
          type: "error",
        });
      }
    });
  };
  /**checks if there is any change in the url */
  checkIfSearchParamsDifferent = (location) => {
    let newLoc = location;
    /**if search item present call country wise api */
    if (newLoc.search && newLoc.search.length)
      this.callCountryWiseStats(location);
    /**else call listing api*/ else
      this.props.listCovidDetailsActions({}, (res) => {
        if (res.status === 200) {
          this.props.handleChange({
            name: "googleMapType",
            value: "basicMap",
          });
          this.props.handleChange({
            name: "mapLoading",
            value: false,
          });
        }
      });
  };
  callTestLabApi = (parsed) => {
    if (parsed.country === "US" && !parsed.province) {
      this.props.getUsaZoneTestLab({ state: "USAZONE" }, () => {});
    } else if (parsed.country === "US" && parsed.province) {
      this.props.getStateLocation(
        { state: parsed.province.split(" ").join("-").toLowerCase() },
        () => {}
      );
    }
  };
  /**api call to show country wise listing */
  callCountryWiseStats = (location) => {
    let parsed = queryString.parse(location.search);
    let { country } = parsed;
    this.props.handleChange({
      name: "googleMapType",
      value: "",
    });
    this.props.handleChange({
      name: "mapLoading",
      value: true,
    });
    this.callTestLabApi(parsed);
    /**for map showing both intensity of city and provice state reported locatoins */
    if (parsed.country && parsed.province && !parsed.zip) {
      let { country, city, province } = parsed;
      this.props.listCovidStatewiseDetails({ country }, (res) => {
        if (res.status === 200) {
          this.props.getCityListWithStateAction(
            { country, city, province },
            (res) => {
              if (res.status === 200) {
                this.props.getReportedLocations(
                  { country, city, province },
                  (res) => {
                    if (res.status === 200) {
                      this.props.handleChange({
                        name: "googleMapType",
                        value: "hasCityIntensity",
                      });
                      this.props.handleChange({
                        name: "mapLoading",
                        value: false,
                      });
                    }
                  }
                );
              }
            }
          );
        }
      });
    } else if (parsed.zip) {
      let { country, city, province } = parsed;
      this.props.getReportedLocationWithZip(
        { country, city, province },
        (res) => {
          if (res.data && res.data.length) {
            this.props.getReportedLocations(
              { country, city, province },
              (res) => {
                if (res.status === 200) {
                  this.props.handleChange({
                    name: "googleMapType",
                    value: "zipMarkerMap",
                  });
                  this.props.handleChange({
                    name: "mapLoading",
                    value: false,
                  });
                }
              }
            );
          } else
            this.props.handleChange({
              name: "googleMapType",
              value: "emptyMap",
            });
          this.props.handleChange({
            name: "mapLoading",
            value: false,
          });
        }
      );
    } else {
      // this.setState({ isCollapsed: true });
      this.props.listCovidStatewiseDetails({ country }, (resp) => {
        if (resp.status === 200) {
          this.props.getReportedLocations({ country }, (res) => {
            if (res.status === 200) {
              if (resp.data[0].Province_State.length) {
                this.props.handleChange({
                  name: "googleMapType",
                  value: "proviceMap",
                });
                this.props.handleChange({
                  name: "mapLoading",
                  value: false,
                });
              } else
                this.props.handleChange({
                  name: "googleMapType",
                  value: "intensityProvinceMap",
                });

              this.props.handleChange({
                type: "showProvinceTotal",
              });
              this.props.handleChange({
                name: "mapLoading",
                value: false,
              });
            }
          });
        }
      });
    }
  };

  /**to change the collapse visiblity */
  changeCollapse = (value) => {
    const { location } = this.props;
    if (location.search && location.search.length) {
      this.setState({
        isCollapsed: value,
      });
    }
  };

  /**adds the country name to url */
  addCountryToUrl = (country) => {
    const stringified = queryString.stringify({
      country,
    });
    this.props.history.push({
      search: stringified,
    });
  };

  /**handler for api call */
  fetchCountryDetails = (country) => {
    if (country) {
      this.addCountryToUrl(country);
    }
  };
  addRegionParamsToUrl = (resp) => {
    let info = getRegionInfo(resp);
    const stringified = queryString.stringify({
      zip: info.zip,
      city: info.city,
      province: info.province,
      country: info.country,
    });
    this.props.history.push({ search: stringified });
  };

  onSubmitZipSearch = (event) => {
    let { zipSearchText } = this.props.reducer;
    this.props.getRegionWithZipAction({ zip: zipSearchText }, (res) => {
      if (res.status === 200) {
        toastNotification({
          msg: "fetch region success",
          type: "success",
        });
        this.addRegionParamsToUrl(res.data);
      }
    });
  };
  /**to render collapse window */
  showCollapseBlock = () => {
    let { isCollapsed } = this.state;
    let { search } = this.props.location;
    /**check if it is in the state wise listing and condition is collapsed */
    if (search && search.length && !isCollapsed) {
      return (
        <HomeCollapseMenu
          reducer={this.props.reducer}
          changeCollapse={this.changeCollapse}
          handleChangeText={this.handleChangeText}
          onSubmitZipSearch={this.onSubmitZipSearch}
        />
      );
    }
  };

  handleChangeCountrySelect = (params) => {
    let { value } = params;
    let { selectedCountryMapList } = this.props.reducer;
    let { search } = this.props.location;
    if (selectedCountryMapList.region === value.Province_State)
      this.changeCollapse(!this.state.isCollapsed);
    else {
      if (this.state.isCollapsed) this.changeCollapse(!this.state.isCollapsed);
      this.props.changeSelectedCountry({
        name: "selectedCountryMapList",
        value,
      });
      /**user clicks a province state the push it to url and only add if province not present */
      const parsed = queryString.parse(search);
      let urlString = queryString.stringify({
        ...parsed,
        province: value.Province_State,
      });
      this.props.history.push({
        search: urlString,
      });
    }
  };
  handleChangeText = (params) => {
    let { name, value } = params;
    this.props.handleChange({ name, value });
  };

  renderSideBar = () => {
    let { mobileView } = this.state;
    return (
      <HomeSideBar
        {...this.props}
        mobileView={mobileView}
        handleChangeCountrySelect={this.handleChangeCountrySelect}
        isCollapsed={this.state.isCollapsed}
        changeCollapse={this.changeCollapse}
        fetchCountryDetails={this.fetchCountryDetails}
      />
    );
  };
  render() {
    let { mobileView } = this.state;
    let { hideCollapse } = this.props.latestReportReducer;
    return (
      <div className={`body-wrapper position-relative ${mobileView && "p-0"}`}>
        {mobileView && (
          <HomeHeader
            reducer={this.props.reducer}
            location={this.props.location}
          />
        )}
        {mobileView ? (
          <MobileViewSlider
            handleChange={this.props.handleChange}
            location={this.props.location}
            history={this.props.history}
            reducer={this.props.reducer}
            onSubmitZipSearch={this.onSubmitZipSearch}
          />
        ) : (
          <>
            {this.renderSideBar()} {this.showCollapseBlock()}
          </>
        )}
        <HomeMapBlock
          reducer={this.props.reducer}
          location={this.props.location}
          history={this.props.history}
          getUsaZoneTestLab={this.props.getUsaZoneTestLab}
          getStateLocation={this.props.getStateLocation}
          handleChange={this.props.handleChange}
          mobileView={mobileView}
          removeActivityCall={this.removeActivityCall}
          getGooglePlaceDetailsAction={this.props.getGooglePlaceDetailsAction}
        />
       {!mobileView&& <AddActivity mobileView={false} />}
       <GeoTracking/>
      </div>
    );
  }
}
