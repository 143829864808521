import { connect } from "react-redux";

/**importing actions */
import {
  handleChangeGlobalHeader,
  handleChangeLR,
  handleChangeHomePage,
  handleChangeAccessRisk,
  getUserInfoAction,
  getRegionWithZipAction,
  getUserLastUpdate,
} from "../../redux/actions";

/**importing components */
import Header from "./globalHeader";

// const Counter = ...

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    reducer: state.globalHeaderReducer,
    showUnauthModal: state.latestReportReducer.showUnauthModal,
  };
};
const apiActions = (dispatch) => {
  return {
    getUserInfoAction: (params,callback) => {
      dispatch(getUserInfoAction(params,callback));
    },
    getRegionWithZipAction: (params, callback) => {
      dispatch(getRegionWithZipAction(params, callback));
    },
    getUserLastUpdate: (params, next) => {
      dispatch(getUserLastUpdate(params, next));
    },
  };
};
const componentActions = (dispatch) => {
  return {
    handleChange: (params) => {
      dispatch(handleChangeGlobalHeader(params));
    },
    handleChangeLatestreport: (params) => {
      dispatch(handleChangeLR(params));
    },  
      handleChangeHomePage: (params) => {
      dispatch(handleChangeHomePage(params));
    },   handleChangeAccessRisk: (params) => {
      dispatch(handleChangeAccessRisk(params));
    },
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ...componentActions(dispatch),
    ...apiActions(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
