/**
 * reducer for home page
 */
import { createReducer } from "@reduxjs/toolkit";

import dummyImage from "../../../assets/images/avatar.png";
import {
  findPosition,
  formatContactList,
  formatFlightList,
  formatCarrierFlights,
  populateEditContact,
  populateEditFlight,
  populateEditLocation,
  populateEditVaccine,
  populateEditTesting,
  formatLocations,
  formatAddedFlightList,
  formatVaccineList,
  formatManufacturers,
  formatDoses,
  formatTestingList,
  populateEditOrg,
} from "./helpers";
import { getTimeArray } from "../../../utils/timeArray";
import moment from "moment";
import {
  commonDateFormtter,
  fromNow,
} from "../../../utils/commonDateFormatter";
import { checkForDuplicates } from "../../../utils/checkForDuplicateArray";

const contactFormbody = () => {
  return {
    name: "",
    phone_number: "",
    date_contacted: "",
    latitude: "",
    longitude: "",
    location: "",
  };
};

const flightFormbody = () => {
  return {
    flight_no: "",
    date_journey: "",
  };
};

const locationFormbody = () => {
  return {
    location: "",
    location_date: "",
    from_time: "",
    to_time: "",
  };
};

const vaccineFormbody = () => {
  return {
    location: "",
    vaccinated_date: "",
    lot: "",
    file_upload: "",
    is_reminded: true,
    manufacturer: "",
    dosage: "",
  };
};

const testingFormbody = () => {
  return {
    name: "",
    location: "",
    test_result: "",
    tested_date: "",
    file_upload: "",
  };
};

const INITIAL_STATE = {
  locationLoading: false,
  locationExpCount: 10,
  showFlightModal: false,
  showLocationModal: false,
  showUnauthModal: false,
  showContactModal: false,
  showVaccineModal: false,
  showQRModal: false,
  isDisabledTestingSubmitBtn: true,
  isDisabledVaccineSubmitBtn: true,
  showSubVaccineLocationModal: false,
  showVaccineViewModal: false,
  showTestingModal: false,
  showSubTestingLocationModal: false,
  showTestingViewModal: false,
  showSubContactModal: false,
  showOrganizationModal: false,
  showOrganizationViewModal: false,
  showRiskDashboard: false,
  hideCollapse: true,
  doses: [],
  manufacturers: [],
  validatyPeriod: 365,
  vaccineFrequency: 60,
  searchKey: "",
  orgList: {
    data: []
  },
  org_subscriptionsList : {
    data: []
  },
  userInfo: {
    id: "",
    loading: false,
    userAvatar: dummyImage,
    name: "",
    zipCode: "",
    risk_level: "",
    exposureScore: 0,
    lastLoginTime: "",
    report: "N/A",
    status: "N/A",
    tested: "N/A",
    vaccinated: "N/A",
  },
  locationFormMode: "add",
  graphSettings: {
    centerLocation: null,
  },
  locationDetails: { name: "", lat: "", lng: "" },
  UserLocation: { address: "", latitude: "", longitude: "" },
  userDiagnonsticInfo: {
    flightsCount: [],
    exposureCount: [],
    infectedCount: [],
    vaccineCount: [],
    testingCount: [],
    vaccineReminderDays: [],
    vaccineInfoLastUpdate: "",
    testingInfoLastUpdate: "",
    vaccinatedDate: "N/A",
    sinceLatestTest: 0,
    userTestingStatus: false,
    userVaccineStatus: false,
    qrCode: "",
  },
  addContactTableConf: {
    rows: [
      { id: 1, name: "Name" },
      { id: 2, name: "Location" },
      { id: 3, name: "Date" },
      { id: 4, name: "Action" },
    ],
  },
  editContactForm: { ...contactFormbody() },
  addContactForm: { ...contactFormbody() },

  addedContactList: [],
  addContactConfig: [
    {
      id: 1,
      label: "Name (Optional)",
      type: "text",
      className: "form-control",
      name: "name",
      placeholder: "First Last",
    },
    {
      id: 2,
      label: "Phone",
      type: "text",
      className: "form-control",
      name: "phone_number",
      pattern: "[0-9+]*",
      placeholder: "Phone Number",
    },
    {
      id: 3,
      label: "Date",
      pickerClassName: "contact-modal-dp flight-datepicker",
      type: "date",
      className: "form-control date-input",
      name: "date_contacted",
      placeholder: "Date",
    },
  ],
  addLocFormConfig: [
    {
      id: 1,
      className: "form-control date-input",
      label: "Date",
      pickerClassName: "flight-datepicker",
      col: "col-sm-3",
      type: "date",
      placeholder: "Date",
      name: "location_date",
    },
    {
      id: 2,
      className: "",
      label: "Time Range",
      col: "col-sm-3",
      placeholder: "Set to all day",
      name: "from_time",
      type: "daterange",
      selectOptions: getTimeArray(),
    },
    // {
    //   id: 3,
    //   className: "form-control time-input",
    //   label: "To time",
    //   col: "col-sm-3",
    //   placeholder: "Set to all day",
    //   name: "to_time",
    //   type: "daterange"
    // }
  ],
  // addLocForm: {
  //   locationName: "",
  //   timeRange: "",
  //   location_date: ""
  // },
  orgFormMode : "add",
  addOrgForm : {},
  editOrgForm: { id:"", name: "" },
  organizationsFormConfig: [
    {
      id: 1,
      label: "Search Organization",
      type: "filterDropdownOrganizations",
      className: "form-control self-inp position-relative text-left w-100",
      organization_name: "organization",
      name: "organization",
      loading: false,
      logo: null,
      pro_code: "56486",
      organization_type: 1,
      is_covid_active: true,
    },
  ],

  orgProCodeFormMode : "add",
  addOrgProCodeForm : {},
  editOrgProCodeForm: { id:"", proCode: "" },
  
  proCodOrgFormConfig: [
    {
      id: 1,
      label: "Enter pro-code",
      type: "filterDropdownProOrg",
      className: "form-control self-inp position-relative text-left w-100",
      name: "pro_code",
      loading: false,
      logo: null,
      pro_code: "56486",
      organization_type: 1,
      is_covid_active: true,
    },
  ],
  flightFormMode: "add",
  flightFormConfig: [
    {
      id: 1,
      label: "Carrier Name (Optional)",
      type: "filterDropdown",
      className: "form-control self-inp position-relative text-left w-100",
      name: "flight",
      loading: false,
      isValid: true,
      selectOptions: [],
    },
    {
      id: 2,
      label: "Flight Number",
      name: "flight_no",
      placeholder: "Enter Flight Number",
      type: "text",
      isValid: true,
      className: "form-control",
    },
    {
      id: 3,
      label: "Date",
      pickerClassName: "flight-datepicker",
      name: "date_journey",
      placeholder: "Date",
      type: "date",
      isValid: true,
      className: "form-control date-input",
    },
  ],
  editFlightForm: { ...flightFormbody(), flight: "" },
  newAddedFlightList: [],
  addFlightForm: { ...flightFormbody(), flight: "" },
  editLocationForm: { ...locationFormbody() },
  addLocationForm: { ...locationFormbody() },
  addFlightTableConf: {
    rows: [
      { id: 1, name: "Name" },
      { id: 2, name: "Flight#" },
      { id: 3, name: "Date" },
      { id: 4, name: "Action" },
    ],
  },
  addedFlightList: [],
  addedLocationList: [],
  localLocationList: [],
  alreadyAddedLocList: [],
  contactFormMode: "add",
  locationExpList: [],
  userLocationList: [],
  addedVaccineList: [],
  vaccineFormMode: "add",
  addVaccineConfig: [
    {
      id: 1,
      label: "",
      labelClass: "d-none",
      type: "text",
      className: "form-control d-none",
      isValid: true,
      name: "location",
      col: "col-lg-12",
    },
    {
      id: 2,
      label: "Date",
      pickerClassName: "flight-datepicker",
      name: "vaccinated_date",
      placeholder: "Date",
      type: "date",
      className: "form-control date-input",
      col: "col-lg-6 pull-left",
    },
    {
      id: 3,
      label: "Manufacturer",
      type: "select",
      className: "form-control",
      name: "manufacturer",
      isValid: true,
      col: "col-lg-6 pull-left",
      selectOptions: [],
    },
    {
      id: 4,
      label: "Dose",
      type: "select",
      className: "form-control",
      col: "col-lg-6 pull-left",
      name: "dosage",
      isValid: true,
      isDisabled: true,
      selectOptions: [],
    },
    {
      id: 5,
      label: "Lot #",
      type: "text",
      className: "form-control",
      name: "lot",
      isValid: true,
      placeholder: "Lot #",
      col: "col-lg-6 pull-left",
    },
    {
      id: 6,
      label: "Reminder",
      labelClass: "fnt-12 text-white",
      type: "checkbox",
      className: "form-control self-inp fnt-12",
      col: "col-lg-6 pull-left",
      name: "is_reminded",
    },
    {
      id: 7,
      btnText: "Image",
      btnClass: "fnt-12 btn btn-submit text-white text-capitalize",
      type: "file",
      isValid: true,
      className: "form-control self-inp fnt-12",
      col: "col-lg-6 pull-left d-flex justify-content-end align-items-end",
      name: "file_upload",
    },
  ],
  editVaccineForm: { ...vaccineFormbody(), vaccine: "" },
  newAddedVaccineList: [],
  addVaccineForm: { ...vaccineFormbody(), vaccine: "" },
  addVaccineTableConf: {
    rows: [
      { id: 1, name: "Name" },
      { id: 2, name: "Address" },
      { id: 3, name: "Date" },
      { id: 4, name: "Action" },
    ],
  },
  addedTestingList: [],
  testingFormMode: "add",
  addTestingConfig: [
    {
      id: 1,
      label: "",
      labelClass: "d-none",
      type: "text",
      className: "form-control d-none",
      isValid: true,
      name: "location",
      col: "col-lg-12",
    },
    {
      id: 2,
      label: "Date",
      pickerClassName: "flight-datepicker",
      name: "tested_date",
      placeholder: "Date",
      type: "date",
      className: "form-control date-input",
      col: "col-lg-6 pull-left",
    },
    {
      id: 3,
      label: "Status",
      labelClass: "fnt-12 text-white",
      type: "select",
      className: "form-control self-inp fnt-12",
      name: "test_result",
      col: "col-lg-6 pull-left",
      selectOptions: [
        { name: "Negative", id: 1 },
        { name: "Positive", id: 2 },
      ],
    },
    {
      id: 4,
      btnText: "Image",
      btnClass: "fnt-12 btn btn-submit text-white text-capitalize",
      type: "file",
      isValid: true,
      className: "form-control self-inp fnt-12",
      col: "offset-lg-6 col-lg-6 pull-left d-flex justify-content-end align-items-end",
      name: "file_upload",
    },
  ],
  editTestingForm: { ...testingFormbody(), testing: "" },
  newAddedTestingList: [],
  addTestingForm: { ...testingFormbody(), testing: "" },
  addTestingTableConf: {
    rows: [
      { id: 1, name: "Name" },
      { id: 2, name: "Address" },
      { id: 3, name: "Date" },
      { id: 4, name: "Action" },
    ],
  },
  addContactStats: {
    loading: false,
  },
  listUserContacts: {
    loading: false,
  },
  listUserFlights: {
    loading: false,
  },
  listCarrierFlight: {
    loading: false,
    list: [],
  },
  listUserLocation: {
    loading: false,
    list: [],
  },
  listUserVaccines: {
    loading: false,
    list: [],
  },
  listUserTestings: {
    loading: false,
    list: [],
  },
  vaccineManufacturersListing: {
    loading: false,
    list: [],
  },
  vaccineDosesListing: {
    loading: false,
    list: [],
  },
  updateUserFight: {
    loading: false,
  },
  latestRecords: {
    vaccine_id: "",
    testing_id: "",
  },
  
};

const resetFormConfigs = (state, formConfg) => {
  return state[formConfg].map((item) => {
    // if (item.name === 'file_upload') {
    //   return { ...item, isValid: false };
    // }
    return { ...item, isValid: true };
  });
};

const watchSubmitBtn = (state, params) => {
  const { parentName, formConfig, disabledBtnStatus } = params;

  if (parentName) {
    let invalidFields = state[formConfig].filter((item) => {
      if (item.hasOwnProperty("isValid")) {
        if (
          state[parentName][item.name] === "" ||
          state[parentName][item.name] === "None"
        ) {
          return item;
        }
      }
    });

    state[disabledBtnStatus] = invalidFields.length ? true : false;
  }
};

const resetStateActions = (state, payload) => {
  if (payload.formType === "contact") {
    state.editContactForm = { ...contactFormbody() };
    state.contactFormMode = "add";
    state.addContactForm = { ...contactFormbody() };
  } else if (payload.formType === "flight") {
    state.editFlightForm = {
      ...flightFormbody(),
      flight:
        (state.listCarrierFlight.list[0] &&
          state.listCarrierFlight.list[0].name) ||
        "",
    };
    state.flightFormMode = "add";
    state.flightFormConfig = resetFormConfigs(state, "flightFormConfig");
    state.addFlightForm = {
      ...flightFormbody(),
      flight:
        (state.listCarrierFlight.list[0] &&
          state.listCarrierFlight.list[0].name) ||
        "",
    };
  } else if (payload.formType === "location") {
    state.editLocationForm = { ...locationFormbody() };
    state.locationFormMode = "add";
    state.addLocationForm = { ...locationFormbody() };
  } else if (payload.formType === "ProCode") {
    state.orgProCodeFormMode = payload.mode;
    state.addOrgProCodeForm = payload.name
  } else if (payload.formType === "vaccine") {
    state.editVaccineForm = { ...vaccineFormbody() };
    state.vaccineFormMode = "add";
    state.isDisabledVaccineSubmitBtn = true;
    state.addVaccineConfig = resetFormConfigs(state, "addVaccineConfig");
    state.addVaccineForm = { ...vaccineFormbody() };
  } else if (payload.formType === "testing") {
    state.editTestingForm = { ...testingFormbody() };
    state.testingFormMode = "add";
    state.isDisabledTestingSubmitBtn = true;
    state.addTestingConfig = resetFormConfigs(state, "addTestingConfig");
    state.addTestingForm = { ...testingFormbody() };
  }
};
const editModeModalOperations = (state, payload) => {
  if (payload.formType === "contact") {
    state.contactFormMode = payload.mode;
    state.editContactForm = populateEditContact(
      state.addedContactList,
      payload.id
    );
  } else if (payload.formType === "flight") {
    state.flightFormMode = payload.mode;
    state.editFlightForm = populateEditFlight(
      state.addedFlightList,
      payload.id
    );
  } else if (payload.formType === "Organization") {
    state.orgFormMode = payload.mode;
    state.editOrgForm = populateEditOrg(
      state.org_subscriptionsList.data,
      payload.id
    );
  } else if (payload.formType === "location") {
    state.locationFormMode = payload.mode;
    if (payload.editType === "localLocationList") {
      state.editLocationForm = populateEditLocation(
        state[payload.editType],
        payload.location
      );
    } else
      state.editLocationForm = populateEditLocation(
        state.userLocationList,
        payload.id
      );
  } else if (payload.formType === "vaccine") {
    state.addVaccineConfig = resetFormConfigs(state, "addVaccineConfig");
    state.vaccineFormMode = payload.mode;
    state.editVaccineForm = populateEditVaccine(
      state.addedVaccineList,
      payload.id
    );
    if (state.editVaccineForm && state.editVaccineForm.file_upload !== "") {
      state.isDisabledVaccineSubmitBtn = false;
      makeFieldValid(state, { type: "vaccine", name: "file_upload" });
    }
    state.addVaccineConfig = enableVaccineDosage(state, payload.value);
  } else if (payload.formType === "testing") {
    state.testingFormMode = payload.mode;
    state.editTestingForm = populateEditTesting(
      state.addedTestingList,
      payload.id
    );
    if (state.editTestingForm && state.editTestingForm.file_upload !== "") {
      state.isDisabledTestingSubmitBtn = false;
      makeFieldValid(state, { type: "testing", name: "file_upload" });
    }
  }
};

const checkForNegativeValues = (value) => {
  if (Number(value < 0)) return 0;
  else return Number(value);
};

const setOrgList = () => {
  return {
    GET_ORG_LIST_STARTED : (state, {payload}) => {
      state.orgList.data =  []
    },
    GET_ORG_LIST_SUCCESS : (state, {payload}) => {
      let info = payload.response
      state.orgList.data =  info
    },
    GET_ORG_LIST_FAILED : (state, {payload}) => {
      state.orgList.data =  []
    }
  }
   
}

const setSubscriptionsList = () => {
  return {
    GET_SUBSCRIPTIONS_LIST_STARTED : (state, {payload}) => {
      state.org_subscriptionsList.data =  []
    },
    GET_SUBSCRIPTIONS_LIST_SUCCESS : (state, {payload}) => {
      let info = payload.response
      state.org_subscriptionsList.data =  info
    },
    GET_SUBSCRIPTIONS_LIST_FAILED : (state, {payload}) => {
      state.org_subscriptionsList.data =  []
    }
  }
   
}

const afterUserInfoFetch = () => {
  return {
    GET_USER_INFO_STARTED: (state, action) => {
      state.userInfo.loading = true;
    },
    GET_USER_INFO_SUCCESS: (state, action) => {
      state.userInfo.loading = false;

      let user_id = localStorage.getItem("user_id");
      let info = action.payload.response.filter(
        (item) => item.id === Number(user_id)
      )[0];
      state.userInfo.name = `${info.first_name} ${info.last_name}`;
      state.userInfo.address = info.address;
      state.userInfo.zipCode = info.zip && info.zip.length ? info.zip : "NIL";
      state.userInfo.id = info.id;
      state.userInfo.country = info.country;
      state.userInfo.lastLoginTime = info.last_login
        ? fromNow(info.last_login)
        : fromNow(new Date());
      state.userInfo.phn = info.phone_number;
      state.userInfo.flight_exposure = checkForNegativeValues(
        info.flight_exposure
      );
      state.userInfo.contact_exposure = checkForNegativeValues(
        info.contact_exposure
      );
      state.userInfo.location_exposure = checkForNegativeValues(
        info.location_exposure
      );
      state.userInfo.exposureScore = state.userInfo.location_exposure * 20;
      if (state.userInfo.exposureScore > 100)
        state.userInfo.exposureScore = 100;
      if (state.userInfo.risk_level === "Low") {
        if (state.userInfo.exposureScore <= 40)
          state.userInfo.risk_level = "Low";
        else if (
          state.userInfo.exposureScore > 40 &&
          state.userInfo.exposureScore <= 80
        )
          state.userInfo.risk_level = "Medium";
        else state.userInfo.risk_level = "High";

        state.userInfo.risk_level =
          state.userInfo.contact_exposure || state.userInfo.flight_exposure
            ? "High"
            : "Low";
      }
      localStorage.setItem(
        "user_info",
        JSON.stringify(action.payload.response)
      );
    },
    GET_USER_INFO_FAILED: (state, action) => {
      state.userInfo.loading = false;
    },
  };
};
const afterAdduserContact = () => {
  return {
    ADD_USER_CONTACTS_STARTED: (state, action) => {
      state.addContactStats.loading = true;
    },
    ADD_USER_CONTACTS_SUCCESS: (state, action) => {
      state.addContactStats.loading = false;
    },
    ADD_USER_CONTACTS_FAILED: (state, action) => {
      state.addContactStats.loading = false;
    },
  };
};
/**reducer operations when the fetch total covid info action called */
const afterFetchUsersAction = () => {
  return {
    FETCH_USERS_ACTION_STARTED: (state) => {
      state.userInfo.loading = true;
    },
    FETCH_USERS_ACTION_SUCCESS: (state, action) => {
      state.userInfo.list = action.payload.response;
      let user_id = localStorage.getItem("user_id");
      let result = action.payload.response.filter(
        (item) => item.user === Number(user_id)
      )[0];
      if (result) {
        state.userInfo.id = user_id;
        state.userInfo.report =
          result.port && result.port.length ? result.port : "NOT INFECTED";
        state.userInfo.status =
          (result.status && result.status.status) || "N/A";
        state.userInfo.tested = result.is_tested ? "YES" : "NO";
        state.userInfo.vaccinated = result.is_vaccinated ? "YES" : "NO";
        state.userInfo.risk_level =
          result.test_result === "Positive" ? "High" : "Low";
      }
      state.userInfo.loading = false;
    },
    FETCH_USERS_ACTION_FAILED: (state) => {
      state.userInfo.loading = false;
    },
  };
};
const afterListUserContacts = () => {
  return {
    LIST_USER_CONTACTS_STARTED: (state, action) => {
      state.listUserContacts.loading = true;
    },
    LIST_USER_CONTACTS_SUCCESS: (state, action) => {
      state.listUserContacts.loading = false;
      state.addedContactList = formatContactList(action.payload.response);
      state.userDiagnonsticInfo.infectedCount = state.addedContactList;
    },
    LIST_USER_CONTACTS_FAILED: (state, action) => {
      state.listUserContacts.loading = false;
    },
  };
};
const afterListUserFlights = () => {
  return {
    LIST_USER_FLIGHTS_STARTED: (state, action) => {
      state.listUserFlights.loading = true;
    },
    LIST_USER_FLIGHTS_SUCCESS: (state, action) => {
      state.listUserFlights.loading = false;
      state.addedFlightList = formatFlightList(action.payload.response);
      state.userDiagnonsticInfo.flightsCount = state.addedFlightList;
    },
    LIST_USER_FLIGHTS_FAILED: (state, action) => {
      state.listUserFlights.loading = false;
    },
  };
};
const afterUpdateUserFlight = () => {
  return {
    UPDATE_USER_FLIGHT_STARTED: (state, action) => {
      state.updateUserFight.loading = true;
    },
    UPDATE_USER_FLIGHT_SUCCESS: (state, action) => {
      state.updateUserFight.loading = false;
    },
    UPDATE_USER_FLIGHT_FAILED: (state, action) => {
      state.updateUserFight.loading = false;
    },
  };
};

const afterListFlightCarriers = () => {
  return {
    LIST_FLIGHT_CARRIERS_STARTED: (state, action) => {
      state.listCarrierFlight.loading = true;
      state.flightFormConfig[0].loading = true;
    },
    LIST_FLIGHT_CARRIERS_SUCCESS: (state, action) => {
      state.listCarrierFlight.loading = false;
      state.flightFormConfig[0].loading = false;
      let formattedOptions = formatCarrierFlights(action.payload.response);
      state.flightFormConfig[0].selectOptions = formattedOptions;
      // state.addFlightForm.flight = formattedOptions[0].name;
      state.listCarrierFlight.list = formattedOptions;
    },
    LIST_FLIGHT_CARRIERS_FAILED: (state, action) => {
      state.listCarrierFlight.loading = false;
      state.flightFormConfig[0].loading = false;
    },
  };
};
const afterListUserLocations = () => {
  return {
    LIST_USER_LOCATIONS_STARTED: (state, action) => {
      state.listUserLocation.loading = true;
    },
    LIST_USER_LOCATIONS_SUCCESS: (state, action) => {
      state.listUserLocation.loading = false;
      state.listUserLocation.list = formatLocations(action.payload.response);
      state.userDiagnonsticInfo.exposureCount = state.listUserLocation.list;
    },
    LIST_USER_LOCATIONS_FAILED: (state, action) => {
      state.listUserLocation.loading = false;
    },
  };
};

const afterListUserVaccines = () => {
  return {
    LIST_USER_VACCINES_STARTED: (state, action) => {
      state.listUserVaccines.loading = true;
    },
    LIST_USER_VACCINES_SUCCESS: (state, action) => {
      state.listUserVaccines.loading = false;
      state.listUserVaccines.list = formatVaccineList(action.payload.response);
      state.addedVaccineList = formatVaccineList(action.payload.response);
      state.userDiagnonsticInfo.vaccineCount = state.addedVaccineList;
      if (state.addedVaccineList.length) {
        const latestVaccine = state.addedVaccineList.sort((a, b) =>
          moment(a.vaccinated_date).isBefore(moment(b.vaccinated_date)) ? 1 : -1
        )[0];
        state.latestRecords.vaccine_id = latestVaccine.id;
        state.userDiagnonsticInfo.vaccineReminderDays = calcVaccineReminderDays(
          state,
          latestVaccine
        );
      }
      state.userDiagnonsticInfo.vaccineInfoLastUpdate =
        getVaccineInfoLastUpdate(state);
      getQrUserDiagnosticInfo(state);
    },
    LIST_USER_VACCINES_FAILED: (state, action) => {
      state.listUserVaccines.loading = false;
    },
  };
};
const afterListDosesAction = () => {
  return {
    LIST_DOSES_STARTED: (state) => {
      state.vaccineDosesListing.loading = true;
    },
    LIST_DOSES_SUCCESS: (state, action) => {
      state.vaccineDosesListing.loading = false;
      state.vaccineDosesListing.list = formatDoses(action.payload.response);
      let pos = findPosition("dosage", state.addVaccineConfig);
      state.addVaccineConfig[pos].selectOptions = formatDoses(
        action.payload.response
      );
    },
    LIST_DOSES_FAILED: (state) => {
      state.vaccineDosesListing.loading = false;
    },
  };
};
const afterListManufacturersAction = () => {
  return {
    LIST_MANUFACTURES_STARTED: (state) => {
      state.vaccineManufacturersListing.loading = true;
    },
    LIST_MANUFACTURES_SUCCESS: (state, action) => {
      state.vaccineManufacturersListing.loading = false;
      state.vaccineManufacturersListing.list = formatManufacturers(
        action.payload.response
      );
      let pos = findPosition("manufacturer", state.addVaccineConfig);
      state.addVaccineConfig[pos].selectOptions = formatManufacturers(
        action.payload.response
      );
    },
    LIST_MANUFACTURES_FAILED: (state) => {
      state.vaccineManufacturersListing.loading = false;
    },
  };
};

const afterListUserTestings = () => {
  return {
    LIST_USER_TESTINGS_STARTED: (state, action) => {
      state.listUserTestings.loading = true;
    },
    LIST_USER_TESTINGS_SUCCESS: (state, action) => {
      state.listUserTestings.loading = false;
      state.listUserTestings.list = formatTestingList(action.payload.response);
      state.addedTestingList = formatTestingList(action.payload.response);
      state.userDiagnonsticInfo.testingCount = state.addedTestingList;
      state.userDiagnonsticInfo.testingInfoLastUpdate =
        getTestingInfoLastUpdate(state);
      if (state.addedTestingList.length) {
        const latestTesting = state.addedTestingList.sort((a, b) =>
          moment(a.tested_date).isBefore(moment(b.tested_date)) ? 1 : -1
        )[0];
        state.latestRecords.testing_id = latestTesting.id;
      }
      getQrUserDiagnosticInfo(state);
    },
    LIST_USER_TESTINGS_FAILED: (state, action) => {
      state.listUserTestings.loading = false;
    },
  };
};

const deleteUserLocationData = () => {
  return {
    DELETE_USER_LOCATION_STARTED: (state, action) => {
      state.locationLoading = true;
    },
    DELETE_USER_LOCATION_SUCCESS: (state, action) => {
      state.locationLoading = false;
    },
    DELETE_USER_LOCATION_FAILED: (state, action) => {
      state.locationLoading = false;
    },
  };
};

const editUserLocationData = () => {
  return {
    EDIT_USER_LOCATION_STARTED: (state, action) => {
      state.locationLoading = true;
    },
    EDIT_USER_LOCATION_SUCCESS: (state, action) => {
      state.locationLoading = false;
    },
    EDIT_USER_LOCATION_FAILED: (state, action) => {
      state.locationLoading = false;
    },
  };
};

const newUserLocationData = () => {
  return {
    NEW_USER_LOCATION_STARTED: (state, action) => {
      state.locationLoading = true;
    },
    NEW_USER_LOCATION_SUCCESS: (state, action) => {
      state.locationLoading = false;
    },
    NEW_USER_LOCATION_FAILED: (state, action) => {
      state.locationLoading = false;
    },
  };
};

const getAllTravelLocation = () => {
  return {
    GET_USER_TRAVEL_LOCATION_STARTED: (state, action) => {
      state.listUserLocation.loading = true;
      state.locationLoading = true;
      state.userLocationList = [];
    },
    GET_USER_TRAVEL_LOCATION_SUCCESS: (state, action) => {
      state.locationLoading = false;
      state.listUserLocation.loading = false;
      state.userLocationList = action.payload.response;
      state.userDiagnonsticInfo.exposureCount = state.userLocationList;
      state.listUserLocation.list = formatLocations(action.payload.response);
    },
    GET_USER_TRAVEL_LOCATION_FAILED: (state, action) => {
      state.locationLoading = false;
      state.listUserLocation.loading = false;
      state.userLocationList = [];
    },
  };
};

const calcVaccineReminderDays = (state, latestVaccine) => {
  if (!state.addedVaccineList.length) {
    return 0;
  }
  const frequency = latestVaccine.manufacturer.frequency || state.frequency;
  const validatyPeriod =
    latestVaccine.manufacturer.validity_period || state.validatyPeriod;
  let daysFromLatestVaccine = moment().diff(
    moment(latestVaccine.vaccinated_date),
    "days"
  );

  if (latestVaccine.dosage.parent) {
    return frequency - daysFromLatestVaccine;
  }
  return validatyPeriod - daysFromLatestVaccine;
};

const getVaccineInfoLastUpdate = (state) => {
  if (!state.addedVaccineList.length) {
    return "";
  }
  const lastUpdatedVaccine = state.addedVaccineList.sort((a, b) =>
    moment(a.updated_at).isBefore(b.updated_at) ? 1 : -1
  )[0];
  return moment(lastUpdatedVaccine.updated_at).fromNow();
};

const getTestingInfoLastUpdate = (state) => {
  if (!state.addedTestingList.length) {
    return "";
  }
  const lastUpdatedTesting = state.addedTestingList.sort((a, b) =>
    moment(a.updated_at).isBefore(b.updated_at) ? 1 : -1
  )[0];
  return moment(lastUpdatedTesting.updated_at).fromNow();
};

const getQrUserDiagnosticInfo = (state, filter) => {
  let name = state.userInfo.name;

  let latestVaccine =
    state.latestRecords.vaccine_id && state.listUserVaccines.list.length
      ? state.listUserVaccines.list.find(
          (v) => v.id === state.latestRecords.vaccine_id
        )
      : null;

  if (!latestVaccine) {
    state.userDiagnonsticInfo.userVaccineStatus = false;
  }

  if (latestVaccine) {
    let vaccinatedDate = moment(latestVaccine.vaccinated_date);
    let days = Math.round(
      moment.duration(moment().startOf("day") - vaccinatedDate).asDays()
    );
    state.userDiagnonsticInfo.vaccinatedDate = moment(
      latestVaccine.vaccinated_date
    ).format("MM/DD/YYYY");

    state.userDiagnonsticInfo.userVaccineStatus = days < 365;
  }

  let latestTesting =
    state.latestRecords.testing_id && state.listUserTestings.list.length
      ? state.listUserTestings.list.find(
          (t) => t.id === state.latestRecords.testing_id
        )
      : null;

  if (!latestTesting) {
    state.userDiagnonsticInfo.userTestingStatus = false;
  }

  if (latestTesting) {
    let testedDate = moment(latestTesting.tested_date);
    let days = Math.round(
      moment.duration(moment().startOf("day") - testedDate).asDays()
    );

    state.userDiagnonsticInfo.sinceLatestTest = days;
    state.userDiagnonsticInfo.userTestingStatus =
      latestTesting.test_result === "Negative" && days < 3;
  }

  if (
    state.userDiagnonsticInfo.userVaccineStatus ||
    state.userDiagnonsticInfo.userTestingStatus
  ) {
    state.userDiagnonsticInfo.qrCode = `${name} satisfies Covid - 19 health screening requirements`;
  } else {
    state.userDiagnonsticInfo.qrCode = `${name} doesn't satisfy Covid - 19 health screening requirements`;
  }
};

const validateField = (config, name, status) => {
  let arrays = config.map((item) => {
    if (item.name === name) {
      return { ...item, isValid: status };
    } else return { ...item };
  });
  return arrays;
};
const checkIfFieldsValid = (state, params) => {
  let { type, name } = params;
  if (type === "flight") {
    state.flightFormConfig = validateField(state.flightFormConfig, name, false);
  } else if (type === "vaccine") {
    state.addVaccineConfig = validateField(state.addVaccineConfig, name, false);
  } else if (type === "testing") {
    state.addTestingConfig = validateField(state.addTestingConfig, name, false);
  }
};
const makeFieldValid = (state, params) => {
  let { type, name } = params;
  if (type === "vaccine") {
    state.addVaccineConfig = validateField(state.addVaccineConfig, name, true);
  } else if (type === "testing") {
    state.addTestingConfig = validateField(state.addTestingConfig, name, true);
  }
};

const formatPayload = (values) => {
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      const element = values[key];
      if (key === "from_time" || key === "location_date") {
        if (!element.length) values[key] = new Date();
      }
    }
  }
  return values;
};

const localListOperations = (state, payload) => {
  if (
    payload.operation === "add" &&
    /**return true if duplicate entry is found */
    !checkForDuplicates({
      array: state[payload.name],
      pivot: "location",
      name: payload.value.location,
    })
  )
    state[payload.name].push(formatPayload(payload.value));
};

const getPivotPosition = (params) => {
  let { array, pivot, type } = params;
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element[type] === pivot) {
        return key;
      }
    }
  }
};
const deleteModeOperations = (state, payload) => {
  if (payload.formType === "location") {
    if (payload.deleteType === "localLocationList") {
      let pos = getPivotPosition({
        array: state[payload.deleteType],
        type: "id",
        pivot: payload.id,
      });
      state[payload.deleteType].splice(pos, 1);
    }
  }
};

const afterComponentActions = () => {
  return {
    HANDLE_CHANGE_LR_PAGE: (state, action) => {
      const { payload } = action;

      if (payload.name === "qrCode") {
        getQrUserDiagnosticInfo(state, payload.value);
      }

      if (payload.name === "manufacturer") {
        if (!payload.valid) {
          state.editVaccineForm.dosage = "";
          state.addVaccineForm.dosage = "";
        }
        state.addVaccineConfig = enableVaccineDosage(state, payload.value);
      }
      if (payload.name === "file_upload") {
        if (
          (payload.parentName === "addVaccineForm" ||
            payload.parentName === "editVaccineForm") &&
          payload.value !== ""
        ) {
          state.addVaccineConfig = validateField(
            state.addVaccineConfig,
            payload.name,
            true
          );
        } else if (
          (payload.parentName === "addTestingForm" ||
            payload.parentName === "editTestingForm") &&
          payload.value !== ""
        ) {
          state.addTestingConfig = validateField(
            state.addTestingConfig,
            payload.name,
            true
          );
        }

        if (
          (payload.parentName === "addVaccineForm" ||
            payload.parentName === "editVaccineForm") &&
          payload.value === "None"
        ) {
          state.addVaccineConfig = validateField(
            state.addVaccineConfig,
            payload.name,
            false
          );
        } else if (
          (payload.parentName === "addTestingForm" ||
            payload.parentName === "editTestingForm") &&
          payload.value === "None"
        ) {
          state.addTestingConfig = validateField(
            state.addTestingConfig,
            payload.name,
            false
          );
        }
      }
      if (payload.type === "updateList") {
        localListOperations(state, payload);
      } else {
        if (payload.validate) {
          checkIfFieldsValid(state, { type: payload.type, name: payload.name });
        } else if (payload.valid) {
          makeFieldValid(state, { type: payload.type, name: payload.name });
        } else if (payload.mode === "edit") {
          editModeModalOperations(state, payload);
        } else if (payload.mode === "update") {
          editModeModalOperations(state, payload);
        } else if (payload.mode === "delete") {
          deleteModeOperations(state, payload);
        } else if (payload.type === "reset") {
          resetStateActions(state, payload);
        } else if (payload.type === "toggle") {
          state[payload.name] = !state[payload.name];
        } else if (payload.parentName) {
          if (payload.type === "latLng") {
            //to add lat and lng
            state[payload.parentName].location = payload.value.name;
            state[payload.parentName].latitude = payload.value.lat;
            state[payload.parentName].longitude = payload.value.lng;
          }
          state[payload.parentName][payload.name] = payload.value;
        } else state[payload.name] = payload.value;
      }

      watchSubmitBtn(state, {
        parentName: payload.parentName,
        formConfig: "addVaccineConfig",
        disabledBtnStatus: "isDisabledVaccineSubmitBtn",
      });
      watchSubmitBtn(state, {
        parentName: payload.parentName,
        formConfig: "addTestingConfig",
        disabledBtnStatus: "isDisabledTestingSubmitBtn",
      });
    },
  };
};

const enableVaccineDosage = (state, type) => {
  let pos = findPosition("dosage", state.addVaccineConfig);
  state.vaccineManufacturersListing.list.map((manufacturer) => {
    if (manufacturer.name === type) {
      let dosage = manufacturer.requirement_dose;
      state.addVaccineConfig[pos].selectOptions =
        state.vaccineDosesListing.list.filter((dose) => {
          if (dose.id === dosage || dose.parent === dosage) {
            return dose;
          }
        });
    }
  });
  let result = state.addVaccineConfig.map((item) => {
    /**the below fields are toggled when user selects */
    if (item.name === "dosage") {
      return { ...item, isDisabled: false };
    } else return { ...item };
  });
  return result;
};

export default createReducer(INITIAL_STATE, {
  ...afterComponentActions(),
  ...afterUpdateUserFlight(),
  ...afterAdduserContact(),
  ...editUserLocationData(),
  ...newUserLocationData(),
  ...getAllTravelLocation(),
  ...deleteUserLocationData(),
  ...afterListUserContacts(),
  ...afterListFlightCarriers(),
  ...afterListUserFlights(),
  ...afterListUserLocations(),
  ...afterListUserVaccines(),
  ...afterListDosesAction(),
  ...afterListManufacturersAction(),
  ...afterListUserTestings(),
  ...afterUserInfoFetch(),
  ...afterFetchUsersAction(),
  ...setOrgList(),
  ...setSubscriptionsList()
});
