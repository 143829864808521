import React from "react";
import { CustomButton } from "../buttons";
export const ALheader = props => {
  return (
    <React.Fragment>
      <h4>Add Locations</h4>
      <CustomButton
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        clickEvent={props.toggleModal}
        text={<span aria-hidden="true">&times;</span>}
      />
    </React.Fragment>
  );
};
