import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_ACTIVITY_SUCCESS,
  ADD_ACTIVITY_FAILED,
  ADD_ACTIVITY_STARTED
} from "../../../../constants/action-types";
import { ADD_ACTIVITIES } from "../../../../constants/configurations";

export const addActivityAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "post",
      api: ADD_ACTIVITIES,
      started: ADD_ACTIVITY_STARTED,
      success: ADD_ACTIVITY_SUCCESS,
      failure: ADD_ACTIVITY_FAILED
    },callBack);
  };
};
