import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_USER_LOCATIONS_SUCCESS,
  LIST_USER_LOCATIONS_FAILED,
  LIST_USER_LOCATIONS_STARTED
} from "../../../../constants/action-types";
import { GET_USER_LOCATIONS_URL } from "../../../../constants/configurations";

export const listLocationAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "get",
      api: GET_USER_LOCATIONS_URL,
      started: LIST_USER_LOCATIONS_STARTED,
      success: LIST_USER_LOCATIONS_SUCCESS,
      failure: LIST_USER_LOCATIONS_FAILED
    },callBack);
  };
};
