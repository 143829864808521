let textLimit = 8;

export const abbreviate = (text, limit, noDot) => {
  if (limit) {
    textLimit = limit;
  }
  if (text.length > textLimit) {
    return `${text.substring(0, textLimit)}${noDot ? "" : "..."}`;
  } else return text;
};

export const abbreviateName = (text) => {
  if (text&&text.length) {
    let arrays = text.split(" ");
    return `${arrays[0] && arrays[0].substring(0, 1)}${
      arrays[1] && arrays[1].substring(0, 1)
    }`;
  }
  else return text;
};


