import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_USER_REPORT_SUCCESS,
  ADD_USER_REPORT_FAILED,
  ADD_USER_REPORT_STARTED
} from "../../../../constants/action-types";
import { GET_USERS_URL } from "../../../../constants/configurations";

export const addUserReportAction = (body, callback) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api: GET_USERS_URL,
        started: ADD_USER_REPORT_STARTED,
        success: ADD_USER_REPORT_SUCCESS,
        failure: ADD_USER_REPORT_FAILED
      },
      callback
    );
  };
};
