/**
 * This component renders the google map ,
 * showing the effected areas inside the selected circle
 */

/**importing react dependencies */
import React, { Component } from "react";

import locationMarker from "../../../../src/assets/images/location.svg";
/**components */
import SelfReportGraph from "../../GoogleMap/selfReportMap";

/**constants */
import {
  homePageConst,
  searchIconBlack,
} from "../../../constants/text-constants";
import { TextInput } from "../../commons/textField";

const { addLocText, radiusText, metric } = homePageConst;

export default class HomePageGoogleMapWrapper extends Component {
  componentDidMount() {
    this.adjustMapHeight()
    window.addEventListener("resize", this.adjustMapHeight.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustMapHeight)
  }
  adjustMapHeight = ()=>{
    let mapContainer = document.getElementById("google-maps");
    if (mapContainer) {
      let height = window.innerHeight;

      if (this.props.mobileView) {
        mapContainer.style.top = "100px";
        mapContainer.style.height = height - 100 + "px";
      } else {
        mapContainer.style.height = height - 10 + "px"; 
        mapContainer.style.top = "10px";
      }
    }
  }
  getHeightOfDiv = (name) => {
    return document.getElementsByClassName(name)[0].offsetHeight;
  };
  /**handle change function for this component */
  handleChange = (params) => {
    let { name, value } = params;
    this.props.handleChange({ parentName: "graphSettings", name, value });
  };

  buildTextFields = (params) => {
    let {
      type,
      className,
      placeholder,
      name,
      id,
      value,
      handleChange,
    } = params;
    return (
      <TextInput
        type={type}
        id={id}
        className={className}
        placeholder={placeholder}
        name={name}
        value={value}
        handleChange={handleChange}
      />
    );
  };
  renderRadiusEntry = () => {
    let { radius } = this.props.graphSettings;
    return (
      <span className="pull-left pl-2">
        {radiusText}
        {this.buildTextFields({
          type: "number",
          className: "map-slct-opt ml-2 mr-2 bg-clr-loc rounded border-0 p-1",
          id: "intensity_radius",
          name: "radius",
          value: radius,
          handleChange: this.handleChange,
        })}
        {metric}
      </span>
    );
  };

  renderLocationEntry = () => {
    return (
      <span className="pull-right">
        {addLocText}
        <span class="position-relative d-inline-block text-dark ml-1">
          <span class="loc-icn">
            {/* <img src={locationMarker} alt="location"/> */}
            <i class="fa fa-map-marker"></i>
          </span>
          {this.buildTextFields({
            type: "text",
            className: "pl-4 pr-4 pt-1 pb-1 bg-clr-loc border-0 rounded",
            placeholder: "Add Location",
            label: "Add Location",
            name: "selectedLocation",
            id: "pac-input",
            handleChange: () => {},
          })}{" "}
          <span class="loc-srch-icn">{searchIconBlack}</span>
        </span>
      </span>
    );
  };
  render() {
    return (
      <div className="map-container">
        <SelfReportGraph
          inputBody={
            []
            // <div class="map-add-loc m-auto w-100 bg-white fnt-12 p-2 rounded nav-p">
            //   {this.renderRadiusEntry()}
            //   {this.renderLocationEntry()}
            // </div>
          }
          {...this.props}
        />
      </div>
    );
  }
}
