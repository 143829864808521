import numeral from "numeral";

export const formatNumber = (value) => {
  return numeral(value).format("0,0");
};

export const convertDigits = (value, count) => {
  let format = "";
  for (let index = 0; index < count; index++) {
    format += "0";
  }
  return numeral(value).format(format);
};
