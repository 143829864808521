/**
 * The global header common for all pages
 */

/** react dependencies */
import React, { Component } from "react";
import queryString from "query-string";

/**components */
import GlobalSearchBar from "./searchBar";
import { AppLogo } from "./logoPane";
import NavButtons from "./navButtons";
import history from "../history";
import {
  APP_BASE_NAME,
  minimumScreenRatio,
} from "../../constants/configurations";
import { toastNotification } from "../commons/toastNotification";
import { getRegionInfo } from "../../utils/getRegionInfo";
import { checkIfUserAuthorized } from "../../utils/checkAuth";

export default class GlobalHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileView: false,
    };
  }

  componentWillUnmount = () => {
    clearInterval(this.dataPolling);
  };
  resize() {
    this.setState({ mobileView: window.innerWidth <= minimumScreenRatio });
  }
  componentDidMount = () => {
    this.props.getUserLastUpdate();
    this.resize();
    this.resizeListner = window.addEventListener(
      "resize",
      this.resize.bind(this)
    );
    this.dataPolling = setInterval(() => {
      this.props.getUserLastUpdate();
    }, 100000);
    let { pathname } = history.location;
    if (
      !checkIfUserAuthorized() &&
      pathname !== APP_BASE_NAME &&
      !this.props.noRedirection &&
      this.props.type !== "login"
    ) {
      // history.push({ pathname: `${APP_BASE_NAME}` });
      // history.push({ pathname: `/` });
    }
    this.props.handleChange({
      type: "showNavs",
      visible: checkIfUserAuthorized(),
      pathname,
    });
    this.unlisten = history.listen((location, prev) => {
      this.props.handleChange({
        type: "showNavs",
        visible: checkIfUserAuthorized(),
        pathname: location.pathname,
      });
    });
    this.props.getUserInfoAction({}, (res) => {
      if (res.status === 200) {
        let user_id = localStorage.getItem("user_id");
        const { first_name, last_name } = res.data.filter(
          (item) => item.id === Number(user_id)
        )[0];
        let name = `${first_name} ${last_name ? last_name : ""}`;
        localStorage.setItem("name", name);
      }
    });
    let info = queryString.parse(history.location.search);
    this.props.handleChange({ name: "searchText", value: info.zip });
    // if (history.location.pathname === "/") {
    //   history.push({ pathname: APP_BASE_NAME });
    // }
  };

  componentWillUnmount = () => {
    if (this.unlisten) this.unlisten();
    /**un mount the listener for performance */
    if (this.resizeListner) this.resizeListner();
  };

  addRegionParamsToUrl = (resp) => {
    let info = getRegionInfo(resp);
    const stringified = queryString.stringify({
      zip: info.zip,
      city: info.city,
      province: info.province,
      country: info.country,
    });
    history.push({ pathname: `/`, search: stringified });
  };
  onUserSearchZip = (event) => {
    let { searchText } = this.props.reducer;
    if (event.key === "Enter") {
      this.props.getRegionWithZipAction({ zip: searchText }, (res) => {
        if (res.status === 200 || res.status === "OK") {
          toastNotification({
            msg: "fetch region success",
            type: "success",
          });
          this.addRegionParamsToUrl(res.data);
        }
      });
    }
  };

  handChangeSearchBar = (params) => {
    let { name, value } = params;
    if (value.length && value.match("^[0-9]") !== null) {
      this.props.handleChange({ name, value });
    } else {
      this.props.handleChange({ name, value });
    }
  };
  toggleHomeCollapse = () => {
    this.props.handleChangeLatestreport({
      name: "hideCollapse",
      type: "toggle",
    });
  };
  render() {
    let { mobileView } = this.state;
    return (
      <header id="gheader" className="fixed">
        <AppLogo />
        <div className="header-wrapper">
          <GlobalSearchBar
            {...this.props}
            className={
              "search-wrapper position-relative w-100 d-inline-block fnt-16 serch-wrapper-resp"
            }
            onUserSearchZip={this.onUserSearchZip}
            handChangeSearchBar={this.handChangeSearchBar}
          />
          {
            <NavButtons
              {...this.props}
              onZipSearch={this.onUserSearchZip}
              handChangeSearchBar={this.handChangeSearchBar}
            />
          }
        </div>
      </header>
    );
  }
}
