import React from "react";
import { CustomButton } from "../buttons";
import UserAddedFlightBlock from "./userAddedFlightBlock";
import { submitText } from "../../../constants/text-constants";
import { BuildFormHandler } from "../BuildFields";
import { Overlay, Popover } from "react-bootstrap";

export const AFBody = (props) => {
  const userInputBlock = () => {
    let { flightFormConfig, addFlightForm } = props;
    let { editFlightForm, flightFormMode } = props.reducer;
    return (
      <div className="col-sm-5">
        <BuildFormHandler
          formDefinition={flightFormConfig}
          variables={flightFormMode === "add" ? addFlightForm : editFlightForm}
          {...props}
        />
        <div className="text-right mt-3 mb-5">
              <button className="add-location-btn" onClick={props.submitAddFlight}>
                {flightFormMode === "add" ? "Add Flight" : "Update Flight"}
              </button>
        </div>
      </div>
    );
  };
  return (
    <div className="modal-body">
      <div className="col-sm-12">
        <div className="row">
          {userInputBlock()}
          <UserAddedFlightBlock {...props} />
        </div>
        <button class="btn btn-block btn-submit fnt-14 SFProText-Regular" onClick={props.closeModal}>
          Submit
        </button>
        {/* <button
          type="button"
          className="btn btn-block btn-submit fnt-14 mt-4"
          data-container="body"
          data-toggle="popover"
          data-placement="left"
          data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          onClick={props.closeModal}
          >
          {submitText}
        </button>*/}
      </div>
    </div>
  );
};
