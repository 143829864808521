import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_MANUFACTURES_SUCCESS,
  LIST_MANUFACTURES_STARTED,
  LIST_MANUFACTURES_FAILED
} from "../../../../constants/action-types";
import { GET_VACCINE_MANUFACTURES_URL } from "../../../../constants/configurations";

export const getManufacturersListAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "get",
      api: GET_VACCINE_MANUFACTURES_URL,
      started: LIST_MANUFACTURES_STARTED,
      success: LIST_MANUFACTURES_SUCCESS,
      failure: LIST_MANUFACTURES_FAILED
    },callBack);
  };
};
