import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_USER_LOCATIONS_SUCCESS,
  ADD_USER_LOCATIONS_FAILED,
  ADD_USER_LOCATIONS_STARTED
} from "../../../../constants/action-types";
import { GET_USER_LOCATIONS_URL } from "../../../../constants/configurations";

export const addLocationAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "get",
      api: GET_USER_LOCATIONS_URL,
      started: ADD_USER_LOCATIONS_STARTED,
      success: ADD_USER_LOCATIONS_SUCCESS,
      failure: ADD_USER_LOCATIONS_FAILED
    },callBack);
  };
};
