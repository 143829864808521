/**
 * reducer for access risk page page
 */
import { createReducer } from "@reduxjs/toolkit";
import { getLocationDetails } from "../../../utils/getRegionInfo";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { getPivotPosition } from "../../../utils/arrayFormatters";

/**helper functions */
const initAccessRisk = {
  location: "",
  latitude: "",
  longitude: "",
  location_date: apiDateFormat(new Date()),
  // locationName: "",
  from_time: null,
  to_time: "",
  Country_Region: "",
  Province_State: "",
  City: "",
};
const INITIAL_STATE = {
  addAccessRiskVariables: initAccessRisk,
  mode: "add",
  localLocationList: [],
  accessRiskConf: {
    rows: [
      { id: 1, name: "Name" },
      { id: 2, name: "Location" },
      { id: 3, name: "Date" },  
      { id: 4, name: "Action" },
    ],
  },
  addAccessRisk: {
    loading: false,
    list: [],
  },
  activePage: "add-risk",
};
const assignGoogleMapValues = (state, action) => {
  let { value, name, parentName } = action.payload;
  let locationDetails = getLocationDetails(value);
  let { address, lat, lng, city, province, country } = locationDetails;
  state[parentName][name] = address;
  state[parentName].latitude = lat;
  state[parentName].longitude = lng;
  state[parentName].City = city;
  state[parentName].Province_State = province;
  state[parentName].Country_Region = country;
};

const addLocationOperations = (state, action) => {
  let { name } = action.payload;
  let list = state[name];
  let { location, location_date } = state.addAccessRiskVariables;

  state[name] = state[name].concat([
    {
      ...state.addAccessRiskVariables,
      id: list.length + 1,
      number: location,
      name: location.split(",")[0],
      date: location_date,
    },
  ]);
};

const deleteLocationOperations = (state, action) => {
  let { id, name } = action.payload;
  let pos = getPivotPosition({
    array: state[name],
    type: "id",
    pivot: id,
  });
  state[name].splice(pos,1);
};

const afterAddAccessRisk = () => {
  return {
    ADD_UNAUTH_USER_LOCATION_STARTED: (state, action) => {
      state.addAccessRisk.loading = true;
    },
    ADD_UNAUTH_USER_LOCATION_SUCCESS: (state, action) => {
      state.addAccessRisk.loading = false;
      state.addAccessRisk.list = action.payload.response;
    },
    ADD_UNAUTH_USER_LOCATION_FAILED: (state, action) => {
      state.addAccessRisk.loading = false;
    },
  };
};
const handleChange = () => {
  return {
    HANDLE_CHANGE_ACCESS_RISK: (state, action) => {
      let { name, value, parentName, type, mode } = action.payload;
      /**when user adds a location */
      if (mode === "add") {
        addLocationOperations(state, action);
        /**assign location details to variables */
      } else if (mode === "delete") {
        deleteLocationOperations(state, action);
        /**assign location details to variables */
      } else if (type === "mapAddress") {
        assignGoogleMapValues(state, action);
        /**resetting state after an opertion is done */
      } else if (type === "reset") {
        state[name] = initAccessRisk;
      } else {
        if (parentName) state[parentName][name] = value;
        else state[name] = value;
      }
    },
  };
};

export default createReducer(INITIAL_STATE, {
  ...handleChange(),
  ...afterAddAccessRisk(),
});
