import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_USER_VACCINES_SUCCESS,
  LIST_USER_VACCINES_FAILED,
  LIST_USER_VACCINES_STARTED
} from "../../../../constants/action-types";
import { ADD_USER_VACCINE_URL } from "../../../../constants/configurations";

export const listUserVaccinesAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: ADD_USER_VACCINE_URL,
        started: LIST_USER_VACCINES_STARTED,
        success: LIST_USER_VACCINES_SUCCESS,
        failure: LIST_USER_VACCINES_FAILED
      },
      callBack
    );
  };
};
