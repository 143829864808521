const google = window.google;

export const findPosition = (name, array) => {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.name === name) {
        return key;
      }
    }
  }
};

export const formatLocations = (list) => {
  return list.map((loc) => {
    return {
      ...loc,
      latLng: new google.maps.LatLng(loc.Lat, loc.Long),
    };
  });
};

export const formatContactList = (array) => {
  let res = array.map((item) => ({
    ...item,
    name: item.is_tagged ? '*******' : item.name,
    number: item.location,
    date: item.date_contacted,
  }));
  return res;
};
export const formatAddedFlightList = (array) => {
  if (array.length)
    return array.map((item) => ({
      ...item,
      name: item.flight,
      number: item.flight_no,
      date: item.date_journey,
    }));
  else return [];
};
export const formatFlightList = (array) => {
  if (array.length)
    return array.map((item) => ({
      ...item,
      id: item.id,
      name: item.flight.name,
      number: item.flight_no,
      date: item.date_journey,
    }));
  else return [];
};
export const formatVaccineList = (array) => {
  if (array.length)
    return array.map((item) => ({
      ...item,
      id: item.id,
      name: item.manufacturer.name,
      number: item.location,
      date: item.vaccinated_date,
    }));
  else return [];
};
export const formatTestingList = (array) => {
  if (array.length)
    return array.map((item) => ({
      ...item,
      id: item.id,
      name: item.name,
      number: item.location,
      date: item.tested_date,
    }));
  else return [];
};

export const populateEditContact = (array, keyId) => {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.id === keyId) {
        return element;
      }
    }
  }
};

export const populateEditLocation = (array, keyId) => {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.id === keyId || element.location === keyId) {
        return element;
      }
    }
  }
};

export const populateEditFlight = (array, keyId) => {
  let matchObj = {};
  let result = {};

  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.id === keyId) {
        matchObj = element;
      }
    }
  }
  for (const key in matchObj) {
    if (matchObj.hasOwnProperty(key)) {
      const item = matchObj[key];
      if (key === "flight") {
        result[key] = item.name;
      } else result[key] = item;
    }
  }
  return result;
};

export const populateEditOrg = (array, keyId) => {
  let matchObj = {};
  let result = {};
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.id === keyId) {
        matchObj = element;
      }
    }
  }
  for (const key in matchObj) {
    if (matchObj.hasOwnProperty(key)) {
      const item = matchObj[key];
      if (key === "Organization") {
        result[key] = item.name;
      } else result[key] = item;
    }
  }
  return result;
};

export const populateEditVaccine = (array, keyId) => {
  let matchObj = {};
  let result = {};

  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.id === keyId) {
        matchObj = element;
      }
    }
  }
  for (const key in matchObj) {
    if (matchObj.hasOwnProperty(key)) {
      const item = matchObj[key];
      if (key === "manufacturer" || key === "dosage") {
        result[key] = item.name;
      } else result[key] = item;
    }
  }
  return result;
};

export const populateEditTesting = (array, keyId) => {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.id === keyId || element.location === keyId) {
        return element;
      }
    }
  }
};

export const formatManufacturers = (array) => {
  return array.map((item) => {
    return { ...item, name: item.name };
  });
};

export const formatDoses = (array) => {
  return array.map((item) => {
    return { ...item, name: item.name };
  });
};

export const formatCarrierFlights = (array) => {
  if (array.length) {
    let list = array.map((item) => ({
      ...item,
      value: item.name,
    }));
    return list;
  } else return [];
};
