import React, { Component } from "react";
import { ModalWindow } from "../modal";
import { AVHeader } from "./AVheader";
import { AVBody } from "./AVBody";
import { CustomButton } from "../../commons/buttons";
import { toastNotification } from "../toastNotification";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { latestReportConst } from "../../../constants/text-constants";
import { errorHandler } from "../../../utils/commonErrorHandler";

let { confirmation } = latestReportConst.addContactTexts;

export default class AddVaccineModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,
      locationDetails: false,
      editToDelete: "",
      showVaccine: ""
    };
  }
  componentDidMount() {
    this.onSelfReportPreview();
  }
  componentWillUnmount() {
    this.props.handleChangeForm({ type: "reset", formType: "vaccine" });
  }

  onSelfReportPreview = () => {
    if (this.props.selfReportVariables) {
      let { vaccinated_date, lot, dosage, manufacturer, is_reminded, vaccine_id } = this.props.selfReportVariables;

      let parentName = "addVaccineForm";
      this.handleFormChange({ parentName, name: 'is_reminded', value: is_reminded })
      this.handleFormChange({ parentName, name: 'lot', value: lot })
      this.handleFormChange({ parentName, name: 'vaccinated_date', value: vaccinated_date })
      this.handleFormChange({ parentName, name: 'manufacturer', value: manufacturer })
      this.handleFormChange({ parentName, name: 'dosage', value: dosage })
    }
  };

  onDeleteVaccine = (params) => {
    let { id } = params;
    this.props.deleteUserVaccinesAction({ id }, (res) => {
      if (res.status === 204) {
        toastNotification({
          msg: "Succesfully Deleted Record",
          type: "success",
        });
        this.props.getUserInfoAction();
        this.props.handleChangeForm({ type: "reset", formType: "vaccine" });
        this.props.listUserVaccinesAction();
      } else {
        toastNotification({
          msg: "Failed to Deleted Record ",
          type: "error",
        });
      }
    });
  };
  onEditVaccine = (params) => {
    let { id } = params;
    this.setState({
      editToDelete: id,
    });
    this.props.handleChangeForm({ mode: "edit", id, formType: "vaccine" });
  };

  onViewVaccine = (params) => {
    let { id } = params;
    this.setState({
      showVaccine: id,
    });
    this.toggleVaccineViewModal()
  };

  formatBody = (formBody) => {
    let body = {};
    let valid = true;
    let { vaccineManufacturersListing, vaccineDosesListing, vaccineFormMode } = this.props.reducer;

    for (const key in formBody) {
      if (formBody.hasOwnProperty(key)) {
        const element = formBody[key];
        /**here we get the flight id from the name */
        /**function to highlight missed out field */
        if (key === "file_upload"
          && !(element instanceof Blob) && element !== 'None' && element !== '') {
          continue
        }
        if ((key === 'manufacturer' || key === 'lot' || key === 'dosage' || key === 'location' || key === 'file_upload')
          && element === '') {
          this.props.handleChangeForm({
            validate: true,
            name: key,
            type: "vaccine",
          });
          valid = false;
        } else {
          if ((key === 'manufacturer' || key === 'lot' || key === 'dosage') && element !== '' && element !== 'None') {
            this.props.handleChangeForm({
              valid: true,
              name: key,
              type: "vaccine",
            });
          }
          if (key === "manufacturer") {
            for (const pos in vaccineManufacturersListing.list) {
              if (vaccineManufacturersListing.list.hasOwnProperty(pos)) {
                const manufacturer = vaccineManufacturersListing.list[pos];
                if (manufacturer.name === element) {
                  body[key] = manufacturer.id;
                }
              }
            }
          } else if (key === "dosage") {
            for (const pos in vaccineDosesListing.list) {
              if (vaccineDosesListing.list.hasOwnProperty(pos)) {
                const dosage = vaccineDosesListing.list[pos];
                if (dosage.name === element) {
                  body[key] = dosage.id;
                }
              }
            }
          } else if (key === "vaccinated_date") {
            body[key] = apiDateFormat(element.length ? element : new Date());
          } else body[key] = element;
        }
      }
    }

    if (formBody['file_upload'] && formBody['file_upload'] === 'None') {
      body['empty_file'] = true;
      valid = false;
      delete body['file_upload'];
    }

    return { body, valid };
  };

  isDuplicate = (body) => {
    let vaccineList = this.props.reducer.addedVaccineList;
    let result = 0;
    vaccineList.map((iter) => {
      if (
        body.vaccine === iter.vaccine.id
        // body.vaccine === iter.vaccine.id &&
        // body.flight_no === iter.flight_no &&
        // body.date_journey === iter.date_journey
      ) {
        result++;
      }
    });
    return result === 0 ? false : true;
  };

  callAddVaccineApi = () => {
    let { addVaccineForm } = this.props.reducer;

    let res = this.formatBody(addVaccineForm);
    let { body, valid } = res;

    if (valid) {
      const formData = new FormData();

      for (const key in body) {
        formData.append(key, body[key]);
      }
      this.props.addUserVaccinesAction(formData, (res) => {
        if (res.status === 201) {
          toastNotification({
            msg: "Added Record Successfully",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listUserVaccinesAction();
          this.props.handleChangeForm({
            type: "reset",
            formType: "vaccine",
          });
        } else {
          toastNotification({
            msg: `Added Record Failed :: ${errorHandler(res.data)}}`,
            type: "error",
          });
        }
      });
    }
  };

  callEditVaccineApi = () => {
    let { editVaccineForm } = this.props.reducer;
    let res = this.formatBody(editVaccineForm);
    let { body, valid } = res;
    if (valid) {
      const formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }
      this.props.updateUserVaccinesAction(formData, (res) => {
        if (res.status === 200) {
          toastNotification({
            msg: "Update Vaccine Success",
            type: "success",
          });
          this.props.getUserInfoAction();
          this.props.listUserVaccinesAction();
          this.props.handleChangeForm({
            type: "reset",
            formType: "vaccine",
          });
        }
      });
    }
  };
  submitAddVaccine = () => {
    let { vaccineFormMode } = this.props.reducer;
    if (vaccineFormMode === "add") this.callAddVaccineApi();
    else if (vaccineFormMode === "edit") this.callEditVaccineApi();
  };

  handleFormChangedLocation = (params) => {
    let { vaccineFormMode } = this.props.reducer;
    const { name, value } = params;
    let parentName = "";
    if (vaccineFormMode === "add") {
      parentName = "addVaccineForm";
    } else if (vaccineFormMode === "edit") {
      parentName = "editVaccineForm";
    }
    this.props.handleChangeForm({ parentName, name, value });
  };

  handleChangeLocationForm = (params) => {
    this.handleFormChange({ ...params, type: "latLng" });
  };

  handleFormChange = (params) => {
    let { vaccineFormMode } = this.props.reducer;
    const { name, value, type } = params;
    let parentName = "";
    if (vaccineFormMode === "add") {
      parentName = "addVaccineForm";
    } else if (vaccineFormMode === "edit") {
      parentName = "editVaccineForm";
    }

    this.props.handleChangeForm({ parentName, name, value, type });
  };

  addNewVaccinesToList = () => {
    let { addVaccineForm } = this.props.reducer;
    this.props.handleChangeForm({
      type: "addToList",
      name: "newAddedVaccineList",
      listName: "addedVaccineList",
      value: addVaccineForm,
    });
  };

  toggleVaccineLocationModal = () => {
    let { showSubVaccineLocationModal } = this.props.reducer;
    this.props.handleChangeForm({
      name: "showSubVaccineLocationModal",
      value: !showSubVaccineLocationModal,
    });
  };

  toggleVaccineViewModal = () => {
    let { showVaccineViewModal } = this.props.reducer;
    this.props.handleChangeForm({
      name: "showVaccineViewModal",
      value: !showVaccineViewModal,
    });
  };

  confirmationModal = () => {
    let { vaccineFormMode, addVaccineForm, editVaccineForm } = this.props.reducer;
    let data = vaccineFormMode === "add" ? addVaccineForm : editVaccineForm;
    if (data.Vaccine_no !== "") {
      this.setState({
        confirmationModal: true,
      });
    } else {
      this.props.toggleModal();
    }
  };

  onBacktoEdit = () => {
    this.setState({ confirmationModal: false });
  };

  close = () => {
    this.props.toggleModal();
    this.setState({ confirmationModal: false });
  };

  submit = () => {
    this.setState({ confirmationModal: false }, () => {
      this.submitAddVaccine();
    });
  };

  render() {
    let { showModal } = this.props;
    let { confirmationModal, editToDelete, showVaccine } = this.state;

    return (
      <>
        <ModalWindow
          showModal={showModal}
          onModalClose={this.confirmationModal}
          heading={<AVHeader toggleModal={this.confirmationModal} />}
          body={
            <AVBody
              {...this.props}
              editToDelete={editToDelete}
              showVaccine={showVaccine}
              // handleFormChangedLocation={this.handleFormChangedLocation}
              handleChangeLocationForm={this.handleChangeLocationForm}
              handleFormChange={this.handleFormChange}
              submitAddVaccine={this.submitAddVaccine}
              onDeleteVaccine={this.onDeleteVaccine}
              onEditVaccine={this.onEditVaccine}
              onViewVaccine={this.onViewVaccine}
              addNewVaccinesToList={this.addNewVaccinesToList}
              toggleLocationModal={this.toggleVaccineLocationModal}
              toggleViewModal={this.toggleVaccineViewModal}
              closeModal={this.confirmationModal}
            />
          }
        />
        <ModalWindow
          showModal={confirmationModal}
          onModalClose={this.onBacktoEdit}
          heading={
            <AVHeader toggleModal={this.onBacktoEdit} title={confirmation} />
          }
          body={
            <div className="text-center">
              <h6 className="modal-text mt-3">
                Do you want to Submit the Changes?
              </h6>
              <div class="row mt-4 text-center w-100 m-auto edit-sub-modal">
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Submit"
                    clickEvent={this.submit}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                    text="Don't Submit"
                    clickEvent={this.close}
                  ></CustomButton>
                </div>
                <div class="col-sm-4">
                  <CustomButton
                    text="Close"
                    className=" btn btn-block btn-submit fnt-14 SFProText-Regular"
                    clickEvent={this.onBacktoEdit}
                  ></CustomButton>
                </div>
              </div>
            </div>
          }
        ></ModalWindow>
      </>
    );
  }
}
