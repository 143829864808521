import React, { Component } from 'react';
import ReactDOM from 'react-dom';
/**
 * Component that alerts if you click outside of it
 */
export default class CatchoutsideEvent extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this.props.targetId);
    if (!domNode || !domNode.contains(event.target)) {
       alert('inside')
    }
}

  render() {
    return <div id={this.props.targetId}>{this.props.children}</div>;
  }
}
