import React, { Component } from "react";
import AddContactModal from "../commons/AddContactModal/addcontactModal";
import AddFlightModal from "../commons/AddFlightModal/addFlightModal";
import AddLocationModal from "../commons/AddLocationModal/addLocationModal";
import AddTestingModal from "../commons/AddTestingModal/addTestingModal";
import AddVaccineModal from "../commons/AddVaccineModal/addVaccineModal";
import AddOrganizationModal from "../commons/AddOrganizationModal/addOrganizationModal"

export default class SelfReportModals extends Component {
  render() {
    let {
      flightFormConfig,
      addFlightTableConf,
      addedFlightList,
      showFlightModal,
      showContactModal,
      showTestingModal,
      showSubTestingLocationModal,
      showTestingViewModal,
      addTestingConfig,
      addTestingForm,
      addTestingTableConf,
      addedTestingList,
      showVaccineModal,
      showSubVaccineLocationModal,
      showVaccineViewModal,
      showOrganizationModal,
      showOrganizationViewModal,
      addVaccineConfig,
      locationDetails,
      showSubContactModal,
      showLocationModal,
      addContactConfig,
      addFlightForm,
      addContactForm,
      addVaccineForm,
      addContactTableConf,
      addVaccineTableConf,
      addedContactList,
      addedVaccineList,
      isDisabledVaccineSubmitBtn,
      isDisabledTestingSubmitBtn
    } = this.props.latestReportReducer;

    let {selfReportVariables} = this.props.reducer

    return (
      <>
        {showTestingModal && (
          <AddTestingModal
            showModal={showTestingModal}
            handleChangeForm={this.props.handleChangePopups}
            getUserInfoAction={this.props.getUserInfoAction}
            deleteUserTestingsAction={this.props.deleteUserTestingsAction}
            updateUserTestingsAction={this.props.updateUserTestingsAction}
            toggleModal={() => this.props.toggleModal("showTestingModal")}
            showSubTestingLocationModal={showSubTestingLocationModal}
            showTestingViewModal={showTestingViewModal}
            selfReportVariables={selfReportVariables}
            testingFormConfig={addTestingConfig}
            addUserTestingsAction={this.props.addUserTestingsAction}
            listUserTestingsAction={this.props.listUserTestingsAction}
            addTestingForm={addTestingForm}
            addedTestingList={addedTestingList}
            addTestingTableConf={addTestingTableConf}
            isDisabledTestingSubmitBtn={isDisabledTestingSubmitBtn}
            reducer={this.props.latestReportReducer}
          />
        )},
        {showVaccineModal && (
           <AddVaccineModal
            showModal={showVaccineModal}
            handleChangeForm={this.props.handleChangePopups}
            getUserInfoAction={this.props.getUserInfoAction}
            locationDetails={locationDetails}
            listUserVaccinesAction={this.props.listUserVaccinesAction}
            getDosesListAction={this.props.getDosesListAction}
            getManufacturersListAction={this.props.getManufacturersListAction}
            deleteUserVaccinesAction={this.props.deleteUserVaccinesAction}
            updateUserVaccinesAction={this.props.updateUserVaccinesAction}
            addUserVaccinesAction={this.props.addUserVaccinesAction}
            toggleModal={() => this.props.toggleModal("showVaccineModal")}
            showSubVaccineLocationModal={showSubVaccineLocationModal}
            showVaccineViewModal={showVaccineViewModal}
            selfReportVariables={selfReportVariables}
            vaccineFormConfig={addVaccineConfig}
            addVaccineForm={addVaccineForm}
            addedVaccineList={addedVaccineList}
            addVaccineTableConf={addVaccineTableConf}
            isDisabledVaccineSubmitBtn={isDisabledVaccineSubmitBtn}
            reducer={this.props.latestReportReducer}
         />
        )}
        {showContactModal && (
          <AddContactModal
            showModal={showContactModal}
            getUserInfoAction={this.props.getUserInfoAction}
            locationDetails={locationDetails}
            handleChangeForm={this.props.handleChangePopups}
            updateUserContactAction={this.props.updateUserContactAction}
            addUserContactsAction={this.props.addUserContactsAction}
            listUserContactsAction={this.props.listUserContactsAction}
            deleteUserContactsAction={this.props.deleteUserContactsAction}
            toggleModal={() => this.props.toggleModal("showContactModal")}
            showSubContactModal={showSubContactModal}
            addContactConfig={addContactConfig}
            addContactForm={addContactForm}
            addContactTableConf={addContactTableConf}
            addedContactList={addedContactList}
            reducer={this.props.latestReportReducer}
          />
        )}
        {showFlightModal && (
          <AddFlightModal
            showModal={showFlightModal}
            handleChangeForm={this.props.handleChangePopups}
            getUserInfoAction={this.props.getUserInfoAction}
            deleteUserFlightsAction={this.props.deleteUserFlightsAction}
            updateUserFlightsAction={this.props.updateUserFlightsAction}
            toggleModal={() => this.props.toggleModal("showFlightModal")}
            flightFormConfig={flightFormConfig}
            listFlightCarriersAction={this.props.listFlightCarriersAction}
            addUserFlightsAction={this.props.addUserFlightsAction}
            listFlightActions={this.props.listFlightActions}
            addFlightForm={addFlightForm}
            addedFlightList={addedFlightList}
            addFlightTableConf={addFlightTableConf}
            reducer={this.props.latestReportReducer}
          />
        )}
        {showLocationModal && (
          <AddLocationModal
            newUserLocation={this.props.newUserLocation}
            getUserInfoAction={this.props.getUserInfoAction}
            deleteUserLocation={this.props.deleteUserLocation}
            editUserLocation={this.props.editUserLocation}
            showModal={showLocationModal}
            handleFormChange={this.props.handleChangeAddLocForm}
            listLocationAction={this.props.listLocationAction}
            handleChange={this.props.handleChangePopups}
            getAllTravelLocation={this.props.getAllTravelLocation}
            addLocationAction={this.props.addLocationAction}
            toggleModal={() => this.props.toggleModal("showLocationModal")}
            reducer={this.props.latestReportReducer}
          />
        )}
          {showOrganizationModal && (
           <AddOrganizationModal
            showModal={showOrganizationModal}
                        showOrganizationViewModal={showOrganizationViewModal}
            isDisabledVaccineSubmitBtn={isDisabledVaccineSubmitBtn}
                        toggleModal={() => this.props.toggleModal("showOrganizationModal")}
            reducer={this.props.latestReportReducer}
         />
        )}
      </>
    );
  }
}
