import React from "react";

export const TextInput = (params) => {
  let {
    id,
    name,
    value,
    type,
    pattern,
    placeholder,
    className,
    handleChange,
    keypressEvent,
    validation,
    disabled
  } = params;
  
  const validateField = () => {
    if (validation !== undefined) {
      if (validation) return "";
      else return "border-red";
    }
  };
  return (
    <input
      type={type}
      id={id}
      disabled={disabled}
      data-date-format="DD MMMM YYYY"
      placeholder={placeholder}
      className={`${className} ${validateField()}`}
      onKeyDown={keypressEvent}
      pattern={pattern}
      onChange={(e) => {
        if (pattern) {
          if (e.target.validity.valid)
            handleChange({ name: e.target.name, value: e.target.value });
        } else handleChange({ name: e.target.name, value: e.target.value });
      }}
      name={name}
      value={!disabled ? value : ''}
    />
  );
};
