/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { APP_BASE_NAME } from "../../../constants/configurations";
import { toastNotification } from "../../commons/toastNotification";
import OtpRightPane from "./otpRightPane";
import OtpHeader from "./otpHeader";

/**constants */
import {
  optText,
  mobileText,
  loginPageConst,
  continueText,
} from "../../../constants/text-constants";
import { checkFieldValid } from "./utils";
import GlobalHeader from "../../GlobalHeader";
import { errorHandler } from "../../../utils/commonErrorHandler";
let {
  enterCodeText,
  enterPhoneText,
  enterCodePlaceholder,
  secureText,
  reallyYouText,
  enterPhonePlaceholder,
  noRecieveText,
  termsAndConditionText,
  wrongNumberText,
  editText,

  resendText,
} = loginPageConst;

export const OtpPage = (props) => {
  /**getting user mobile number */
  let info = localStorage.getItem("phone_number");
  let phoneNumber = info && info.length ? info : "";

  const [mode, setMode] = useState(phoneNumber.length ? "code" : "phonenumber");
  const [formItems, setFormItems] = useState({
    phone_number: phoneNumber,
    otp: "",
  });
  /**re-send opt api call */
  const reSend = () => {
    let { phone_number } = formItems;
    props.resendOtpAction(
      {
        mobile_number: phone_number,
      },
      (response) => {
        if (response.status === 200) {
          setMode("code");
        } else {
          toastNotification({
            msg: `${errorHandler(response.data)}`,
            type: "error",
          });
        }
      }
    );
  };

  const getVerificationCode = () => {
    let body = {};
    body.mobile_number = formItems.phone_number;
    body.email = localStorage.getItem("email");
    props.changePhoneNumberAction(body, (response) => {
      if (response.status === 200) {
        setMode("code");
      } else
        return toastNotification({
          msg: `${errorHandler(response.data)}`,
          type: "error",
        });
    });
  };
  /**when user clicks submit otp */
  const handleSubmit = (event) => {
    event.preventDefault();
    let { phone_number, otp } = formItems;
    if (mode === "phonenumber") {
      if (checkFieldValid(phone_number)) {
        getVerificationCode();
      }
    } else {
      /**actual otp api call  */
      if (checkFieldValid(otp))
        props.otpAction(formItems, (response) => {
          if (response.status === 200) {
            props.history.push({ pathname: `${APP_BASE_NAME}/login` });
          } else
            return toastNotification({
              msg: `${errorHandler(response.data)}`,
              type: "error",
            });
        });
    }
  };

  const handleChangeForm = (params) => {
    let { name, value } = params;
    setFormItems({ ...formItems, [name]: value });
  };

  const formInputBlock = (config) => {
    let { text, placeholder, name, pattern } = config;
    return (
      <div className="col-sm-12 pull-left">
        <div className="row">
          <h6 className="fnt-12 text-white">{text}</h6>
          <input
            type="text"
            value={formItems[name]}
            name={name}
            pattern={pattern}
            onChange={(e) => {
              if (pattern) {
                e.target.validity.valid &&
                  handleChangeForm({
                    name: e.target.name,
                    value: e.target.value,
                  });
              } else
                handleChangeForm({
                  name: e.target.name,
                  value: e.target.value,
                });
            }}
            class="w-100 p-2 rounded mb-3 nav-p self-inp2 form-control"
            placeholder={placeholder}
          />
        </div>
      </div>
    );
  };
  const changeFormMode = (name) => {
    setMode(name);
  };
  const formSubmitButton = () => {
    return (
      <div className="w-100 pull-left">
        <button
          type="submit"
          className="btn pl-4 pr-4 btn-submit w-100 fnt-14 Gilroy-Semibold"
        >
          {continueText}
        </button>
      </div>
    );
  };
  const formFooter = () => {
    return (
      <>
        <div className="text-center w-100 fnt-13 ald-pad text-white pull-left w-100 mb-0">
          {noRecieveText}{" "}
          <a onClick={reSend} className="color clr-blue link-style">
            {resendText}
          </a>
        </div>
        <div className="text-center w-100 fnt-13 ald-pad2 text-white pull-left w-100">
          {wrongNumberText}{" "}
          <a
            className="color clr-blue link-style"
            onClick={() => changeFormMode("phonenumber")}
          >
            {editText}
          </a>
        </div>
        <div className="text-center w-100 fnt-11 nav-p terms-wdt m-auto w-100 d-table">
          {termsAndConditionText}
        </div>
      </>
    );
  };
  const formTitle = () => {
    let { phone_number } = formItems;
    return (
      <div className="title-head p-0 code-p">
        <div className="">
          <h4>{reallyYouText}</h4>
          {mode === "code" ? optText(phone_number) : mobileText()}
        </div>
      </div>
    );
  };

  const getFormMode = () => {
    if (mode === "code")
      return {
        name: "otp",
        text: enterCodeText,
        placeholder: enterCodePlaceholder,
      };
    else if (mode === "phonenumber")
      return {
        name: "phone_number",
        pattern: "[0-9+]*",
        text: enterPhoneText,
        placeholder: enterPhonePlaceholder,
      };
  };
  let formMode = getFormMode();

  return (
    <React.Fragment>
      <GlobalHeader noRedirection={true} />
      <div className="body-wrapper2 bodyWrapper-pd">
        <OtpHeader />
        <div className="login-wrapper signup-page">
          <div className="signup-wrapper log-main m-auto w-100 d-table h-100">
            {secureText}
            <div className="col-md-8 pull-left">
              <form
                onSubmit={handleSubmit}
                className="form-wrapper pull-left w-100"
              >
                {formTitle()}
                {formInputBlock(formMode)}
                {formSubmitButton()}
                {mode === "code" && formFooter()}
              </form>
            </div>
            <OtpRightPane />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
