import { connect } from "react-redux";

/**importing actions */
import {
  handleChangeAccessRisk,
  addUnauthuserLocation,
} from "../../redux/actions";

/**importing components */
import QuickAccessRisk from "./quickAccessRisk";

// const Counter = ...

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    reducer: state.accessRiskReducer,
  };
};

const componentActions = (dispatch) => {
  return {
    handleChange: (params) => {
      dispatch(handleChangeAccessRisk(params));
    },
  };
};

const apiActions = (dispatch) => {
  return {

    addUnauthuserLocation: (params, next) => {
      dispatch(addUnauthuserLocation(params, next));
    },

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...componentActions(dispatch),
    ...apiActions(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccessRisk);
