/**
 * reducer for home page
 */
import { createReducer } from "@reduxjs/toolkit";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { defaultLatLng } from "../../../constants/configurations";

const google = window.google;

const selfReportVariablesInit = {
  id: "",
  disease: "covid19",
  is_tested: "No",
  tested_date: apiDateFormat(),
  test_result: "Positive",
  symptoms: [],
  data_started: apiDateFormat(),
  status: "Never Infected",
  port: "",
  testing_id: "",
  is_vaccinated: "No",
  vaccinated_date: apiDateFormat(),
  manufacturer: [],
  lot: "",
  dosage: "",
  is_reminded: true,
  vaccine_id: ""
};
const INITIAL_STATE = {
  graphSettings: {
    radius: 10,
    centerLocation: defaultLatLng,
    circleApex: new google.maps.LatLng(39.3958, -101.0524),
    selectedLocation: "10.850516,76.27108",
  },
  fetchUserDetails: { loading: false, list: [], isCreated: false },
  selfReportVariables: {
    id: "",
    disease: "covid19",
    is_tested: "No",
    testing_id: "",
    tested_date: apiDateFormat(),
    test_result: "Positive",
    symptoms: [],
    data_started: apiDateFormat(),
    status: "Never Infected",
    port: "",
    startDate: "",
    is_vaccinated: "No",
    vaccine_id: "",
    vaccinated_date: apiDateFormat(),
    manufacturer: "",
    lot: "",
    dosage: "",
    is_reminded: true,
  },
  diseaseList: [{ name: "covid19", id: 1 }],
  selfReportForm: [
    {
      id: 1,
      label: "Type of disease",
      heading: "Disease",
      labelClass: "fnt-12 text-white",
      isMulti: true,
      type: "select",
      className: "form-control self-inp fnt-12",
      col: "col-lg-12 pull-left dis-space",
      name: "disease",
      selectOptions: [{ name: "covid19", id: 1 }],
    },
    {
      id: 2,
      label: "Have you been tested for Covid-19?",
      labelClass: "fnt-12 text-white",
      heading: "Testing",
      type: "select",
      disabledLabel: "Yes",
      isDisabled: false,
      className: "form-control self-inp fnt-12 drop-font",
      col: "col-lg-6 pull-left",
      name: "is_tested",
      selectOptions: [
        { name: "Yes", id: 1 },
        { name: "No", id: 2 },
      ],
    },
    {
      id: 3,
      label: "Test Date",
      labelClass: "fnt-12 text-white",
      type: "date",
      isDisabled: true,
      isValid: true,
      pickerClassName: "testing-date-picker",
      className: "form-control date-input self-inp fnt-12",
      col: "col-lg-6 pull-left test-date-position",
      placeholder: "Date",
      name: "tested_date",
    },
    {
      id: 4,
      label: "Test Result?",
      labelClass: "fnt-12 text-white",
      type: "select",
      isDisabled: true,
      isValid: true,
      className: "form-control self-inp fnt-12",
      col: "col-lg-12 pull-left",
      name: "test_result",
      selectOptions: [
        { name: "Negative", id: 1 },
        { name: "Positive", id: 2 },
      ],
    },
    {
      id: 9,
      btnText: "Attach",
      btnClass: "btn text-capitalize fnt-14",
      type: "modalBtn",
      isDisabled: true,
      className: "form-control self-inp-text fnt-12",
      col: "col-lg-6 offset-lg-6",
      name: "addedTestingList",
    },
    {
      id: 10,
      label: "Have you received the Covid-19 vaccine?",
      labelClass: "fnt-11 text-white",
      heading: "Vaccine",
      type: "select",
      disabledLabel: "Yes",
      isDisabled: false,
      className: "form-control self-inp fnt-12 drop-font",
      col: "col-lg-6 pull-left",
      name: "is_vaccinated",
      selectOptions: [
        { name: "Yes", id: 1 },
        { name: "No", id: 2 },
      ],
    },
    {
      id: 11,
      label: "Vaccination date",
      labelClass: "fnt-12 text-white",
      type: "date",
      isDisabled: true,
      isValid: true,
      pickerClassName: "testing-date-picker",
      className: "form-control date-input self-inp fnt-12",
      col: "col-lg-6 pull-left test-date-position",
      placeholder: "Date",
      name: "vaccinated_date",
    },
    {
      id: 12,
      label: "Vaccine Manufacturer?",
      labelClass: "fnt-12 text-white",
      type: "select",
      isDisabled: true,
      isValid: true,
      className: "form-control self-inp fnt-12",
      col: "col-lg-6 pull-left",
      name: "manufacturer",
      selectOptions: []
    },
    {
      id: 13,
      label: "Lot #",
      labelClass: "fnt-12 text-white",
      type: "text",
      isDisabled: true,
      isValid: true,
      className: "form-control self-inp-text fnt-12",
      col: "col-lg-6 pull-left",
      name: "lot",
    },
    {
      id: 14,
      label: "Which Dosage?",
      labelClass: "fnt-12 text-white",
      type: "select",
      isDisabled: true,
      isValid: true,
      className: "form-control self-inp fnt-12",
      col: "col-lg-6 pull-left",
      name: "dosage",
      width: "5rem",
      selectOptions: []
    },
    {
      id: 15,
      label: "Would you like a reminder?",
      labelClass: "fnt-12 text-white",
      type: "checkbox",
      isDisabled: true,
      className: "form-control self-inp fnt-12",
      col: "col-lg-6 pull-left",
      name: "is_reminded",
    },
    {
      id: 16,
      btnText: "Attach",
      btnClass: "btn text-capitalize fnt-14",
      type: "modalBtn",
      isDisabled: true,
      className: "form-control self-inp-text fnt-12",
      col: "col-lg-6 offset-lg-6",
      name: "addedVaccineList",
    },
    {
      id: 5,
      heading: "Symptoms",
      label: "Are you experiencing any symptoms? (Select all that apply)",
      isMulti: true,
      labelClass: "fnt-12 text-white",
      // isDisabled: true,
      type: "customCheckbox",
      className:
        "form-control self-inp position-relative text-left fnt-12 h-35",
      col: "col-lg-12 pull-left",
      name: "symptoms",
      selectOptions: [],
    },
    {
      id: 6,
      label: "When did you begin to experience symptoms",
      labelClass: "fnt-12 text-white w-100",
      type: "date",
      // isDisabled: true,
      pickerClassName: "sample class 1",
      className: "form-control date-input self-inp fnt-12",
      col: "col-lg-12 pull-left",
      placeholder: "Date",
      name: "data_started",
    },
    {
      id: 7,
      heading: "Status",
      label: "How would you like to Self Report",
      labelClass: "fnt-12 text-white",
      type: "select",
      // isDisabled: true,
      disabledLabel: "Never Infected",
      className: "form-control self-inp fnt-12",
      col: "col-lg-12 pull-left",

      name: "status",
      selectOptions: [],
    },
    {
      id: 8,
      label: "Current status",
      labelClass: "fnt-12 text-white",
      type: "select",
      className: "form-control self-inp2 height-38",
      col: "col-lg-12 pull-left",
      placeholder: "port",
      name: "port",
      // isDisabled: true,
      selectOptions: [
        { name: "Active", id: 1 },
        { name: "Recovered", id: 2 },
        { name: "Deceased", id: 3 },
      ],
    },
  ],
  symptomsListing: {
    loading: false,
    list: [],
  },
  statusListing: {
    loading: false,
    list: [],
  },
  vaccineManufacturersListing: {
    loading: false,
    list: [],
  },
  vaccineDosesListing: {
    loading: false,
    list: [],
  },
};
const findPostion = (name, array) => {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.name === name) {
        return key;
      }
    }
  }
};

const populateUserReport = (state, response) => {
  for (const key in response) {
    if (response.hasOwnProperty(key)) {
      const element = response[key];
      if (key === "symptoms" && element) {
        state.selfReportVariables[key] = element;
      } else if (key === "disease") {
        state.selfReportVariables[key] = "covid19";
      } else if (key === "status" && element) {
        state.selfReportForm = enableCurrentStatus(state, element.status);
        state.selfReportVariables[key] = element.status;
      } else if (key === "is_tested") {
        let value = element ? "Yes" : "No";
        state.selfReportForm = enableDate(state, value);
        state.selfReportVariables[key] = value;
        // } else if (key === "test_result") {
        //   state.selfReportVariables = disableAllFields(state, element);
        //   state.selfReportForm = toggleSymtomsAndStatus(state, element);
        //   state.selfReportVariables[key] = element;
      } else if (key === "is_vaccinated") {
        let value = element ? "Yes" : "No";
        state.selfReportForm = enableVaccinatedData(state, value);
        state.selfReportVariables[key] = value;
      } else if (key === 'vaccine' || key === 'testing') {
        state.selfReportVariables[key] = { ...element };
        fillSelfReportVariablesFromObj(state, key);
      } else if (element) {
        if (key === "data_started") {
          state.selfReportVariables.startDate = element;
          state.selfReportVariables.data_started = element;
        }
        state.selfReportVariables[key] = element;
      }
    }
  }
};
/**reducer operations when the fetch total covid info action called */
export const afterListSymptomsAction = () => {
  return {
    LIST_SYMPTOMS_STARTED: (state) => {
      state.symptomsListing.loading = true;
    },
    LIST_SYMPTOMS_SUCCESS: (state, action) => {
      state.symptomsListing.loading = false;
      state.symptomsListing.list = action.payload.response;
      let pos = findPostion("symptoms", state.selfReportForm);
      state.selfReportForm[pos].selectOptions = action.payload.response;
    },
    LIST_SYMPTOMS_FAILED: (state) => {
      state.symptomsListing.loading = false;
    },
  };
};
const formatStatus = (array) => {
  return array.map((item) => {
    return { ...item, name: item.status };
  });
};
/**reducer operations when the fetch total covid info action called */
export const afterListStatusAction = () => {
  return {
    LIST_STATUS_STARTED: (state) => {
      state.statusListing.loading = true;
    },
    LIST_STATUS_SUCCESS: (state, action) => {
      state.statusListing.loading = false;
      state.statusListing.list = formatStatus(action.payload.response);
      let pos = findPostion("status", state.selfReportForm);
      state.selfReportForm[pos].selectOptions = formatStatus(
        action.payload.response
      );
    },
    LIST_STATUS_FAILED: (state) => {
      state.statusListing.loading = false;
    },
  };
};
/**reducer operations when the fetch total covid info action called */
const afterListDosesAction = () => {
  return {
    LIST_DOSES_STARTED: (state) => {
      state.vaccineDosesListing.loading = true;
    },
    LIST_DOSES_SUCCESS: (state, action) => {
      state.vaccineDosesListing.loading = false;
      state.vaccineDosesListing.list = formatDoses(action.payload.response);
      let pos = findPosition("dosage", state.selfReportForm);
      state.selfReportForm[pos].selectOptions = formatDoses(
        action.payload.response
      );
    },
    LIST_DOSES_FAILED: (state) => {
      state.vaccineDosesListing.loading = false;
    },
  };
};
const afterListManufacturersAction = () => {
  return {
    LIST_MANUFACTURES_STARTED: (state) => {
      state.vaccineManufacturersListing.loading = true;
    },
    LIST_MANUFACTURES_SUCCESS: (state, action) => {
      state.vaccineManufacturersListing.loading = false;
      state.vaccineManufacturersListing.list = formatManufacturers(action.payload.response);
      let pos = findPosition("manufacturer", state.selfReportForm);
      state.selfReportForm[pos].selectOptions = formatManufacturers(
        action.payload.response
      );
    },
    LIST_MANUFACTURES_FAILED: (state) => {
      state.vaccineManufacturersListing.loading = false;
    },
  };
};


const afterGetUserReport = () => {
  return {
    FETCH_USERS_ACTION_STARTED: (state) => {
      state.fetchUserDetails.loading = true;
    },
    FETCH_USERS_ACTION_SUCCESS: (state, action) => {
      state.fetchUserDetails.loading = false;
      state.fetchUserDetails.list = action.payload.response;
      state.fetchUserDetails.isCreated = action.payload.response.length
        ? true
        : false;
      if (action.payload.response.length)
        populateUserReport(state, action.payload.response[0]);
      // else state.selfReportVariables = selfReportVariablesInit;
      /**will get a non -empty array if user already added */
    },
    FETCH_USERS_ACTION_FAILED: (state) => {
      state.fetchUserDetails.loading = false;
    },
  };
};

const toggleSymtomsAndStatus = (state, type) => {
  let result = state.selfReportForm.map((item) => {
    if (
      item.name === "symptoms" ||
      item.name === "data_started" ||
      item.name === "status"
    ) {
      return { ...item, isDisabled: type === "Negative" ? true : false };
    } else return { ...item };
  });
  return result;
};
const enableDate = (state, type) => {
  let result = state.selfReportForm.map((item) => {
    /**the below fields are toggled when user selects */
    if (
      item.name === "tested_date" ||
      item.name === "test_result" ||
      item.name === "addedTestingList"
      // item.name === "status" 
      // item.name === "symptoms" ||
      // item.name === "data_started"
    ) {
      return { ...item, isDisabled: type === "Yes" ? false : true };
    } else return { ...item };
  });
  return result;
};

const enableVaccinatedData = (state, type) => {
  let result = state.selfReportForm.map((item) => {
    /**the below fields are toggled when user selects */
    if (
      item.name === "vaccinated_date" ||
      item.name === "manufacturer" ||
      item.name === "lot" ||
      item.name === "dosage" ||
      item.name === "addedVaccineList" || 
      item.name === "is_reminded"
    ) {
      return { ...item, isDisabled: type === "Yes" ? false : true };
    } else return { ...item };
  });
  return result;
};

const enableCurrentStatus = (state, type) => {
  let resp = state.selfReportForm.map((item) => {
    if (item.name === "port") {
      state.selfReportVariables["port"] = "";
      return { ...item, isDisabled: type === "Never Infected" ? true : false };
    } else return { ...item };
  });
  return resp;
};

const selfReportFormReset = (state) => {
  state.selfReportVariables["symptoms"] = [];
  state.selfReportVariables["data_started"] = "";
  state.selfReportVariables["port"] = "";
  state.selfReportVariables["status"] = "Never Infected";
};
const disableAllFields = (state, type) => {
  if (type === "Negative") {
    state.selfReportVariables["data_started"] = apiDateFormat();
    state.selfReportVariables["tested_date"] = apiDateFormat();
    state.selfReportVariables["vaccinated_date"] = apiDateFormat();
    // selfReportFormReset(state);
    for (const key in state.selfReportVariables) {
      if (state.selfReportVariables.hasOwnProperty(key)) {
        state.selfReportForm = enableCurrentStatus(state, "Never Infected");
      }
    }
    // state.selfReportVariables = toggleSymtomsAndStatus(state, type);
  }
  return state.selfReportVariables;
};
const disableInfectedFields = (state, type) => {
  state.selfReportVariables["test_result"] = "Positive";
  if (type === "No") {
    // selfReportFormReset(state);
    for (const key in state.selfReportVariables) {
      if (state.selfReportVariables.hasOwnProperty(key)) {
        // const element = state.selfReportVariables[key];
        if (key === "status") {
          state.selfReportVariables["status"] = "Never Infected";
          state.selfReportForm = enableCurrentStatus(state, "Never Infected");
        }
      }
    }
  }

  return state.selfReportVariables;
};
const resetFormConfigs = (state, type) => {
  return state.selfReportForm.map((item) => {
    if (type === 'testing') {
      if (item.name === 'tested_date'
        || item.name === 'test_result'
        || item.name === 'addedTestingList') {
        return { ...item, isValid: true, isDisabled: true };
      }
    } else if (type === 'vaccine') {
      if (item.name === 'vaccinated_date'
        || item.name === 'manufacturer'
        || item.name === 'lot'
        || item.name === 'dosage'
        || item.name === 'addedVaccineList') {
        return { ...item, isValid: true, isDisabled: true };
      }
    }
    if (item.hasOwnProperty('isValid')) {
      return { ...item, isValid: true };
    }
    return { ...item };
  });
};

const findPosition = (name, array) => {
  for (const key in array) {
    if (array.hasOwnProperty(key)) {
      const element = array[key];
      if (element.name === name) {
        return key;
      }
    }
  }
};

const formatManufacturers = (array) => {
  return array.map((item) => {
    return { ...item, name: item.name };
  });
};

const formatDoses = (array) => {
  return array.map((item) => {
    return { ...item, name: item.name };
  });
};

const enableVaccineDosage = (state, type) => {
  let pos = findPosition("dosage", state.selfReportForm);
  state.vaccineManufacturersListing.list.map((manufacturer) => {
    if (manufacturer.name === type) {
      let dosage = manufacturer.requirement_dose
      state.selfReportForm[pos].selectOptions = state.vaccineDosesListing.list.filter((dose) => {
        if (dose.id === dosage || dose.parent === dosage) {
          return dose
        }
      })
    }
  });
}

const fillSelfReportVariablesFromObj = (state, objName) => {
  const fieldObj = state.selfReportVariables[objName]

  if (Object.keys(fieldObj).length === 0 && fieldObj.constructor === Object) {
    return true
  }

  Object.keys(fieldObj).forEach(function (key) {
    if (state.selfReportVariables.hasOwnProperty(key)) {
      if (Object.keys(fieldObj[key]).length !== 0 && fieldObj[key].constructor === Object && fieldObj[key].hasOwnProperty('name')) {
        state.selfReportVariables[key] = fieldObj[key].name
      } else if (key === 'id' && objName === 'vaccine') {
        state.selfReportVariables['vaccine_id'] = fieldObj[key];
      } else if (key === 'id' && objName === 'testing') {
        state.selfReportVariables['testing_id'] = fieldObj[key];
      } else {
        state.selfReportVariables[key] = fieldObj[key]
      }
    }
  });
}

const toggleDisablingField = (state, field, status) => {
  return state.selfReportForm.map((item) => {
    /**the below fields are toggled when user selects */
    if (item.name === field) {
      return { ...item, isDisabled: status };
    } else return { ...item };
  });
}

const validateField = (config, name, status) => {
  let arrays = config.map((item) => {
    if (item.name === name) {
      return { ...item, isValid: status };
    } else return { ...item };
  });
  return arrays;
};

const checkIfFieldsValid = (state, params) => {
  let { name } = params;
  state.selfReportForm = validateField(state.selfReportForm, name, false);
};

const componentActions = () => {
  return {
    HANDLE_CHANGE_SELF_REPORT: (state, action) => {
      let { payload } = action;
      /**the following actions are just to do operations in check box in case of form builder
       * add and deleting check box items
       */

      if (payload.type === "customCheckbox") {
        if (payload.operation === "add") {
          state[payload.parentName][payload.name].push(payload.value);
        } else {
          let valueArray = state[payload.parentName][payload.name];
          state[payload.parentName][payload.name] = valueArray.filter(
            (elem) => {
              return elem.id !== payload.value;
            }
          );
        }
      } else {
        // if (payload.name === "test_result") {
        //   state.selfReportVariables = disableAllFields(state, payload.value);
        //   state.selfReportForm = toggleSymtomsAndStatus(state, payload.value);
        // }
        if (payload.name === "status") {
          state.selfReportForm = enableCurrentStatus(state, payload.value);
        }

        if (payload.name === 'manufacturer') {
          state.selfReportVariables.vaccine_dosage = "";
          enableVaccineDosage(state, payload.value);
        }

        if (!payload.disabled && payload.name === "is_tested") {
            if (payload.value === 'Yes') {
              state.selfReportForm = enableDate(state, payload.value);
            } else {
              state.selfReportForm = resetFormConfigs(state, 'testing')
            }
        }

        if (!payload.disabled && payload.name === "is_vaccinated") {
            if (payload.value === 'Yes') {
              state.selfReportForm = enableVaccinatedData(state, payload.value);
            } else {
              state.selfReportForm = resetFormConfigs(state, 'vaccine')
            }
        }

        if (payload.validate) {
          checkIfFieldsValid(state, { name: payload.name });
        } else if (payload.disabled) {
          let field = state.selfReportForm.find(o => o.name === payload.name);
          field.isDisabled = payload.value;
        } else if (payload.type === "reset") {
          state.selfReportForm = resetFormConfigs(state);
        } else {
          state[payload.parentName][payload.name] = payload.value;
        }
      }
    },
  };
};

export default createReducer(INITIAL_STATE, {
  ...componentActions(),
  ...afterGetUserReport(),
  ...afterListSymptomsAction(),
  ...afterListStatusAction(),
  ...afterListDosesAction(),
  ...afterListManufacturersAction(),
});
