// import React from "react";
export const errorHandler = (resp) => {
  if(resp){
    let resKeys = Object.keys(resp);
    let lastObject = resKeys[resKeys.length - 1];
    let message = "";
    for (const key in resp) {
      if (resp.hasOwnProperty(key)) {
        const element = resp[key];
        message += `${key} : ${element[0]}`;
        if (key !== lastObject) {
          message += "    \n";
        }
      }
    }
  
    return message;
  }
  else return 'something went wrong'
};
