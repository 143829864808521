import { simpleApiCalls } from "../../../../services/simpleApiCalls";
import {
  GET_GOOGLE_STATE_LOCATION_SUCCESS,
  GET_GOOGLE_STATE_LOCATION_FAILED,
  GET_GOOGLE_STATE_LOCATION_STARTED,
} from "../../../../constants/action-types";


export const getStateLocation = (body, callBack) => {
  return (dispatch) => {
    simpleApiCalls(
      {
        dispatch,
        body,
        methordType: "get",
        url: `https://api.samscloud.io/covid19/api/location/province-lab-locations/${body.state}`,
        started: GET_GOOGLE_STATE_LOCATION_STARTED,
        success: GET_GOOGLE_STATE_LOCATION_SUCCESS,
        failure: GET_GOOGLE_STATE_LOCATION_FAILED,
      },
      callBack
    );
  };
};
