import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_USER_FLIGHT_SUCCESS,
  ADD_USER_FLIGHT_FAILED,
  ADD_USER_FLIGHT_STARTED
} from "../../../../constants/action-types";
import { GET_FLIGHTS_LIST_URL } from "../../../../constants/configurations";

export const addUserFlightsAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "post",
      api: GET_FLIGHTS_LIST_URL,
      started: ADD_USER_FLIGHT_STARTED,
      success: ADD_USER_FLIGHT_SUCCESS,
      failure: ADD_USER_FLIGHT_FAILED
    },callBack);
  };
};
