import React from "react";
import { CustomButton } from "../buttons";
import UserAddedTestingBlock from "./userAddedTestingBlock";
import { AddTestingLocationModal } from "./addTestingLocationModal";
import { AddTestingViewModal } from "./addTestingViewModal";
import { latestReportConst } from "../../../constants/text-constants";
import { submitText, downloadIcon } from "../../../constants/text-constants";
import { BuildFormHandler } from "../BuildFields";
import { Overlay, Popover } from "react-bootstrap";
import { TextInput } from "../textField";

export const ATVBody = (props) => {
  const {testing, toggleViewModal} = props

  const onEditItem = () => {
    toggleViewModal()
    props.onEditTesting({ id: testing.id });
  }

  const download = () => {
    fetch(testing.file_upload, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", testing.file_upload.split('/').pop());
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };

  const previewImage = () => {
    return (
      <div>
        <div className="d-flex justify-content-center mt-2">
            <img src={testing.file_upload} style={{ maxWidth: "450px"}} alt="dummy" width="100%" height="100%" />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button class="btn btn-submit fnt-14 SFProText-Regular" onClick={() => download()}>
            {downloadIcon} Download
          </button>
        </div>
      </div>
    );
  }

  let { testingFormConfig, addTestingForm } = props;
  let { editTestingForm } = props.reducer;

  return (
    <div className="modal-body">
      <div className="col-12">
        <div className="row">
          <table className="table d-op-table">
          <thead>
            <tr>
              <th className="col-2">
                Name
              </th>
              <th className="col-1">
                Status
              </th>
              <th className="col-6">
                Location
              </th>
              <th className="col-2">
                Date
              </th>
              <th className="col-1">
                Action
              </th>
            </tr>
          </thead>
          <tbody style={{background: "#242527", overflowY: "unset", height: "50px"}}>
            <tr>
              <td className="col-2">{ testing.name || 'Not' }</td>
              <td className="col-1">{ testing.test_result }</td>
              <td className="col-6">{ testing.location }</td>
              <td className="col-2">{ testing.date }</td>
              <td className="col-1"><button className="btn-edit fnt-10" onClick={onEditItem}>Edit</button></td>
            </tr>
          </tbody>
        </table>
        </div>
        {
          testing.file_upload && testing.file_upload !== 'None' ?  previewImage() : ''
        }
      </div>
    </div>
  );
};
