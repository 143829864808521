import React, { Component } from "react";
import Icon from '@material-ui/core/Icon';

import {
  noData,
  editIcon,
  deleteIcon,
} from "../../../constants/text-constants";
import actionIcon from "../../../assets/images/more-vertical.svg";
import moment from "moment";

const dropDownConfig = [
  { type: "edit", name: "Edit", icon: editIcon },
  { type: "delete", name: "Delete", icon: deleteIcon },
];
// const sampleDat= ['A','B','C', 'D','E','F','G','H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'V', 'W', 'X', 'Y', 'Z' ]

export default class UserVisitedLocations extends Component {
  userLocationAction = (data, action) => {
    let { onDeleteLocation, onEditLocation, onHideLocation, editContact } = this.props;

    if (action === "Edit") {
      onEditLocation(data);
      //editfunc added siva
      editContact();
    } else if (action === "Hide") {
      onHideLocation(data)
    } else {
      onDeleteLocation(data);
    }
  };

  /**drop down for user */
  dropDownItems = (element) => {
    let { unAuthorizedUser } = this.props;
    return dropDownConfig.map((item, index) => {
      if (unAuthorizedUser) {
        if (item.type === "delete") {
          return (
            <div
              key={index}
              className="dropdown-item"
              onClick={() => this.userLocationAction(element, item.name)}
            >
              {item.icon} {item.name}
            </div>
          );
        }
      } else {
        return (
          <div
            key={index}
            className="dropdown-item"
            onClick={() => this.userLocationAction(element, item.name)}
          >
            {item.icon} {item.name}
          </div>
        );
      }
    });
  };

  dropDownForTag = (element) => {
    return (
      <div
        className="dropdown-item"
        onClick={() => this.userLocationAction(element, "Hide")}
      >
        <Icon className="fa fa-eye-slash eye-slash-icon" /> Hide
      </div>
    );
  };

  getListing = () => {
    let {
      userLocationList,
      localLocationList,
    } = this.props.reducer;
    let { unAuthorizedUser } = this.props;
    if (unAuthorizedUser) {
      return localLocationList;
    } else return userLocationList;
  };

  buildList = () => {
    let lists = this.getListing();
    if (lists && lists.length)
      return lists.map((loc, index) => {
        return (
          <div key={loc.id} className="location-direction-bottom d-op-table">
            <p>
              <span>{index + 1}</span>
              {loc.location}, {loc.place_tag || ''}
              <b>{moment(loc.location_date).format("MM/DD/YYYY")}</b>
              <b>{moment(loc.from_time, "HH:mm:s").format("HH:mm")} - {moment(loc.to_time, "HH:mm:s").format("HH:mm")}</b>
            </p>
            <button
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              className="more-act"
            >
              <img alt="location" src={actionIcon} />
            </button>
            <div
              className="dropdown-menu"
              aria-labelledby="#dropdownMenuButton"
            >
              {loc.place_tag ? this.dropDownForTag(loc) : this.dropDownItems(loc)}
            </div>
          </div>
        );
      });
    else return [];
  };
  render() {
    return (
      <div className="location-direction-bottom-wrap">{this.buildList()}</div>
    );
  }
}
