import React, { Component } from "react";
import CommonDatePicker from "../commons/DatePicker/datePicker";
import TimePickerCustom from "../commons/TimePicker/timePicker";

export default class AddAccessRiskForm extends Component {
  commonHandleChange = (e) => {
    let { name, value } = e.target;
    this.props.handleChangeForm({ name, value });
  };
  onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };
  validateFields = (name) => {
    let { showValidation } = this.props;
    if (showValidation) {
      if (this.props.variables[name].length) return "";
      else return "border-red";
    } else return "";
  };

  renderNameBlock = () => {
    let { locationName } = this.props.variables;
    return (
      <div class="col-sm-12 pull-left">
        <div class="row">
          <h6 class="fnt-12 text-white">Name of a place (Optional)</h6>
          <input
            type="text"
            autocomplete="new-password"
            name={"locationName"}
            value={locationName}
            className={"w-100 p-2 rounded mb-20 nav-p self-inp2 form-control"}
            placeholder=""
            onChange={this.commonHandleChange}
          />
        </div>
      </div>
    );
  };
  renderAddressBlock = () => {
    return (
      <div class="col-sm-12 pull-left">
        <div class="row">
          <h6 class="fnt-12 text-white">Address</h6>
          <input
            type="text"
            onFocus={this.onFocus}
            autocomplete="off"
            id="pac-input"
            className={`w-100 p-2 rounded mb-20 nav-p self-inp2 form-control ${this.validateFields(
              "location"
            )}`}
            placeholder=""
          />
        </div>
      </div>
    );
  };
  renderDatePicker = () => {
    let { location_date } = this.props.variables;
    let { handleChangeForm } = this.props;
    return (
      <div class="col-lg-12 pull-left">
        <div class="row">
          <h6 class="fnt-12 text-white">Contacted date</h6>
          <div className="assess-risk-datepicker w-100">
          <CommonDatePicker
            value={location_date}
            handleFormChange={handleChangeForm}
            configs={{
              className: `form-control p-2 rounded nav-p date-input w-100`,
              isDisabled: false,
              name: "location_date",
            }}
          />
          </div>
        </div>
      </div>
    );
  };

  renderTimePicker = () => {
    let { from_time } = this.props.variables;
    return (
      <div class="col-lg-12 pull-left">
        <div class="row">
          <h6 class="fnt-12 text-white">Time range</h6>
          <TimePickerCustom
            value={from_time}
            name="from_time"
            className={`form-control p-0 border-0 rounded mb-20 nav-p self-inp2 w-100 `}
            handleTimeChange={this.props.handleChangeForm}
          />
        </div>
      </div>
    );
  };
  render() {
    return (
      <div class="left-top-wrapper element-bg">
        <h6 class="text-white left-top-wrapper-h6 Gilroy-Medium">Add Location</h6>
        {/* {this.renderNameBlock()} */}
        {this.renderAddressBlock()}
        {this.renderDatePicker()}
        {this.renderTimePicker()}
        <button
          class="btn btn-block btn-submit fnt-14"
          onClick={this.props.addLocationToList}
        >
          Add Location
        </button>
      </div>
    );
  }
}
