import React from "react";
export const ATHeader = props => {
  return (
    <React.Fragment>
      <h4>Testing Records</h4>
      <button
        type="button"
        className="close"
        onClick={props.toggleModal}
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </React.Fragment>
  );
};
