import React from "react";
import { TextInput } from "../textField";
import { FileInput } from "../fileField";
import { ModalButton } from "../modalButton";
import PropTypes from "prop-types";
import CustomAsynSelect from "../AsyncSelect/asyncSelect";
import {
  getDiseasesLoadOptions,
  getSymtomsLoadOptions,
  getFlightListLoadOptions,
  getOrganizationsListLoadOptions,
} from "../../SelfReport/loadOptions";
import TimePicker from "rc-time-picker";
import moment from "moment";
import CheckBoxDrop from "../CheckBoxDrop/checkBoxDrop";
import CheckBox from "../CheckBox/checkBox";
import DatePicker from "react-datepicker";
import FilterableDropDown from "../FilterDropDown";
import FilterDropDownOrg from "../AddOrganizationModal/Filterable/filterableDropDownOrg";
import { StatusLoader } from "../loader";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import SimpleDropDown from "../SimpleDropDown/simpleDropDown";
import CustomTimePickers from "../CustomTimePickers/customTimePickers";
import SelectWithLabels from "../SelectWithLabels/selectWithLabels";
import datedwnarw from "../../../assets/images/Drop-down2.svg";
import { convertDateToUTC } from "../../../utils/getTimezone";
import CommonDatePicker from "../DatePicker/datePicker";
import FilterableDropDownProCode from "../AddOrganizationModal/Filterable/filterDropDownProCode";
const format = "h:mm a";
const now = moment();

/**building select field */
export const BuildFormHandler = (props) => {
  /**function to perform handle change */

  const formHeading = (heading) => {
    if (heading)
      return <h2 className="text-white w-100 self-section-head">{heading}</h2>;
    else return [];
  };
  const handleChangeDatePicker = (params) => {
    let { name, value } = params;
    props.handleFormChange({ name, value });
  };

  /**renders the simple select drop downs */
  const renderSelectField = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group">
          <label className={configs.labelClass}>{configs.label}</label>
          <SimpleDropDown
            disabledLabel={configs.disabledLabel}
            disabled={configs.isDisabled}
            validation={configs.isValid}
            className={configs.className}
            selectOptions={configs.selectOptions}
            onChange={props.handleFormChange}
            name={configs.name}
            width={configs.width}
            value={props.variables[configs.name]}
          />
        </div>
      </div>
    );
  };
  const getLoadOptions = (e, type) => {
    switch (type) {
      case "disease":
        return getDiseasesLoadOptions(e);
      case "symptoms":
        return getSymtomsLoadOptions(e);
      case "flight":
        return getFlightListLoadOptions(e);
      case "organization":
        return getOrganizationsListLoadOptions();
      default:
        return () => {};
    }
  };
  const renderCustomSelectFields = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group">
          <label className={configs.labelClass}>{configs.label}</label>
          <CustomAsynSelect
            className={configs.className}
            loadOptions={(e) => getLoadOptions(e, configs.name)}
            isMulti={configs.isMulti}
            name={configs.name}
            value={props.variables[configs.name]}
            handleChange={props.handleFormChange}
          />
        </div>
      </div>
    );
  };
  /**do not delete */
  const renderCheckboxdropFields = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group position-relative">
          <label className={configs.labelClass}>{configs.label}</label>
          <CheckBoxDrop
            className={configs.className}
            optionsList={configs.selectOptions}
            name={configs.name}
            value={props.variables[configs.name]}
            handleChange={props.handleFormChange}
          />
        </div>
      </div>
    );
  };
  const renderCheckboxFields = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group position-relative">
          <CheckBox
            id={configs.id}
            className={configs.className}
            optionsList={configs.selectOptions}
            disabled={configs.isDisabled}
            name={configs.name}
            label={configs.label}
            labelClass={configs.labelClass}
            value={props.variables[configs.name]}
            handleChange={props.handleFormChange}
          />
        </div>
      </div>
    );
  };
  const renderSelectWithLabels = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group position-relative">
          <label className={configs.labelClass}>{configs.label}</label>
          <SelectWithLabels
            disabled={configs.isDisabled}
            className={configs.className}
            optionsList={configs.selectOptions}
            name={configs.name}
            value={props.variables[configs.name]}
            handleChange={props.handleFormChange}
          />
        </div>
      </div>
    );
  };
  const renderDatePicker = (configs) => {
    let value = props.variables[configs.name];

    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group">
          <label className={configs.labelClass}>{configs.label}</label>
          <CommonDatePicker
            configs={configs}
            value={value}
            handleFormChange={props.handleFormChange}
          />
        </div>
      </div>
    );
  };
  const renderInputField = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group">
          <label className={configs.labelClass}>{configs.label}</label>
          <TextInput
            type={configs.type}
            validation={configs.isValid}
            className={configs.className}
            placeholder={configs.placeholder}
            disabled={configs.isDisabled}
            name={configs.name}
            handleChange={props.handleFormChange}
            pattern={configs.pattern}
            value={props.variables[configs.name]}
          />
        </div>
      </div>
    );
  };
  const renderFileField = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group">
          <label className={configs.labelClass}>{configs.label}</label>
          <FileInput
            type={configs.type}
            validation={configs.isValid}
            className={configs.className}
            placeholder={configs.placeholder}
            disabled={configs.isDisabled}
            name={configs.name}
            btnText={configs.btnText}
            btnClass={configs.btnClass}
            handleChange={props.handleFormChange}
            value={props.variables[configs.name]}
          />
        </div>
      </div>
    );
  };
  const renderModalButton = (configs, modalSection) => {
      return modalSection(configs)
  };
  const renderFilterDrop = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group">
          <label className={configs.labelClass}>{configs.label}</label>
          {!configs.loading ? (
            <FilterableDropDown
              className={configs.className}
              optionsList={configs.selectOptions}
              name={configs.name}
              value={props.variables[configs.name]}
              handleChange={props.handleFormChange}
            />
          ) : (
            <StatusLoader />
          )}
        </div>
      </div>
    );
  };

    const renderFilterDropOrganizations = (configs) => {
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group">
          <label className={configs.labelClass}>{configs.label}</label>
          {!configs.loading ? (
            <FilterDropDownOrg
              className={configs.className}
              optionsList={configs.selectOptions}
              name={configs.organization_name}
              loadOptions={() => getLoadOptions(null, "organization")}
              value={props.variables[configs.name]}
              handleChange={props.handleFormChange}
              state = {props.state}
            />
          ) : (
            <StatusLoader />
          )}
        </div>
      </div>
    );
  };

  const renderFilterDropProCodeOrganizations = (configs) => {
  return (
    <div key={configs.id} className={configs.col}>
      {formHeading(configs.heading)}
      <div className="form-group">
        <label className={configs.labelClass}>{configs.label}</label>
        {!configs.loading ? (
          <FilterableDropDownProCode
            className={configs.className}
            optionsList={configs.selectOptions}
            name={configs.pro_code}
            loadOptions={(e) => getLoadOptions(e, configs.name)}
            value={props.variables[configs.name]}
            handleChange={props.handleProCodeFormChange}
            state = {props.state}
          />
        ) : (
          <StatusLoader />
        )}
      </div>
    </div>
  );
};
  const renderDateRangePicker = (configs) => {
    let value = props.variables[configs.name];
    let time = now;
    if (value !== "") {
      time = moment(value, "HH:mm:SS");
    }
    return (
      <div key={configs.id} className={configs.col}>
        {formHeading(configs.heading)}
        <div className="form-group timer">
          <label className={configs.labelClass}>{configs.label}</label>
          {/* <CustomTimePickers
            name={configs.name}
            onChange={handleChangeDatePicker}
            value={date}
          /> */}
          <TimePicker
            showSecond={false}
            className={configs.className}
            name={configs.name}
            defaultValue={time}
            onChange={(e) => {
              handleChangeDatePicker({ value: e, name: configs.name });
            }}
            format={format}
            use12Hours
            inputReadOnly
          />
          ,
        </div>
      </div>
    );
  };
  const buildFields = () => {
    let { formDefinition, modalSection, renderCustomFieldTemplate} = props;

    let form = [];
    if (formDefinition && formDefinition.length) {
      for (const key in formDefinition) {
        if (formDefinition.hasOwnProperty(key)) {
          const element = formDefinition[key];
          if (element.type === "select") {
            form.push(renderSelectField(element));
          } else if (element.type === "date") {
            form.push(renderDatePicker(element));
          } else if (element.type === "filterDropdown") {
            form.push(renderFilterDrop(element));
          } else if (element.type === "filterDropdownOrganizations") {
            form.push(renderFilterDropOrganizations(element));
          } else if (element.type === "filterDropdownProOrg") {
            form.push(renderFilterDropProCodeOrganizations(element));
          } else if (element.type === "text" || element.type === undefined) {
            form.push(renderInputField(element));
          } else if (element.type === "daterange") {
            form.push(renderDateRangePicker(element));
          } else if (element.type === "customselect") {
            form.push(renderCustomSelectFields(element));
          } else if (element.type === "customCheckbox") {
            form.push(renderSelectWithLabels(element));
          } else if (element.type === "checkbox") {
            form.push(renderCheckboxFields(element));
          } else if (element.type === "file") {
            form.push(renderFileField(element));
          } else if (element.type === "modalBtn") {
            form.push(renderModalButton(element, renderCustomFieldTemplate));
          }
        }
      }
    }
    return form;
  };

  return buildFields();
};
BuildFormHandler.propTypes = {
  formDefinition: PropTypes.object,
  variables: PropTypes.object,
  handleFormChange: PropTypes.func,
};
