/**all the component actions such as handle change and toggle modal etc. keeped here */

import { HANDLE_CHANGE_LR_PAGE } from "../../../constants/action-types";

export const handleChangeLR = params => dispatch => {
  dispatch({
    type: HANDLE_CHANGE_LR_PAGE,
    payload: params
  });
};
