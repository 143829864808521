import React, { Component } from "react";

export default class CheckBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  handleCheckboxChange = (e) => {
    this.setState({ checked: e.target.checked })
    this.props.handleChange({ name: e.target.name, value: e.target.value });
  }

  toggleCheckbox = (e) => {
    this.setState({checked: !this.state.checked})
    this.props.handleChange({ name: this.props.name, value: !this.props.value });
  }

  render() {
    let {
      id,
      name,
      value,
      pattern,
      className,
      handleChange,
      keypressEvent,
      validation,
      btnText,
      btnClass,
      inputClass,
      disabled,
      labelClass, 
      label, 
    } = this.props;
    
    let isDisabled = disabled ? 'disabled' : false;

    return (
      <React.Fragment>
        <label for={id} className={labelClass}>{label}</label>
        <div>
          <input type="checkbox" className="checkbox" disabled={isDisabled} id={id} name={name}
            checked={value}
            onChange={this.handleCheckboxChange} 
            />
          <span className="checkbox-slider" onClick={this.toggleCheckbox}></span>
        </div>
      </React.Fragment>
    );
  }
}
