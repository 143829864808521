import React, { Component } from "react";
import { formatNumber } from "../../../utils/numberFormatters";
import queryString from "query-string";
import { abbreviate } from "../../../utils/abbreviate";
import { StatusLoader } from "../../commons/loader";

export default class HomeHeader extends Component {
  renderTitle = () => {
    return (
      <div className="d-table w-100">
        <div className="xs-report-head align-middle d-table-cell w-100 Gilroy-Medium">
          COVID-19 REPORT
        </div>
      </div>
    );
  };
  getCounts = () => {
    let { selectedCountryMapList } = this.props.reducer;
    let { cases } = selectedCountryMapList;
    let body = {};
    if (cases.length) {
      for (const key in cases) {
        if (cases.hasOwnProperty(key)) {
          const element = cases[key];
          body[element.status] = element.count;
        }
      }
    }

    return body;
  };
  renderCounts = () => {
    let { covidBrief } = this.props.reducer;
    let { affectedCount, deathCount, recoveredCount } = covidBrief;
    let parsed = queryString.parse(this.props.location.search);
    let countList = {
      confimed: affectedCount,
      recovered: recoveredCount,
      deaths: deathCount,
    };
    if (parsed.country) {
      let selectedInfo = this.getCounts();
      let { Confirmed, Recovered, Deaths } = selectedInfo;
      countList.confimed = Confirmed;
      countList.recovered = Recovered;
      countList.deaths = Deaths;
    }
    return countList;
  };
  renderCovidInfo = () => {
    let { covidBrief, affectedCountry } = this.props.reducer;
    let name = "Global";
    let parsed = queryString.parse(this.props.location.search);
    if (parsed.province) {
      name = parsed.province;
    } else if (parsed.country) {
      name = parsed.country;
    }
    if (name === "US") name = "USA";
    let { loading } = covidBrief;
    let countInfo = this.renderCounts();
    let { confimed, recovered, deaths } = countInfo;

    const selectListLoading = affectedCountry.loading;
    return (
      <div className="resp-home-header">
        {this.renderTitle()}
        <div className="title-head Gilroy-Medium resp-title-header">
          <h4>COVID-19 REPORT</h4>
        </div>
        <div className="d-flex justify-content-between ss-v">
          {!selectListLoading && !loading ? (
            <>
              <div className="xs-b-blocks">
                <h3 title={name}>
                  {" "}
                  <span className="cir resp-dot-bg  mar-5 mr-2"></span> Cases (
                  {abbreviate(name, 7)})
                </h3>
                <span className="xs-b-count Gilroy-Medium">
                  {formatNumber(confimed)}
                </span>
              </div>
              <div className="xs-b-blocks">
                <h3>
                  {" "}
                  <span className="cir green  mar-5 mr-2"></span> Recovered
                </h3>
                <span className="xs-b-count Gilroy-Medium">
                  {formatNumber(recovered)}
                </span>
              </div>
              <div className="xs-b-blocks">
                <h3>
                  {" "}
                  <span className="cir orange  mar-5 mr-2"></span> Deaths
                </h3>
                <span className="xs-b-count Gilroy-Medium">
                  {formatNumber(deaths)}
                </span>
              </div>
            </>
          ) : (
            <StatusLoader />
          )}
        </div>
      </div>
    );
  };
  render() {
    return this.renderCovidInfo();
  }
}
