import {
  GET_ORG_LIST_STARTED,
  GET_ORG_LIST_SUCCESS,
  GET_ORG_LIST_FAILED,
  GET_SUBSCRIPTIONS_LIST_STARTED,
  GET_SUBSCRIPTIONS_LIST_SUCCESS,
  GET_SUBSCRIPTIONS_LIST_FAILED,
  CREATE_SUBSCRIPTIONS_LIST_STARTED,
  CREATE_SUBSCRIPTIONS_LIST_SUCCESS,
  CREATE_SUBSCRIPTIONS_LIST_FAILED,
  CREATE_SUBSCRIPRION_STARTED,
  CREATE_SUBSCRIPRION_SUCCESS,
  CREATE_SUBSCRIPRION_FAILED,
} from "../../../../constants/action-types";
import {
  CREATE_SUBSCRIPTIONS_LIST_URL,
  GET_ORGANIZATIONS_LIST_URL,
  GET_VIEW_ORGANIZATIONS_LIST_URL,
  ADD_BUTTON_ORGANIZATIONS_LIST_URL,
} from "../../../../constants/configurations";

import { apiServiceHandler } from "../../../../services/apiCallHandler";

export const listOrganizationsForSelect = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GET_ORGANIZATIONS_LIST_URL(),
        started: GET_ORG_LIST_STARTED,
        success: GET_ORG_LIST_SUCCESS,
        failure: GET_ORG_LIST_FAILED,
      },
      callBack
    );
  };
};

export const createSubscription = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api: CREATE_SUBSCRIPTIONS_LIST_URL(),
        started: CREATE_SUBSCRIPRION_STARTED,
        success: CREATE_SUBSCRIPRION_SUCCESS,
        failure: CREATE_SUBSCRIPRION_FAILED,
      },
      callBack
    );
  };
};

export const listSubscriptions = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GET_VIEW_ORGANIZATIONS_LIST_URL(),
        started: GET_SUBSCRIPTIONS_LIST_STARTED,
        success: GET_SUBSCRIPTIONS_LIST_SUCCESS,
        failure: GET_SUBSCRIPTIONS_LIST_FAILED,
      },
      callBack
    );
  };
};


export const addSubscriptionsRequest = (body, callBack) => {
console.log();
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "post",
        api: ADD_BUTTON_ORGANIZATIONS_LIST_URL(localStorage.getItem("subs_id")),
        started: CREATE_SUBSCRIPTIONS_LIST_STARTED,
        success: CREATE_SUBSCRIPTIONS_LIST_SUCCESS,
        failure: CREATE_SUBSCRIPTIONS_LIST_FAILED,
      },
      callBack
    );
  };
};