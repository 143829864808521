import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


import {
  ClipIcon,
  UploadIcon
} from "../../constants/text-constants";


export const FileInput = (params) => {
  let {
    id,
    name,
    value,
    type,
    pattern,
    placeholder,
    className,
    handleChange,
    keypressEvent,
    validation,
    btnText,
    btnClass,
    inputClass,
    disabled
  } = params;

  const [image, setImage] = useState({ preview: "", raw: "" });

  const validateField = () => {
    if (validation !== undefined) {
      if (validation) return "";
      else return "border-red";
    }
  };

  return (
    <div class="pull-right">
      <input
        disabled={disabled}
        accept="image/*"
        className={inputClass + ' d-none'}
        id="contained-button-file"
        type="file"
        name={name}
        onChange={(e) => {
          handleChange({ name: e.target.name, value: e.target.files[0] });
        }}
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained"  className={`${btnClass} ${validateField()}`} component="span" startIcon={<UploadIcon />} disabled={disabled}>
          {btnText}
        </Button>
      </label>
    </div>
  );
}
