import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  DELETE_USER_VACCINE_SUCCESS,
  DELETE_USER_VACCINE_FAILED,
  DELETE_USER_VACCINE_STARTED
} from "../../../../constants/action-types";
import { DELETE_USER_VACCINE_URL } from "../../../../constants/configurations";

export const deleteUserVaccinesAction = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "delete",
        api: DELETE_USER_VACCINE_URL(body.id),
        started: DELETE_USER_VACCINE_STARTED,
        success: DELETE_USER_VACCINE_SUCCESS,
        failure: DELETE_USER_VACCINE_FAILED
      },
      callBack
    );
  };
};
