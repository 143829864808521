import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  GET_USER_LAST_UPDATE_SUCCESS,
  GET_USER_LAST_UPDATE_FAILED,
  GET_USER_LAST_UPDATE_STARTED,
} from "../../../../constants/action-types";
import { GET_USER_LAST_UPDATE } from "../../../../constants/configurations";

export const getUserLastUpdate = (payload, callback) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body: payload,
        methordType: "get",
        api: GET_USER_LAST_UPDATE,
        started: GET_USER_LAST_UPDATE_STARTED,
        success: GET_USER_LAST_UPDATE_SUCCESS,
        failure: GET_USER_LAST_UPDATE_FAILED,
      },
      callback
    );
  };
};
