/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { ReactComponent as IconOrganization } from "../../../assets/images/group-8.svg";
import { TableBuilder } from "../tableBuilder";

const AddUserOrganizationBlock = (props) => {
  const [state, setState] = useState([]);
  const {onEditOrg} = props 

  const filterData = state
    ? state.map((el) => ({
        name: el.organization,
        id: el.id,
        location: el.quarantine_ends_at,
        Status: el.status,
        Action: "edit",
      }))
    : [];


  useEffect(() => {
    if (props.state) setState(props.state.subscriptions);
  }, [props]);

  const conf = {
    rows: [
      { id: 1, name: "Name" },
      { id: 2, name: "location" },
      { id: 3, name: "Status" },
      { id: 4, name: "Action" },
    ],
  };
  return (
    <div className="col-sm-7">
      <div className="modal-data-bg">
        <div className="d-flex d-op">
          <div
            className="d-flex"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h1 className="mt-1">
                {filterData.length > 0 ? filterData.length : 0}
              </h1>
              <h4
                className="mt-0"
                style={{
                  width: 84,
                  height: 28,
                  fontSize: 12,
                  lineHeight: 1.17,
                  letterSpacing: 0.5,
                  color: "#8c8e8f",
                }}
              >
                {"Connected Organizations"}
              </h4>
            </div>
            <div>{filterData.length > 0 ? <IconOrganization /> : null}</div>
          </div>
        </div>
        {filterData.length > 0 ? (
          <TableBuilder
            tableItemList={filterData}
            tableConfig={conf}
            onDeleteItem={(id) => {
              console.log("delete", id);
            }}
            onEditItem={onEditOrg}
            org={true}
          />
        ) : (
          <>
            <IconOrganization
              style={{ margin: 167, marginTop: 32, marginBottom: 16 }}
            />
            <h2
              style={{
                fontSize: 16,
                fontWeight: 500,
                letterSpacing: 0.5,
                textAlign: "center",
                color: "#fff",
              }}
            >
              No available organization info
            </h2>
            <h3
              style={{
                fontSize: 13,
                lineHeight: 1.33,
                letterSpacing: 0.5,
                textAlign: "center",
                color: "#65696b",
                marginBottom: 24,
              }}
            >
              Add Organization information and well get you connected for
              Covid-19 Updates.
            </h3>
          </>
        )}
      </div>
    </div>
  );
};

export default AddUserOrganizationBlock;
