import history from "../components/history";
import { APP_BASE_NAME } from "../constants/configurations";

export const checkIfUserAuthorized = () => {
  let info = JSON.parse(localStorage.getItem("logged_user"));

  let route =
    history.location.pathname === `/${APP_BASE_NAME}/signup` ||
    history.location.pathname === `/${APP_BASE_NAME}/forgotpassword` ||
    history.location.pathname === `/${APP_BASE_NAME}/verification`;
  if (!info && !route) return false;
  else return true;
};
