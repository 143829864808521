/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { BuildFormHandler } from "../commons/BuildFields";
import locationMarker from "../../../src/assets/images/location.svg";
import circleSvg from "../../../src/assets/images/account-circle-blue.svg";
import penSvg from "../../../src/assets/images/pen-blue.svg";
import Button from '@material-ui/core/Button';

import {
  submitText,
  updateText,
  selfReportConst,
  secureText,
  userIcon,
  flightIcon,
  ClipIcon
} from "../../constants/text-constants";

import { CustomButton } from "../commons/buttons";
import { toastNotification } from "../commons/toastNotification";
import { StatusLoader } from "../commons/loader";
import SelfReportModals from "./selfReportModals";
import { convertDigits } from "../../utils/numberFormatters";
import { apiDateFormat } from "../../utils/commonDateFormatter";
import { errorHandler } from "../../utils/commonErrorHandler";
import GeoTracking from "../GeoTracking"

const { flightUserDesc, contactUserDesc, locationUserDesc } = selfReportConst;

export default class SelfReport extends Component {
  componentDidMount() {
    this.props.fetchUsersAction();

    /**action for listing options in form */
    this.props.listSymptomsActions();
    this.props.getStatusListAction();
    /**listing of exposure entity */
    this.props.listFlightActions();
    this.props.listUserContactsAction();
    this.props.getManufacturersListAction();
    this.props.getDosesListAction();
    this.props.getAllTravelLocation();
    this.props.listUserVaccinesAction();
    this.props.listUserTestingsAction();
  }

  handleChangeForm = (params) => {
    let { name, value, type, operation } = params;
    this.props.handleChange({
      parentName: "selfReportVariables",
      name,
      value,
      type,
      operation,
    });
  };

  formatArray = (data) => {
    if (data.length) return data.map((item) => item.id.toString());
    else return data;
  };

  returnId = (data, value) => {
    return data.filter((item) => item.name === value)[0].id;
  };
  formatVariables = () => {
    let {
      symptoms,
      test_result,
      tested_date,
      data_started,
      port,
      is_tested,
      id,
      disease,
      startDate,
      status,
      is_vaccinated,
      vaccinated_date,
      manufacturer,
      lot,
      dosage,
      is_reminded,
      vaccine_id,
      testing_id
    } = this.props.reducer.selfReportVariables;

    let { diseaseList, statusListing, vaccineDosesListing, vaccineManufacturersListing } = this.props.reducer;
    let body = {};
    let valid = true;
    body["id"] = id;
    body["disease"] = this.returnId(diseaseList, disease);
    body["symptoms"] = this.formatArray(symptoms);
    body["user_id"] = Number(localStorage.getItem("user_id"));
    // && status !== "Never Infected"
    !data_started.length && (body["data_started"] = startDate);
    body["port"] = port;
    body["status"] = this.returnId(statusListing.list, status);
    body["data_started"] = data_started.length ? data_started : apiDateFormat(new Date());
    if (is_tested === "Yes" && test_result === "Positive") {
      // body["port"] = port;
      // body["status"] = this.returnId(statusListing.list, status);
      body["is_tested"] = is_tested === "Yes" ? true : false;
      body["tested_date"] = tested_date;
      body["test_result"] = test_result;
    }
    if (is_tested === "Yes" && test_result !== "Positive") {
      body["tested_date"] = tested_date;
      body["test_result"] = test_result;
      body["is_tested"] = is_tested === "Yes" ? true : false;
      body["testing_id"] = testing_id || "";
    }
    if (is_vaccinated === "Yes") {
      body["is_vaccinated"] = is_vaccinated === "Yes" ? true : false;
      body["vaccinated_date"] = vaccinated_date;
      if (!manufacturer) {
        valid = false;
        this.validateField('manufacturer')
      } else {
        body["manufacturer"] = this.returnId(vaccineManufacturersListing.list, manufacturer);
      }
      if (lot === '') {
        valid = false;
        this.validateField('lot')
      } else {
        body["lot"] = lot;
      }
      if (!dosage) {
        valid = false;
        this.validateField('dosage')
      } else {
        body["dosage"] = this.returnId(vaccineDosesListing.list, dosage);
      }
      body["is_reminded"] = is_reminded;
      body["vaccine_id"] = vaccine_id || "";
    }

    return { body, valid };
  };

  validateField = (name) => {
    this.props.handleChange({
      validate: true,
      name
    });
  }

  toggleModal = (type) => {
    let visbility = this.props.latestReportReducer[type];
    this.props.handleChangePopups({
      name: type,
      value: !visbility,
    });
  };

  submitUserProfileDetails = () => {
    let { fetchUserDetails } = this.props.reducer;
    let { body, valid } = this.formatVariables();

    if (!valid) {
      return true;
    }

    if (fetchUserDetails.isCreated) {
      this.props.updateUserReportAction(
        { ...body },
        (res) => {
          if (res.status === 200) {
            this.props.handleChange({
              type: "reset",
            });
            toastNotification({
              msg: "Update user report Success",
              type: "success",
            });
            this.props.history.push({ pathname: `/latestreports` });
          } else {
            toastNotification({
              msg: errorHandler(res.data),
              type: "error",
            });
          }
        }
      );
    } else {
      this.props.addUserReportAction({ ...body }, (res) => {
        if (res.status === 201) {
          this.props.handleChange({
            type: "reset",
          });
          toastNotification({
            msg: "Create user report Success ",
            type: "success",
          });
          this.props.history.push({ pathname: `/latestreports` });
        } else {
          toastNotification({
            msg: errorHandler(res.data),
            type: "error",
          });
        }
      });
    }
  };
  renderAddEntityBlock = (name) => {
    switch (name) {
      case "addedContactList":
        return this.addContactBlock();
      case "addedFlightList":
        return this.addFlightBlock();
      case "userLocationList":
        return this.addLocationBlock();
      default:
        return [];
    }
  };
  renderModalButton = (configs) => {
    let config = [
      {
        listName: "addedTestingList",
        toggleName: "showTestingModal",
        loadingState: "listUserTestings",
        leftLabel: (
          <>
            {" "}
            Added
            <br /> Testings
          </>
        ),
        label: <> Added Testings</>,
      },
      {
        listName: "addedVaccineList",
        toggleName: "showVaccineModal",
        loadingState: "listUserVaccines",
        leftLabel: (
          <>
            {" "}
            Added
            <br /> Vaccines
          </>
        ),
        label: <> Added Vaccines</>,
      },
    ]
    return config.map((entity) => {
      let reducer = this.props.latestReportReducer;
      let listItems = [...reducer[entity.listName]];
      let loaderStatus = reducer[entity.loadingState];
      let loading =
        loaderStatus.loading === undefined
          ? loaderStatus
          : loaderStatus.loading;
      if (configs.name == entity.listName) {
        return this.renderAddModalBtnBlock(configs);
      }
    });
  }
  renderAddModalBtnBlock = (configs) => {
    const { name } = configs;
    switch (name) {
      case "addedVaccineList":
        return this.addVaccineModalBtn(configs);
      case "addedTestingList":
        return this.addTestingModalBtn(configs);
      default:
        return [];
    }
  };
  renderFooterSection = () => {
    let config = [
      {
        listName: "addedContactList",
        toggleName: "showContactModal",
        loadingState: "listUserContacts",
        leftLabel: (
          <>
            {" "}
            Added
            <br /> Contacts
          </>
        ),
        label: <> Added Contacts</>,
      },
      {
        listName: "userLocationList",
        toggleName: "showLocationModal",
        loadingState: "locationLoading",
        leftLabel: (
          <>
            {" "}
            Added
            <br /> Locations
          </>
        ),
        label: <> Added Locations</>,
      },
      {
        listName: "addedFlightList",
        loadingState: "listUserFlights",
        toggleName: "showFlightModal",

        label: <> Added Flights</>,
        leftLabel: (
          <>
            {" "}
            Added
            <br /> Flights
          </>
        ),
      },
    ];
    return config.map((entity) => {
      let reducer = this.props.latestReportReducer;
      let listItems = [...reducer[entity.listName]];
      let loaderStatus = reducer[entity.loadingState];
      let loading =
        loaderStatus.loading === undefined
          ? loaderStatus
          : loaderStatus.loading;
      // if (!loading) {
      if (listItems.length) {
        return this.exposureEntityCount({
          count: listItems.length,
          modalTriggerName: entity.toggleName,
          label: entity.label,
          leftLabel: entity.leftLabel,
          name: entity.listName,
        });
      } else return this.renderAddEntityBlock(entity.listName);
      // } else return <StatusLoader simpleLoader={true}/>;
    });
  };
  exposureEntityCount = (params) => {
    let { count, modalTriggerName, label, leftLabel, name } = params;
    let list = this.props.latestReportReducer[name];
    if (list.length)
      return (
        <React.Fragment>
          <div className="d-op self-report-data w-100 pull-left mt-0">
            <div className="pull-left">
              <h1 className="ml-0 pull-left">{convertDigits(count, 2)}</h1>
              <p className="self-data-p nav-p fnt-12 ml-2 pull-left mb-0">
                {leftLabel}
              </p>
            </div>
            <div className="pull-right">
              <a
                className="fnt-12 color clr-blue link-style"
                onClick={() => this.toggleModal(modalTriggerName)}
              >
                <img className="mr-1" src={penSvg} alt={"drop"} />
                Manage
              </a>
              <a
                className="fnt-12 add-cont-self-data color clr-blue link-style"
                onClick={() => this.toggleModal(modalTriggerName)}
              >
                <img className="mr-1" src={circleSvg} alt={"drop"} />
                {label}
              </a>
            </div>
          </div>
        </React.Fragment>
      );
  };
  addLocationBlock = () => {
    return (
      <div className="col-lg-12 pull-left update-add-flight mb-4">
        <div className="row">
          <p className="fnt-14 assess-p text-white">{locationUserDesc}</p>
          <button
            type="button"
            data-toggle="modal"
            data-target="#loca2"
            className="fnt-14 let-spac-4 exp-ass-button"
            onClick={() => this.toggleModal("showLocationModal")}
          >
            <i className="fa fa-map-marker" aria-hidden="true"></i> Add Location
          </button>
        </div>
      </div>
    );
  };
  addFlightBlock = () => {
    return (
      <div className="col-lg-12 pull-left update-add-flight">
        <div className="row">
          <p className="fnt-14 assess-p text-white">{flightUserDesc} </p>
          <button
            type="button"
            className="fnt-14 let-spac-5 exp-ass-button"
            onClick={() => this.toggleModal("showFlightModal")}
            data-toggle="modal"
            data-target="#add-flight"
          >
            {flightIcon}Add Flight{" "}
          </button>
        </div>
      </div>
    );
  };
  addContactBlock = () => {
    return (
      <div className="col-lg-12 pull-left update-add-flight mb-4">
        <div className="row">
          <p className="fnt-14 assess-p text-white">{contactUserDesc} </p>
          <button
            type="button"
            className="fnt-14 let-spac-5 exp-ass-button"
            onClick={() => this.toggleModal("showContactModal")}
            data-toggle="modal"
            data-target="#contact-people"
          >
            {userIcon}Add Contacts
          </button>
        </div>
      </div>
    );
  };


  addVaccineModalBtn = (configs) => {
    const { isDisabled, btnClass } = configs;
    const btnActiveClass = isDisabled ? 'btn-invalid' : 'btn-submit';

    return (
      <div className="col-lg-6 offset-lg-6">
        <div className="pull-right">
          <Button variant="contained" className={`${btnActiveClass} ${btnClass}`} component="span"
            onClick={() => isDisabled ? false : this.toggleModal("showVaccineModal")} startIcon={<ClipIcon />}>
            Attach
        </Button>
        </div>
      </div>
    );
  };

  manageSelfReportForm = () => {
    let { selfReportForm, selfReportVariables } = this.props.reducer;
    const reducer = this.props.latestReportReducer;

    let config = [{ listName: "addedTestingList", loadingState: "listUserTesting", disabledField: 'is_tested' },
    { listName: "addedVaccineList", loadingState: "listUserVaccines", disabledField: 'is_vaccinated' }];

    config.map((entity) => {
      let listItems = [...reducer[entity.listName]];

      if (listItems.length && selfReportVariables[entity.disabledField] === 'Yes') {
        this.props.handleChange({
          disabled: true,
          name: entity.disabledField,
          value: true
        });
      } else {
        this.props.handleChange({
          disabled: true,
          name: entity.disabledField,
          value: false
        });
      }
    });
  }

  addTestingModalBtn = (configs) => {
    const { isDisabled, btnClass } = configs;
    const btnActiveClass = isDisabled ? 'btn-invalid' : 'btn-submit';

    return (
      <div className="col-lg-6 offset-lg-6">
        <div className="pull-right">
          <Button variant="contained" className={`${btnActiveClass} ${btnClass}`} component="span"
            onClick={() => isDisabled ? false : this.toggleModal("showTestingModal")} startIcon={<ClipIcon />}>
            Attach
        </Button>
        </div>
      </div>
    );
  };
  render() {
    let {
      selfReportForm,
      selfReportVariables,
      fetchUserDetails,
    } = this.props.reducer;
    let name = localStorage.getItem("name");
    let { loading } = fetchUserDetails;

    return (
      <React.Fragment>
        <div className="body-wrapper2">
          <div className="title-head">
            <div className="">
              <h4 className="let-spac-0 fnt-18">
                {name && name.toUpperCase()}
              </h4>
              <p className="fnt-12">Self Report / Status</p>
            </div>
          </div>
          <div className="content-wrapper content-wrapper-self log-main m-auto w-100 d-table h-100 pl-3 pr-3">
            {secureText}
            <div className="w-100 pull-left login-page position-relative">
              <div className="title-head p-0">
                <h4>Create Profile</h4>
                <p>Know your status, protect yourself and others.</p>
              </div>
              {this.manageSelfReportForm()}
              <div className="row">
                {!loading ? (
                  <BuildFormHandler
                    variables={selfReportVariables}
                    formDefinition={selfReportForm}
                    handleFormChange={this.handleChangeForm}
                    renderCustomFieldTemplate={this.renderModalButton}
                    {...this.props}
                  />
                ) : (
                    <StatusLoader />
                  )}
                <div className="col-lg-12 pull-left">
                  <h4 className="text-white exp-ass-head">
                    Exposure Assessment
                  </h4>
                  <p className="nav-p fnt-12 exp-ass-p">
                    Provide additional information to help us evaluate your risk
                    of COVID-19.
                  </p>
                  {this.renderFooterSection()}
                  <CustomButton
                    className="btn pl-4 pr-4 mt-5 btn-submit w-100 fnt-14"
                    text={fetchUserDetails.isCreated ? updateText : submitText}
                    clickEvent={this.submitUserProfileDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <SelfReportModals
          toggleModal={this.toggleModal}
          handleChangeAddLocForm={this.handleChangeAddLocForm}
          {...this.props}
        />
        <GeoTracking />
      </React.Fragment>
    );
  }
}
