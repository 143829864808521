import {
  EDIT_USER_LOCATION_SUCCESS,
  EDIT_USER_LOCATION_FAILED,
  EDIT_USER_LOCATION_STARTED,
} from "../../../../constants/action-types";

import { apiServiceHandler } from "../../../../services/apiCallHandler";
import { UPDATE_USER_LOCATION_URL } from "../../../../constants/configurations";

export const editUserLocation = (body, callBack) => {
  return (dispatch) => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "patch",
        api: UPDATE_USER_LOCATION_URL(body.id),
        started: EDIT_USER_LOCATION_STARTED,
        success: EDIT_USER_LOCATION_SUCCESS,
        failure: EDIT_USER_LOCATION_FAILED,
      },
      callBack
    );
  };
};
