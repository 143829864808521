import React, { Component } from "react";
import { ModalWindow } from "../modal";
import { ACBody } from "./ACBody";
import { latestReportConst } from "../../../constants/text-constants";
import { CommonModalHeader } from "../ModalHeader";
import { toastNotification } from "../toastNotification";
import { CustomButton } from "../../commons/buttons";
import { apiDateFormat } from "../../../utils/commonDateFormatter";

let { peopleCText, confirmation } = latestReportConst.addContactTexts;
export default class AddContactModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,
      locationDetails: false,
      editToDelete: "",
    };
  }

  componentDidMount() {
    this.props.listUserContactsAction();
  }

  componentWillUnmount = () => {
    this.props.handleChangeForm({ type: "reset", formType: "contact" });
  };

  onEditContact = (params) => {
    let { id } = params;
    this.setState({
      editToDelete: id,
    });
    this.props.handleChangeForm({ mode: "edit", id, formType: "contact" });
  };

  onDeleteContact = (params) => {
    let { id } = params;
    this.props.deleteUserContactsAction({ id }, (res) => {
      if (res.status === 204) {
        toastNotification({
          msg: "Successfully deleted Contact ",
          type: "success",
        });
        this.props.handleChangeForm({ type: "reset", formType: "contact" });
        this.props.listUserContactsAction();
      } else {
        toastNotification({
          msg: "Error occured while deleting Contacts... ",
          type: "error",
        });
      }
    });
  };

  isDuplicate = (body) => {
    let contactList = this.props.reducer.addedContactList;
    let result = 0;
    contactList.map((iter) => {
      if (
        body.name === iter.name &&
        body.phone_number === iter.phone_number &&
        body.date_contacted === iter.date_contacted
      ) {
        result++;
      }
    });
    return result === 0 ? false : true;
  };

  submitContactForm = () => {
    let { contactFormMode } = this.props.reducer;
    let body = this.formBody();
    if (
      body.name !== "" &&
      body.phone_number !== "" &&
      body.date_contacted !== ""
    ) {
      // if (!this.isDuplicate(body)) {
      if (contactFormMode === "add") {
        this.props.addUserContactsAction(body, (res) => {
          if (res.status === 201) {
            toastNotification({
              msg: "succesfully created Contact ",
              type: "success",
            });
            this.props.getUserInfoAction();
            this.props.handleChangeForm({
              type: "reset",
              formType: "contact",
            });
            this.props.listUserContactsAction();
          } else {
            toastNotification({
              msg: "Error occured while creating... ",
              type: "error",
            });
          }
        });
      } else if (contactFormMode === "edit") {
        this.props.updateUserContactAction(body, (res) => {
          if (res.status === 200) {
            toastNotification({
              msg: "succesfully updated Contact ",
              type: "success",
            });
            this.props.getUserInfoAction();
            this.props.listUserContactsAction();
            this.props.handleChangeForm({
              type: "reset",
              formType: "contact",
            });
          } else {
            toastNotification({
              msg: "Error occured while updating Contact ",
              type: "error",
            });
          }
        });
      }
      // } else {
      //   this.props.handleChangeForm({ type: "reset", formType: "contact" });
      // }
    } else
      toastNotification({
        msg: "Fill all fields",
        type: "warning",
      });
  };

  handleChangeLocationForm = (params) => {
    this.handleFormChangeContacts({ ...params, type: "latLng" });
  };

  handleFormChangeContacts = (params) => {
    let { contactFormMode } = this.props.reducer;
    const { name, value, type } = params;
    let parentName =
      contactFormMode === "add" ? "addContactForm" : "editContactForm";
    this.props.handleChangeForm({
      parentName,
      name,
      value,
      type,
    });
  };

  formBody = () => {
    let body = {};
    let {
      addContactForm,
      contactFormMode,
      editContactForm,
    } = this.props.reducer;
    let formObj = contactFormMode === "add" ? addContactForm : editContactForm;
    for (const key in formObj) {
      if (formObj.hasOwnProperty(key)) {
        const element = formObj[key];
        if (key === "date_contacted") body[key] = apiDateFormat(new Date());
        else if (key !== "locationDetails") body[key] = element;
      }
    }
    return body;
  };

  toggleContactsSubModal = () => {
    let { showSubContactModal } = this.props.reducer;
    this.props.handleChangeForm({
      name: "showSubContactModal",
      value: !showSubContactModal,
    });
  };

  confirmationModal = () => {
    let {
      addContactForm,
      contactFormMode,
      editContactForm,
    } = this.props.reducer;
    let data = contactFormMode === "add" ? addContactForm : editContactForm;
    if (data.name !== "" || data.phone_number !== "") {
      this.setState({
        confirmationModal: true,
      });
    } else {
      this.props.toggleModal();
    }
  };

  onBacktoEdit = () => {
    this.setState({ confirmationModal: false });
  };

  close = () => {
    this.props.toggleModal();
    this.setState({ confirmationModal: false });
  };

  submit = () => {
    this.setState({ confirmationModal: false }, () => {
      this.submitContactForm();
    });
  };

  render() {
    let { showModal, toggleModal } = this.props;
    let { confirmationModal, editToDelete } = this.state;
    return (
      <>
        <ModalWindow
          showModal={showModal}
          onModalClose={this.confirmationModal}
          heading={
            <CommonModalHeader
              toggleHandler={this.confirmationModal}
              title={peopleCText}
            />
          }
          body={
            <ACBody
              {...this.props}
              editToDelete={editToDelete}
              handleFormChange={this.handleFormChangeContacts}
              onDeleteContact={this.onDeleteContact}
              onEditContact={this.onEditContact}
              toggleSubModal={this.toggleContactsSubModal}
              handleChangeLocationForm={this.handleChangeLocationForm}
              submitContactForm={this.submitContactForm}
              close={this.confirmationModal}
            />
          }
        />
        {
          <div className="sample class">
            <ModalWindow
              showModal={confirmationModal}
              onModalClose={toggleModal}
              heading={
                <CommonModalHeader
                  toggleHandler={this.onBacktoEdit}
                  title={confirmation}
                />
              }
              body={
                <div className="text-center">
                  <h6 className="mt-3 modal-text">
                    Do you want to save the Changes? Please go back to save your
                    change!{" "}
                  </h6>
                  <div class="row mt-4 text-center w-100 m-auto edit-sub-modal">
                    <div class="col-sm-4">
                      <CustomButton
                        className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                        text="Submit"
                        clickEvent={this.submit}
                      ></CustomButton>
                    </div>
                    <div class="col-sm-4">
                      <CustomButton
                        className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                        text="Don't Submit"
                        clickEvent={this.close}
                      ></CustomButton>
                    </div>
                    <div class="col-sm-4">
                      <CustomButton
                        text="Close"
                        className=" btn btn-block btn-submit fnt-14 SFProText-Regular"
                        clickEvent={this.onBacktoEdit}
                      ></CustomButton>
                    </div>
                  </div>
                </div>
              }
            ></ModalWindow>
          </div>
        }
      </>
    );
  }
}
