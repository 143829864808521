import React, { Component } from "react";

export default class SimpleDropDown extends Component {
  dropItems = () => {
    let { selectOptions, name, onChange } = this.props;
    return selectOptions.map((item, index) => {
      return (
        <li
          className="mb-2"
          key={index}
          onClick={() => onChange({ name, value: item.name })}
        >
          <span
            className="large com-drop sign-up-drp"
            data-value="option0"
            tabindex="-1"
          >
            {item.name}
          </span>
        </li>
      );
    });
  };

  validateField = () => {
    let { validation } = this.props;
    if (validation !== undefined) {
      if (validation) return "";
      else return "border-red";
    }
  };

  renderSimpleDropDown = () => {
    let { value, disabled, disabledLabel, validation } = this.props;
    let activeValue = disabled ? disabledLabel : value;

    return (
      <React.Fragment>
        <button
          type="button"
          disabled={disabled}
          className={"form-control self-inp position-relative text-left fnt-12 h-35 " + `${this.validateField()}`}
          // className="form-control self-inp position-relative text-left fnt-12 h-35"
          data-toggle="dropdown"
          aria-expanded="false"
          style={{width: `${ this.props.width || '100%'}`}}
        >
          <span className="glyphicon glyphicon-cog"></span>
          {activeValue}
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu self-inp2 text-white pt-0 pb-0" style={{"min-width": `${ this.props.width || '100%'}`}}>
          {this.dropItems()}
        </ul>
      </React.Fragment>
    );
  };
  render() {
    return this.renderSimpleDropDown();
  }
}
