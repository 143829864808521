import React from "react";
import { ModalWindow } from "../modal";
import { AVVBody }  from "./AVVBody";
import { latestReportConst } from "../../../constants/text-constants";
import { CommonModalHeader } from "../ModalHeader";

const { viewTitle } = latestReportConst.addRecordTexts;
export const AddVaccineViewModal = props => {
  let { toggleViewModal, showVaccineViewModal, showVaccine, addedVaccineList } = props;
  const viewItem = addedVaccineList.find(x => x.id === showVaccine)
  return (
    <ModalWindow
      showModal={showVaccineViewModal}
      onModalClose={toggleViewModal}
      heading={
        <CommonModalHeader title={viewTitle} toggleHandler={toggleViewModal} />
      }
      body={<AVVBody vaccine={viewItem} {...props}
      buttonText = 'Add Location' />}
    />
  );
};
