/**renders the app logo */
import React from "react";
import { SVGIcon } from "../VectorGraphics";
import { APP_BASE_NAME } from "../../constants/configurations";
import history from "../history";

export const AppLogo = () => {
  return (
    <div className="logo">
      <span onClick={(e) => history.push({ pathname: `/` })}>
        {/* <SVGIcon
          width={"150px"}
          height={"36px"}
          viewBox={"0 0 163 42"}
          type="logo"
          className={"app-logo"}
        /> */}
        <div className="logo-wrapper"></div>
      </span>
    </div>
  );
};
