/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import actionIcon from "../../assets/images/more-dot-white.png";
import { abbreviate } from "../../utils/abbreviate";
import { editIcon, deleteIcon } from "../../constants/text-constants";
import moment from "moment";
import './style.css'

const dropDownConfig = [
  { type: "edit", name: "Edit", icon: editIcon },
  { type: "delete", name: "Delete", icon: deleteIcon },
];

const viewDropDownConfig = [
  { type: "edit", name: "View", icon: editIcon },
  { type: "delete", name: "Delete", icon: deleteIcon },
];

export const TableBuilder = (props) => {
  // console.log(props);
  /** when user clicks edit or delete flight */
  const manageAction = (params) => {
    let { type, id } = params;
    if (type === "edit") {
      if (props.editContact) {
        props.editContact();
      }
      props.onEditItem({ id });
    } else if (type === "delete") props.onDeleteItem({ id });
  };

  /**drop down for user */
  const dropDownItems = (element) => {
    let { viewMode } = props;
    const dropConfig = viewMode ? viewDropDownConfig : dropDownConfig;

    return dropConfig.map((item) => {
      return (
        <a
          className="dropdown-item"
          onClick={() =>
            manageAction({
              type: item.type,
              id: element.id,
            })
          }
        >
          {item.icon}
          {item.name}
        </a>
      );
    });
  };

  /**dropdown block */
  const dropDownBlock = (element) => {
    return (
      <td className="text-ceter">
        <div className="dropdown text-center">
          <button
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              alt="showoptions"
              src={actionIcon}
              className="m-auto d-block"
            />
          </button>
          <div className="dropdown-menu" aria-labelledby="#dropdownMenuButton">
            {dropDownItems(element)}
          </div>
        </div>
      </td>
    );
  };
  const dateFormat = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };
  const renderButtonBlock = (element) => {
    let { id } = element;
    let { clickEvent, editButton, deleteButton } = props;
    return (
      <>
        {/* only edit button needed */}
        {editButton && (
          <td>
            <button
              onClick={() => {
                clickEvent();
                manageAction({
                  type: "edit",
                  id,
                });
              }}
            >
              Edit
            </button>
          </td>
        )}
        {/* delete button needed */}
        {deleteButton && (
          <td>
            <button
              className="ml-0"
              onClick={() => {
                clickEvent();
                manageAction({
                  type: "delete",
                  id,
                });
              }}
            >
              Delete
            </button>
          </td>
        )}
      </>
    );
  };
  const tableGenerator = () => {
    const { tableItemList, disableDropDown, noDropDown, onViewItem } = props;
    if (tableItemList && tableItemList.length) {
      const tableRows = tableItemList.map((element) => {
        const { id } = element;

        if (onViewItem) {
          return (
            <tr key={id} role="button" className="cursor">
              {tableRow(element)}
            </tr>
          );
        }
        return (
          <tr key={id}>
            {tableRow(element)}
          </tr>
        );
      });
      return tableWrapper(tableRows);
    } else return props.emptyState;
  };
  const tableWrapper = (tableRows) => {
    const { tableConfig, className } = props;
    return (
      <table className={className || "table d-op-table"}>
        <thead>
          <tr>
            {tableConfig.rows.map((item, index) => {
              return (
                <th
                  key={item.id}
                  className={
                    index === tableConfig.rows.length - 1 ? "text-center" : ""
                  }
                >
                  {item.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    );
  };
  const tableRow = (element) => {
    const { disableDropDown, noDropDown, onViewItem , clickEvent} = props;
    const { name, number, date, id, location, Status} = element;
    const clazz = Status === "CONNECTED"? "Connected" : Status === "REMOVED" ? "Rejected" : "Pending"

    if (onViewItem) {
      return (
        <>
          <td onClick={() => { onViewItem({ id })}} title={name}>{name}</td>
          <td onClick={() => { onViewItem({ id })}} title={number} className="simple class">
            {number && number.length ? abbreviate(number, 12) : "Nil"}
          </td>
          <td onClick={() => { onViewItem({ id })}}>{dateFormat(date)}</td>
          {/* when drop down or button is needed */}
          {!noDropDown
            ? !disableDropDown
              ? dropDownBlock(element)
              : renderButtonBlock(element)
            : []}
        </>)
      }
      return (
        <>
          <td title={name}>{name}</td>
          {props.org? 
          <>
            <td title={location} className="simple class">
              {dateFormat(location)}
            </td> 
            <td  title={Status} className={`${clazz}`}>{Status.toLowerCase()}</td>
            <td style={{width:'au', marginLeft:"13px"}}>
              <button
                style={{borderRadius: "2px", border:" solid 0.8px #3e3f42", backgroundColor: "#2c2d30", color:'#fff' , textAlign:'center'}}
                onClick={() => {
                  manageAction({
                    type: "edit",
                    id,
                  });
                }}
              >
                Edit
              </button>
            </td>
          </>
          :
          <>
            <td title={number} className="simple class">
              {number && number.length ? abbreviate(number, 12) : "Nil"}
            </td>
            <td>{dateFormat(date)}</td>
            {!noDropDown
            ? !disableDropDown
              ? dropDownBlock(element)
              : renderButtonBlock(element)
            : []}
          </>
          }
          {/* when drop down or button is needed */}
          
        </>)
    }
    
  return tableGenerator();
};
