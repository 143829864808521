/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import history from "../history";
import { headerConst } from "../../constants/text-constants";
import { CustomButton } from "../commons/buttons";
import {
  APP_BASE_NAME,
  minimumScreenRatio,
} from "../../constants/configurations";
import dots from "../../assets/images/more-vertical-white.svg";
import { checkIfUserAuthorized } from "../../utils/checkAuth";
/**icons */
import logo from "../../assets/images/samscloud-white.svg";
import GlobalSearchBar from "./searchBar";


const { selfReportText, accessRiskText, loginText, logoutText } = headerConst;

export default class NavButtons extends Component {
  constructor(props) {
    super(props);
    this.state = { showDots: false, showNav: false };
  }
  toggleState = (name) => {
    let stateItem = this.state[name];
    this.setState({
      [name]: !stateItem,
    });
  };
  isNavActive = () => {
    let { showNav } = this.state;
    if (showNav) return "";
    else return "d-none";
  };
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    this.setState({ showDots: window.innerWidth <= minimumScreenRatio });
  }
  userZipSearch = (event)=>{
    if (event.key === "Enter") {
    this.toggleState('showNav')
    this.props.onZipSearch(event)
    }
  }
  changeCurrentActiveRoute = (item) => {
    let { route } = item;
    let { showUnauthModal } = this.props;

    this.props.handleChange({ name: "activeComponent", route });
    if (item.label === logoutText) {
      localStorage.clear();
    }
    if (item.label === "Logout") {
      window.location.href = route;
    }
    // else if(route === '/accessrisk'){
    //   window.location.href = route;
    // }
    else if (history.location.pathname !== route) {
      if (route === "/accessrisk") {
        localStorage.clear();
        this.props.handleChangeAccessRisk({
          name: "localLocationList",
          value: [],
        });
      }
      history.push({ pathname: route });
    }
    /**only true when the user is not auth and clicks the access risk button */
    if (item.showModals)
      this.props.handleChangeLatestreport({
        name: "showUnauthModal",
        value: !showUnauthModal,
      });
  };
  hightLightButton = (active) => {
    if (active) return "";
    else return "as-risk";
  };
  renderButtons = () => {
    let { buttonConf } = this.props.reducer;
    return buttonConf.map((item) => {
      if (item.showButton) return <CustomButton {...this.getConfigs(item)} />;
    });
  };
  getConfigs = (item) => {
    return {

            className: `Gilroy-Medium let-spac-5 mr-2 slf-rpt ${item.label === selfReportText ? "" : "as-risk"}

       ${item.label === loginText ? "login" : ""} border-0 rounded`,
      clickEvent: () => this.changeCurrentActiveRoute(item),
      text: item.label,
    };
  };

  renderMobileViewButtons = () => {
    let { buttonConf } = this.props.reducer;
    return buttonConf.map((item) => {
      if (item.showButton)
        return (
          <button
            onClick={() => {
              this.changeCurrentActiveRoute(item);
              this.toggleState("showNav");
            }}
            className="w-100 bg-transparent text-left text-white border-top-0 border-right-0 border-left-0"
          >
            {item.label}
          </button>
        );
    });
  };
  renderMobileViewNav = () => {
    return (
      <div className="pull-right rep-drp">
        <div className="hamburgger" onClick={() => this.toggleState("showNav")}>
          <div className="bar1"></div>
          {/* <div className="bar2"></div> */}
          <div className="bar3"></div>
        </div>
        <div className={`resp-menu-bar-sec ${this.isNavActive()}`}>
          <div className="res-logo pull-left" onClick={(e) => history.push({ pathname: `/` })}>
            <div className="logo-wrapper"></div>
          </div>
          <div
            className="res-close pull-right"
            onClick={() => this.toggleState("showNav")}
          >
            <div className="bar1 bar4"></div>
            <div className="bar2 bar5"></div>
            <div className="bar3 bar6"></div>
          </div>
          <div className="res-menu-sapcing w-100 pull-left">
            <GlobalSearchBar
              {...this.props}
              onUserSearchZip={this.userZipSearch}
              className={
                "search-wrapper res-search-wrapper position-relative w-100"
              }
            />
            {/* <div className="search-wrapper res-search-wrapper position-relative w-100">
              <input
                type="text"
                placeholder="Search loaction or zip code..."
                className="w-100 border-0 rounded"
              />
              <span className="srch-icn">
                <i className="fa fa-search"></i>
              </span>
            </div> */}
          </div>
          <div className="w-100 fnt-16 mt-1 res-menu-bar">
            {this.renderMobileViewButtons()}
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        {this.state.showDots ? (
          this.renderMobileViewNav()
        ) : (
          <div className="pull-right fnt-14 hrd-right-btn">{this.renderButtons()}</div>
        )}
      </React.Fragment>
    );
  }
}
