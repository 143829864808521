import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  LIST_COVID_STATEWISE_STATUS_SUCCESS,
  LIST_COVID_STATEWISE_STATUS_FAILED,
  LIST_COVID_STATEWISE_STATUS_STARTED
} from "../../../../constants/action-types";
import { GET_STATE_STATUS_URL } from "../../../../constants/configurations";

export const listCovidStatewiseDetails = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GET_STATE_STATUS_URL(body.country),
        started: LIST_COVID_STATEWISE_STATUS_STARTED,
        success: LIST_COVID_STATEWISE_STATUS_SUCCESS,
        failure: LIST_COVID_STATEWISE_STATUS_FAILED
      },
      callBack
    );
  };
};
