import React, { useEffect } from "react";
import { CustomButton } from "../buttons";
import UserAddedVaccineBlock from "./userAddedVaccineBlock";
import { AddVaccineLocationModal } from "./addVaccineLocationModal";
import { AddVaccineViewModal } from "./addVaccineViewModal";
import { latestReportConst } from "../../../constants/text-constants";
import { submitText, trashIcon } from "../../../constants/text-constants";
import { BuildFormHandler } from "../BuildFields";
import { Overlay, Popover } from "react-bootstrap";
import { TextInput } from "../textField";

export const AVBody = (props) => {

  const onRemoveVaccineImage = (params) => {
    props.handleFormChange({
      parentName: "addVaccineForm",
      name: 'file_upload',
      value: 'None'
    });
  };

  const validateField = () => {
    let { vaccineFormConfig } = props;
    const location = vaccineFormConfig.find(x => x.name === 'location');

    if (!location.isValid) {
      return "border-red";
    }

    return false;
  };

  // useEffect(() => {
  //   let { addVaccineForm } = props;

  //   if (addVaccineForm.file_upload instanceof Blob) {
  //       alert(123)
  //   }
  //   console.log('ouit', addVaccineForm);


  //   let { vaccine_id } = props.selfReportVariables
  //   let { listUserVaccines } = props.reducer


  //   if (vaccine_id && listUserVaccines.list.length) {

  //     props.onEditVaccine({ id: vaccine_id })
  //   }
  // }, []);

  const userInputBlock = () => {
    let { vaccineFormConfig, addVaccineForm, toggleLocationModal, toggleViewModal, isDisabledVaccineSubmitBtn } = props;
    let { editVaccineForm, vaccineFormMode } = props.reducer;
    const { locationText } = latestReportConst.addRecordTexts;

    const notDisabledBtnClass = !isDisabledVaccineSubmitBtn ? 'btn-submit' : '';

    return (
      <div className="col-sm-5">
        <div className="row">
          <div className="col-12">
            <label>Clinic or Site Vaccinated</label>
            <input
              className={"form-control h-35 " + `${validateField()}`}
              type="text"
              placeholder={locationText}
              // value={vaccineFormMode === "add" ? (addVaccineForm.location !== '' ? addVaccineForm.location : '')
              //   :(editVaccineForm.location !== '' ? editVaccineForm.location : '')}
              onChange={toggleLocationModal}
            ></input>
          </div>
          <BuildFormHandler
            formDefinition={vaccineFormConfig}
            handleFormChange={onRemoveVaccineImage}
            variables={vaccineFormMode === "add" ? addVaccineForm : editVaccineForm}
            {...props}
          />
        </div>
        <div className="text-right mt-1 mb-1">
              <button className={"add-record-btn w-100 " + `${notDisabledBtnClass}`} onClick={props.submitAddVaccine}>
                {vaccineFormMode === "add" ? "Add Record" : "Update Record"}
              </button>
        </div>
        <AddVaccineLocationModal {...props} />
      </div>
    );
  };
  let { vaccineFormConfig, addVaccineForm } = props;
  let { editVaccineForm } = props.reducer;

  return (
    <div className="modal-body">
      <div className="col-sm-12">
        <div className="row">
          {userInputBlock()}
          <UserAddedVaccineBlock {...props} />
        </div>

        <div className="d-flex justify-content-center mt-3">
            { addVaccineForm && addVaccineForm.file_upload && addVaccineForm.file_upload instanceof Blob || editVaccineForm && editVaccineForm.file_upload && editVaccineForm.file_upload instanceof Blob ? (
              <img src={URL.createObjectURL(addVaccineForm.file_upload || editVaccineForm.file_upload)} style={{ maxWidth: "380px"}} alt="dummy" width="100%" height="100%" />) : ''}
               {editVaccineForm && editVaccineForm.file_upload && editVaccineForm.file_upload !== 'None' && !(editVaccineForm.file_upload instanceof Blob) ? (
              <img src={editVaccineForm.file_upload} style={{ maxWidth: "380px"}} alt="dummy" width="100%" height="100%" />) : ''}
        </div>
        <div className="d-flex justify-content-end mt-1">
            {addVaccineForm && addVaccineForm.file_upload && addVaccineForm.file_upload !== 'None' || editVaccineForm && editVaccineForm.file_upload && editVaccineForm.file_upload !== 'None' ? (
              <button class="btn modal-remove-btn fnt-14 SFProText-Regular" onClick={onRemoveVaccineImage}>
              <span className="modal-remove-icon">{trashIcon}</span> Remove
            </button>) : ''}
        </div>

        {/* <button class="btn btn-block btn-submit fnt-14 SFProText-Regular" onClick={props.closeModal}>
          Submit
        </button> */}
        {/* <button
          type="button"
          className="btn btn-block btn-submit fnt-14 mt-4"
          data-container="body"
          data-toggle="popover"
          data-placement="left"
          data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."
          onClick={props.closeModal}
          >
          {submitText}
        </button>*/}
      </div>
      <AddVaccineViewModal {...props} />
    </div>
  );
};
