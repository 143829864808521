import React, { Component } from "react";
import { ModalWindow } from "../modal";
import { ALheader } from "./ALHeader";
import ALBody from "./ALBody";
import { toastNotification } from "../toastNotification";
import moment from "moment";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { CustomButton } from "../../commons/buttons";
import { CommonModalHeader } from "../ModalHeader";

export default class AddLocationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,
      clear: false,
    };
  }

  componentDidMount() {
    if (!this.props.unAuthorizedUser) this.props.getAllTravelLocation();
  }
  componentWillUnmount = () => {
    this.props.handleChange({ type: "reset", formType: "location" });
  };

  onEditLocation = (params) => {
    let { id, location } = params;
    let { unAuthorizedUser } = this.props;
    if (unAuthorizedUser) {
      this.props.handleChange({
        mode: "edit",
        editType: "localLocationList",
        location,
        formType: "location",
      });
    } else {
      this.props.handleChange({ mode: "edit", id, formType: "location" });
    }
  };

  onHideLocation = (params) => {
    let { unAuthorizedUser } = this.props;
    if (unAuthorizedUser) {
      return false
    }

    const formBody= { ...params, is_hidden: true }
   
    this.props.editUserLocation(formBody, (res) => {
      if (res.status === 200) {
        toastNotification({
          msg: "Hide Location Success",
          type: "success",
        });
        this.props.handleChange({ type: "reset", formType: "location" });
        this.setState({ clear: true });
        this.props.getAllTravelLocation();
        this.props.getUserInfoAction();
      } else {
        console.log("develop");
        toastNotification({
          msg: "Hide Location Failed",
          type: "error",
        });
      }
    });
  };

  onDeleteLocation = (params) => {
    let { unAuthorizedUser } = this.props;
    let { id } = params;
    if (unAuthorizedUser) {
      this.props.handleChange({
        mode: "delete",
        deleteType: "localLocationList",
        id,
        formType: "location",
      });
    } else {
      this.props.deleteUserLocation(id, (res) => {
        if (res.status === 204) {
          toastNotification({
            msg: "Succesfully Deleted Location ",
            type: "success",
          });
          this.props.handleChange({ type: "reset", formType: "location" });
          this.props.getUserInfoAction();
          this.props.getAllTravelLocation();
        } else {
          toastNotification({
            msg: "Failed to  Deleted Location ",
            type: "error",
          });
        }
      });
    }
  };

  handleFormChangedLocation = (params) => {
    let { locationFormMode } = this.props.reducer;
    const { name, value } = params;
    let parentName = "";
    if (locationFormMode === "add") {
      parentName = "addLocationForm";
    } else if (locationFormMode === "edit") {
      parentName = "editLocationForm";
    }
    this.props.handleChange({ parentName, name, value });
  };

  addNewLocationApiCall = (mergedLocation) => {
    let { addLocationForm } = this.props.reducer;

    if (addLocationForm.location !== "") {
      this.props.newUserLocation(mergedLocation, (res) => {
        if (res.status === 201) {
          toastNotification({
            msg: "Added Location Successfully",
            type: "success",
          });
          this.props.handleChange({ type: "reset", formType: "location" });
          this.setState({ clear: true });
          this.props.getUserInfoAction();
          this.props.getAllTravelLocation();
        } else {
          // if (res.data)
          toastNotification({
            msg: `Added Location Failed , ${
              res.data && res.data.location && res.data.location[0]
            }`,
            type: "error",
          });
        }
      });
    } else {
      toastNotification({
        msg: "Location field is required",
        type: "error",
      });
    }
  };

  updateLocationApiCall = () => {
    let { UserLocation, editLocationForm } = this.props.reducer;
    let body = this.formBody();
    delete body.latitude;
    delete body.location;
    delete body.longitude;
    let mergedEditLocation = { ...UserLocation, ...body };
    if (typeof mergedEditLocation.from_time === "string") {
      mergedEditLocation.to_time =
        mergedEditLocation.from_time.split(":")[0] + ":05";
    } else {
      let date = mergedEditLocation.from_time;
      mergedEditLocation.from_time = moment(date).format("HH:mm:s");
      mergedEditLocation.to_time = moment(date)
        .add(5, "minute")
        .format("HH:mm:s");
    }
    if (editLocationForm.location !== "") {
      this.props.editUserLocation(mergedEditLocation, (res) => {
        if (res.status === 200) {
          toastNotification({
            msg: "Update Location Success",
            type: "success",
          });
          this.props.handleChange({ type: "reset", formType: "location" });
          this.setState({ clear: true });
          this.props.getAllTravelLocation();
          this.props.getUserInfoAction();
        } else {
          console.log("develop");
          toastNotification({
            msg: "Update Location Failed",
            type: "error",
          });
        }
      });
    } else {
      toastNotification({
        msg: "Location field is required",
        type: "error",
      });
    }
  };

  submitAddLocation = () => {
    let {
      locationFormMode,
      UserLocation,
      addLocationForm,
      editLocationForm,
      localLocationList,
    } = this.props.reducer;
    let { unAuthorizedUser } = this.props;

    /**getting the current form variables to be submitted */
    let mergedLocation =
      locationFormMode === "add"
        ? { ...UserLocation, ...addLocationForm }
        : { ...UserLocation, ...editLocationForm };
    let date = mergedLocation.from_time || new Date();
    if (!mergedLocation.location_date.length)
      mergedLocation.location_date = apiDateFormat(new Date());
    mergedLocation.from_time = moment(date).format("HH:mm:s");
    mergedLocation.to_time = moment(date).add(5, "minute").format("HH:mm:s");
    /** calling api accordingly */
    if (locationFormMode === "add") {
      mergedLocation.id = localLocationList.length + 1;
      if (unAuthorizedUser) {
        this.props.handleChange({
          name: "localLocationList",
          type: "updateList",
          operation: "add",
          value: mergedLocation,
        });
        this.props.handleChange({ type: "reset", formType: "location" });
      } else this.addNewLocationApiCall(mergedLocation);
    } else if (locationFormMode === "edit") {
      // this.props.handleChange({ type: "reset", formType: "location" });
      if (unAuthorizedUser) {
        this.props.handleChange({
          name: "localLocationList",
          type: "updateList",
          operation: "edit",
          value: mergedLocation,
        });
      } else this.updateLocationApiCall();
    }
  };

  formBody = () => {
    let body = {};
    let {
      addLocationForm,
      locationFormMode,
      editLocationForm,
    } = this.props.reducer;
    let formObj =
      locationFormMode === "add" ? addLocationForm : editLocationForm;
    for (const key in formObj) {
      if (formObj.hasOwnProperty(key)) {
        const element = formObj[key];
        body[key] = element;
      }
    }
    return body;
  };

  confirmationModal = () => {
    let {
      locationFormMode,
      addLocationForm,
      editLocationForm,
    } = this.props.reducer;
    let data = locationFormMode === "add" ? addLocationForm : editLocationForm;
    if (data.location !== "") {
      this.setState({
        confirmationModal: true,
      });
    } else {
      this.props.toggleModal();
    }
  };

  onBacktoEdit = () => {
    this.setState({ confirmationModal: false });
  };

  submit = () => {
    this.setState({ confirmationModal: false }, () => {
      this.submitAddLocation();
    });
  };

  resetClear = () => {
    this.setState({
      clear: false,
    });
  };

  formatUnauthData = () => {
    let { localLocationList } = this.props.reducer;
    let value = [];
    let object = {};
    for (const key in localLocationList) {
      if (localLocationList.hasOwnProperty(key)) {
        const element = localLocationList[key];
        object = {
          ...element,
        };
        value.push(object);
      }
    }
    return value;
  };
  addUnauthlocations = () => {
    let apiBody = this.formatUnauthData();
    this.props.addUnauthuserLocation(apiBody, (res) => {
      if (res.status === 200) {
        localStorage.setItem("addedLocs", JSON.stringify(res.data));
        toastNotification({
          msg: "Location added succesfully",
          type: "success",
        });
        this.props.handleChange({ name: "showRiskDashboard", value: true });
        this.props.handleChange({ name: "showUnauthModal", value: false });
        this.props.handleChange({ type: "reset", formType: "location" });
      }
    });
  };
  render() {
    let { showModal, toggleModal } = this.props;
    let { confirmationModal } = this.state;
    return (
      <>
        <ModalWindow
          showModal={showModal}
          onModalClose={this.confirmationModal}
          heading={<ALheader toggleModal={this.confirmationModal} />}
          body={
            <ALBody
              addUnauthlocations={this.addUnauthlocations}
              handleFormChangedLocation={this.handleFormChangedLocation}
              submitAddLocation={this.submitAddLocation}
              onDeleteLocation={this.onDeleteLocation}
              onEditLocation={this.onEditLocation}
              onHideLocation={this.onHideLocation}
              close={this.confirmationModal}
              clear={this.state.clear}
              resetClear={this.resetClear}
              {...this.props}
            />
          }
        />
        {
          <ModalWindow
            showModal={confirmationModal}
            onModalClose={toggleModal}
            heading={
              <CommonModalHeader
                toggleHandler={this.onBacktoEdit}
                title={"Confirmation"}
              />
            }
            body={
              <div className="text-center">
                <h6 className="mt-3 modal-text">
                  Do you want to save the Changes? Please go back to save your
                  change!{" "}
                </h6>
                <div class="row mt-4 text-center">
                  <div class="col-sm-4">
                    <CustomButton
                      className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                      text="Submit"
                      clickEvent={this.submit}
                    ></CustomButton>
                  </div>
                  <div class="col-sm-4">
                    <CustomButton
                      className="btn btn-block btn-submit fnt-14 SFProText-Regular"
                      text="Don't Submit"
                      clickEvent={toggleModal}
                    ></CustomButton>
                  </div>
                  <div class="col-sm-4">
                    <CustomButton
                      text="Close"
                      className=" btn btn-block btn-submit fnt-14 SFProText-Regular"
                      clickEvent={this.onBacktoEdit}
                    ></CustomButton>
                  </div>
                </div>
              </div>
            }
          ></ModalWindow>
        }
      </>
    );
  }
}
