
import states from './usStateDataset';
const citiesFormat=(cities)=>{
        let citiesList = cities.map((iter, index)=>{
            return {id: index, city: iter};
        });
        return citiesList;
    }
export const stateCity=()=>{
    let stateList = Object.keys(states);
    let stateCity = stateList.map((iter, index)=>{
            return {id: index, state: iter, cities: citiesFormat(states[iter]) };
        });
        return stateCity;
    }




// import { CountryRegionData } from 'react-country-region-selector';

// const citiesFormat=(iter)=>{
//     let strSplit = iter.split('|');
//     let cities = strSplit.map((iter, index)=>{
//         return {id: index, city: iter.split('~')[0]};
//     });
//     return cities;
// }
// export const stateCity=()=>{
//     let stateCity = CountryRegionData.map((iter, index)=>{
//         return {id: index, state: iter[0], cities: citiesFormat(iter[2]) };
//     });
//     return stateCity;
// }