import React from "react";
import { CustomButton } from "../../commons/buttons";
import { latestReportConst, flightIcon, updateIcon } from "../../../constants/text-constants";
import history from '../../history';
import { APP_BASE_NAME } from "../../../constants/configurations";
const {
  updateStatusText,
  addFlight
} = latestReportConst.contentPaneTexts.summaryText;
export const UserActivityButtons = props => {
  return (
    <div className="ml-auto update-add-flight">
      <CustomButton
        className="main-dash-up-btn"
        clickEvent={()=>history.push(`${APP_BASE_NAME}/selfreport`)}
        text={
          <React.Fragment>
            {updateIcon} {updateStatusText}{" "}
          </React.Fragment>
        }
      />
      <CustomButton
        type="button"
        className="main-dash-up-btn main-dash-fly-btn"
        dataToggle="modal"
        dataTarget="#add-flight"
        clickEvent={props.toggleModal}
        text={
          <React.Fragment>
            {flightIcon} {addFlight}{" "}
          </React.Fragment>
        }
      />
    </div>
  );
};
