import React, { Component } from "react";
import { latestReportConst } from "../../../constants/text-constants";
import { CustomButton } from "../../commons/buttons";
import { toastNotification } from "../../commons/toastNotification";
import moment from "moment";
import { apiDateFormat } from "../../../utils/commonDateFormatter";
import { groupAddress } from "../../../utils/getRegionInfo";

let { header, subHeading } = latestReportConst;


export default class LRHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationval: ""
    };
  }

  generateAddress = (grouped) => {
    let address = "";
    for (const key in grouped) {
      if (grouped.hasOwnProperty(key)) {
        const element = grouped[key];
        if (element.length) {
          if (key === "country") address += element;
          else if (key !== "zip") address += `${element}, `;
        }
      }
    }
    return address;
  };


  addNewLocationApiCall = (mergedLocation) => {
    let { addLocationForm } = this.props.reducer;

    console.log('addLocationForm', addLocationForm);

    if (addLocationForm.location !== "") {
      this.props.newUserLocation(mergedLocation, (res) => {
        if (res.status === 201) {
          toastNotification({
            msg: "Added Location Successfully",
            type: "success",
          });
          this.props.handleChange({ type: "reset", formType: "location" });
          this.props.getUserInfoAction();
          this.props.getAllTravelLocation();
        } else {
          // if (res.data)
          toastNotification({
            msg: `Added Location Failed , ${res.data && res.data.location && res.data.location[0]
              }`,
            type: "error",
          });
        }
      });
    } else {
      toastNotification({
        msg: "Location field is required",
        type: "error",
      });
    }
  };

  mergedCurrentLocation = () => {
    let {
      UserLocation,
      addLocationForm,
      localLocationList,
    } = this.props.reducer;

    let mergedLocation = { ...addLocationForm, ...UserLocation };
    let date = mergedLocation.from_time || new Date();

    if (!mergedLocation.location_date.length)
      mergedLocation.location_date = apiDateFormat(new Date());
    mergedLocation.from_time = moment(date).format("HH:mm:s");
    mergedLocation.to_time = moment(date).add(5, "minute").format("HH:mm:s");

    mergedLocation.id = localLocationList.length + 1;

    this.props.handleChange({
      name: "addLocationForm",
      value: {
        from_time: mergedLocation.from_time,
        location: mergedLocation.location,
        location_date: mergedLocation.location_date,
        to_time: mergedLocation.to_time
      }
    });

    this.addNewLocationApiCall(mergedLocation);
  }

  createCurrentUserLocation = (latlng, results) => {
    let Country_Region = "";
    let City = "";
    let address = "";
    let Province_State = "";
    let grouped = groupAddress(results.address_components);
    let { province, locality, city, country } = grouped;
    Province_State = province;
    City = city || locality;
    Country_Region = country;
    address = this.generateAddress(grouped, City);

    console.log('address', results.place_id);

    this.props.handleChange({
      name: "UserLocation",
      value: {
        location: address,
        Country_Region,
        Province_State,
        City,
        latitude: latlng.lat,
        longitude: latlng.lng,
        place_tag: results.place_id
      },
    });

    this.mergedCurrentLocation();
  }

  geocodeLatLng = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();

    const latlng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.createCurrentUserLocation(latlng, results[0]);
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }

  reverseGeoLocation = () => {
    const positionTimer = navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        this.geocodeLatLng(latitude, longitude);
      },
      (err) => {
        toastNotification({
          msg: "Unable to determine current location",
          type: "error",
        });
        console.log(err);
      },
    )
  }

  render() {
    return (
      <div className="title-head Gilroy-Medium Recommendation d-flex justify-content-between">
        <div className="">
          <h4>{header}</h4>
          <p>{subHeading}</p>
        </div>
        <CustomButton text="Check In" clickEvent={this.reverseGeoLocation} />
      </div>
    );
  }
};