import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  GET_USER_TRAVEL_LOCATION_SUCCESS,
  GET_USER_TRAVEL_LOCATION_FAILED,
  GET_USER_TRAVEL_LOCATION_STARTED,
} from "../../../../constants/action-types";
import { GET_USER_LOCATIONS_URL } from "../../../../constants/configurations";

export const getAllTravelLocation = (body, callBack) => {
  return dispatch => {
    apiServiceHandler(
      {
        dispatch,
        body,
        methordType: "get",
        api: GET_USER_LOCATIONS_URL,
        started: GET_USER_TRAVEL_LOCATION_STARTED,
        success: GET_USER_TRAVEL_LOCATION_SUCCESS,
        failure: GET_USER_TRAVEL_LOCATION_FAILED
      },
      callBack
    );
  };
};
