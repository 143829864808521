import { mapStyles } from "./config";
import { defaultLatLng } from "../../constants/configurations";


const google = window.google;

var map = null;

export const drawLoadingMap = (props) => {
  let { mapId } = props;
  map = new window.google.maps.Map(document.getElementById(mapId), {
    center: defaultLatLng,
    zoom: 2,
    styles: mapStyles,
    zoomControl: true,
    zoomControlOptions: {
      style: google.maps.ZoomControlStyle.SMALL,
      position: google.maps.ControlPosition.TOP_RIGHT,
    },
    minZoom: 2,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,
  });
  map.setOptions({draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true});

};
