import { connect } from "react-redux";

/**importing actions */
import {
  loginAction,
  signupAction,
  otpAction,
  getGooglePlaceDetailsAction,
  forgotPasswordVerification,
  changePhoneNumberAction,
  resendOtpAction,
  changePasswordAction,
  forgotPasswordOtp,
} from "../../redux/actions";

/**importing components */
import { LogIn } from "./login";
import SignUp from "./signup";
import { OtpPage } from "./OtpPage/otpPage";
import { ForgotPassword } from "./ForgotPassword/forgotPassword";

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    reducer: state.homePageReducer,
  };
};

const apiActions = (dispatch) => {
  return {
    loginAction: (params, callback) => {
      dispatch(loginAction(params, callback));
    },
    signupAction: (params, callback) => {
      dispatch(signupAction(params, callback));
    },
    verifyForgotPassAction: (params, callback) => {
      dispatch(forgotPasswordVerification(params, callback));
    },
    otpAction: (params, callback) => {
      dispatch(otpAction(params, callback));
    },
    resendOtpAction: (params, callback) => {
      dispatch(resendOtpAction(params, callback));
    },
    forgotPasswordOtp: (params, callback) => {
      dispatch(forgotPasswordOtp(params, callback));
    },
    changePasswordAction: (params, callback) => {
      dispatch(changePasswordAction(params, callback));
    },
    changePhoneNumberAction: (params, callback) => {
      dispatch(changePhoneNumberAction(params, callback));
    },
    getGooglePlaceDetailsAction: (params, callback) => {
      dispatch(getGooglePlaceDetailsAction(params, callback));
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...apiActions(dispatch),
  };
};

export default {
  LogIn: connect(mapStateToProps, mapDispatchToProps)(LogIn),
  SignUp: connect(mapStateToProps, mapDispatchToProps)(SignUp),
  Otp: connect(mapStateToProps, mapDispatchToProps)(OtpPage),
  ForgotPassword: connect(mapStateToProps, mapDispatchToProps)(ForgotPassword),
};
