/**renders the user card in right pane of the latest report page */

/**importing react dep */
import React, { Component } from "react";

/**components */
import { CustomButton } from "../../commons/buttons";

/**constants */
import { latestReportConst, locationEmptyState, editIcon } from "../../../constants/text-constants";
import moment from "moment";
import { StatusLoader } from "../../commons/loader";

const {
    addText,
    viewText
} = latestReportConst.rightPaneTexts;

export default class UserCard extends Component {
    scoreBox = () => {
        let { label, counter, isDaysCount } = this.props;

        return (
            <div className="d-flex justify-content-center text-center score-b-inner">
                <div>
                    <h4>{label}</h4>
                    {
                        counter && counter > 0 ? (
                            <p>
                                {counter}<span className="score-card-days">{isDaysCount ? 'days' : ''} </span>
                            </p>) :
                            (<span className="score-card-none">N/A</span>)
                    }
                </div>
            </div>
        );
    };

    addModalButtonBlock = () => {
        const { clickEvent, type } = this.props;
        return (
            <div className="d-flex p-10 justify-content-end item-center">
                <CustomButton
                    type="button"
                    className="add-card-btn"
                    data-toggle="modal"
                    data-target="#loca2"
                    text={`+ ${addText}`}
                    clickEvent={clickEvent}
                />
            </div>
        );
    };

    addScoreLine = () => {
        const { lineLabel, viewEvent } = this.props;
        return (
            <div className="d-flex score-line justify-content-between">
                <div className="score-line-label">
                    {lineLabel}
                </div>
                <CustomButton
                    type="button"
                    className="btn-edit fnt-10"
                    data-toggle="modal"
                    data-target="#loca2"
                    text={viewText}
                    clickEvent={viewEvent}
                />
            </div>
        );
    }

    render() {
        const { updated } = this.props;

        return (
            <div className="col-xl-12 col-lg-4 col-md-6 col-sm-12 px-2 score-card pull-left">
                <div className="score-b element-bg">
                    {this.addModalButtonBlock()}
                    {this.scoreBox()}
                    {this.addScoreLine()}
                    <div className="update-box-d">
                        <span>Updated:</span>
                        {" "}{updated}
                    </div>
                </div>
            </div>
        );
    }
}
