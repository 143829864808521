import React, { Component } from "react";
import modalempty from "../../../assets/images/contact-empty.svg";
import { latestReportConst, contactsEmptyState } from "../../../constants/text-constants";
import { TableBuilder } from "../tableBuilder";
import { StatusLoader } from "../loader";

const { addedContactsText } = latestReportConst.addContactTexts;

export default class UserAddedContactBlock extends Component {
  renderLoader = () => {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };
  render() {
    let {
      addContactTableConf,
      addedContactList,
      onDeleteContact,
      editToDelete,
      onEditContact,
      editContact
    } = this.props;
    let updatedContactTableConf = JSON.parse( JSON.stringify( addContactTableConf ) );
    updatedContactTableConf.rows[1].name = "Phone No.";
    let alteredContactList = JSON.parse( JSON.stringify( addedContactList ) );
    alteredContactList = alteredContactList.map((item) => {
      item.number = item.phone_number;
      return item;
    });
    let { listUserContacts } = this.props.reducer;
    return (
      <div className="col-sm-7">
        <div className="modal-data-bg">
        <div className="d-flex d-op">
          <div>
            <img src={modalempty} alt={"contact-icon"} />
          </div>
          <h4>{addedContactsText}</h4>
          <h1>{addedContactList.length}</h1>
        </div>
        {listUserContacts.loading ? (
          <StatusLoader />
        ) : (
          <TableBuilder
            editToDelete={editToDelete}
            emptyState = {contactsEmptyState}
            onDeleteItem={onDeleteContact}
            tableItemList={alteredContactList}
            tableConfig={updatedContactTableConf}
            onEditItem={onEditContact}
            editContact={editContact}
          />
        )}
      </div>
      </div>
    );
  }
}
