import React, { Component } from "react";
import { ModalWindow } from "../../commons/modal";
import { CommonModalHeader } from "../../commons/ModalHeader";
import AddActivityForm from "./addActivityForm";

export default class AddActivity extends Component {
  componentDidMount() {
    this.props.listActivitiesAction();
  }

  /**component action to filter the activities list */
  filterActivityList = (name, filterPresent) => {
    let type = "";
    if (!filterPresent) type = "add";
    else type = "delete";
    this.props.activityFilterChange({
      name,
      type,
    });
  };
  /**check if the filter is selected */
  checkIfSelected = (name) => {
    let { activityFilter } = this.props.reducer;
    let filterPresent = activityFilter.includes(name);
    return { filterPresent };
  };

  listActivities = () => {
    let { activityListConf } = this.props.reducer;
    return activityListConf.map((elem) => {
      let { className, label, count, name } = elem;
      let { filterPresent } = this.checkIfSelected(name);
      return (
        <button
          style={{ outline: "none" }}
          className={`map-cmn-btn  ${className} bg-white mr-2 ${
            filterPresent ? "map-cmn-btn-active" : ""
          }`}
          onClick={() => this.filterActivityList(name, filterPresent)}
          type="button"
        >
          <span className="txt-drk">{label}</span>
          <span className="pull-right">{count}</span>
        </button>
      );
    });
  };
  toggleModal = () => {
    let { showActivityModal } = this.props.reducer;
    if (!showActivityModal) {
      this.props.handleChange({
        type: "reset",
        name: "addActivityForm",
      });
    }
    this.props.handleChange({
      name: "showActivityModal",
      value: !showActivityModal,
    });
  };
  render() {
    let { showActivityModal, listActivity } = this.props.reducer;
    let userId = localStorage.getItem("user_id");
    let { mobileView } = this.props;
    if (!listActivity.loading) {
      return (
        <>
          <div
            style={!mobileView?{position:'fixed'}:{}}
            className={`${
              !userId && mobileView ? "bottom-icn-width" : ""
            } map-bottom-button-wrapper Gilroy-Medium`}
          >
            {this.listActivities()}
            {userId && (
              <button
                className="map-bottom-add-location "
                onClick={this.toggleModal}
                type="button"
              ></button>
            )}
          </div>
          {showActivityModal && (
            <AddActivityForm {...this.props} toggleModal={this.toggleModal} />
          )}
        </>
      );
    } else return [];
  }
}
