import React, { Component } from "react";
import LRHeader from "./LRHeader/LRHeader";
import LRLeftPane from "./LRLeftBlock/LRLeftPane";
import LRRightPane from "./LRRightBlock/LRRightPane";
import LRcontentBlock from "./LRContentBlock/LRcontentBlock";
import GeoTracking from "../GeoTracking"

export default class LatestReports extends Component {
  componentDidMount() {
    this.props.listUserContactsAction();
    this.props.listFlightActions();
    this.props.listLocationAction()
    this.props.fetchUsersAction();
    this.props.getManufacturersListAction();
    this.props.getDosesListAction();
    this.props.listUserVaccinesAction();
    this.props.listUserTestingsAction();
    this.props.listOrganizationsForSelect()
    this.props.listSubscriptions()
  }

  contentWrapper = body => {
    return (
      <div className="content-wrapper content-resp-wrap">
        <div className="col-xl-12 col-lg-12 col-md-12">
          <div className="row "><div className="responsive-direction">{body}</div></div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <div className="body-wrapper2">
        <LRHeader {...this.props} />
        {this.contentWrapper(
          <React.Fragment>
            <LRLeftPane
              reducer={this.props.reducer}
              handleChange={this.props.handleChange}
              deleteUserLocation={this.props.deleteUserLocation}
              newUserLocation={this.props.newUserLocation}
              editUserLocation={this.props.editUserLocation}
              listLocationAction={this.props.listLocationAction}
              getLocationAction={this.props.addLocationAction}
              getAllTravelLocation={this.props.getAllTravelLocation}
              getUserInfoAction={this.props.getUserInfoAction}
            />
            <LRcontentBlock {...this.props} />
            <LRRightPane
              getUserInfoAction={this.props.getUserInfoAction}
              listUserVaccinesAction={this.props.listUserVaccinesAction}
              listUserTestingsAction={this.props.listUserTestingsAction}
              {...this.props} />
          </React.Fragment>
        )}
        <GeoTracking />
      </div>
    );
  }
}
