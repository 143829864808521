import moment from "moment";
import { abbreviate } from "../../utils/abbreviate";
import { formatNumber } from "../../utils/numberFormatters";
import React from "react";
import "./style.css";
/** set of svg icons */
import {
  comfirmedSvg,
  financialSupply,
  vaccine,
  foodSupply,
  supply,
  healthIcon,
  calenderIcon,
  durationIcon,
  timeIcon,
  iphoneSvg,
  addressIcon,
} from "./infoWindowIcons/infoWindowIcons";
import {
  commonDateFormtter,
  differenceInDate,
} from "../../utils/commonDateFormatter";

const renderIcons = (type) => {
  switch (type) {
    case "foodAid":
      return foodSupply;
    case "supplies":
      return supply;
    case "financialHelp":
      return financialSupply;
    case "testCenter":
      return healthIcon;
    case "vaccine":
      return vaccine;
    default:
      return [];
  }
};

const removeMinutes = (time) => {
  let res = time.replace(":00", "");
  return res;
};
const formatTime = (params) => {
  let { to_time, from_time } = params;
  if (to_time && from_time)
    return `${removeMinutes(from_time)}-${removeMinutes(to_time)}`;
  else return emptyCondition();
};
const emptyCondition = () => {
  return "Nil";
};
export const activityPopUp = (params) => {
  let {
    location,
    address,
    description,
    activity,
    type,
    from_date,
    to_date,
    phone_number,
    name,
    user,
    confirmed,
  } = params;
  let userId = localStorage.getItem("user_id");
  var data = JSON.stringify(params).replace(/"/g, "'");
  let duration = differenceInDate({
    from_date,
    to_date,
    interval: "days",
  });
  let body = {
    html: `<div id="content">
        <div id="bodyContent">
        <div class="popup_container">
        <div class="unconfirmed Gilroy-Semibold">${comfirmedSvg}${
      confirmed ? "Confirmed" : "Unconfirmed"
    }
        ${
          user === Number(userId)
            ? `<button class="text-center btn popup-map Gilroy-Semibold" 
          onclick="removeActivty(${data})">Remove</button>`
            : ``
        }</div>
        <div class="map-pop-content-header pull-left w-100 pt-3">
            ${renderIcons(name)}
            <div class="pull-left">
                <h6 class="mb-1 Gilroy-Medium" title="${location}">${abbreviate(
      location,
      20
    )}</h6>
                <p class="m-0 Gilroy-Medium address-width" title="${address}">${address}</p>
            </div>
            <p class="map-popup-para w-100 pull-left mt-2 mb-2 Gilroy-Medium" title="${description}">${abbreviate(
      description,
      80
    )}.</p>
            ${
              activity
                ? `<button class="map-tst pull-left mr-2 Gilroy-Medium">${activity}</button>`
                : ""
            }
            ${
              type
                ? `<button class="map-tst map-asst pull-left Gilroy-Medium">${type}</button>`
                : ""
            }
            <div class="map-popup-btm-section w-100 pull-left">
                <div class="map-popup-btm-content w-100 pull-left">
                    ${calenderIcon}
                    <div class="pull-left">
                        <h6 class="mb-1 Gilroy-Semibold">Event Starts</h6>
                        <p class="m-0 Gilroy-Medium">${
                          from_date
                            ? commonDateFormtter(from_date)
                            : emptyCondition()
                        }</p>
                    </div>
                </div>
                <div class="map-popup-btm-content w-100 pull-left">
                    ${durationIcon}
                    <div class="pull-left">
                        <h6 class="mb-1 Gilroy-Semibold">Duration</h6>
                        <p class="m-0 Gilroy-Medium">${
                          duration === 0 ? emptyCondition() : `${duration} Days`
                        }</p>
                    </div>
                </div>
                <div class="map-popup-btm-content w-100 pull-left">
                    ${timeIcon}
                    <div class="pull-left">
                        <h6 class="mb-1 Gilroy-Semibold">Time</h6>
                        <p class="m-0 Gilroy-Medium">${formatTime(params)}</p>
                    </div>
                </div>
                <div class="map-popup-btm-content iphone w-100 pull-left">
                    ${iphoneSvg}
                    <div class="pull-left ml-12">
                        <h6 class="mb-1 Gilroy-Semibold">Phone</h6>
                        <p class="m-0 Gilroy-Medium">${
                          phone_number || emptyCondition()
                        }</p>
                    </div>
                </div>
                <div class="map-popup-btm-content w-100 pull-left">
                    ${addressIcon}
                    <div class="pull-left">
                        <h6 class="mb-1 Gilroy-Semibold">Address (Permanent)</h6>
                        <p class="m-0 Gilroy-Medium" title="${address}">${abbreviate(
      address,
      40
    )}</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
      </div>`,
  };
  return body;
};

export const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const mapStyles3 = [
  {
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const mapStyles2 = [
  {
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export const popUpTemplate = (params) => {
  let { info } = params;
  let body = {
    html: `<div id="content">
    <div id="bodyContent">
    <div class="Exposure-locations mb-0 bg-white rounded position-relative p-0">
    <div class="d-flex align-items-stretch item-center">
      <div class="user-int bg-clr-loc covid-map-pop">
        ${abbreviate(info.name, 1, true)}  
      </div>
      <div class="Exposure-txt text-dark covid-map-padding">
        <h4 title=${info.name}>${abbreviate(info.name, 10)}</h4>
        <p class="add-wdth">${info.name}</p>
        <h6 class="fnt-12">First Exposure : ${moment(
          info.first_exposure_date
        ).format("L")}</h6>
        <p class="nav-p fnt-12 mb-2">Last Report Date : ${moment(
          info.latest_exposure_date
        ).fromNow()}</p>
        <h6 class="text-uppercase nav-p place">${info.Country_Region}</h6>
      </div>
      <div class="Exposure-location-info">
        <h4 class="clr-blue">Store</h4>
        <h6 class="text-dark" id="test">
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 8 8"><defs><style>.a{fill:#c23934;}.b{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;stroke-width:1.2px;}</style></defs><circle class="a" cx="4" cy="4" r="4"/><path class="b" d="M.2,0V1.6" transform="translate(3.8 2.4)"/><path class="b" d="M.2.2h0" transform="translate(3.802 5.4)"/></svg>
        <span class="cor-popup"> ${formatNumber(
          info.exposure
        )} Report</span></h6>
      </div>
    </div>
    </div>
    </div>`,
  };
  return body;
};
