import { connect } from "react-redux";

/**importing actions */
import {
  handleChangeHomePage,
  componentActionHome,
  listCovidDetailsActions,
  listCovidStatewiseDetails,
  getRegionWithZipAction,
  changeSelectedCountry,
  listGlobalEffectsAction,
  getReportedLocations,
  getReportedLocationWithZip,
  getCityListWithStateAction,
  listCountryWiseDetails,
  getGooglePlaceDetailsAction,
  fetchUsersAction,
  getUserInfoAction,
  globalCountyWiseInfo,
  getStateLocation,
  getUsaZoneTestLab,
  newUserLocation,
  deleteUserLocation,
  editUserLocation,
  listLocationAction,
  getAllTravelLocation,
  handleChangeLR,
  addLocationAction,
  addUnauthuserLocation,
  getUserLastUpdate,
  deleteActivity,
  listActivitiesAction,
} from "../../redux/actions";

/**importing components */
import Home from "./home";

// const Counter = ...

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    reducer: state.homePageReducer,
    latestReportReducer: state.latestReportReducer,
  };
};

const componentActions = (dispatch) => {
  return {
    handleChange: (params) => {
      dispatch(handleChangeHomePage(params));
    },
    componentActionHome: (params) => {
      dispatch(componentActionHome(params));
    },
    changeSelectedCountry: (params) => {
      dispatch(changeSelectedCountry(params));
    },
    handleChangePopups: (params) => {
      dispatch(handleChangeLR(params));
    },
  };
};
const apiActions = (dispatch) => {
  return {
    addLocationAction: (params, next) => {
      dispatch(addLocationAction(params, next));
    },
    getUserLastUpdate: (params, next) => {
      dispatch(getUserLastUpdate(params, next));
    },
    deleteActivity: (params, next) => {
      dispatch(deleteActivity(params, next));
    },
    listActivitiesAction: (params, next) => {
      dispatch(listActivitiesAction(params, next));
    },
    addUnauthuserLocation: (params, next) => {
      dispatch(addUnauthuserLocation(params, next));
    },
    getAllTravelLocation: (params, next) => {
      dispatch(getAllTravelLocation(params, next));
    },
    listLocationAction: (params, next) => {
      dispatch(listLocationAction(params, next));
    },
    editUserLocation: (params, next) => {
      dispatch(editUserLocation(params, next));
    },
    deleteUserLocation: (params, next) => {
      dispatch(deleteUserLocation(params, next));
    },
    getStateLocation: (params, callback) => {
      dispatch(getStateLocation(params, callback));
    },
    newUserLocation: (params, next) => {
      dispatch(newUserLocation(params, next));
    },
    getUsaZoneTestLab: (params, callback) => {
      dispatch(getUsaZoneTestLab(params, callback));
    },
    listCovidDetailsActions: (params, callback) => {
      dispatch(listCovidDetailsActions(params, callback));
    },
    fetchUsersAction: (params, callback) => {
      dispatch(fetchUsersAction(params, callback));
    },
    getUserInfoAction: (params, callback) => {
      dispatch(getUserInfoAction(params, callback));
    },
    listCovidStatewiseDetails: (params, callback) => {
      dispatch(listCovidStatewiseDetails(params, callback));
    },
    globalCountyWiseInfo: (params, callback) => {
      dispatch(globalCountyWiseInfo(params, callback));
    },
    getRegionWithZipAction: (params, callback) => {
      dispatch(getRegionWithZipAction(params, callback));
    },
    listGlobalEffectsAction: (params, callback) => {
      dispatch(listGlobalEffectsAction(params, callback));
    },
    getReportedLocations: (params, callback) => {
      dispatch(getReportedLocations(params, callback));
    },
    getReportedLocationWithZip: (params, callback) => {
      dispatch(getReportedLocationWithZip(params, callback));
    },
    getCityListWithStateAction: (params, callback) => {
      dispatch(getCityListWithStateAction(params, callback));
    },
    getGooglePlaceDetailsAction: (params, callback) => {
      dispatch(getGooglePlaceDetailsAction(params, callback));
    },
    listCountryWiseDetails: (params, callback) => {
      dispatch(listCountryWiseDetails(params, callback));
    },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ...componentActions(dispatch),
    ...apiActions(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
