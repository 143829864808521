/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

export default class OtpRightPane extends Component {
  render() {
    return (
      <div className="col-md-4 pull-left">
        <div className="signup-right otp-right position-relative pull-left w-100">
          <div className="circle-wrapper position-relative m-auto d-table">
            <div className="circle pull-left"></div>
            <div className="circle circle-pad pull-left brd-white">
              <span className="cir-line"></span>
            </div>
            <div className="circle circle-pad pull-left"></div>
          </div>
          <div className="signup-rht-cont">
            <h6 className="text-center text-white">Verify your phone</h6>
            <p className="nav-p fnt-13 m-auto text-center">
              Help us keep your account safe by entering the security code we
              just sent to your phone.
            </p>
          </div>
          <span className="nav-p signip-rht-btm text-center fnt-13">
            Need help?{" "}
            <a href="" className="color">
              Contact Us
            </a>
          </span>
        </div>
      </div>
    );
  }
}
