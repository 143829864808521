import React, { Component } from "react";
import TimePicker from "rc-time-picker";
import moment from "moment";
const now = moment();
const format = "h:mm a";
export default class TimePickerCustom extends Component {
  render() {
    let { className, name, value, handleTimeChange } = this.props;
    return (
      <TimePicker
        showSecond={false}
        className={className}
        name={name}
        value={value ? value : now }
        onChange={(e) => {
          handleTimeChange({ value: e, name: name });
        }}
        format={format}
        use12Hours
        inputReadOnly
      />
    );
  }
}
