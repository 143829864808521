import { simpleApiCalls } from "../../../../services/simpleApiCalls";
import {
  GET_GOOGLE_PLACES_SUCCESS,
  GET_GOOGLE_PLACES_FAILED,
  GET_GOOGLE_PLACES_STARTED,
} from "../../../../constants/action-types";
import { GET_REGION_WITH_ZIP } from "../../../../constants/configurations";

export const getGooglePlaceDetailsAction = (body, callBack) => {
  return (dispatch) => {
    simpleApiCalls(
      {
        dispatch,
        body,
        methordType: "get",
        url: GET_REGION_WITH_ZIP(body.place),
        started: GET_GOOGLE_PLACES_STARTED,
        success: GET_GOOGLE_PLACES_SUCCESS,
        failure: GET_GOOGLE_PLACES_FAILED,
      },
      callBack
    );
  };
};
