import { apiServiceHandler } from "../../../../services/apiCallHandler";
import {
  ADD_USER_CONTACTS_SUCCESS,
  ADD_USER_CONTACTS_FAILED,
  ADD_USER_CONTACTS_STARTED
} from "../../../../constants/action-types";
import { ADD_USER_CONTACT_URL } from "../../../../constants/configurations";

export const addUserContactsAction = (body,callBack) => {
  return dispatch => {
    apiServiceHandler({
      dispatch,
      body,
      methordType: "post",
      api: ADD_USER_CONTACT_URL,
      started: ADD_USER_CONTACTS_STARTED,
      success: ADD_USER_CONTACTS_SUCCESS,
      failure: ADD_USER_CONTACTS_FAILED
    },callBack);
  };
};
