/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";

export default class SignupDropDown extends Component {

  // componentDidMount() {
  //   document.addEventListener("mousedown", this.handleClick, false);
  // }
  // componentWillUnmount() {
  //   document.removeEventListener("mousedown", this.handleClick, false);
  // }
  // handleClick = (e) => {
  //   if (!this.node.contains(e.target)) {
  //     this.setState({ showDrop: false });
  //   }
  // };

  generateList = () => {
    let { handleChange, optionsList, value } = this.props;
    if (optionsList.length) {
      let list = optionsList;
      list = list.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      if (list.length) {
        return list.map((item) => {
          return (
            <li
              className="mb-2"
              onClick={() => {
                handleChange({ name: item.name });
              }}
            >
              <span
                className="large com-drop sign-up-drp"
                data-value="option0"
                tabindex="-1"
              >
                {item.name}
              </span>
            </li>
          );
        });
      } else
        return <li className="mb-2 state-drp-no-opt">No options available</li>;
    } else return [];
  };

  render() {
    let { label, disabled, userTextChange, name, value,showDrop } = this.props;
    return (
      <div
        className="form-group position-relative w-100 mb-0"
        onClick={this.checkUserClick}
      >
        <label className="fnt-12 text-white">{label}</label>

        <input
          type={"text"}
          disabled={disabled}
          ref={(node) => (this.node = node)}
          value={value}
          onChange={(e) => {
            userTextChange(name, e.target.value);
          }}
          className="form-control self-inp position-relative text-left w-100"
          data-toggle="dropdown"
          aria-expanded="true"
        />
        <ul
          className="dropdown-menu self-inp2 text-white w-100 pt-0 pb-0 state-drpdwn"
          // style={showDrop ? { display: "block" } : {}}
        >
          {this.generateList()}
        </ul>
      </div>
    );
  }
}
