import { simpleApiCalls } from "../../../../services/simpleApiCalls";
import {
  GET_GOOGLE_ALL_STATE_LOCATION_SUCCESS,
  GET_GOOGLE_ALL_STATE_LOCATION_FAILED,
  GET_GOOGLE_ALL_STATE_LOCATION_STARTED,
} from "../../../../constants/action-types";


export const getUsaZoneTestLab = (body, callBack) => {
  return (dispatch) => {
    simpleApiCalls(
      {
        dispatch,
        body,
        methordType: "get",
        url: "https://api.samscloud.io/covid19/api/location/all-lab-locations/",
        started: "GET_GOOGLE_ALL_STATE_LOCATION_STARTED",
        success: "GET_GOOGLE_ALL_STATE_LOCATION_SUCCESS",
        failure: "GET_GOOGLE_ALL_STATE_LOCATION_FAILED",
      },
      callBack
    );
  };
};
