
const comfirmedSvg = `  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10">
<g fill="none" fill-rule="evenodd">
    <path d="M0 0h10v10H0V0zm4.894 4.781l-.046-.046.046.046z"/>
    <path fill="#FFF" fill-rule="nonzero" d="M5 2.708c.575 0 1.042.467 1.042 1.042 0 .306-.136.58-.346.77l1.512 1.513c.407-.775.709-1.58.709-2.283C7.917 2.14 6.61.833 5 .833c-.825 0-1.569.344-2.098.894L4.23 3.054c.19-.21.465-.346.771-.346zm1.823 4L4.896 4.781l-.046-.046L1.363 1.25l-.53.531 1.325 1.325c-.048.207-.075.421-.075.644C2.083 5.938 5 9.167 5 9.167s.696-.771 1.406-1.813L7.802 8.75l.531-.531-1.51-1.51z"/>
</g>
</svg>`;
const durationIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path fill="#8C8E8F" d="M6.22 0C2.785 0 0 2.785 0 6.22c0 3.435 2.785 6.22 6.22 6.22 3.435 0 6.22-2.785 6.22-6.22 0-1.65-.655-3.232-1.822-4.398C9.452.655 7.87 0 6.22 0zm0 11.196c-2.748 0-4.976-2.228-4.976-4.976 0-2.748 2.228-4.976 4.976-4.976 2.748 0 4.976 2.228 4.976 4.976 0 1.32-.524 2.585-1.457 3.519-.934.933-2.2 1.457-3.519 1.457zm.323-8.708c-.084-.003-.167.028-.228.087-.06.058-.095.14-.095.224v3.44l-2.388 2.4c-.063.06-.099.142-.099.228 0 .086.036.168.099.227 1.238 1.012 2.984 1.122 4.34.273 1.356-.848 2.02-2.467 1.652-4.023-.368-1.557-1.688-2.706-3.28-2.856z"/></svg>`;

const iphoneSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14">
<path fill="#8C8E8F" d="M6.91 0H1.883C.844 0 0 .844 0 1.884v10.05c0 1.04.844 1.884 1.884 1.884H6.91c1.04 0 1.884-.843 1.884-1.884V1.884C8.793.844 7.95 0 6.91 0zm.627 11.934c0 .347-.281.628-.628.628H1.884c-.347 0-.628-.281-.628-.628V1.884c0-.347.281-.628.628-.628s.628.281.628.628.282.628.628.628h2.513c.347 0 .628-.28.628-.628 0-.347.281-.628.628-.628s.628.281.628.628v10.05z"/>
</svg>`;
const addressIcon = ` <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14">
<path fill="#8C8E8F" d="M6.91 5.527c0 .763-.62 1.382-1.383 1.382S4.145 6.29 4.145 5.527c0-.763.62-1.382 1.382-1.382.763 0 1.382.62 1.382 1.382zM5.526 1.382c-2.29 0-4.145 1.856-4.145 4.145 0 1.935.815 2.584 1.948 3.455.953.644 1.712 1.534 2.197 2.577.488-1.041 1.247-1.931 2.197-2.577 1.133-.87 1.949-1.52 1.949-3.455 0-2.29-1.856-4.145-4.146-4.145m0-1.382c3.053 0 5.528 2.475 5.528 5.527 0 4.298-3.49 4.263-4.326 6.757l-.352 1.064c-.099.294-.381.486-.69.47h-.388c-.31.016-.592-.176-.69-.47l-.353-1.064C3.489 9.79 0 9.824 0 5.527 0 2.475 2.475 0 5.527 0z"/>
</svg>`;

const timeIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
<path fill="#8C8E8F" d="M6.14 0C2.749 0 0 2.749 0 6.14c0 3.391 2.749 6.14 6.14 6.14 3.391 0 6.14-2.749 6.14-6.14 0-1.628-.647-3.19-1.798-4.342C9.33.647 7.768 0 6.14 0zm0 11.052c-2.713 0-4.912-2.2-4.912-4.912 0-2.713 2.2-4.912 4.912-4.912 2.713 0 4.912 2.2 4.912 4.912 0 1.303-.517 2.552-1.439 3.473-.92.922-2.17 1.44-3.473 1.44zm-.134-4.75V3.146c0-.17-.138-.307-.307-.307h-.467c-.17 0-.307.137-.307.307V6.62c.003.08.036.157.092.215l2.456 2.456c.121.118.314.118.436 0l.325-.326c.118-.121.118-.314 0-.435L6.006 6.3z"/>
</svg>`;

const calenderIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
<path fill="#8C8E8F" d="M9.825 1.228H9.21V.307C9.21.137 9.073 0 8.904 0h-.615c-.17 0-.307.137-.307.307v.921H3.07V.307C3.07.137 2.933 0 2.763 0H2.15c-.17 0-.307.137-.307.307v.921h-.614C.55 1.228 0 1.778 0 2.456v7.983c0 .678.55 1.228 1.228 1.228h8.597c.678 0 1.228-.55 1.228-1.228V2.456c0-.678-.55-1.228-1.228-1.228zm0 9.21H1.228V3.685h8.597v6.755zM5.219 6.14h.614c.17 0 .307-.137.307-.307V5.22c0-.17-.137-.307-.307-.307H5.22c-.17 0-.307.138-.307.307v.614c0 .17.138.307.307.307zm2.456 0h.614c.17 0 .307-.137.307-.307V5.22c0-.17-.137-.307-.307-.307h-.614c-.17 0-.307.138-.307.307v.614c0 .17.138.307.307.307zm-4.912 0h.614c.17 0 .307-.137.307-.307V5.22c0-.17-.137-.307-.307-.307h-.614c-.17 0-.307.138-.307.307v.614c0 .17.138.307.307.307zM5.22 8.596h.614c.17 0 .307-.137.307-.307v-.614c0-.17-.137-.307-.307-.307H5.22c-.17 0-.307.138-.307.307v.614c0 .17.138.307.307.307zm-2.456 0h.614c.17 0 .307-.137.307-.307v-.614c0-.17-.137-.307-.307-.307h-.614c-.17 0-.307.138-.307.307v.614c0 .17.138.307.307.307z"/>
</svg>`;

const healthIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20">
<path fill="#0156FF" d="M8.929 0H.595C.266 0 0 .224 0 .5s.266.5.595.5h.595v16c0 1.656 1.599 3 3.572 3s3.571-1.344 3.571-3V1h.596c.329 0 .595-.224.595-.5S9.258 0 8.929 0zM7.143 16.429c0 1.312-1.068 2.38-2.381 2.38-1.313 0-2.381-1.068-2.381-2.38v-.834h2.38v-1.19h-2.38v-1.19h1.19v-1.191h-1.19v-1.19h2.38V9.642h-2.38V4.959c.552-.132 1.303-.149 2.05.35.985.655 1.943.71 2.712.575v10.545z"/>
</svg>`;

const foodSupply =  `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
<path fill="#0156FF" d="M19.668 4.663l-3.972 4.31-.016.015c-.664.654-1.527.98-2.392.98-.515 0-1.031-.116-1.504-.348l-3.405 3.404.25.25-6.144 6.145c-.249.25-.565.389-.89.42-.046.005-.093.007-.14.007-.023 0-.046 0-.07-.002-.139-.007-.278-.033-.411-.08l-.066-.025c-.175-.071-.34-.178-.482-.32-.142-.142-.248-.306-.32-.481l-.024-.066c-.047-.134-.074-.272-.08-.412-.004-.07-.002-.14.005-.21.03-.324.17-.64.42-.889l6.143-6.144.251.25 3.405-3.404c-.626-1.274-.41-2.856.649-3.915l.001.001L15.185.18c.133-.132.282-.179.41-.179s.236.045.289.098l-4.529 4.529h.001L15.884.1c.106.106.183.435-.08.699l-3.97 4.307.975.976 4.308-3.97c.133-.133.282-.18.41-.18s.236.046.288.1l-4.528 4.528 4.529-4.528c.106.106.183.434-.081.699l-3.97 4.307.977.977 4.308-3.97c.133-.132.282-.179.41-.179s.236.046.289.099L15.22 8.492l4.529-4.528c.106.106.183.435-.08.699zm-6.97 6.554l-.252.251-.692-.692-1.557 1.557.692.692-.25.25 6.144 6.144c.568.569 1.49.568 2.058 0 .569-.568.568-1.49 0-2.058l-6.144-6.144zm-5.887-.866l2.26-2.26-.693-.692L3.95 2.973c-.453-.454-1.749-1.443-2.303-.943v.001c-.969.97.345 3.856 2.934 6.445.736.736 1.496 1.369 2.229 1.875z"/>
</svg>`

const financialSupply = `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="19" viewBox="0 0 21 19">
<g fill="#0156FF">
    <path d="M20.614 11.692c-.999 1.949-1.973 3.873-2.972 5.846-.536-.267-1.047-.584-1.607-.803-.268-.098-.634-.147-.902-.073-1.948.609-3.897 1.266-5.821 1.9-1.12.365-2.144.243-3.167-.39-1.632-1.023-3.313-2.022-4.97-3.02-.194-.123-.389-.22-.56-.366-.633-.487-.803-1.291-.39-1.9.415-.633 1.194-.853 1.876-.463 1.364.78 2.729 1.56 4.068 2.412.78.487 1.51.609 2.363.243.707-.292 1.462-.487 2.217-.73.34-.122.78-.195.609-.707-.17-.487-.56-.292-.877-.195-.73.244-1.437.487-2.144.707-.804.243-1.534-.098-1.778-.829-.244-.73.146-1.461.926-1.73.536-.194 1.096-.316 1.632-.535.828-.341 1.632-.706 2.436-1.096 1.705-.877 3.434-1.072 5.188-.268 1.267.633 2.533 1.315 3.873 1.997zM6.73.244c-2.68 0-4.872 2.168-4.872 4.871 0 2.68 2.168 4.872 4.872 4.872 2.703 0 4.871-2.168 4.871-4.872C11.577 2.435 9.41.244 6.73.244zM8.946 6.26c-.048.366-.243.658-.536.902-.219.194-.462.34-.755.414-.097.024-.122.073-.122.146v.828H5.901v-.78c0-.121-.024-.17-.146-.219-.39-.122-.73-.34-.999-.682-.194-.243-.316-.536-.316-.852h1.608c.17.462.706.609 1.071.316.22-.17.22-.438.025-.609-.122-.121-.293-.146-.463-.17-.439 0-.877-.073-1.267-.292-.414-.244-.78-.536-.925-1.024-.171-.584.048-1.169.584-1.559.22-.146.463-.267.707-.365.073-.049.121-.073.121-.17v-.56h1.632v.608c0 .073.025.098.098.122.34.122.658.292.901.585.244.268.39.584.39.95H7.436c-.073 0-.122 0-.146-.098 0-.048-.025-.073-.049-.097-.22-.341-.852-.341-1.096-.025-.122.147-.097.366.049.488.146.146.34.195.536.195.584 0 1.12.146 1.583.511.487.414.706.853.633 1.437z"/>
    <path d="M6.73 10.23c-2.826 0-5.116-2.289-5.116-5.115C1.614 2.29 3.904 0 6.73 0c2.825 0 5.115 2.29 5.115 5.115 0 2.826-2.314 5.116-5.115 5.116zm0-9.743C4.172.487 2.1 2.557 2.1 5.115c0 2.558 2.07 4.629 4.629 4.629 2.557 0 4.628-2.07 4.628-4.629 0-2.557-2.095-4.628-4.628-4.628zM7.8 8.842H5.682V8.38v-.535c-.463-.147-.804-.39-1.096-.756-.244-.292-.365-.633-.365-.998v-.244h2.021l.05.146c.072.195.243.293.413.293.098 0 .195-.025.268-.098.049-.024.073-.073.073-.122 0-.048-.024-.097-.073-.121-.073-.073-.17-.098-.317-.098-.535 0-.998-.122-1.388-.317-.365-.194-.853-.535-1.047-1.169-.195-.682.073-1.388.682-1.827.195-.146.414-.243.633-.34l.097-.05v-.755h2.12v.78c.365.146.682.341.95.609.316.34.462.706.462 1.12v.22l-.267.024H7.41c-.292 0-.365-.195-.365-.292v-.025c-.048-.097-.195-.146-.34-.146-.147 0-.269.049-.342.122-.049.073-.049.122.025.195.073.073.194.122.365.122.682 0 1.242.195 1.73.56.56.438.779.974.706 1.608-.049.39-.244.73-.61 1.071-.218.195-.486.341-.779.463V8.842zM6.17 8.355h1.145v-.584c0-.195.098-.342.292-.39.244-.073.488-.22.658-.366.268-.243.414-.487.463-.755.073-.463-.097-.828-.512-1.145-.39-.316-.852-.462-1.437-.462-.195 0-.487-.025-.706-.268-.22-.22-.244-.56-.073-.804.146-.22.414-.341.73-.341.317 0 .61.146.756.365.024.024.048.049.048.098h1.145c-.048-.195-.146-.39-.316-.56-.22-.244-.488-.39-.804-.512-.17-.049-.268-.195-.268-.366v-.34H6.145v.316c0 .268-.17.365-.244.39l-.121.048c-.195.098-.39.171-.536.293-.463.34-.634.804-.512 1.29.098.366.341.634.804.878.317.17.706.268 1.145.268.243 0 .463.073.633.219.146.146.244.317.22.511 0 .195-.098.366-.269.488-.17.121-.365.195-.584.195-.341 0-.633-.171-.804-.463H4.683c.05.17.122.316.22.463.219.292.511.487.877.584.219.073.316.22.316.463.073.122.073.317.073.487zm1.267-4.677l.024.244v-.244h-.024z"/>
</g>
</svg>`

const supply = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17">
<path fill="#0156FF" d="M15.253 9.796c0-.315-.043-.629-.127-.932l-1.22-4.237c-.205-.73-.87-1.235-1.627-1.237H2.957c-.749.008-1.404.508-1.61 1.228L.127 8.855c-.085.306-.128.623-.127.94V16.1c0 .468.38.848.847.848h.848c.468 0 .847-.38.847-.848v-.847h10.169v.847c0 .468.38.848.847.848h.848c.468 0 .847-.38.847-.848V9.796zM2.77 5.025c.026-.09.11-.153.203-.153h9.322c.094 0 .177.063.203.153l.966 3.449H1.788l.983-3.449zm1.466 6.838c0 .468-.38.848-.847.848h-.848c-.468 0-.847-.38-.847-.848v-.847c0-.468.38-.847.847-.847h.848c.468 0 .847.379.847.847v.847zm9.321 0c0 .468-.38.848-.847.848h-.848c-.468 0-.847-.38-.847-.848v-.847c0-.468.38-.847.847-.847h.848c.468 0 .847.379.847.847v.847zm-3.39-10.168H5.085V.424c0-.234.19-.424.424-.424h4.237c.234 0 .424.19.424.424v1.27z"/>
</svg>
`

const vaccine = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
<path fill="#0156FF" d="M16.9 7.3l-1.4-1.4 2.1-2.1.7.7c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L16.9.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l.7.7-2.1 2.1-1.4-1.4c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l-6.8 6.8c-.9.9-1.1 2.3-.6 3.4l-.5.5c-.2.2-.2.4 0 .6L0 19.9l4.1-3.3c.2.2.4.2.6 0l.5-.5c1.1.5 2.5.4 3.4-.6l6.8-6.8c.4.4 1 .4 1.4 0 .5-.4.5-1 .1-1.4zm-9.6 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l4.7-4.7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.7 4.7z"/>
</svg>
`
export {
  comfirmedSvg,
  financialSupply,
  vaccine,
  foodSupply,
  supply,
  healthIcon,
  calenderIcon,
  durationIcon,
  timeIcon,
  iphoneSvg,
  addressIcon,
};
