import { combineReducers } from "redux";
import homePageReducer from "./HomePageReducer";
import selfReportReducer from "./selfReportReducer";
import latestReportReducer from "./latestReportReducer";
import globalHeaderReducer from "./globalReducer";
import accessRiskReducer from "./accessRiskReducer";

export default combineReducers({
  homePageReducer,
  selfReportReducer,
  latestReportReducer,
  globalHeaderReducer,
  accessRiskReducer,
});
