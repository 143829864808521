import React from "react";
import { ModalWindow } from "../modal";
import { ATVBody }  from "./ATVBody";
import { latestReportConst } from "../../../constants/text-constants";
import { CommonModalHeader } from "../ModalHeader";

const { viewTestingTitle } = latestReportConst.addRecordTexts;
export const AddTestingViewModal = props => {
  let { toggleViewModal, showTestingViewModal, showTesting, addedTestingList } = props;
  const viewItem = addedTestingList.find(x => x.id === showTesting)
  return (
    <ModalWindow
      showModal={showTestingViewModal}
      onModalClose={toggleViewModal}
      heading={
        <CommonModalHeader title={viewTestingTitle} toggleHandler={toggleViewModal} />
      }
      body={<ATVBody testing={viewItem} {...props}
      buttonText = 'Add Location' />}
    />
  );
};
